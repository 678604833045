// import db from "../../../config/firebase";
import { MAIL_FUNCTION_URL, WHATS_APP_NUMBER, GATEWAY_URL, FUNCTION_API_KEY } from '../../../config/siteConfig';
import firebaseServices from "../../../firebase";
import { nameIntials, getDateInUTC, isCallingEnable } from './Comman';
import $ from 'jquery';
const { Device } = require('twilio-client');
const SyncClient = require('twilio-sync');
var connectionObj = null;
var isCallStarted = false;
var isSmsSending = false;
var callData = {};
var currentChat = {};
var enablePopup = false;
const isLive = true;
const twilioApiUrl = isLive ? MAIL_FUNCTION_URL : "http://localhost:5000/reacttest-29b23/us-central1";

/// For Live 
export const loadCapabilityToken = (agent) => {
    let canCalling = isCallingEnable(agent);
    if (!canCalling) {
        return false;
    }
    var uuid = "LIVECHAT-" + agent.agentId + "-tw";
    //fetch(twilioApiUrl + "/twilio/token?dId=" + uuid, {
    fetch(GATEWAY_URL + "/token?dId=" + uuid, {
        method: "get",
        headers: {
            "X-API-Key" : FUNCTION_API_KEY,
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Origin": window.location.origin
        }
    })
        .then(res => res.json())
        .then(response => {
            const status = response.status ? response.status : "";
            if (status === "success") {
                const data = response.message ? response.message : {};

                /* For regenerate token Start */
                var syncClient = new SyncClient(data.token);
                syncClient.on('tokenAboutToExpire', async function () {
                    console.log("---------------   tokenAboutToExpire  ------------------");
                });
                syncClient.on('tokenExpired', async function () {
                    const refToken = await getRefreshedToken(uuid);
                    Device.updateToken(refToken);
                });
                /* For regenerate token End */

                // Setup Device
                Device.setup(data.token, {
                    allowIncomingWhileBusy: false,
                    enableRingingState: true,
                    debug: false,
                    sounds: {
                        incoming: 'https://d23i99usg1q4tk.cloudfront.net/widget/ringtone/incoming.mp3' ///DOMAIN_NAME + '/ringtones/incoming.mp3',
                        //outgoing: 'https://chat-staging.pbodev.info/ringtones/incoming.mp3',
                        // dtmf8: 'https://chat-staging.pbodev.info/ringtones/incoming.mp3'
                    }
                });
                Device.on('ready', function (device) {
                    console.log('Device Ready!');
                });
                Device.on('error', async function (error) {
                    console.log('Device Error: ' + error.message);
                    if (error.code === 31205 && error.message.indexOf("Token") !== -1) {
                        const refToken = await getRefreshedToken(uuid);
                        Device.updateToken(refToken);
                    }
                    connectionObj = null;
                    isCallStarted = false;
                });
                Device.on('connect', function (conn) {
                    if(conn.direction !== "OUTGOING") {
                        connectionObj = conn;
                        isCallStarted = true;
                        bindConnectCall();
                        localStorage.setItem('isCallStarted', 'true');
                    }
                });
                Device.on('disconnect', function (conn) {
                    console.log('Call ended.');
                    connectionObj = null;
                    isCallStarted = false;
                    bindDisconnectCall();
                });
                Device.on('cancel', (e) => {
                    console.log('Call cancel.');
                    connectionObj = null;
                    isCallStarted = false;
                    bindCancelCall();
                });
                Device.on('incoming', function (conn) {
                    if(isCallStarted) {
                        return false;
                    }
                    let visitorId = conn.customParameters.get("visitorId");
                    callData.visitorId = visitorId;
                    callData.ownerId = conn.customParameters.get("ownerId");
                    callData.agentId = conn.customParameters.get("agentId");
                    connectionObj = conn;
                    isCallStarted = true;


                    conn.on("ringing", (hasEarlyMedia) => {
                        console.log("=================    ringing   INCOMING  !===================");
                    });
                
                    conn.on("accept", (connection) => {
                        console.log("================= INCOMING The other person answered the phone!===================");
                    });
                
                    conn.on("disconnect", () => {
                      console.log("================ INCOMING The other person hung up.====================");
                      localStorage.setItem('isCallStarted', 'false');
                    });
                
                    conn.on("error", (error) => {
                        console.log("================  INCOMING error called  555555555   ====================");
                        console.log(error);
                    });

                    bindIncomingCall(visitorId);
                });
            }
        })
        .catch(e => {
            //console.log(e, "ERROR");
        });
}

export const startVoipCall = (clientId, agent, from, dataObject2 = null) => {
    if (isCallStarted) {
        return false;
    }
    let newClientId = "LIVECHAT-"+clientId+"-tw";
    let agent_id = agent.agentId;
    let agent_name = agent.agent_name;
    let agent_alias = agent.alias?agent.alias:agent.agent_short;

    if (from === "agent") {
        return new Promise(resolve => {
            var agentProfile = encodeURIComponent(agent.profile_img);
            var agentNumber = agent.phone_number ? encodeURIComponent(agent.phone_number) : "";
            var toAgentNumber = dataObject2.phone_number ? encodeURIComponent(dataObject2.phone_number) : "";

            let callBody = [`companyID=${agent.ownerId}&inc=2&name=${encodeURIComponent(agent_name)}&FromCountry=&agentId=${clientId}&fromAgentId=${agent_id}&Phone=${agentNumber}&toPhone=${toAgentNumber}&email=${encodeURIComponent(agent.email)}&type=outgoing&agentProfile=${agentProfile}&agentShort=${nameIntials(agent.name)}&agentName=${agent.name}&toagentName=${encodeURIComponent(dataObject2.agent_name)}&toAlias=${dataObject2.alias}&toAgentShort=${dataObject2.agent_short}&toAgentProfile=${encodeURIComponent(dataObject2.profile_img)}&toagentEmail=${encodeURIComponent(dataObject2.email)}`];

            //fetch(MAIL_FUNCTION_URL + "/createChat/createAgentChat", {
            fetch(GATEWAY_URL + "/createAgentChat", {
                method: "post",
                body: callBody,
                headers: {
                    "X-API-Key" : FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(response => {
                var msgId = response.data.msgId ? response.data.msgId : "";
                var chatId = response.data.visitorId ? response.data.visitorId : "";
                callData.visitorId = chatId;
                callData.ownerId = agent.ownerId;
                callData.agentId = agent_id;
                var params = {
                    To: newClientId,
                    //StatusCallback: MAIL_FUNCTION_URL + "/twilio/callstatus?msgId=" + msgId + "&ownerId=" + agent.ownerId + "&chatId=" + chatId,
                    StatusCallback: GATEWAY_URL + "/callstatus?msgId=" + msgId + "&ownerId=" + agent.ownerId + "&chatId=" + chatId,
                    customFrom : from,
                    callType: "voip",
                    visitorId: chatId,
                    ownerId: agent.ownerId,
                    agentId: agent_id,
                    agentName : agent_alias
                };
                let outboundCall = Device.connect(params);
                bindOutgoingCall(outboundCall);
                resolve(chatId);
            }).catch((err) => { });
        });
    } else {
        try {
            $('#button-call').attr('style','display:none !important');
            $('#button-hangup').attr('style','display:inline !important');
            $('#headerbtn-mute').attr('style','display:inline !important');
            $('#headerbtn-addagent').attr('style','display:none !important');
            $('#headerbtn-noteslist').attr('style','display:none !important');
            $('#headerbtn-endchat').attr('style','display:none !important');
            $('#headerbtn-otheroptions').attr('style','display:none !important');
        } catch (err) {}
        var msgId = dataObject2.msgId ? dataObject2.msgId : "";
        var ownerId = dataObject2.ownerId ? dataObject2.ownerId : "";
        var chatId = dataObject2.chatId ? dataObject2.chatId : "";
        callData.visitorId = chatId;
        callData.ownerId = ownerId;
        callData.agentId = agent_id;
        var params = {
            To: newClientId,
            StatusCallback: GATEWAY_URL + "/callstatus?msgId=" + msgId + "&ownerId=" + ownerId + "&chatId=" + chatId,
           // StatusCallback: MAIL_FUNCTION_URL + "/twilio/callstatus?msgId=" + msgId + "&ownerId=" + ownerId + "&chatId=" + chatId,
            customFrom : from,
            callType: "voip",
            visitorId: chatId,
            ownerId: ownerId,
            agentId: agent_id,
            agentName : agent_alias
        };

        var outboundCall = Device.connect(params);
        bindOutgoingCall(outboundCall);
    }
}

export const startCall = (phoneNumber, agent, from, dataObject2 = null) => {
    if (isCallStarted) {
        return false;
    }
    if (enablePopup) {
        try {
            document.getElementById('incpopupcontent').style.display = 'block';
        } catch (errrr) {}
    }

    let agentNumber = agent.phone_number;
    let agent_id = agent.agentId;
    if (from === "sidebar") {
        return new Promise(resolve => {
            var agentProfile = encodeURIComponent(agent.profile_img);
            var email = encodeURIComponent(agent.email);
            let callBody = [`companyID=${agent.ownerId}&inc=2&name=${encodeURIComponent(phoneNumber)}&FromCountry=US&agentId=${agent_id}&Phone=${encodeURIComponent(phoneNumber)}&type=outgoing&agentProfile=${agentProfile}&email=${email}&alias=${agent.alias}&agentShort=${nameIntials(agent.name)}&agentName=${agent.agent_name}`];
            //fetch(MAIL_FUNCTION_URL + "/createChat/createCallChat", {
            fetch(GATEWAY_URL + "/createCallChat", {
                method: "post",
                body: callBody,
                headers: {
                    "X-API-Key" : FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(response => {
                var msgId = response.data.msgId ? response.data.msgId : "";
                var chatId = response.data.visitorId ? response.data.visitorId : "";
                callData.visitorId = chatId;
                callData.ownerId = agent.ownerId;
                callData.agentId = agent_id;
                var params = {
                    To: phoneNumber,
                    From: agentNumber,
                    //StatusCallback: MAIL_FUNCTION_URL + "/twilio/callstatus?msgId=" + msgId + "&ownerId=" + agent.ownerId + "&chatId=" + chatId,
                    StatusCallback: GATEWAY_URL + "/callstatus?msgId=" + msgId + "&ownerId=" + agent.ownerId + "&chatId=" + chatId,
                    customFrom : from,
                    callType: "phone"
                };
                let outboundCall = Device.connect(params);
                bindOutgoingCall(outboundCall);
                resolve(chatId);
            }).catch((err) => { });
        });
    } else {
        try {
            $('#button-call').attr('style','display:none !important');
            $('#button-hangup').attr('style','display:inline !important');
            $('#headerbtn-mute').attr('style','display:inline !important');
            $('#headerbtn-addagent').attr('style','display:none !important');
            $('#headerbtn-noteslist').attr('style','display:none !important');
            $('#headerbtn-endchat').attr('style','display:none !important');
            $('#headerbtn-otheroptions').attr('style','display:none !important');
        } catch (err) {}
        var msgId = dataObject2.msgId ? dataObject2.msgId : "";
        var ownerId = dataObject2.ownerId ? dataObject2.ownerId : "";
        var chatId = dataObject2.chatId ? dataObject2.chatId : "";
        callData.visitorId = chatId;
        callData.ownerId = ownerId;
        callData.agentId = agent_id;
        var params = {
            To: phoneNumber,
            From: agentNumber,
            //StatusCallback: MAIL_FUNCTION_URL + "/twilio/callstatus?msgId=" + msgId + "&ownerId=" + ownerId + "&chatId=" + chatId,
            StatusCallback: GATEWAY_URL + "/callstatus?msgId=" + msgId + "&ownerId=" + ownerId + "&chatId=" + chatId,
            customFrom : from,
            callType: "phone"
        };
        let outboundCall = Device.connect(params);
        bindOutgoingCall(outboundCall);
    }
}

export const endCall = () => {
    bindDisconnectCall();
    Device.disconnectAll();
    connectionObj = null;
    isCallStarted = false;
    localStorage.setItem('isCallStarted', 'false');
}

export const dialExtension = (number) => {
    number = number.toString();
    if (isCallStarted && connectionObj) {
        connectionObj.sendDigits(number);
    }
}

export const acceptIncomingCall = () => {
    try {
        document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
        document.getElementById('lastchattime_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'flex';
    } catch (errrr) {}

    try {
        $('#button-call').attr('style','display:none !important');
        $('#button-hangup').attr('style','display:inline !important');
        $('#headerbtn-mute').attr('style','display:inline !important');
        $('#headerbtn-unmute').attr('style','display:none !important');
        $('#headerbtn-addagent').attr('style','display:none !important');
        $('#headerbtn-noteslist').attr('style','display:none !important');
        $('#headerbtn-endchat').attr('style','display:none !important');
        $('#headerbtn-otheroptions').attr('style','display:none !important');
    } catch (err) {}

    try {
        document.getElementById('calling_incoming').style.display = 'none';
    } catch(err){}

    //// For voip call ///////

    //let isVoipCall = (connectionObj.parameters.From.indexOf("client:") === -1) ? false : true;
    if (enablePopup) { //|| isVoipCall
        try {
            document.getElementById('hangupIncCall').style.display = 'inline';
            document.getElementById('muteIncCall').style.display = 'inline';
            document.getElementById('acceptIncCall').style.display = 'none';
            document.getElementById('rejectIncCall').style.display = 'none';
            document.getElementById('fromNumberIncCall').innerText = "";
        } catch (err) {}

        try {
            $('#maxminCallPopup').html(`<img alt="" id="maximizeIcon" src=${require('../../../assets/images/livechat/maxmize.png')} />`);
            $("#incpopupcontent").hide();
            $('#showHidePopupContent').hide();
            $("#incomingCallPopup").animate({
                left: $(window).width() - 200,
                top: $(window).height() - 30
            }, 300);
        } catch (err) {}
    }

    try {
        document.getElementById('acceptRejectCall').style.display = 'none';
        document.getElementById('callInProgress').style.display = 'block';
    } catch (errrr) {}
    
    connectionObj.accept();
}

export const rejectCall = () => {
    
    if (connectionObj === null) {
        return false;
    }

    try {
        $('#button-call').attr('style','display:inline !important');
        $('#button-hangup').attr('style','display:none !important');
        $('#headerbtn-mute').attr('style','display:none !important');
        $('#headerbtn-unmute').attr('style','display:none !important');
        $('#headerbtn-addagent').attr('style','display:block !important');
        $('#headerbtn-noteslist').attr('style','display:block !important');
        $('#headerbtn-endchat').attr('style','display:block !important');
        $('#headerbtn-otheroptions').attr('style','display:block !important');
    } catch (err){}

    try {
        document.getElementById('geodetail_' + callData.visitorId).style.display = 'block';
    } catch(eee){}

    try {
        document.getElementById('lastchattime_' + callData.visitorId).style.display = 'block';
        document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'none';
    } catch (errrr){}

    try {
        document.getElementById('calling_incoming').style.display = 'none';
    } catch(err){}

    ///// For voip call ///////

    /*let isVoipCall = false;
    if(connectionObj.parameters.From) {
        isVoipCall = (connectionObj.parameters.From.indexOf("client:") === -1) ? false : true;
    } else{
        let From = connectionObj.customParameters.get("From");
        if(From) {
            isVoipCall = (From.indexOf("client:") === -1) ? false : true;
        }
    } */

    if (enablePopup) { //|| isVoipCall
        try {
            document.getElementById('incomingCallPopup').style.display = 'none';
            document.getElementById('hangupIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'none';
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('acceptIncCall').style.display = 'none';
            document.getElementById('rejectIncCall').style.display = 'none';
            document.getElementById('fromNumberIncCall').innerText = "";
        } catch (errr) {}
    }

    try {
        document.getElementById('mainPhoneCall').style.display = 'none';
        document.getElementById('acceptRejectCall').style.display = 'none';
        document.getElementById('callInProgress').style.display = 'none';
        document.getElementById('unmuteAudio').style.display = 'none';
        document.getElementById('muteAudio').style.display = 'inline';
    } catch (errrr) {}

    connectionObj.reject();
    endCall();
}

export const muteAudio = () => {
    if (!connectionObj.isMuted()) {
        connectionObj.mute(true);
        try {
            $('.mute_from_ong').hide();
            $('.unmute_from_ong').show();
        } catch (errr) {}
        try {
            document.getElementById('headerbtn-mute').style.display = 'none';
            document.getElementById('headerbtn-unmute').style.display = 'inline';
        } catch (errr) {}

        if (enablePopup) {
            try {
                document.getElementById('muteIncCall').style.display = 'none';
                document.getElementById('unmuteIncCall').style.display = 'inline';
            } catch (errr) { }
        }

        try {
            document.getElementById('unmuteAudio').style.display = 'inline';
            document.getElementById('muteAudio').style.display = 'none';
        } catch (errr) {}
    }
}

export const unmuteAudio = () => {
    if (connectionObj.isMuted()) {
        connectionObj.mute(false);
        try {
            $('.mute_from_ong').show();
            $('.unmute_from_ong').hide();
        } catch (errr) {}
        try {
            document.getElementById('headerbtn-mute').style.display = 'inline';
            document.getElementById('headerbtn-unmute').style.display = 'none';
        } catch (errr) {}

        if (enablePopup) {
            try {
                document.getElementById('muteIncCall').style.display = 'inline';
                document.getElementById('unmuteIncCall').style.display = 'none';
            } catch (errr) { }
        }

        try {
            document.getElementById('unmuteAudio').style.display = 'none';
            document.getElementById('muteAudio').style.display = 'inline';
        } catch (errr) {}
    }
}

$(document).ready(function () {

    /// For Mute Start /////

    $(document).on('click', '.mute_from_ong', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        muteAudio();
    });

    $(document).on('click', '.unmute_from_ong', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        unmuteAudio();
    });

    $(document).on('click', '#headerbtn-mute', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        muteAudio();
    });

    $(document).on('click', '#headerbtn-unmute', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        unmuteAudio();
    });

    /// For Mute End /////

    /// for ongoing Chats if any ////

    $(document).on('click', '.accept_from_ong', function (event) {
        // event.preventDefault();
        if (connectionObj !== null) {
            acceptIncomingCall();
        }
    });

    $(document).on('click', '.reject_from_ong', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        rejectCall();
    });

    $(document).on('click', '#calling_accept_call', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        acceptIncomingCall();
    });

    $(document).on('click', '#calling_reject_call', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        rejectCall();
    });

    /* All page layout start */

    $(document).on('click', '.main-btn-accept', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        acceptIncomingCall();
    });

    $(document).on('click', '.main-btn-reject', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        rejectCall();
    });

    $(document).on('click', '#muteAudio', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        muteAudio();
    });

    $(document).on('click', '#unmuteAudio', function (event) {
        event.preventDefault();
        if (connectionObj === null) {
            return false;
        }
        unmuteAudio();
    });

    /* All page layout end */

    if (enablePopup) {

        $('#unmuteIncCall').on('click', function (event) {
            event.preventDefault();
            if (connectionObj === null) {
                return false;
            }
            unmuteAudio();
        });

        $('#muteIncCall').on('click', function (event) {
            event.preventDefault();
            if (connectionObj === null) {
                return false;
            }
            muteAudio();
        });
        $('#acceptIncCall').on('click', function (event) {
            event.preventDefault();
            if (connectionObj === null) {
                return false;
            }
            acceptIncomingCall();
        });

        $('#rejectIncCall').on('click', function (event) {
            event.preventDefault();
            if (connectionObj === null) {
                return false;
            }
            rejectCall();
        });

        $('#hangupIncCall').on('click', function (event) {
            event.preventDefault();
            endCall();
            document.getElementById('incomingCallPopup').style.display = 'none';
            document.getElementById('hangupIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'none';
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('acceptIncCall').style.display = 'none';
            document.getElementById('rejectIncCall').style.display = 'none';
            document.getElementById('fromNumberIncCall').innerText = "";

            try {
                document.getElementById('geodetail_' + callData.visitorId).style.display = 'block';
            } catch(eee){}

            try {
                document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
                document.getElementById('lastchattime_' + callData.visitorId).style.display = 'block';
            } catch (errrr) { }
        });

        $('#showHidePopupContent').on('click', function (event) {
            event.preventDefault();
            let htmlContent = $(this).html();
            if (htmlContent.indexOf("downToggleIcon") === -1) {
                $(this).html(`<img alt="" id="downToggleIcon" src=${require('../../../assets/images/livechat/down.svg')} />`);
            } else {
                $(this).html(`<img alt="" id="upToggleIcon" src=${require('../../../assets/images/livechat/left.svg')} />`);
            }
            $("#incpopupcontent").slideToggle();
        });

        $('#maxminCallPopup').on('click', function (event) {
            event.preventDefault();
            let screenHeight = $(window).height();
            let screenWidth = $(window).width();
            let htmlContent = $(this).html();
            if (htmlContent.indexOf("minimizeIcon") === -1) {
                var topPx = Math.ceil(screenHeight / 2);
                var leftPx = Math.ceil(screenWidth / 2);
                $(this).html(`<img alt="" id="minimizeIcon" src=${require('../../../assets/images/livechat/minimize.png')} />`);
                $("#incpopupcontent").show();
                $('#showHidePopupContent').show();
                $("#incomingCallPopup").animate({
                    //height: '320px',
                    top: topPx,
                    left: leftPx
                }, 300);
            } else {
                $(this).html(`<img alt="" id="maximizeIcon" src=${require('../../../assets/images/livechat/maxmize.png')} />`);
                $("#incpopupcontent").hide();
                $('#showHidePopupContent').hide();
                $("#incomingCallPopup").animate({
                    left: screenWidth - 200,
                    top: screenHeight - 30
                }, 300);
            }
        });

        /// Dragable start 
        // Make the DIV element draggable:
        try {
            dragElement(document.getElementById("incomingCallPopup"));
        } catch (err) {
            //console.log("Error in twilio popup movable : " + err);
        }
    }
});

export const dragElement = (elmnt) => {

    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY
        let checkTopP = elmnt.offsetTop - pos2;
        let checkLeftP = elmnt.offsetLeft - pos1;
        if (checkTopP >= 20 && checkTopP <= ($(window).height() - 40)) {
            elmnt.style.top = checkTopP + "px";
        }
        if (checkLeftP >= 200 && checkLeftP <= ($(window).width() - 200)) {
            elmnt.style.left = checkLeftP + "px";
        }
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}


/*export const bindOutgoingMuteUnmute =  (connection) => {

    connection.on('mute', function(isMuted, conn2) {
        console.log("-------------- Outgoing mute Called -------------");
        console.log("-----------Outgoing conn2------------");
        console.log(conn2);
        console.log("-------------Outgoing isMuted---------");
        console.log(isMuted);
    });

  
} 

export const bindIncomingMuteUnmute = (connection) => {

    $('#muteUnmuteIncCall').on('click', function (event) {
        event.preventDefault();
        if (connection.isMuted()) {
            connection.mute(false);
        } else {
            connection.mute(true);
        }
    });
}

export const bindOutgoingSendDigits =  (connection) => {
    $('#btnExtension').on('click', function (event) {
        console.log("---------------  bindOutgoingSendDigits Called ----------------")
        event.preventDefault();
        connection.sendDigits("1");  // Send string number only
    });
} */

/* SMS functions start */

export const sendSMS = (from_number, phoneNumber, message, dataObject) => {
    return new Promise((resolve, reject) => {
        from_number = encodeURIComponent(from_number);
        phoneNumber = encodeURIComponent(phoneNumber);
        message = encodeURIComponent(message);
        var body = [`from_number=${from_number}&to_number=${phoneNumber}&message=${message}&msgId=${dataObject.msgId}&ownerId=${dataObject.ownerId}&chatId=${dataObject.chatId}`];
        //fetch(MAIL_FUNCTION_URL + "/twilio/sendmessage", {
        fetch(GATEWAY_URL + "/sendsmsbytwilio", {
            method: "POST",
            body: body,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                resolve(response);
            })
            .catch(e => {
                reject({ status: "error" });
            });
    });
}

export const sendOrCreateVisitorSMS = (agent, msgData, fromSide) => {
    if (isSmsSending) {
        return false;
    }
    isSmsSending = true;
    var agentProfile = encodeURIComponent(agent.profile_img);
    var tmpmsgContent = encodeURIComponent(msgData.message);
    var toPhoneNumber = msgData.phoneNumber ? msgData.phoneNumber : "";
    if (fromSide === "outside") {
        return new Promise(resolve => {
            var isVisitorExist = false;
            firebaseServices.db.collection('users').doc(agent.ownerId).collection('visitors').where("agent_ids", "array-contains", agent.agentId).where("visitor_status", "==", "ongoing").orderBy('updatedon', 'desc').get().then((visitors) => {
                visitors.forEach((visitor) => {
                    var vist = visitor.data();
                    let phoneNumber2 = msgData.phoneNumber.slice(1);
                    if (vist.client_phone.indexOf(phoneNumber2) !== -1 && vist.isChatend === 0) {
                        isVisitorExist = true;
                        /* Save SMS as message start */
                        var msg_url = "";
                        var contentType = "";
                        var actionType = "addmessage";
                        var body = [`ownerId=${agent.ownerId}&chatId=${visitor.id}&agentId=${agent.agentId}&agentName=${agent.name}&agentProfile=${agentProfile}&agentShort=${nameIntials(agent.name)}&msg=${tmpmsgContent}&msg_type=11&msg_url=${msg_url}&contenttype=${contentType}&filesize=0&action=${actionType}`];
                        //fetch(MAIL_FUNCTION_URL + "/saveMessage", {
                        fetch(GATEWAY_URL + "/saveMessage", {
                            method: "post",
                            body: body,
                            headers: {
                                "X-API-Key" : FUNCTION_API_KEY,
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Accept": "application/json",
                                "Origin": window.location.origin
                            }
                        }).then(res => res.json()).then(async (response) => {
                            if (response.result === "success") {
                                let serverTimestamp = await getDateInUTC();
                                firebaseServices.db.collection('users').doc(agent.ownerId).collection('visitors').doc(visitor.id).update({ updatedon: serverTimestamp, latest_msg: msgData.message });
                                let dataObj2 = {
                                    msgId: response.chatId,
                                    ownerId: agent.ownerId,
                                    chatId: visitor.id
                                };
                                sendSMS(agent.phone_number, toPhoneNumber, msgData.message, dataObj2);
                                isSmsSending = false;
                                try {
                                    document.getElementById("txt_Sms").value = "";
                                } catch (errr) { }
                                try {
                                    document.getElementById("rig_txt_Sms").value = "";
                                } catch (errr) { }
                                resolve(visitor.id);
                            }
                        }).catch((err) => { });
                        /* Save SMS as message End  */
                    }
                });
                if (!isVisitorExist) {
                    let callBody = [`companyID=${agent.ownerId}&inc=2&name=${encodeURIComponent(msgData.phoneNumber)}&FromCountry=US&agentId=${agent.agentId}&Phone=${encodeURIComponent(msgData.phoneNumber)}&type=outgoingsms&agentProfile=${agentProfile}&agentShort=${nameIntials(agent.name)}&agentName=${agent.name}&msg=${tmpmsgContent}&msg_type=11`];
                    //fetch(MAIL_FUNCTION_URL + "/createChat/createSmsChat", {
                    fetch(GATEWAY_URL + "/createSmsChat", {
                        method: "post",
                        body: callBody,
                        headers: {
                            "X-API-Key" : FUNCTION_API_KEY,
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Accept": "application/json",
                            "Origin": window.location.origin
                        }
                    }).then(res => res.json()).then(async (response) => {
                        var msgId = response.data.msgId ? response.data.msgId : "";
                        var chatId = response.data.visitorId ? response.data.visitorId : "";
                        if (response.status) {
                            let dataObj2 = {
                                msgId: msgId,
                                ownerId: agent.ownerId,
                                chatId: chatId
                            };
                            sendSMS(agent.phone_number, toPhoneNumber, msgData.message, dataObj2);
                            isSmsSending = false;
                            try {
                                document.getElementById("txt_Sms").value = "";
                            } catch (errr) { }
                            try {
                                document.getElementById("rig_txt_Sms").value = "";
                            } catch (errr) { }
                            resolve(chatId);
                        }
                    }).catch((err) => { });
                }
            });
        });
    }
}


export const sendOrCreateAgentChat = (agent, msgData, fromSide) => {
    if (isSmsSending) {
        return false;
    }
    isSmsSending = true;
    var agentProfile = encodeURIComponent(agent.profile_img);
   // var tmpmsgContent = encodeURIComponent(msgData.message);
    var agentNumber = agent.phone_number ? encodeURIComponent(agent.phone_number) : "";
    var toAgentNumber = msgData.toAgentPhone ? encodeURIComponent(msgData.toAgentPhone) : "";
    var client_name = msgData.client_name ? msgData.client_name : "";
    var client_email = msgData.client_email ? msgData.client_email : "";
    var changeTextLang = msgData.changeTextLang ?  msgData.changeTextLang : '';

    if (fromSide === "outside") {
        return new Promise(resolve => {
            var isVisitorExist = false;
            firebaseServices.db.collection('users').doc(agent.ownerId).collection('visitors').where("agent_ids", "array-contains", agent.agentId).where("visitor_status", "==", "ongoing").orderBy('updatedon', 'desc').get().then((visitors) => {

                visitors.forEach((visitor) => {
                    var vist = visitor.data();
                    if (vist.agent_ids.indexOf(msgData.toAgentId) !== -1 && vist.start_url === "voip_call" && vist.isChatend === 0) {
                        isVisitorExist = true;
                        isSmsSending = false;
                        resolve(visitor.id);
                        
                        /*var msg_url = "";
                        var contentType = "";
                        var actionType = "addmessage";
                        var body = [`ownerId=${agent.ownerId}&chatId=${visitor.id}&agentId=${agent.agentId}&agentName=${agent.name}&agentProfile=${agentProfile}&agentShort=${nameIntials(agent.name)}&chatType=voip&msg_type=1&msg_url=${msg_url}&contenttype=${contentType}&filesize=0&action=${actionType}&agent_start=${vist.agent_start}`];
                        fetch(MAIL_FUNCTION_URL + "/saveMessage", {
                            method: "post",
                            body: body,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Accept": "application/json",
                                "Origin": window.location.origin
                            }
                        }).then(res => res.json()).then(async (response) => {
                            if (response.result === "success") {
                                let serverTimestamp = await getDateInUTC();
                                firebaseServices.db.collection('users').doc(agent.ownerId).collection('visitors').doc(visitor.id).update({ updatedon: serverTimestamp, latest_msg: msgData.message });
                                isSmsSending = false;
                                resolve(visitor.id);
                            }
                        }).catch((err) => {}); */
                        
                    }
                });
                if (!isVisitorExist) {
                    let callBody = [`companyID=${agent.ownerId}&inc=2&name=${encodeURIComponent(agent.agent_name)}&email=${encodeURIComponent(agent.email)}&FromCountry=&agentId=${msgData.toAgentId}&fromAgentId=${agent.agentId}&Phone=${agentNumber}&toPhone=${toAgentNumber}&type=start_chat&agentProfile=${agentProfile}&agentShort=${nameIntials(agent.name)}&agentName=${agent.name}&toagentName=${encodeURIComponent(client_name)}&toAlias=${msgData.alias}&toAgentShort=${msgData.agent_short}&toAgentProfile=${encodeURIComponent(msgData.profile_img)}&toagentEmail=${encodeURIComponent(client_email)}`];
                    //fetch(MAIL_FUNCTION_URL + "/createChat/createAgentChat", {
                    fetch(GATEWAY_URL + "/createAgentChat", {
                        method: "post",
                        body: callBody,
                        headers: {
                            "X-API-Key" : FUNCTION_API_KEY,
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Accept": "application/json",
                            "Origin": window.location.origin
                        }
                    }).then(res => res.json()).then(response => {
                        var chatId = response.data.visitorId ? response.data.visitorId : "";
                        isSmsSending = false;
                        resolve(chatId);
                    }).catch((err) => {});
                }
            });
        });
    }
}

/* SMS functions End */

/* WhatsApp functions start */

export const sendWhatsAppMessage = (dataObject, chatData) => {
    let msgType = "text";
    if(chatData.whatsapp_templateName && chatData.whatsapp_templateName !== "") {
        msgType = "template";
    }
    let callBody = [`from_number=${encodeURIComponent(WHATS_APP_NUMBER)}&to_number=${encodeURIComponent(dataObject.client_phone)}&msgId=${encodeURIComponent(chatData.msgId)}&ownerId=${encodeURIComponent(chatData.ownerId)}&chatId=${encodeURIComponent(chatData.chatId)}&message=${encodeURIComponent(chatData.message)}&agentId=${encodeURIComponent(chatData.agentId)}&email=${encodeURIComponent(dataObject.client_email)}&msg_type=${chatData.msg_type}`]; 
    fetch(GATEWAY_URL + "/sendwhatsapp", {
        method: "post",
        body: callBody,
        headers: {
            "X-API-Key" : FUNCTION_API_KEY,
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
            "Origin": window.location.origin
        }
    }).then(res => res.json()).then(response => {
    }).catch((err) => {
        console.log("-------------   err   ---------------");
        console.log(err); });
}

export const sendWhatsAppTemplate = async (dataObject, chatData,templateId,agentName,content) => {
    console.log(dataObject);
    let msgType = "text";
    if (chatData.whatsapp_templateName && chatData.whatsapp_templateName !== "") {
        msgType = "template";
    }
    let callBody = `from_number=${encodeURIComponent(WHATS_APP_NUMBER)}&to_number=${encodeURIComponent(dataObject.client_phone)}&visitorId=${dataObject.id}&msgId=${encodeURIComponent(chatData.msgId)}&ownerId=${encodeURIComponent(chatData.ownerId)}&chatId=${encodeURIComponent(chatData.chatId)}&message=${encodeURIComponent(chatData.message)}&agentId=${encodeURIComponent(chatData.agentId)}&email=${encodeURIComponent(dataObject.client_email)}&msg_type=${msgType}&templateId=${templateId}&agentName=${encodeURIComponent(agentName)}&content=${encodeURIComponent(content)}`;

    try {
        let response = await fetch("https://us-central1-livechat-production.cloudfunctions.net/twilio"+"/sendwhatsappTemp", {
            method: "POST",
            body: callBody,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        });
        let jsonResponse = await response.json();
        return jsonResponse;
    } catch (err) {
        console.error(err);
        return false;
    }
};
/* Private function Start */

export const checkPopupToShow = () => {
    if (connectionObj === null) {
        return false;
    }
    if (isCallStarted) {
        if (Object.keys(currentChat).length > 0) {
            let isActiveChat = checkCurrentChat(callData.visitorId);
            if (isActiveChat) {
                if (connectionObj.direction === "INCOMING" && (connectionObj._status === "pending" || connectionObj._isAnswered === false)) {
                    try {
                        document.getElementById('calling_from_number').innerText = getFilteredNumber(connectionObj.parameters.From);
                        document.getElementById('calling_incoming').style.display = 'block';
                    } catch (err) { }
                } else if(connectionObj._status === "ringing" || connectionObj._status === "open") {
                    setTimeout(() => {
                        try {
                            $('#button-call').attr('style','display:none !important');
                            $('#button-hangup').attr('style','display:inline !important');
                            if(connectionObj.isMuted()) {
                                $('#headerbtn-unmute').attr('style','display:inline !important');
                                $('#headerbtn-mute').attr('style','display:none !important');
                            } else {
                                $('#headerbtn-unmute').attr('style','display:none !important');
                                $('#headerbtn-mute').attr('style','display:inline !important');
                            }
                            $('#headerbtn-addagent').attr('style','display:none !important');
                            $('#headerbtn-noteslist').attr('style','display:none !important');
                            $('#headerbtn-endchat').attr('style','display:none !important');
                            $('#headerbtn-otheroptions').attr('style','display:none !important');
                        } catch (err) {}
                    }, 200);
                }

                if (enablePopup) {
                    try{
                        document.getElementById('incomingCallPopup').style.display = 'block';
                    } catch(err) {}
                }
                return false;
            } else {
                setTimeout(() => {
                    try {
                        $('#button-call').attr('style','display:inline !important');
                        $('#button-hangup').attr('style','display:none !important');
                        $('#headerbtn-mute').attr('style','display:none !important');
                        $('#headerbtn-unmute').attr('style','display:none !important');
                        $('#headerbtn-addagent').attr('style','display:block !important');
                        $('#headerbtn-noteslist').attr('style','display:block !important');
                        $('#headerbtn-endchat').attr('style','display:block !important');
                        $('#headerbtn-otheroptions').attr('style','display:block !important');
                    } catch (err) {}
                }, 200);
            }
        }

        try {
            document.getElementById('calling_incoming').style.display = 'none';
        } catch(err){}

        if (enablePopup) {
            try{
                document.getElementById('incomingCallPopup').style.display = 'none';
            } catch(err) {}
        }
    }
}

export const setCurrentChat = (data) => {
    if (data !== null && Object.keys(data).length > 0) {
        currentChat = data;
    } else {
        currentChat = {};
    }
    checkPopupToShow();
}

export const checkCurrentChat = (visitorid) => {
    if (Object.keys(currentChat).length > 0) {
        if (currentChat.id === visitorid) {
            return true;
        }
    } else {
        return false;
    }
}

export const getCallStatus = () => {
    if (connectionObj === null) {
        return false;
    }
    let tmpObj = callData;
    tmpObj.isCallStarted = isCallStarted;
    tmpObj.isSmsSending = isSmsSending;
    tmpObj.calldirection = connectionObj.direction ? connectionObj.direction : connectionObj._direction;
    tmpObj.callstatus = connectionObj._status ? connectionObj._status : connectionObj.status();
    tmpObj.isAnswered = connectionObj._isAnswered;
    tmpObj.isMuted = connectionObj.isMuted();
    return tmpObj;
}

export const checkPhoneCall = () => {
    let tmpCallData = getCallStatus();
    if (tmpCallData && tmpCallData.isCallStarted) {
        if (tmpCallData.calldirection === "INCOMING") {
            if (tmpCallData.callstatus === "pending" || tmpCallData.isAnswered === false) {
                try {
                    document.getElementById('contact_number').innerText = getFilteredNumber(connectionObj.parameters.From);
                    document.getElementById('mainPhoneCall').style.display = 'block';
                    document.getElementById('acceptRejectCall').style.display = 'block';
                } catch (errrr) {}
            } else if (tmpCallData.callstatus === "open" || tmpCallData.isAnswered === true) {
                try {
                    document.getElementById('contact_number2').innerText = getFilteredNumber(connectionObj.parameters.From);
                    document.getElementById('mainPhoneCall').style.display = 'block';
                    document.getElementById('callInProgress').style.display = 'block';
                } catch (errrr) {}
            }
        } else if (tmpCallData.calldirection === "OUTGOING") {
            if (tmpCallData.callstatus === "open" || tmpCallData.callstatus === "ringing" || tmpCallData.isAnswered === true) {
                try {
                    document.getElementById('mainPhoneCall').style.display = 'block';
                    document.getElementById('callInProgress').style.display = 'block';
                } catch (errrr) {}
            }
        }
    }
}

const bindConnectCall = () => {
    let customFrom = connectionObj.customParameters.get("customFrom");
    localStorage.setItem('isCallStarted', 'true');
    try {
        document.getElementById('btncall').style.display = 'none';
        document.getElementById('btnhangup').style.display = 'inline';
    } catch (err) {}
    try {
        document.getElementById('rig_btncall').style.display = 'none';
        document.getElementById('backspace_no').style.display = 'none';
        document.getElementById('rig_btnhangup').style.display = 'block';
        document.getElementById('mutebutton').style.display = 'block';
        document.getElementById('mute_btn').style.display = 'block';
    } catch (err) {}
    try {
        document.getElementById('geodetail_' + callData.visitorId).style.display = 'none';
    } catch(eee){}
    try {
        document.getElementById('lastchattime_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'flex';
    } catch (errrr) {}

    if(customFrom !== "header") {
        setTimeout(() => {
            try {
                $('#button-call').attr('style','display:none !important');
                $('#button-hangup').attr('style','display:inline !important');
                $('#headerbtn-mute').attr('style','display:inline !important');
                $('#headerbtn-unmute').attr('style','display:none !important');
                $('#headerbtn-addagent').attr('style','display:none !important');
                $('#headerbtn-noteslist').attr('style','display:none !important');
                $('#headerbtn-endchat').attr('style','display:none !important');
                $('#headerbtn-otheroptions').attr('style','display:none !important');
            } catch (err) {}
        }, 1000);
    }

    if(customFrom === "agent") {
        try{
            $(".agent_callbtn").hide();
        } catch(eee){}
    }
    
    if (enablePopup) { //  || customFrom === "agent"
        try {
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'inline';
            document.getElementById('phone_call_heading').innerText = 'Outgoing Call';
            document.getElementById('fromNumberIncCall').innerText = 'Calling to ' + getFilteredNumber(connectionObj.message.To);
            document.getElementById('hangupIncCall').style.display = 'inline';
            document.getElementById('incomingCallPopup').style.display = 'block';
        } catch (err) { }
        /*try {
            $('#maxminCallPopup').html(`<img alt="" id="maximizeIcon" src=${require('../../../assets/images/livechat/maxmize.png')} />`);
            $("#incpopupcontent").hide();
            $('#showHidePopupContent').hide();
            $("#incomingCallPopup").animate({
                left: $(window).width() - 200,
                top: $(window).height() - 30
            }, 300);
        } catch (err) {} */
    }

    try{
        document.getElementById('contact_number').innerText = getFilteredNumber(connectionObj.message.To);
        document.getElementById('contact_number2').innerText = getFilteredNumber(connectionObj.message.To);
    } catch(errrr){}
}

const bindDisconnectCall = () => {
    try {
        $('#button-call').attr('style','display:inline !important');
        $('#button-hangup').attr('style','display:none !important');
        $('#headerbtn-mute').attr('style','display:none !important');
        $('#headerbtn-unmute').attr('style','display:none !important');
        $('#headerbtn-addagent').attr('style','display:block !important');
        $('#headerbtn-noteslist').attr('style','display:block !important');
        $('#headerbtn-endchat').attr('style','display:block !important');
        $('#headerbtn-otheroptions').attr('style','display:block !important');
    } catch (err) {}
    try {
        document.getElementById('btncall').style.display = 'inline';
        document.getElementById('btnhangup').style.display = 'none';
    } catch (err) { }
    try {
        document.getElementById('rig_btncall').style.display = 'block';
        document.getElementById('rig_btnhangup').style.display = 'none';
        document.getElementById('backspace_no').style.display = 'block';
        document.getElementById('mutebutton').style.display = 'none';
        document.getElementById('unmute_btn').style.display = 'none';
        document.getElementById('mute_btn').style.display = 'none';
    } catch (err) {}
    try {
        document.getElementById('calling_incoming').style.display = 'none';
    } catch(err){}

    try {
        document.getElementById('mainPhoneCall').style.display = 'none';
        document.getElementById('acceptRejectCall').style.display = 'none';
        document.getElementById('callInProgress').style.display = 'none';
    } catch (errrr) {}

    if (enablePopup) {
        try {
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'none';
            document.getElementById('fromNumberIncCall').innerText = '';
            document.getElementById('hangupIncCall').style.display = 'none';
            document.getElementById('incomingCallPopup').style.display = 'none';
        } catch (err) { }
    }

    try {
        document.getElementById('geodetail_' + callData.visitorId).style.display = 'block';
    } catch(eee){}
    try {
        document.getElementById('lastchattime_' + callData.visitorId).style.display = 'block';
        document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'none';
        $('.mute_from_ong').show();
        $('.unmute_from_ong').hide();
    } catch (errrr) { }

    try{
        $(".agent_callbtn").show();
    } catch(eee){}
}

const bindIncomingCall = (visitorId) => {
    let isActiveChat = checkCurrentChat(visitorId);
    ///// For voip call ///////
    
    /*let isVoipCall = (connectionObj.parameters.From.indexOf("client:") === -1) ? false : true; */

    if (enablePopup ) { //  || isVoipCall
        try {
            //// For Calling PopuP Window ////////
            document.getElementById('fromNumberIncCall').innerText = 'Call from ' + getFilteredNumber(connectionObj.parameters.From);
            document.getElementById('phone_call_heading').innerText = 'Incoming Call';
            document.getElementById('acceptIncCall').style.display = 'inline';
            document.getElementById('rejectIncCall').style.display = 'inline';
            document.getElementById('hangupIncCall').style.display = 'none';
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'none';
            document.getElementById('incomingCallPopup').style.display = 'block';
            /*if (isActiveChat) {
                document.getElementById('incomingCallPopup').style.display = 'block';
            }*/
        } catch (errrr) { }
    }
    
    try {
        document.getElementById('calling_from_number').innerText = getFilteredNumber(connectionObj.parameters.From);
        if (isActiveChat) {
            document.getElementById('calling_incoming').style.display = 'block';
        }
    } catch(err){}

    try {
        document.getElementById('geodetail_' + visitorId).style.display = 'none';
    } catch(eee){}

    try {
        document.getElementById('lastchattime_' + visitorId).style.display = 'none';
        document.getElementById('call_starting_' + visitorId).style.display = 'flex';
    } catch (errrr) {}
    try {
        document.getElementById('incpopupcontent').style.display = 'block';
    } catch (errrr) {}

    try {
        let currentAgent = localStorage.getItem('agent')? JSON.parse(localStorage.getItem('agent')) : {};
        let organization_alias = currentAgent.ownerIdentifire?currentAgent.ownerIdentifire:currentAgent.organization_alias;
        organization_alias = organization_alias?organization_alias.trim():"";
        if(window.location.pathname !== '/'+organization_alias) {
            document.getElementById('contact_number').innerText = getFilteredNumber(connectionObj.parameters.From);
            document.getElementById('contact_number2').innerText = getFilteredNumber(connectionObj.parameters.From);
            document.getElementById('mainPhoneCall').style.display = 'block';
            document.getElementById('acceptRejectCall').style.display = 'block';
        }
    } catch (errrr) {}
}

const bindCancelCall = () => {

    try {
        document.getElementById('calling_incoming').style.display = 'none';
    } catch(err){}

    try {
        document.getElementById('geodetail_' + callData.visitorId).style.display = 'block';
    } catch(eee){}

    try {
        document.getElementById('lastchattime_' + callData.visitorId).style.display = 'block';
        document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
        document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'none';
        $('.mute_from_ong').show();
        $('.unmute_from_ong').hide();
    } catch (errrr) {}

    try {
        document.getElementById('mainPhoneCall').style.display = 'none';
        document.getElementById('acceptRejectCall').style.display = 'none';
        document.getElementById('callInProgress').style.display = 'none';
    } catch (errrr) {}

    if (enablePopup) {
        try {
            //// For Calling PopuP Window ////////
            document.getElementById('incomingCallPopup').style.display = 'none';
            document.getElementById('hangupIncCall').style.display = 'none';
            document.getElementById('unmuteIncCall').style.display = 'none';
            document.getElementById('muteIncCall').style.display = 'none';
            document.getElementById('acceptIncCall').style.display = 'none';
            document.getElementById('rejectIncCall').style.display = 'none';
            document.getElementById('fromNumberIncCall').innerText = "";
        } catch (errrr) { }
    }
}

const getFilteredNumber = (number) => {
    if(number.indexOf("client:") !== -1) {
        return number.replace('client:','');
    }
    return number;
}

const getRefreshedToken = (uuid) => {
    return new Promise((resolve, reject) => {
        //fetch(twilioApiUrl + "/twilio/token?dId=" + uuid, {
        fetch(GATEWAY_URL + "/token?dId=" + uuid, {
            method: "get",
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response222 => {
                const status2222 = response222.status ? response222.status : "";
                if (status2222 === "success") {
                    const data222 = response222.message ? response222.message : {};
                    resolve(data222.token);
                }
            })
            .catch(e => {});
    });
}                
const bindOutgoingCall = (outboundCall) => {
    
    outboundCall.on("ringing", (hasEarlyMedia) => {
        console.log("=================    ringing     !===================");
        //("============= The call has started and the other phone is ringing. =====================");
        connectionObj = outboundCall;
        isCallStarted = true;
        bindConnectCall();
    });

    outboundCall.on("accept", (connection) => {
        console.log("=================The other person answered the phone!===================");
    });

    outboundCall.on("disconnect", () => {
      console.log("================The other person hung up.====================");
      localStorage.setItem('isCallStarted', 'false');
    });

    outboundCall.on("error", (error) => {
        console.log("================   error called  555555555   ====================");
        console.log(error);
    });
}

/* Private function End */

/* Purchase phone numbers functions start */

export const getCountries = () => {
    return new Promise(resolve => {
        //fetch(twilioApiUrl+"/twilio/getCountries", {
        fetch(GATEWAY_URL+"/getCountries", {
            method: "GET",
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(response => {
            if(response.status === "success") {
                return resolve(response.data);
            } else {
                return resolve("error");
            }
        }).catch((err) => {
            resolve("error");
        });
    });
}

export const getAvailablePhoneNumbers = (searchData = null) => {
    return new Promise( resolve => {
        var countryCode;
        var regionCode;
        var searchType;
        var search_text;
        if(searchData) {
            countryCode = searchData.countryCode ? searchData.countryCode : "US";
            regionCode = searchData.regionCode ? searchData.regionCode : "";
            searchType = searchData.searchType ? searchData.searchType : "";
            search_text = searchData.search_text ? encodeURIComponent(searchData.search_text) : "";
        } else {
            countryCode = "US";
            regionCode = "";
            searchType = "";
            search_text = ""
        }
        let callBody = [`country_code=${countryCode}&region_code=${regionCode}&search_type=${searchType}&search_text=${search_text}`];
        //fetch(twilioApiUrl+"/twilio/getAvailablePhNumbers", {
        fetch(GATEWAY_URL+"/getAvailablePhNumbers", {
            method: "POST",
            body: callBody,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(response => {
            if(response.status === "success") {
                return resolve(response.data);
            } else {
                return resolve("error");
            }
        }).catch((err) => {
            console.log("-------------   err   ---------------");
            console.log(err);
            resolve("error");
        });
    });
}

export const buyNumber = (phoneData) => {
    return new Promise(resolve => {
        let callBody = [`phone_number=${phoneData.phoneNumber}&friendly_name=${phoneData.friendlyName}`];
        //fetch(twilioApiUrl+"/twilio/purchase", {
        fetch(GATEWAY_URL+"/purchase", {
            method: "POST",
            body: callBody,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(response => {
            resolve(response);
        }).catch((err) => {
            resolve({ status : "error", data : "Something went wrong!" });
        });
    });
}

export const releaseNumber = (phone_sid) => {
    return new Promise(resolve => {
        let callBody = [`phone_sid=${phone_sid}`];
        //fetch(twilioApiUrl+"/twilio/release", {
        fetch(GATEWAY_URL+"/release", {
            method: "POST",
            body: callBody,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(response => {
            resolve(response);
        }).catch((err) => {
            resolve({ status : "error", data : "Something went wrong!" });
        });
    });
}

/*export const getNumbers = () => {
    return new Promise(resolve => {
        fetch("http://localhost:5000/reacttest-29b23/us-central1/twilio/getnumbers", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(response => {
            if(response.status === "success") {
                resolve(response.data);
            } else {
                resolve({ status : "error", data : "Something went wrong!" });
            }
        }).catch((err) => {
            resolve({ status : "error", data : "Something went wrong!" });
        });
    });
} */

/* Purchase phone numbers functions end   */

export const bindVolumeIndicators = (connection) => {
    connection.volume(function (inputVolume, outputVolume) {
        var inputColor = 'red';
        if (inputVolume < .50) {
            inputColor = 'green';
        } else if (inputVolume < .75) {
            inputColor = 'yellow';
        }

        document.getElementById('input-volume').style.width = Math.floor(inputVolume * 300) + 'px';
        document.getElementById('input-volume').style.background = inputColor;

        var outputColor = 'red';
        if (outputVolume < .50) {
            outputColor = 'green';
        } else if (outputVolume < .75) {
            outputColor = 'yellow';
        }

        document.getElementById('output-volume').style.width = Math.floor(outputVolume * 300) + 'px';
        document.getElementById('output-volume').style.background = outputColor;
    });
}

export const updateAllDevices = () => {
    var speakerDevices = document.getElementById('speaker-devices');
    var ringtoneDevices = document.getElementById('ringtone-devices');
    updateDevices(speakerDevices, Device.audio.speakerDevices.get());
    updateDevices(ringtoneDevices, Device.audio.ringtoneDevices.get());
}

// Update the available ringtone and speaker devices
export const updateDevices = (selectEl, selectedDevices) => {
    selectEl.innerHTML = '';
    Device.audio.availableOutputDevices.forEach(function (device, id) {
        var isActive = (selectedDevices.size === 0 && id === 'default');
        selectedDevices.forEach(function (device) {
            if (device.deviceId === id) { isActive = true; }
        });

        var option = document.createElement('option');
        option.label = device.label;
        option.setAttribute('data-id', id);
        if (isActive) {
            option.setAttribute('selected', 'selected');
        }
        selectEl.appendChild(option);
    });
}

// Activity log
export const log = (message) => {
    // var logDiv = document.getElementById('log');
    // logDiv.innerHTML += '<p>&gt;&nbsp;' + message + '</p>';
    //logDiv.scrollTop = logDiv.scrollHeight;
}

// Set the client name in the UI
export const setClientNameUI = (clientName) => {
    var div = document.getElementById('client-name');
    div.innerHTML = 'Your client name: <strong>' + clientName + '</strong>';
}