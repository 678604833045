import { CONNECT_DEVELOPER_URL, COOKIES_LIST, MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from './../config/siteConfig';
import { getCookie } from './service';
const axios = require('axios');

export const getAppListFromConnect = () => {
    return new Promise((resolve, reject) => {
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/getAppList', {
        fetch(GATEWAY_URL + '/getAppList', {
            method: "get",
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                // "jvgl0d4fff345b00ypzx", 
                let excludedAppIds = [ "jmuc04oke815bc892ylx"];

                response.data.data = response.data.data.filter(obj => {
                    return !excludedAppIds.includes(obj.appId);
                });
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const getIntigrationUrl = (appId, integrationUrl, ownerEmail, ownerId, organization_alias) => {
    return new Promise((resolve, reject) => {
        let body = [`appId=${appId}&integrationUrl=${integrationUrl}&email=${ownerEmail}&clientId=${ownerId}&organization_alias=${organization_alias}`]
        // fetch('http://localhost:5001/reacttest-29b23/us-central1/connectIntegration/integration-url', {
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/integration-url', {
        fetch(GATEWAY_URL + '/integration-url', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(async (response) => {
                const resData = response.data;
                resolve(resData);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const getIntigrationUrl_OLD = (appId, integrationUrl, ownerEmail, ownerId, organization_alias) => {
    return new Promise(async (resolve, reject) => {
        var authData = await axios({
            method: 'POST',
            url: GATEWAY_URL + "/authorize-integration",
            data: {
                "client_id": ownerId,
                "redirect_uri": `${MAIL_FUNCTION_URL}/connectIntegration/redirect`,
                "response_type": "code",
                "grant_type": "authorization_code",
                "state": "read",
                "email": ownerEmail
            },
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                'Content-Type': "application/json",
                "Authorization": "Basic YXBweWxpdmVjaGF0OmFwcHlsaXZlY2hhdHBhc3N3b3Jk"
            }
        })
        console.log(authData);
        // let body = [`state=read&response_type=code&email=${ownerEmail}&client_id=${ownerId}&grant_type=authorization_code&redirect_uri=https://us-central1-reacttest-29b23.cloudfunctions.net/connectIntegration/redirect`]
        //     let body = {
        //         "client_id": ownerId,
        //         "redirect_uri": "https://us-central1-reacttest-29b23.cloudfunctions.net/connectIntegration/redirect",
        //         "response_type": "code",
        //         "grant_type": "authorization_code",
        //         "state": "read",
        //         "email": ownerEmail
        //     };
        //     fetch('http://localhost:5001/reacttest-29b23/us-central1/connectIntegration/authorize-integration', {
        //         method: "post",
        //         body: body,
        //         headers: { "Content-Type": "application/json", 
        //         "Authorization" : "Basic YXBweWxpdmVjaGF0OmFwcHlsaXZlY2hhdHBhc3N3b3Jk" 
        //     }
        //     })
        //     .then(response => response.json())
        //     .then(async (response) => {
        //         let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
        //         if(response.success === true) {
        //             returnValue = { status: 200, data : response.data }
        //         } 
        //         resolve(returnValue);
        //     }).catch((err) => {
        //         console.log('err >>>> ', err);
        //         let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
        //         resolve(returnValue);
        //     })
    })
}

export const getConnectedApps = (userId = "") => {
    return new Promise((resolve, reject) => {
        let body = [`userId=${userId}`]
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/getInstalledApp', {
        fetch(GATEWAY_URL + '/getInstalledApp', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const deletedConnectedApp = (userId, appUrlName) => {
    const connectSid = getCookie('APPYID');
    const common_eml = getCookie('common_eml');
    let body = {
        userId: userId,
        appUrlName: encodeURIComponent(appUrlName),
        connectSid: encodeURIComponent(connectSid),
        common_eml: encodeURIComponent(common_eml),
        loginCookie: 1
    }

    return new Promise((resolve, reject) => {
        // fetch('http://localhost:5001/reacttest-29b23/us-central1/connectIntegration/deleteConnectedApp', {
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/deleteConnectedApp', {
        fetch(GATEWAY_URL + '/deleteConnectedApp', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const connectApps = (userId, matchId) => {
    return new Promise((resolve, reject) => {
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        const loginCookie = getCookie('79fefaf2564aabb19d44fe324844c86b');
        let body = [`userId=${userId}&matchId=${matchId}&connectSid=${connectSid}&common_eml=${encodeURIComponent(common_eml)}&loginCookie=${loginCookie}`];
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/connectApp', {
        fetch(GATEWAY_URL + '/connectApp', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const createZoomMetting = (userId, meeting_topic) => {
    return new Promise((resolve, reject) => {
        const connectSid = encodeURIComponent(getCookie('APPYID'));
        const common_eml = getCookie('common_eml');
        const loginCookie = getCookie('79fefaf2564aabb19d44fe324844c86b');
        let body = {
            ownerId: userId,
            meeting_topic: meeting_topic,
            connectSid: connectSid,
            common_eml: encodeURIComponent(common_eml),
            loginCookie: loginCookie
        }
        // fetch(MAIL_FUNCTION_URL + '/connectIntegration/createmeeting', {
        fetch(GATEWAY_URL + '/zoom_createmeeting', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const getMatches = (appId, email) => {
    return new Promise((resolve, reject) => {
        let body = [`appId=${appId}&email=${email}`]
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/getMatches', {
        fetch(GATEWAY_URL + '/getMatches', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const deleteConnect = (userId, connectId) => {
    return new Promise((resolve, reject) => {
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        let body = {
            ownerId: userId,
            connectId: connectId,
            connectSid: encodeURIComponent(connectSid),
            common_eml: encodeURIComponent(common_eml),
            loginCookie: 1
        }
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/deleteConnect', {
        fetch(GATEWAY_URL + '/deleteConnect', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const subscribePage = (userId, fbPage, facToLivConnect, liveToFacConnect, connectType = "", page_name = "") => {
    return new Promise((resolve, reject) => {
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        let body = {
            userId: userId,
            fbPage: fbPage,
            connectSid: encodeURIComponent(connectSid),
            common_eml: encodeURIComponent(common_eml),
            loginCookie: 1,
            facToLivConnect, liveToFacConnect,
            connectType, page_name
        }
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/subscribePage', {
        fetch(GATEWAY_URL + '/subscribePage', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = "";
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                } else {
                    let msg = response.msg ? response.msg : "Something Went Wrong, Please try again after some time";
                    returnValue = { status: 500, msg: msg }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const unsubscribePage = (userId, fbPage, facToLivConnect, liveToFacConnect, connectType) => {
    return new Promise((resolve, reject) => {
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        let body = {
            userId: userId,
            fbPage: fbPage,
            connectSid: encodeURIComponent(connectSid),
            common_eml: encodeURIComponent(common_eml),
            loginCookie: 1,
            facToLivConnect, liveToFacConnect, connectType
        }
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/unsubscribePage', {
        fetch(GATEWAY_URL + '/unsubscribePage', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}

export const unInstallConnectedApp = (userId, seoLink = "") => {
    return new Promise((resolve, reject) => {
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        let body = {
            userId: userId,
            connectSid: encodeURIComponent(connectSid),
            common_eml: encodeURIComponent(common_eml),
            loginCookie: 1,
            seoLink
        }
        console.log("Here");
        console.log(body);
        //fetch(MAIL_FUNCTION_URL + '/connectIntegration/disconnectConnectedApp', {
        fetch(GATEWAY_URL + '/disconnectConnectedApp', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                if (response.success === true) {
                    returnValue = { status: 200, data: response.data }
                }
                resolve(returnValue);
            }).catch((err) => {
                console.log('err >>>> ', err);
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            })
    })
}
// comment it as not in use 
// export const getAccessToken = () => {
//     const { username, password } = CONNECT_DEV_ACCOUNTS;
//     return btoa(username + ":" + password);
// }

