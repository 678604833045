import React from 'react';
// import db from "../../../../config/firebase";
import moment from 'moment-timezone';
import Ongoing from './ongoing';
import { getDateInUTC } from '../../components/Comman';

import { MAIL_FUNCTION_URL, DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY, COMMON_LOGIN_URL, commonLoginFacebook, commonLoginGoogle, currencyData, PAYMENT_CHECKOUT_URL, HIDE_TRY_NOW_COUNTRIES, PAYMENT_TRIAL_PERIOD } from '../../../../config/siteConfig';

import { checkPaymentStatus } from '../../../../services/payment';
import firebaseServices from '../../../../firebase';
import PopUp from '../../../../layout/components/popup';




class Upgrade extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if (this.agent.roleType !== 'Owner') {
            window.location.href = "/" + this.agent.ownerIdentifire;
        }
        this.state = {
            plan: 'Free',
            // modalVisible: false,
            billing_cycle: '',
            amount: '',
            agents: '',
            chats_completed: '',
            next_billing_date: '',
            billing_information: '',
            plan_data: [],
            loader: false,
            billingList: '',
            no_of_agent: 1,
            requestKey: '',
            errormessage: '',
            currency: '',
            errormessage: '',
            planprice: '',
            totalprice: '',
            payment_list: [],
            historyCount: 0,
            history_link: '',
            owner_id: '',
            company_name: '',
            owner_email: '',
            current_plan_id: '',
            current_plan_period: '',
            current_agent: 1,
            commonlogin_user_id: '',
            payment_status: '',
            payment_enabled: 0,
            chat_count: 1,
            currencyData: currencyData,
            show_currency: '',
            country_code: 'IN',
            plan_list: [],
            totalAgents: 1,
            paymentDone: false,
            trialDaysleft: 0,
            showTryNowButon: false,
            trialStatus: true,
            currPaymentStatus: false,
            countryCode: '',
            originalCountryCode: '',
            currencyCode: '',
            last_billing_date: '-',
            add_no_of_agent: 1,
            total_add_agent_price: 0,
            isTrialwithoutCC: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.getRequestKey = this.getRequestKey.bind(this);
        this.validateStatusData = this.validateStatusData.bind(this);
        this.openPopup = this.openPopup.bind(this);
    }

    componentWillUnmount() {

    }

    openPopup = () => {
        var billingData = this.state.billing_information.split('_');
        var agent = 0;
        if (this.state.payment_status === "trial" ||this.state.payment_status === "trialWithoutCC" || this.state.plan === "Free") {
            agent = 0;
        } else {
            agent = parseInt(this.state.current_agent);
        }
        /* if (parseInt(this.state.add_no_of_agent) < parseInt(this.state.current_agent) || this.state.add_no_of_agent === '') {
            agent = parseInt(this.state.current_agent);
        } */
        // var netprice = parseInt(billingData[4]) * parseInt(agent);
        // var netprice2 = parseInt(billingData[4]) * parseInt(this.state.add_no_of_agent);
        this.setState({
            // total_add_agent_price: netprice2,
            // totalprice: netprice,
            no_of_agent: agent,
            // modalVisible: true,
            errormessage: '',
            loader: true
        });
        if (this.state.showTryNowButon && !this.state.trialStatus && this.state.currPaymentStatus === false) {
            this.handleSubmit('trial'); // todo
        }
        else {
            this.handleSubmit('pay'); // todo
        }
    }

    async getRequestKey(requestData) {
        //const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
        const response = await fetch(GATEWAY_URL + "/generateKey", {
            method: "post",
            body: JSON.stringify(requestData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({ requestKey: responseJson.stringdata });
                    return true;
                } else {

                    return false
                }
            });
        return response;
    }

    getPlanData() {
        var agentData = JSON.parse(localStorage.getItem('agent'));
        var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };

        //fetch(MAIL_FUNCTION_URL + "/payment/plandetail", {
        fetch(GATEWAY_URL + "/plandetail", {
            method: "post",
            body: JSON.stringify(body),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    var priceData = responseJson.priceData;
                    if (priceData.length > 0) {
                        var billing_information = '';
                        var planPrice;
                        var agent = parseInt(this.state.no_of_agent);
                        if (this.state.current_plan_period === 'yearly') {
                            let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[1].currencySign;
                            billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
                            planPrice = priceData[1].planPrice;
                        } else {
                            let currencySign = priceData[0].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[0].currencySign;
                            billing_information = priceData[0].productName + '_' + priceData[0].planId + '_' + priceData[0].planPeriod + '_' + currencySign + '_' + priceData[0].planPrice;
                            planPrice = priceData[0].planPrice;
                        }

                        var netprice = parseInt(planPrice) * parseInt(agent);
                        var planList = [];
                        var optionData;

                        for (const [index, value] of responseJson.priceData.entries()) {
                            optionData = { name: value.planPeriod, keyValue: value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + this.state.currencyData[value.currencyCode] + '_' + value.planPrice };
                            if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {

                            }
                            else {
                                planList.push(optionData);
                            }
                        }

                        var history_link = responseJson.history_link
                        this.setState({ totalprice: netprice, history_link: history_link, currency: this.state.currencyData[priceData[0].currencyCode], planprice: planPrice, billing_information: billing_information, plan_data: responseJson.priceData, plan_list: planList, countryCode: priceData[0].country, originalCountryCode: priceData[0].originalCountryCode, currencyCode: priceData[0].currencyCode });
                    }
                    return true
                } else {
                    return false;
                }
            });
    }

    validateStatusData = () => {
        var checkNumber = isNaN(this.state.no_of_agent);
        var checkPaymentType = true;
        // return false;
        var billing_information = this.state.billing_information;
        var billingData = billing_information.split('_');

        if (billingData[1] === this.state.billing_cycle) {
            checkPaymentType = true;
        } else if (this.state.billing_cycle === "monthly" && billingData[2] === "yearly") {
            checkPaymentType = false;
        }
        if (this.state.payment_status === 'trial' || this.state.payment_status === "trialWithoutCC" ) {
            checkPaymentType = false;
        }

        var billingData = this.state.billing_information.split('_');
        if (checkNumber) {
            this.setState({ loader: false, errormessage: 'Please enter valid no of Agent' });
            return false;
        }
        else if (this.state.no_of_agent < 1) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be greater than 0' });
            return false;
        }
        else if ((this.state.no_of_agent) > 999) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be less than 1000' });
            return false;
        }
        /* else if (parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent) && this.state.paymentDone === true && checkPaymentType === true) {
            this.setState({ loader: false, errormessage: 'Agent count cannot be less than or equalto count of agent for which subscription has already been bought' });
            return false;
        } */
        /*else if(( this.state.current_plan_period ===  billingData[2] ) && ( parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent )) )
        {
            this.setState({loader:false,errormessage:'Please plan and no of agent are same, either increase no of agent and change plan period'});	
            return false;
        }*/
        else {
            return true;
        }
    }

    async handleSubmit(paymentType = 'pay') {
        // e.preventDefault();
        // this.setState({ loader: true });
        var no_of_agent = this.state.agents;

        console.log("-------- handleSubmit no_of_agent  --------- >>>> " + no_of_agent);

        var billing_information = this.state.billing_information;
        var checkCondition = await this.validateStatusData();
        if (checkCondition === false) {
            return false;
        }
        else {
            var agentData = JSON.parse(localStorage.getItem('agent'));
            var ownerEmail;
            var ownerId;
            var companyName;
            var curentPlanId;
            var currentPlanPeriod;

            if (billing_information === undefined || billing_information === '') {
                billing_information = "livechat_6_monthly_$_10";
            }
            var billingData = billing_information.split('_');

            var uniqueId = Date.now() + ((Math.random() * 100000).toFixed());
            var orderId = 'livechat_' + uniqueId;
            if (curentPlanId === "undefined" || curentPlanId === undefined) {
                curentPlanId = "";
                currentPlanPeriod = "";
            }

            /* Added for script chat start */

            let success_url = DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentsuccess';
            if (paymentType === "pay") {
                success_url += '?ptype=paid&cpId=' + this.state.current_plan_id;
            } else {
                success_url += '?ptype=trial&cpId=' + this.state.current_plan_id;
            }
            if (this.state.plan === "Free") {
                no_of_agent = 0;
            }
            var agentString = "Current no of agent " + no_of_agent;
            var myArray = JSON.stringify({ "1": agentString });
            /* Added for script chat end */
            if (this.state.plan === "Free" && this.agent.payment_method !== "trialWithoutCC" && this.agent.plan_price !== "trialWithoutCC") {
                // logic to give free trial without credit card details
                console.log('------------new url----------->')
                var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                }
            }
            else {
                console.log('------------older url----------->')
                var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
                }
            }
            var netprice = parseInt(billingData[4]) * parseInt(no_of_agent);

            var requestResponse = await this.getRequestKey({ stringdata: priceKey, action: 'encrypt', no_of_agents: no_of_agent, netprice: netprice, currency: billingData[3], plan_name: 'Premium', plan_id: billingData[1], product_name: billingData[0], plan_period: billingData[2], order_id: orderId, product_id: this.state.owner_id });
            console.log("\n\n\n💡🛠 -> file: Upgrade.js -> line 296 -> requestResponse", requestResponse);

            if (requestResponse === true) {
               

                var form = document.getElementById('checkout');
                this.setState({ loader: false });
                form.submit();
            }
            else {
                return false;
            }
        }
    }

    async componentDidMount() {
        var body = document.getElementById('root');
        body.classList.add("plan-upgrade")
        this.setState({ loader: true });

        //await this.getRequestKey();
        const userDetails = JSON.parse(localStorage.getItem('agent'));
        let currentUnixTimeStamp = await getDateInUTC();
        await fetch('https://snappy.appypie.com/webservices/ClientDetails.php', {
            method: "get",
            headers: {
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.IP) {
                    const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(responseJson.CountryCode) > -1 ? false : true;
                    this.setState({ country_code: responseJson.CountryCode, showTryNowButon: showTryNowButon });
                } else {

                    this.setState({ country_code: 'US' });
                }
            });
        await firebaseServices.db.collection("users").doc(userDetails.ownerId).get().then(async user => {
            if (user.exists) {
                var currentStatus = '0';
                var plan_type = 'Free';
                var billing_cycle = '';
                var cost = '';
                var next_billing_date = '';
                var no_of_agents = 1;
                var plan_id = '';
                var user_id = user.data().user_id;
                var no_of_agent = 1;
                var owner_email = '';
                var payment_status = '';
                var payment_enabled = this.state.payment_enabled;
                var chat_count = 1;
                var show_currency = '';
                var totalAgents = 1;
                var paymentDone = false;
                var currPaymentStatus = await checkPaymentStatus(user.data());
                var last_billing_date = '';

                if (userDetails.ownerEmail === 'undefined' || userDetails.ownerEmail === undefined) {
                    owner_email = user.data().email;

                }
                else {
                    owner_email = userDetails.ownerEmail;
                }
                //	alert(user.data().payment_status);
                if (user.data().payment_status === 'undefined' || user.data().payment_status === undefined) {
                    currentStatus = '0'
                    payment_status = 'complete'
                    payment_enabled = 0;
                    chat_count = 1;
                    show_currency = '';
                    totalAgents = parseInt(no_of_agents) + 1;
                }
                else {
                    //   var billingDate = user.data().next_billing_date;
                    var billinUnixTimestamp = Math.round(new Date(user.data().next_billing_date + " 00:00:00.000").getTime());
                    // var currentUnixTimeStamp =Math.round(new Date().getTime());
                    let splitDate = user.data().next_billing_date ? user.data().next_billing_date.split('-') : '';
                    let splitDay = parseInt(splitDate[2]);
                    var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                    paymentDate = paymentDate + 86400000;

                    var billingDate = new Date(user.data().next_billing_date);
                    var dateString = '';
                    if (billingDate.getDate() < 10) {
                        dateString = '0' + billingDate.getDate();
                    }
                    else {
                        dateString = billingDate.getDate();
                    }
                    if (billingDate.getMonth() + 1 < 10) {
                        dateString = dateString + '-0' + (billingDate.getMonth() + 1);
                    }
                    else {
                        dateString = dateString + '-' + (billingDate.getMonth() + 1);
                    }
                    dateString = dateString + '-' + billingDate.getFullYear();
                    if (user.data().payment_status === 'complete') {
                        show_currency = this.state.currencyData[user.data().currency]
                        if (user.data().currency === 'undefined' || user.data().currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 0;
                        chat_count = 0;
                        payment_status = user.data().payment_status;
                        currentStatus = 1;
                        plan_type = user.data().plan_type;
                        billing_cycle = user.data().billing_cycle;
                        cost = user.data().plan_price;
                        next_billing_date = dateString;
                        no_of_agents = user.data().no_of_agents;
                        plan_id = user.data().plan_id;
                        user_id = user.data().user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = parseInt(no_of_agent);
                        paymentDone = true;
                        last_billing_date = user.data().payment_data.addedon;
                    }
                    else if (paymentDate >= currentUnixTimeStamp) {
                        show_currency = this.state.currencyData[user.data().currency]
                        if (user.data().currency === 'undefined' || user.data().currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 1;
                        chat_count = 0;
                        payment_status = user.data().payment_status;
                        currentStatus = 1;
                        plan_type = user.data().plan_type;
                        billing_cycle = user.data().billing_cycle;
                        cost = user.data().plan_price;
                        next_billing_date = dateString;
                        no_of_agents = user.data().no_of_agents;
                        plan_id = user.data().plan_id;
                        user_id = user.data().user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = parseInt(no_of_agent);
                        paymentDone = true;
                        last_billing_date = user.data().payment_data.addedon;
                    }
                    else {
                        show_currency = '';
                        payment_enabled = 0;
                        chat_count = 1;
                        totalAgents = parseInt(no_of_agents) + 1;
                        paymentDone = false;
                    };

                    if (user.data().payment_status === 'trial' || user.data().payment_status === "trialWithoutCC" ) {
                        let serverTimeStamp = await getDateInUTC();
                        var timeDiff = paymentDate - serverTimeStamp;
                        var daysDifference = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
                        no_of_agent = 1;
                    }
                }
                var no_chat_served = user.data().no_chat_served;
                var chat_limited = user.data().chat_limited;
                if (user.data().no_chat_served === 'undefined' || user.data().no_chat_served === undefined) {
                    no_chat_served = 0;
                }

                if (user.data().chat_limited === 'undefined' || user.data().chat_limited === undefined) {
                    chat_limited = 500;
                }


                this.setState({
                    payment_enabled: payment_enabled,
                    payment_status: payment_status,
                    owner_id: user.id,
                    company_name: user.data().company_name,
                    owner_email: owner_email,
                    current_plan_id: plan_id,
                    current_agent: no_of_agents,
                    current_plan_period: billing_cycle,
                    plan: plan_type,
                    billing_cycle: billing_cycle,
                    amount: cost,
                    agents: no_of_agents,
                    chats_completed: no_chat_served + '/' + chat_limited,
                    commonlogin_user_id: user_id,
                    next_billing_date: next_billing_date,
                    no_of_agent: no_of_agent,
                    chat_count: chat_count,
                    show_currency: show_currency,
                    totalAgents: totalAgents,
                    paymentDone: paymentDone,
                    trialDaysleft: daysDifference ? daysDifference : 0,
                    trialStatus: user.data().trialStatus === true ? true : false,
                    currPaymentStatus: currPaymentStatus,
                    last_billing_date: moment(parseInt(last_billing_date)).format('DD-MM-YYYY'),
                    isTrialwithoutCC: user.data().cost === 'trialWithoutCC'
                });
            }
        });
        await this.getPlanData();

        await firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('payment_log').get().then((docs) => {
            var payment_data = [];
            var i;
            if (docs.size >= 1) {
                docs.forEach((doc) => {
                    payment_data.push(doc.data())
                    i++;
                });
            }
            this.setState({ payment_list: payment_data, historyCount: docs.size });

        })

        this.setState({ loader: false });
    }

    render() {
        return (<>
            <Ongoing openUpgrade={this.openPopup} paymentStatus={this.state.payment_status} currPaymentStatus={this.state.currPaymentStatus} isTrialwithoutCC={this.state.isTrialwithoutCC}/>

            <form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
                <input type="hidden" name="request" value={this.state.requestKey} />
            </form>
        </>
        )
    }

}

export default Upgrade;
