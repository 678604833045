import React from 'react';
// import db from "../../config/firebase";
import { toast } from "react-toastify";
////import {mailUrl} from "../../config/mailFunctions.js";
import { GATEWAY_URL , FUNCTION_API_KEY, DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig.js";
import { activityLog, getAllAwsAgents, getOwnerDetails } from "../../services/service";

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import firebaseServices from '../../firebase';
import { removeHtmlTags } from '../livechat/components/Comman.js';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AddDepartment extends React.Component {
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            showAddDepartment:'',
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[],
            agents:[],
            ownerId:'',
            loader:false,
            agentsName:[],
            ownerEmail:'',
            company_name:'',
            agentsWithoutOwner:[],
            disableCreateBtn: true
        }
    }

    async componentDidMount() {
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showAddDepartment:this.props.sendDepartmentStatus,
            ownerId:userDetails.ownerId,
            loader: true
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then((owners) => {
            if(owners.exists) {
                this.setState({
                    ownerEmail:owners.data().email,
                    company_name:owners.data().company_name
                })
            }
        })
        let allAgentss = await getAllAwsAgents();
        let ownerDetails = await getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
        let ownerDetailsData = {}
        if (ownerDetails.status === 200) {
            ownerDetailsData = ownerDetails.data;
        }
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').where('deletetime','==',0).get().then((docs) => {
            var agents = [];
            var owner = [];
            var i = 0;
            let currenTime = new Date().getTime();
            docs.forEach((doc) => {
                if (doc.data().roleType === 'Owner') {
                    let ownerData = doc.data();
                    if (ownerDetails.status === 200) {
                        ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                        ownerData.last_name = ownerDetailsData.last_name ? ownerDetailsData.last_name : '';
                        ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                        ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                        ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                        ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                        ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                        ownerData.alias = ownerDetailsData.alias ? ownerDetailsData.alias : '';
                        ownerData.profile_pic = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : ''
                    }
                    owner[0] = ownerData;
                    owner[0]['id'] = doc.id;
                } else {
                    let agentData = doc.data();
                    delete agentData.alias;
                    agents[i] = agentData;
                    agents[i]['id'] = doc.id;
                    i++;
                }
            })
            let merged = [];
            for (let i = 0; i < allAgentss.length; i++) {
                merged.push({
                    ...allAgentss[i],
                    ...(agents.find((itmInner) => itmInner.id == allAgentss[i].subid))
                }
                );
            }
            merged.push(owner[0]);
            let agentsWithoutOwner = [];
            merged.forEach((mAgent) => {
                console.log('email_verified', mAgent.email_verified);
                if(mAgent.status === 1 && mAgent.email_verified) {
                    let agentName = mAgent.last_name && mAgent.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? mAgent.first_name + ' ' + mAgent.last_name : mAgent.first_name;
                    agentsWithoutOwner.push({ name : agentName, id : mAgent.id });
                }
            })
            this.setState({
                agentsWithoutOwner : agentsWithoutOwner,
                agents:merged,
                loader: false
            })
        });
    }

    handleChange = (e) => {
        let target = e.target;
        if(target.name === "name") {
            if((target.value).trim() !== "") {
                this.setState({
                    disableCreateBtn : false
                });
            } else {
                this.setState({
                    disableCreateBtn : true
                });
            }
        }
        let values = target.value;
        removeHtmlTags(values);
        this.setState({
            [target.name]:values
        })
    }

    /* Added By AG Start */

    handleAgentChange = (e, agents) => {
        let agentIdes = [];
        let agentNames = [];
        agents.forEach(agent => {
            agentIdes.push(agent.id);
            agentNames.push(agent.name);
        });
        this.setState({
            agentsName: agentNames,
            agentList : agentIdes
        });
    }

    /* Added By AG End */

    hideDepartment = (e) => {
        this.props.getDepartmentStatus(false);
        this.setState({
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validation();
        if(validate===true){
            let departmentName = this.state.name.trim();
            this.setState({name:removeHtmlTags(departmentName)})
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').where('name','==',departmentName).get().then((data) => {
                if(data.empty===true) {
                    this.setState({
                        loader:true
                    })
                    let addedon = Date.now();
                    this.setState({description:removeHtmlTags(this.state.description)})
                    console.log("------hellooo---", this.state.description)
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').add({addedon:addedon,name:departmentName,description:this.state.description}).then((ref)=> {
                        if(this.state.agentList.length>0) {
                            this.updateDepartments(ref.id);
                        }    
                        this.sendEmail();
                        this.props.getDepartmentStatus(false);
                        toast.success('Team has been added successfully!');
                        this.setState({
                            name:'',
                            name_error:'',
                            description:'',
                            description_error:'',
                            agentList:[],
                            loader:false
                        })
                        
                        activityLog('Added department');
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    this.setState({
                        name_error:'This Team has been already added'
                    })
                }
            })
            return false;
            
        }
    }

    updateDepartments = (depId) => {
        this.state.agentList.forEach((agentId) => {
            let oldDepartment = this.getDepartment(agentId);
            if(Object.keys(oldDepartment).length === 0) {
                oldDepartment = [];
            }
            oldDepartment.push(depId);
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({departments:oldDepartment}).then((ref) => {
                // console.log(ref);
            }).catch((err) => {
                console.log(err);
            })
        })
    }

    getDepartment = (agentId) => {
        let agentValue;
        this.state.agents.forEach((value,index) => {
            if(value.id===agentId) {
                agentValue = value.departments;
            }
        })
        return agentValue;
    }

    validation = (field) => {
        const htmlTagRegex = /<[^>]*>/;
        const hyperlinkRegex = /https?:\/\/[^\s]+/;
    
        let nameError = '';
        let descriptionError = '';
    
        if (this.state.name === '') {
            nameError = 'Team Name should not be empty';
        } else if (htmlTagRegex.test(this.state.name)) {
            nameError = 'Team Name should not contain HTML tags';
        } else if (hyperlinkRegex.test(this.state.name)) {
            nameError = 'Team Name should not contain hyperlinks';
        }
    
        if (this.state.description === '') {
            descriptionError = 'Team Description should not be empty';
        } else if (htmlTagRegex.test(this.state.description)) {
            descriptionError = 'Team Description should not contain HTML tags';
        } else if (hyperlinkRegex.test(this.state.description)) {
            descriptionError = 'Team Description should not contain hyperlinks';
        }
    
        this.setState({
            name_error: nameError,
            description_error: descriptionError,
            disableCreateBtn: nameError !== '' || descriptionError !== ''
        });
    
        return nameError === '' && descriptionError === '';
    };
    
    

    componentDidUpdate(prev_props) {
        if(prev_props.sendDepartmentStatus === false && this.props.sendDepartmentStatus === true) {
            activityLog('Opened Add department page');
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showAddDepartment !== props.sendDepartmentStatus) {
          return {
            showAddDepartment: props.sendDepartmentStatus
          }
        }
        return null
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.hideDepartment();
    }

    sendEmail = () => {
        let ownerEmail = this.agent.ownerEmail ?  this.agent.ownerEmail : this.state.ownerEmail;
        var body = [
                `email=${ownerEmail.toLowerCase()}&name=${this.state.name}&type=department_created&description=${this.state.description}&agents=${this.state.agentsName.toString()}&company_name=${this.state.company_name}`
              ];
              fetch(GATEWAY_URL+'/sendEmail', {
                method: "post",
                body: body,
                headers: { "X-API-Key" : FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
              })
                .then()
                .then(response => {
                //   console.log(response);
                })
                .catch(e => {
                  console.log(e, "ERROR");
                });
    }

    render() {
        let selectedAgents = [];
        this.state.agentsWithoutOwner.map(v => {
            if (this.state.agentList.indexOf(v.id) !== -1) {
                selectedAgents.push(v);
            }
        });
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showAddDepartment ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Add Team</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideDepartment}>
                                        <img src={ require('../../assets/img/icon/right-panel-crose.svg') } alt="" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="outlined-basic" label="Team Name*" variant="outlined" name="name" value={this.state.name} onChange={this.handleChange} onKeyUp={()=>this.validation("name")} autoComplete="off" />
                                        {/*  <label className="mb-1 small text-screen1">Name</label>
                                        <input type="text" placeholder="Enter Team*" maxLength="40" className="w-100" name="name" onChange={this.handleChange} value={this.state.name} onKeyUp={this.validation} autoComplete="off" /> */}
                                        <p className="error">{this.state.name_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" minRows={4} id="outlined-multiline" label="Team Description" variant="outlined" name="description" onChange={this.handleChange} onKeyUp={()=>this.validation("description")} value={this.state.description}  multiline />
                                        <p className="error">{this.state.description_error}</p>
                                        {/* <label className="mb-1 small text-screen1">Description</label>
                                        <textarea type="text" placeholder="Description" className="w-100 tarea-lh" name="description"  onChange={this.handleChange} value={this.state.description}></textarea> */}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4 mt-2">
                                    <div className="input-group material-select">
                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            value={selectedAgents}
                                            options={this.state.agentsWithoutOwner}
                                            //disableCloseOnSelect
                                            //onOpen={this.handleOpen}
                                            //onClose={this.handleClose}
                                            open={true}
                                            onChange={(e, option) => this.handleAgentChange(e, option)}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <><Checkbox icon={icon} name="agentList" value={option.id} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />{option.name}</>
                                            )}
                                            style={{ width: 500 }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Select Agent(s)" placeholder="Search Agent" />
                                            )}
                                        />

                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4 posBottom">
                                    <div className="wrapperInfo">
                                        <button className= { this.state.disableCreateBtn ? "mr-4 btnBlue btn-disable" : "mr-4 btnBlue"} disabled={this.state.disableCreateBtn}>Create Team</button>
                                        <button className="btnWhite" onClick={this.cancelForm}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default AddDepartment;