import React from 'react';
import { MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
import queryString from 'query-string';
import { getCookie } from '../../services/service';

class Verify extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
           
        }
    }

    async componentDidMount() {
        const urlValues = queryString.parse(window.location.search);
        const connectSid = getCookie('APPYID');
        const common_eml = getCookie('common_eml');
        console.log('urlValues >>>', urlValues);
        // let body = [`t=${urlValues.t}`];
        let body = {
            t : encodeURIComponent(urlValues.t),
            connectSid: encodeURIComponent(connectSid),
            common_eml: encodeURIComponent(common_eml),
            loginCookie: 1
        }
        //fetch(MAIL_FUNCTION_URL + "/connectIntegration/getauth", {
        fetch(GATEWAY_URL + "/getauth", {
            method: "post",
            body: JSON.stringify(body),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/json",
                "Origin": window.location.origin
            }
            }).then((resp) => resp.json())
            .then((response) => {
                console.log(response);
                setInterval(() => {
                    window.close();
                }, 2000);
            }).catch((err) => {
                console.log(err);
                setInterval(() => {
                    window.close();
                }, 2000);
            })

        // let cookieVal = await getCookieVal('79fefaf2564aabb19d44fe324844c86b');
        // let loginStatus = window.location.hostname === "localhost" ? 1 : cookieVal;
        // const redirectURI = urlValues.redirect_uri ? urlValues.redirect_uri : REDIRECT_URI;
        // console.log('loginStatus ', loginStatus);
        // const { client_id, redirect_uri, response_type, state } = urlValues;
        // if (parseInt(loginStatus) === 1) {
        //     const userSession = await getUserSession('owner');
        //     const resultUserData = userSession.userdata !== '' ? JSON.parse(userSession.userdata) : "";
        //     const userData = resultUserData.userdata;
        //     const clientEmail = userData.email ? userData.email : "";
        //     const liveChatAccess = userData.apps_access.filter((user) => user.product === 'livechat');
        //     const userId = liveChatAccess[0].user_id;
        //     let body = [`client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}&clientEmail=${clientEmail}&userId=${userId}`]
        //     fetch(MAIL_FUNCTION_URL+"/connectIntegration/saveAuth", {
        //         method: "post",
        //         body: body,
        //         headers: {
        //             "Content-Type": "application/x-www-form-urlencoded",
        //             "Origin": window.location.origin
        //         }
        //     }).then((resp) => resp.json())
        //     .then((response) => {
        //         window.location.href = redirectURI;
        //     }).catch((err) => {
        //         console.log(err);
        //         window.location.href = redirectURI;
        //     })
        // } else {
        //     window.location.href = COMMON_LOGIN_URL + "/login?frompage=" + DOMAIN_NAME + "/connect/verify?website=" + DOMAIN_NAME;
        // }
    }

    render() {
        return (<div className="loading" style={{ display: 'block' }}>Loading&#8230;</div>);
    }
}

export default Verify;