import React, { PureComponent } from 'react';
import parse from 'html-react-parser';
import 'emoji-mart/css/emoji-mart.css';
// import db from "../../../../config/firebase";
import ChatContext from '../../components/ChatContext';
import moment from 'moment-timezone';
import { GOOGLE_TRANSLATION_KEY, MAIL_FUNCTION_URL, currencyData, DOMAIN_NAME, PAYMENT_CHECKOUT_URL, GATEWAY_URL, FUNCTION_API_KEY, HIDE_TRY_NOW_COUNTRIES, PAYMENT_TRIAL_PERIOD } from '../../../../config/siteConfig';
import WidgetInstallationComponent from '../../../organization/WidgetInstallationComponent';
import { checkUrl, getDateInUTC, decodeEntities } from '../../components/Comman';
import singleTick from '../../../../assets/images/tick.svg';
import doubleTick from '../../../../assets/images/double-tick.svg';
import blueTick from '../../../../assets/images/double-tick-indicator.svg';
import sendingMsg from '../../../../assets/img/icon/loaderUpload3.gif';
import { addChatData, getChatData, deleteChatData, saveInActiveChat, addMessage, getMessage, deleteMessage, deletePlanData } from '../../components/Dblovefield';
import { VISITOR_MESSAGE_TEMPLATE, AGENT_MESSAGE_TEMPLATE, AGENT_ATTACHMENT_MESSAGE, VISITOR_ATTACHMENT_MESSAGE, FORM_DATA_TEMPLATE, THIRD_MESSAGE_TEMPLATE, AGENT_DOCS_MESSAGE, VISITOR_DOCS_MESSAGE, AGENT_VIDEO_MESSAGE, VISITOR_VIDEO_MESSAGE, VISITOR_PLAY_VIDEO_MESSAGE, AGENT_SMS_TEMPLATE, VISITOR_SMS_TEMPLATE, AGENT_CALL_TEMPLATE, VISITOR_CALL_TEMPLATE, AGENT_VOIP_MESSAGE_TEMPLATE, AGENT_VOIP_ATTACHMENT_MESSAGE, AGENT_WHATSAPP_TEMPLATE, VISITOR_WHATSAPP_TEMPLATE, AGENT_VOIP_VIDEO_MESSAGE, AGENT_VOIP_DOCS_MESSAGE, AGENT_FB_TEMPLATE, VISITOR_FB_TEMPLATE, VISITOR_FB_ATTACHMENT_MESSAGE, VISITOR_FB_VIDEO_MESSAGE, AGENT_TWITTER_TEMPLATE, VISITOR_TWITTER_TEMPLATE, VISITOR_TWITTER_ATTACHMENT_MESSAGE, VISITOR_TWITTER_VIDEO_MESSAGE, AGENT_INSTA_TEMPLATE, VISITOR_INSTA_TEMPLATE, AGENT_ZOOM_TEMPLATE, AGENT_INSTA_DELETED_TEMPLATE, VISITOR_INSTA_ATTACHMENT_MESSAGE, VISITOR_INSTA_VIDEO_MESSAGE, VISITOR_INSTA_STORY_REPLY, VISITOR_INSTA_MESSAGE_REPLY_TEMPLATE, TRIGGER_MESSAGE_TEMPLATE, AGENT_CALL_RECORDING_TEMPLATE, VISITOR_CALL_RECORDING_TEMPLATE, VISITOR_INSTA_DOCS_MESSAGE, VISITOR_WHATSAPP_ATTACHMENT_MESSAGE, VISITOR_WHATSAPP_DOCS_MESSAGE, VISITOR_WHATSAPP_VIDEO_MESSAGE, VISITOR_WHATSAPP_MESSAGE, AGENT_WHATSAPP_ATTACHMENT_MESSAGE, AGENT_WHATSAPP_VIDEO_MESSAGE, AGENT_WHATSAPP_MESSAGE, AGENT_INSTA_ATTACHMENT_MESSAGE } from './messageTemplates';
import WidgetInstallationInfo from './widgetInstallationInfo';
import { checkPaymentStatus } from '../../../../services/payment';
import { getCallStatus, rejectCall, endCall } from '../../components/Twilio';
import { logActivityInFirestore } from '../../../../services/service';

import $ from 'jquery';
import Loader from '../../../loader/loader';
import { ContactsOutlined } from '@material-ui/icons';
import firebaseServices from '../../../../firebase';


var scrollPosition = 0;
const totalRunSnapshot = 25;
const showMessagesTypes = [2, 3, 4, 7, 11, 12, 13, 16, 17, 22, 23, 31, 41, 42, 43, 44, 45, 46, 47, 48, 71, 72, 73, 74, 75]; /// 15,16,17 are for facebook, 21,22, 23 is for Twitter, 41, 42, 43, 44, 45, 46, 47, 48 for instagram, 31 for Zoom
var lang_code = {
	'Afrikaans': 'af',
	'Albanian': 'sq',
	'Amharic': 'am',
	'Arabic': 'ar',
	'Armenian': 'hy',
	'Azerbaijani': 'az',
	'Basque': 'eu',
	'Belarusian': 'be',
	'Bengali': 'bn',
	'Bosnian': 'bs',
	'Bulgarian': 'bg',
	'Catalan': 'ca',
	'Cebuano': 'ceb',
	'Chinese (Simplified)': 'zh-CN',
	"Chinese (Traditional)": 'zh-TW',
	'Corsican': 'co',
	'Croatian': 'hr',
	'Czech': 'cs',
	'Danish': 'da',
	'Dutch': 'nl',
	'English': 'en',
	'Esperanto': 'eo',
	'Estonian': 'et',
	'Finnish': 'fi',
	'French': 'fr',
	'Frisian': 'fy',
	'Galician': 'gl',
	'Georgian': 'ka',
	'German': 'de',
	'Greek': 'el',
	'Gujarati': 'gu',
	'Haitian Creole': 'ht',
	'Hausa': 'ha',
	'Hawaiian': 'haw',
	'Hebrew': 'iw',
	'Hindi': 'hi',
	'Hmong': 'hmn',
	'Hungarian': 'hu',
	'Icelandic': 'is',
	'Igbo': 'ig',
	'Indonesian': 'id',
	'Irish': 'ga',
	'Italian': 'it',
	'Japanese': 'ja',
	//'Javanese': 'jv',
	'Javanese': 'jw',
	'Kannada': 'kn',
	'Kazakh': 'kk',
	'Khmer': 'km',
	'Korean': 'ko',
	'Kurdish': 'ku',
	'Kyrgyz': 'ky',
	'Lao': 'lo',
	'Latin': 'la',
	'Latvian': 'lv',
	'Lithuanian': 'lt',
	'Luxembourgish': 'lb',
	'Macedonian': 'mk',
	'Malagasy': 'mg',
	'Malay': 'ms',
	'Malayalam': 'ml',
	'Maltese': 'mt',
	'Maori': 'mi',
	'Marathi': 'mr',
	'Mongolian': 'mn',
	'Myanmar (Burmese)': 'my',
	'Nepali': 'ne',
	'Norwegian': 'no',
	'Nyanja': 'ny',
	'odia': 'or',
	'Pashto': 'ps',
	'Persian': 'fa',
	'Polish': 'pl',
	'Portuguese': 'pt',
	'Punjabi': 'pa',
	'Romanian': 'ro',
	'Russian': 'ru',
	'Samoan': 'sm',
	'Scots Gaelic': 'gd',
	'Serbian': 'sr',
	'Sesotho': 'st',
	'Shona': 'sn',
	'Sindhi': 'sd',
	'Sinhala': 'si',
	'Slovak': 'sk',
	'Slovenian': 'sl',
	'Somali': 'so',
	'Spanish': 'es',
	'Sundanese': 'su',
	'Swahili': 'sw',
	'Swedish': 'sv',
	'Tagalog': 'tl',
	'Tajik': 'tg',
	'Tamil': 'ta',
	'Tatar': 'tt',
	'Telugu': 'te',
	'Thai': 'th',
	'Turkish': 'tr',
	'Turkmen': 'tk',
	'Ukrainian': 'uk',
	'Urdu': 'ur',
	'Uyghur': 'ug',
	'Uzbek': 'uz',
	'Vietnamese': 'vi',
	'Welsh': 'cy',
	'Xhosa': 'xh',
	'Yiddish': 'yi',
	'Yoruba': 'yo',
	'Zulu': 'zu'
};

let lang_code_list = [];
for (const key in lang_code) {
	if (lang_code.hasOwnProperty(key)) {
		const c = lang_code[key];
		var temp = {};
		temp['Name'] = key;
		temp['Code'] = c.trim();
		lang_code_list.push(temp);
	}
}

class Chat extends PureComponent {
	static contextType = ChatContext;  // this.context will work when it defined as is
	isFirstTime = true;

	// To use global data pass second parameter context in constructor
	constructor(props, context) {
		super(props);
		this.chatSnapShots = [];
		this.typingSnapshots = [];
		this.visitorSnapShotsId = [];
		this.visitorStatusTimeout = [];
		this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
		this.settings = {
			dots: false,
			infinite: true,
			speed: 0,
			slidesToShow: context.slidesToShow,
			slidesToScroll: 1,
			focusOnSelect: true
		};
		this.intervelTime = 40 * 1000;
		this.isActive = true;
		this.getQueryOnEndChat = true;
		//// For paging in chat section start /////////

		this.messages = [];
		this.listeners = []; // list of listeners
		this.start = null; // start position of listener
		this.end = null;  // end position of listener
		this.load_id=0;
		///////d////
		this.messageListElement = "";

		//// For paging in chat section End /////////

		var offset = new Date().getTimezoneOffset() * 60000;
		this.state = {
			showMidChat: context.showMidChat,
			liveChatDocId: "",
			currentDate: new Date().setHours(0, 0, 0, 0),
			isServerTimeGet: false,
			currentDate_server: '',
			agent_short: this.agent.agent_short? this.agent.agent_short:(this.agent.agent_name?.trim()?.split(" ")[0][0] + (this.agent.agent_name?.trim().split(" ")[1] ? this.agent.agent_name?.trim().split(" ")[1][0] : this.agent.agent_name?.trim().split(" ")[0][1])).toUpperCase(),
			chatdata: [],
			chatdetail: [],
			pholder: {},
			offset: offset,
			windowDetails: {},
			idle_time: 5,
			detected_lang: {},
			autotranslation: {},
			settings: {},
			checkReload: false,
			endChatVisitorId: "",
			detectedLanguageCode: "",
			isShowPaidButtonSec: "none",

			plan: '',
			modalVisible: false,
			billing_cycle: '',
			amount: '',
			agents: '',
			chats_completed: '',
			next_billing_date: '',
			billing_information: '',
			plan_data: [],
			loader: false,
			billingList: '',
			no_of_agent: 1,
			requestKey: '',
			errormessage: '',
			currency: '$',
			planprice: '',
			totalprice: '',
			payment_list: [],
			historyCount: 0,
			history_link: '',
			owner_id: '',
			company_name: '',
			owner_email: '',
			current_plan_id: '',
			current_plan_period: '',
			current_agent: 1,
			commonlogin_user_id: '',
			payment_status: '',
			payment_enabled: 0,
			chat_count: 1,
			currencyData: currencyData,
			show_currency: '',
			country_code: 'US',
			plan_list: [],
			planPriceYearly: 84,
			planPriceMonthly: 10,
			planPriceDiscount: 3,
			showGoToDashboard: true,
			totalAgents: 1,
			showTryNowButon: false,
			trialStatus: true,
			countryCode: '',
			originalCountryCode: '',
			currencyCode: '',
			show_old_free_plan: false,
			revealDataEnabled: false,
			triggermsgId:""
		};

		this.getSettings();
	}

	////////////// Private Function start ////////

	getSettings = () => {
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('settings').doc("IDLETIME").get().then(result => {
			if (result.exists) {
				var setting = result.data();
				setting.agent_inactive_min = parseInt(setting.agent_inactive_min);
				setting.agent_inactive_status = parseInt(setting.agent_inactive_status);
				setting.agent_reply_min = parseInt(setting.agent_reply_min);
				this.setState({ settings: setting });
			}
		});
	}

	contentTypeFromURL(url) {
		var xhttp = new XMLHttpRequest();
		xhttp.open('HEAD', url);
		xhttp.onreadystatechange = function () {
			if (this.readyState == this.DONE) {
				return this.getResponseHeader("Content-Type");
			}
		};
		return xhttp.send();
	}


	compareformdata(ndata,odata){
		if(ndata[1]==""&&ndata[2]==""&&ndata[3]==""){
			ndata=odata
		}
	}


	updateTimeForm=(key,time)=>{
		return
		console.log("updating_time_form",key,time)
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).collection('chat_data').doc("FORM_DATA").update({ addedon: time });

	}

	/* Get All Chat Data start */

	createAndInsertMessage = (id, docData, visitorId,skipLoadCheck,load_id) => {
		console.log(docData,visitorId,load_id,this.load_id,"createAndInsertMessage")
		if ( visitorId == this.state.liveChatDocId && (skipLoadCheck || load_id == this.load_id)) {
			// its correct call of messages of active client
		}else{
			return false;
		}
		try {
			document.getElementById('messagesdata').style.display = 'block';
		} catch (eee) { }

		const tmpCurVisitor = this.context.currentVisitor ? this.context.currentVisitor : {};
		var chatType = tmpCurVisitor.start_url ? tmpCurVisitor.start_url : "";

		var timestamp = docData.addedon ? docData.addedon : Date.now();
		const container = document.createElement('div');
		var isForm=false
		
		var isTrigger=false
		
		if(this.context.currentVisitor.current_pageUrl=="Whatsapp"  ){
		this.autocloseWhatsAppChat(this.context.currentVisitor?.operating,this.context.currentVisitor?.id)
		}

		switch (docData.msg_type) {
			case 6:
			case 7:
				container.innerHTML = THIRD_MESSAGE_TEMPLATE;
				break;
			case 8:
				container.innerHTML = FORM_DATA_TEMPLATE;
				isForm=true
				break;
			case 2:
				if (docData.agent_id) {
					if (chatType === "voip_call") {
						container.innerHTML = AGENT_VOIP_ATTACHMENT_MESSAGE;
					} else {
						container.innerHTML = AGENT_ATTACHMENT_MESSAGE;
					}
				} else {
					container.innerHTML = VISITOR_ATTACHMENT_MESSAGE;
				}
				break;
			case 3:
				if (docData.agent_id) {
					if (chatType === "voip_call") {
						container.innerHTML = AGENT_VOIP_DOCS_MESSAGE;
					} else {
						container.innerHTML = AGENT_DOCS_MESSAGE;
					}
				} else {
					container.innerHTML = VISITOR_DOCS_MESSAGE;
				}
				break;
			case 4:
				if (docData.msg_url.indexOf(".wmv") !== -1) {
					if (docData.agent_id) {
						if (chatType === "voip_call") {
							container.innerHTML = AGENT_VOIP_VIDEO_MESSAGE;
						} else {
							container.innerHTML = AGENT_VIDEO_MESSAGE;
						}
					} else {
						container.innerHTML = VISITOR_PLAY_VIDEO_MESSAGE;
					}
				} else {
					if (docData.agent_id) {
						if (chatType === "voip_call") {
							container.innerHTML = AGENT_VOIP_VIDEO_MESSAGE;
						} else {
							container.innerHTML = AGENT_VIDEO_MESSAGE;
						}
					} else {
						container.innerHTML = VISITOR_VIDEO_MESSAGE;
					}
				}
				break;
			case 11:
				if (docData.agent_id) {
					container.innerHTML = AGENT_SMS_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_SMS_TEMPLATE;
				}
				break;
			case 12:
				if (docData.agent_id) {
					container.innerHTML = AGENT_CALL_RECORDING_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_CALL_RECORDING_TEMPLATE;
				}
				break;
			case 13:
				if (docData.agent_id) {
					container.innerHTML = AGENT_WHATSAPP_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_WHATSAPP_TEMPLATE;
				}
				break;
			case 15:
				if (docData.agent_id) {
					container.innerHTML = AGENT_FB_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_FB_TEMPLATE;
				}
				break;
			case 16:
				if (docData.agent_id) {
					container.innerHTML = AGENT_ATTACHMENT_MESSAGE;
				} else {
					container.innerHTML = VISITOR_FB_ATTACHMENT_MESSAGE;
				}
				break;
			case 17:
				if (docData.agent_id) {
					container.innerHTML = AGENT_VIDEO_MESSAGE;
				} else {
					container.innerHTML = VISITOR_FB_VIDEO_MESSAGE;
				}
				break;
			case 21:
				if (docData.agent_id) {
					container.innerHTML = AGENT_TWITTER_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_TWITTER_TEMPLATE;
				}
				break;
			case 22:
				if (docData.agent_id) {
					container.innerHTML = AGENT_ATTACHMENT_MESSAGE;
				} else {
					container.innerHTML = VISITOR_TWITTER_ATTACHMENT_MESSAGE;
				}
				break;
			case 23:
				if (docData.agent_id) {
					container.innerHTML = AGENT_VIDEO_MESSAGE;
				} else {
					container.innerHTML = VISITOR_TWITTER_VIDEO_MESSAGE;
				}
				break;
			case 31:
				container.innerHTML = AGENT_ZOOM_TEMPLATE;
				break;
			case 41:
				if (docData.msg_deleted === 1) {
					container.innerHTML = AGENT_INSTA_DELETED_TEMPLATE;
				} else {
					if (docData.agent_id) {
						container.innerHTML = AGENT_INSTA_TEMPLATE;
					} else {
						container.innerHTML = VISITOR_INSTA_TEMPLATE;
					}
				}
				break;
			case 42:
				if (docData.agent_id) {
					container.innerHTML = AGENT_INSTA_ATTACHMENT_MESSAGE;
				} else {
					container.innerHTML = VISITOR_INSTA_ATTACHMENT_MESSAGE;
				}
				break;
			case 43:
				if (docData.agent_id) {
					container.innerHTML = AGENT_VIDEO_MESSAGE;
				} else {
					container.innerHTML = VISITOR_INSTA_VIDEO_MESSAGE;
				}
				break;
			case 44:
				if (docData.agent_id) {
					container.innerHTML = AGENT_ATTACHMENT_MESSAGE;
				} else {
					container.innerHTML = VISITOR_INSTA_ATTACHMENT_MESSAGE;
				}
				break;
			case 45:
				if (docData.agent_id) {
					container.innerHTML = AGENT_VIDEO_MESSAGE;
				} else {
					container.innerHTML = VISITOR_INSTA_STORY_REPLY;
				}
				break;
			case 46:
				if (docData.agent_id) {
					container.innerHTML = AGENT_INSTA_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_INSTA_MESSAGE_REPLY_TEMPLATE;
				}
				break;
			case 47:
				if (docData.agent_id) {
					container.innerHTML = AGENT_INSTA_TEMPLATE;
				} else {
					container.innerHTML = VISITOR_INSTA_MESSAGE_REPLY_TEMPLATE;
				}
				break;
			case 48:
				if (docData.agent_id) {
					container.innerHTML = AGENT_DOCS_MESSAGE; // It will not use
				} else {
					container.innerHTML = VISITOR_INSTA_DOCS_MESSAGE;
				}
				break;
			case 61:
				
				isTrigger=true
				
				container.innerHTML = TRIGGER_MESSAGE_TEMPLATE;
				break;
			case 71:
				// image/jpeg
				if (docData.agent_id) {
					container.innerHTML = AGENT_WHATSAPP_ATTACHMENT_MESSAGE;
				} else {
					container.innerHTML = VISITOR_WHATSAPP_ATTACHMENT_MESSAGE;
				}
				break;
			case 72:
				// recording
				container.innerHTML = VISITOR_WHATSAPP_DOCS_MESSAGE;
				break;
			case 73:
				// video
				if (docData.agent_id) {
					container.innerHTML = AGENT_WHATSAPP_VIDEO_MESSAGE;
				} else {
					container.innerHTML = VISITOR_WHATSAPP_VIDEO_MESSAGE;
				}
				break;
			case 74:
			case 75:
				// application/pdf
				// 75 :  application/vnd.openxmlformats-officedocument.wordprocessingml.document
				if (docData.agent_id) {
					container.innerHTML = AGENT_WHATSAPP_MESSAGE;
				} else {
					container.innerHTML = VISITOR_WHATSAPP_MESSAGE;
				}
				break;
			default:
				if (docData.agent_id) {
					if (chatType === "voip_call") {
						container.innerHTML = AGENT_VOIP_MESSAGE_TEMPLATE;
					} else {
						container.innerHTML = AGENT_MESSAGE_TEMPLATE;
					}
				} else {
					container.innerHTML = VISITOR_MESSAGE_TEMPLATE;
				}
		}

		const div = container.firstChild;
		div.setAttribute('id', id);
		if(docData.msg_type==8){
			timestamp=0
		}
		// If timestamp is null, assume we've gotten a brand new message.
		// https://stackoverflow.com/a/47781432/4816918
		div.setAttribute('timestamp', timestamp);

		// figure out where to insert new message
		var messageListElement = document.getElementById('messagesdata');
		const existingMessages = messageListElement.children;
		console.log("existingMessages",existingMessages)
		if (existingMessages.length === 0) {
			console.log("first_msg_added",docData, visitorId, this.state.liveChatDocId, load_id, this.load_id, skipLoadCheck);
			if (visitorId == this.state.liveChatDocId && (skipLoadCheck || load_id == this.load_id)) {
				messageListElement.appendChild(div);
			}else{
				console.log("first_msg_addedelse", visitorId)
				return false;
			}
			this.context.updateGlobalData("chatLoader",false)
		} else {
			this.context.updateGlobalData("chatLoader",false)

			
			
			let messageListNode = existingMessages[0];
			while (messageListNode) {
				const messageListNodeTime = messageListNode.getAttribute('timestamp');
				if (!messageListNodeTime) {
					throw new Error(
						`Child ${messageListNode.id} has no 'timestamp' attribute`
					);
				}
				if (messageListNodeTime > timestamp) {
					break;
				}
				messageListNode = messageListNode.nextSibling;
			}
			try {
				if (visitorId == this.state.liveChatDocId && (skipLoadCheck || load_id == this.load_id)) {
					messageListElement.insertBefore(div, messageListNode);
				}else{
					return
				}
			} catch (err) {
				console.log("---------------   Error in inserting message in middle section   -----------------");
				console.log(err);
			}

		// }

		}
		return div;
	}
	isVisitorValid = (vId) =>{
		if(this.state.liveChatDocId == vId){
			return true;
		}else{
			return false;
		}
	}
	getMsgList = (c) => {
		var messageListElement = document.getElementById('messagesdata');
		const existingMessages = messageListElement?.children;
		console.log("num_messages_"+c,existingMessages?.length)
	}


	getChatSnapshot = async (currentVisitor, isForceReload = false) => {
		const load_id_old = Date.now();
		this.load_id = load_id_old;
		// this.getMsgList(3);
		console.log("onsnapshot_started1",currentVisitor);
		if (currentVisitor.incoming === 0 && currentVisitor.visitor_status === "new") {
			return false;
		}
		const fromVisitor = currentVisitor.fromVisitor ? currentVisitor.fromVisitor : false;
		let key = currentVisitor.id;

		if(!this.isVisitorValid(key)){
			return;
		}

		this.isActive = true;
		let indexOfKey = this.visitorSnapShotsId.indexOf(key);
		this.isFirstTime = true;
		if (indexOfKey !== -1) {
			this.context.updateGlobalData("placeHolder", "Type here to chat");
			this.visitorSnapShotsId.splice(indexOfKey, 1);  // Remove
			this.visitorSnapShotsId.splice(0, 0, key); // Add

			try {
				firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).collection('chat_data').doc("FORM_DATA").update({ showdata: Date.now() });
			} catch (err) {
				console.log("err")
			}
			console.log(key,"returning_fromsnapshot")
			return false;
		} else {
			if (this.visitorSnapShotsId.length > 0) {
				this.visitorSnapShotsId.splice(0, 0, key);
			} else {
				this.visitorSnapShotsId.push(key);
			}
		}

		this.context.updateGlobalData("placeHolder", "Type here to chat");
		if (!currentVisitor.client_short) {
			currentVisitor.client_short = "AP";
		}

		///////////// To getting chat messages Start /////////////

		var isChatEnd = false;
		// var msgsCount = new Array();
		// msgsCount.visitor = [];
		// msgsCount.agents = [];
		// var visitorM = [];
		// var agentM = [];
		var totalMsgs = [];
		if (this.getQueryOnEndChat && (currentVisitor.agent_end === "VISITOR_END_CHAT" || currentVisitor.isChatend === 1)) {

			this.setState({
				isShowPaidButtonSec: false
			})

			try {
				let indexOfKey = this.visitorSnapShotsId.indexOf(key);
				if (indexOfKey !== -1) {
					this.visitorSnapShotsId.splice(indexOfKey, 1); // remove from this array also
				}
			} catch (err) { }

			const chatDataFromLocalStorage = await getChatData(key);
			
			if (chatDataFromLocalStorage) {

				const visitorsChats = chatDataFromLocalStorage.chat_data;
				const parentID = chatDataFromLocalStorage.chat_id;

				try {
					document.getElementById('messagesdata').innerHTML = "";
				} catch (err) { }

				var totalChats = visitorsChats.length ? visitorsChats.length : 0;
				var lastMessageAgentId = null;
				var lastChatAction = null;

				if (totalChats > 0) {
					lastMessageAgentId = visitorsChats[0].agent_id;
					lastChatAction = visitorsChats[0].action;
				}

				for (const index in visitorsChats) {
					/* For stoping another chat data append in current chat start */
					if (parentID !== this.state.liveChatDocId) {
						return false;
					}
					/* For stoping another chat data append in current chat End */
					const docData = visitorsChats[index];
					const docId = docData.id;
					const {
						addedon,
						agent_id,
						msg,
						msg_url,
						agent_short,
						contenttype,
						filesize,
						msg_read_status
					} = docData;

					const msg_type = docData.msg_type ? docData.msg_type : 'NA';
					const agentProfileImg = docData.agent_profile_img ? docData.agent_profile_img : "";

					if (msg_type === 6) {
						const kk = docData.msg.indexOf('joined chat with you');
						if ((kk > 5) && (docData.agent_id !== this.agent.agentId) && fromVisitor !== "served") {
							//firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).get().then((visitor) => {
							//if (visitor.exists) {
							//var _data = visitor.data();
							if (!currentVisitor.agent_ids.includes(this.agent.agentId)) {
								if (lastMessageAgentId === this.agent.agentId && lastChatAction === "leavechat") {
									let enabledTranslations = this.disableTranslationOfChat(key);
									var arrayToSet = [{ key: "chatTranslator", value: enabledTranslations }];
									this.context.updateGlobalData("array", arrayToSet);
								} else {
									if (this.context.chatPickStatusTransaction.status === "chatting" && this.context.chatPickStatusTransaction.key === key) {
										// Do Nothing and Continue
									} else {
										let enabledTranslations = this.disableTranslationOfChat(key);
										var arrayToSet2 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Chat has been picked by another agent" } }, { key: "chatTranslator", value: enabledTranslations }];
										this.context.updateGlobalData("array", arrayToSet2);
									}
								}
								try {
									document.getElementById('Testing').style.height = 'calc(100vh - 215px)';
								} catch (error) { }
							}
							//}
							//});
						}

						var pp = docData.msg.search('end the chat');
						if ((pp > -1) && (agent_id !== this.agent.agentId)) {
							this.hideCenter(9, 'temp');
						}

						//You ended the chat.

						var pp2 = docData.msg.search('ended the chat');
						if (pp2 > -1 && docData.action === "VISITOR_END_CHAT" && agent_id === null) {
							let enabledTranslations = this.disableTranslationOfChat(key);
							var arrayToSet3 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Visitor has ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }];
							this.context.updateGlobalData("array", arrayToSet3);
						}

						if (docData.action === "EndChat1" || docData.action === "EndChat" || docData.action === "END_CHAT") {
							let enabledTranslations = this.disableTranslationOfChat(key);
							var arrayToSet4 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: currentVisitor.message_to_show } }, { key: "chatTranslator", value: enabledTranslations }];
							this.context.updateGlobalData("array", arrayToSet4);
						}

					}

					let chtMsg = msg;

					var showli = 1;
					var msgReadStatusTmp = msg_read_status;
					if (msg_type === 1 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46 || msg_type === 61) {
						var tmpchtMsg = String(chtMsg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						chtMsg = checkUrl(tmpchtMsg);
					} else if (showMessagesTypes.indexOf(msg_type) !== -1) {
						showli = 1;
					} else if (msg_type === 8 && msg !== '') {
						var checkEmpty = true;
						msg.forEach((msgData, i) => {
							if (msgData.A !== "") {
								checkEmpty = false;
							}
						});
						if (checkEmpty) {
							showli = 0;
						}
					} else if (msg_type === 6) {
						var agentId = docData.agent_id;
						if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
							showli = 0;
						} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "VISITOR_END_CHAT" || docData.action === "WELCOME_PAID_VISITOR")) {
							showli = 0;
						} else {
							if (docData.action === "addagent") {
								if (fromVisitor !== "served") {
									if (docData.agent_id_of_added_agent === this.agent.agentId) {
										if (chtMsg.indexOf('added agent') > -1) {
											chtMsg = "You've been added to chat by " + chtMsg.split('added agent')[0];
										}
									} else if (agentId === this.agent.agentId) {
										if (chtMsg.indexOf('added agent') > -1) {
											chtMsg = "You've added " + chtMsg.split('added agent')[1];
										}
									}
								}
							} else if (docData.action === "transferagent" && agentId !== this.agent.agentId) {
								if (chtMsg.indexOf('transferred chat to agent') !== -1 && fromVisitor !== "served") {
									chtMsg = chtMsg.split('transferred chat to agent')[0] + "has transferred the chat to you";
								}
							} else {
								if (agentId === this.agent.agentId) {
									chtMsg = docData.msg_alias !== undefined ? docData.msg_alias : chtMsg;
								} else {
									chtMsg = docData.msg_otheragent !== undefined ? docData.msg_otheragent : chtMsg;
								}
							}
						}
					} else {
						showli = 0;
					}

					if (docData.hasOwnProperty("msg_deleted") && showli === 1) {
						showli = 0;
					}

					if (showli === 1) {
						var div = document.getElementById(docId) || this.createAndInsertMessage(docId, docData, parentID,false,load_id_old);
						if (!div) {
							return false;
						}
						var tickIcon = "";
						if (msgReadStatusTmp === 1) {
							tickIcon = singleTick;
						} else if (msgReadStatusTmp === 2) {
							tickIcon = doubleTick;
						} else if (msgReadStatusTmp === 3) {
							tickIcon = blueTick;
						}

						var msgTime = this.getTimeFromDate(addedon);
						var stringUrl = '';
						var msgNew = '';
						var formatedSize = '';
						if (msg_type === 6) {
							div.querySelector('.date-agent').textContent = msgTime;
							div.querySelector('.text-muted').textContent = chtMsg;
						} else if (msg_type === 8) {
							var tmpchtMsg = "";
							var kppp = 0;
							var msgString = '<ul class="liveChat-question-answer">';
							msg.forEach((msgData, i) => {
								kppp = i;
								//tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
								tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
								msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + parse(tmpchtMsg) + '</span></p></li>';
							});
							msgString = msgString + '</ul>';
							//var messageElement = div.querySelector('.message-content-new');
							div.querySelector('.opacity-65').textContent = msgTime;
							div.querySelector('.message-content-new').innerHTML = msgString;
							try {
								document.getElementById("formAnswer_" + kppp).textContent = tmpchtMsg;
							} catch (errr) { }
						} else if (msg_type === 2 || msg_type === 16 || msg_type === 22 || msg_type === 42 || msg_type === 44) {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = agent_short ? agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							try {
								div.querySelector('.attachment').src = chtMsg;
								div.querySelector('.openattachment').href = chtMsg;
							} catch (err) { }
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
						} else if (msg_type === 3) {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							stringUrl = msg_url.replace(/[0-9]/g, '');
							msgNew = stringUrl;
							if (stringUrl.length > 15) {
								msgNew = stringUrl.substring(0, 15) + '...';
							}
							formatedSize = this.formatBytes(filesize, 2);
							let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
							div.querySelector('.docattach').href = msg;
							div.querySelector('.docattach').src = msg;
							div.querySelector('.docattach').download = msg_url;
							div.querySelector('.filesNameView').textContent = msgNew;
							div.querySelector('.fileSizeView').textContent = formatedSize;
							div.querySelector('.whichFileName').textContent = contenttypeTmp;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
						} else if (msg_type === 4 || msg_type === 17 || msg_type === 23 || msg_type === 43) {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							div.querySelector('.videomsg').href = msg;
							div.querySelector('.videomsg').src = msg;
							div.querySelector('.videomsg').download = msg_url;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
							if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
								stringUrl = msg_url.replace(/[0-9]/g, '');
								msgNew = stringUrl;
								if (stringUrl.length > 15) {
									msgNew = stringUrl.substring(0, 15) + '...';
								}
								div.querySelector('.filesNameView').textContent = msgNew;
								formatedSize = this.formatBytes(filesize, 2);
								div.querySelector('.fileSizeView').textContent = formatedSize;
								div.querySelector('.downloadattach').href = msg;
							}
						} else if (msg_type === 45) {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							let contentType = await this.contentTypeFromURL(msg);
							if (contentType === 'video/mp4') {
								let messageToAppend = '<video class="videomsg" src="' + msg + '" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="' + msg + '" download="' + msg + '"></video>'

								div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
							}
							else {
								let messageToAppend = '<a href="' + msg + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + msg + '"></a>';

								div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
							}
							div.querySelector('.message-content-new').innerHTML = docData.storyMsg;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
							if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
								stringUrl = msg_url.replace(/[0-9]/g, '');
								msgNew = stringUrl;
								if (stringUrl.length > 15) {
									msgNew = stringUrl.substring(0, 15) + '...';
								}
								div.querySelector('.filesNameView').textContent = msgNew;
								formatedSize = this.formatBytes(filesize, 2);
								div.querySelector('.fileSizeView').textContent = formatedSize;
								div.querySelector('.downloadattach').href = msg;
							}
						} else if (msg_type === 41 || msg_type === 46) {
							if (docData.msg_deleted === 1) {

							} else {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								if (docData.repliedToMessage && docData.repliedToMessage.message) {
									let messageToAppend = "";
									if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
										messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.repliedToMessage.message + '"></a>';
										div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
									} else if (docData.repliedMessageType === 43) {
										messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
										div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
									} else if (docData.repliedToMessage.messageType === 42) {
										messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.repliedToMessage.message + '"></a>';
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
									} else if (docData.repliedToMessage.messageType === 43) {
										messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="max-width: 50px; max-height: 50px" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
									} else if (docData.repliedToMessage.messageType === 48) {
										messageToAppend = '<audio controls><source class="instaaudio" src="' + docData.repliedToMessage.message + '" type="audio/ogg"><source class="instaaudio" src="' + docData.repliedToMessage.message + '" type="audio/mpeg">Your browser does not support the audio element.</audio>';
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
									} else {
										messageToAppend = docData.repliedToMessage.message;
										div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
									}
								} else if (msg_type === 46) {
									try {
										div.querySelector('.message_reply').style.display = "none";
									} catch (err) {
									}

								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							}
						} else if (msg_type === 47) {
							if (docData.msg_deleted === 1) {

							} else {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								if (docData.reactOnMessageId && docData.reactOnMessageId.message) {
									let messageToAppend = "";
									if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
										messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.reactOnMessageId.message + '"></a>';
										div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
									} else if (docData.repliedMessageType === 43) {
										messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
										div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
									} else if (docData.reactOnMessageId.messageType === 42) {
										messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
									} else if (docData.reactOnMessageId.messageType === 43) {
										messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
									} else if (docData.reactOnMessageId.messageType === 48) {
										messageToAppend = '<audio controls><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/ogg"><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/mpeg">Your browser does not support the audio element.</audio>';
										div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
									} else {
										messageToAppend = docData.reactOnMessageId.message;
										div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
									}
								} else if (msg_type === 47) {
									try {
										div.querySelector('.message_reply').style.display = "none";
									} catch (err) {
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							}
						} else if (msg_type === 48) {
							if (docData.agent_id) {
								div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							div.querySelector('.instaaudio').src = msg;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
						} else if (msg_type === 7) {
							div.querySelector('.text-muted').textContent = msg;
							div.querySelector('.date-agent').textContent = msgTime;
						} else if (msg_type === 11) {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							var messageElement = div.querySelector('.message-content-new');
							div.querySelector('.opacity-65').textContent = msgTime;
							messageElement.innerHTML = chtMsg;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
						} else if (msg_type === 12) {
							div.querySelector('.call-muted').innerHTML = msg;
							div.querySelector('.date-agent').textContent = msgTime;
							if (docData.recordingUrl) {
								div.querySelector('.call-record-audio').innerHTML = `<audio controls preload="none"><source src='${docData.recordingUrl}' type='audio/ogg'><source src='${docData.recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`;
							}
						} else {
							if (docData.agent_id) {
								div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
							} else {
								if (currentVisitor.visitor_type === "voip") {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									div.querySelector('.avshort').textContent = currentVisitor.client_short;
								}
							}
							div.querySelector('.opacity-65').textContent = msgTime;
							div.querySelector('.message-content-new').innerHTML = chtMsg;
							if (tickIcon) {
								div.querySelector('.tickimg').src = tickIcon;
							}
						}
				}
				}	
				try {
					this.scrollChatScreen(lastMessageAgentId);
					//this.clearChatData(key);
				} catch (err) { }

			} else {
				firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).collection('chat_data').orderBy('addedon', 'desc').limit(10).get({ source: "default" }).then(async (docs) => {
					if(!this.isVisitorValid(key)){
						return;
					}

					try {
						document.getElementById('messagesdata').innerHTML = "";
					} catch (err) { }

					var totalChats = docs.size;
					var lastMessageAgentId = null;
					var lastChatAction = null;

					if (totalChats > 0) {
						lastMessageAgentId = docs.docs[0].data().agent_id;
						lastChatAction = docs.docs[0].data().action;
					}

					const tmpChatDataForLF = [];
					for (const index in docs.docs) {
						const change = docs.docs[index];

						/* For stoping another chat data append in current chat start */
						const parentID = change.ref.parent.parent.id ? change.ref.parent.parent.id : "";
						if (parentID !== this.state.liveChatDocId) {
							return false;
						}
						/* For stoping another chat data append in current chat End */

						const docData = change.data();
						const docId = change.id;
						const {
							addedon,
							agent_id,
							msg,
							msg_url,
							contenttype,
							filesize,
							msg_read_status
						} = docData;

						/* for lovefield DB start */
						var tmpdocData = docData;
						tmpdocData.id = docId;
						tmpChatDataForLF.push(tmpdocData);
						/* for lovefield DB End */

						const msg_type = docData.msg_type ? docData.msg_type : 'NA';
						const agentProfileImg = docData.agent_profile_img ? docData.agent_profile_img : "";

						if (msg_type === 6) {
							const kk = docData.msg.indexOf('joined chat with you');
							if ((kk > 5) && (agent_id !== this.agent.agentId) && fromVisitor !== "served") {
								if (!currentVisitor.agent_ids.includes(this.agent.agentId)) {
									if (lastMessageAgentId === this.agent.agentId && lastChatAction === "leavechat") {
										let enabledTranslations = this.disableTranslationOfChat(key);
										var arrayToSet = [{ key: "chatTranslator", value: enabledTranslations }];
										this.context.updateGlobalData("array", arrayToSet);
									} else {
										if (this.context.chatPickStatusTransaction.status === "chatting" && this.context.chatPickStatusTransaction.key === key) {
											// Do Nothing and Continue
										} else {
											let enabledTranslations = this.disableTranslationOfChat(key);
											var arrayToSet5 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Chat has been picked by another agent" } }, { key: "chatTranslator", value: enabledTranslations }];
											this.context.updateGlobalData("array", arrayToSet5);
										}
									}
									try {
										document.getElementById('Testing').style.height = 'calc(100vh - 215px)';
									} catch (error) { }
								}
							}

							var pp3 = docData.msg.search('end the chat');
							if ((pp3 > -1) && (agent_id !== this.agent.agentId)) {
								this.hideCenter(9, 'temp');
							}

							//You ended the chat.
							var pp21 = docData.msg.search('ended the chat');
							if (pp21 > -1 && docData.action === "VISITOR_END_CHAT" && docData.agent_id === null) {
								let enabledTranslations = this.disableTranslationOfChat(key);
								var arrayToSet6 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Visitor has ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }];
								this.context.updateGlobalData("array", arrayToSet6);
							}

							if (docData.action === "EndChat1" || docData.action === "EndChat" || docData.action === "END_CHAT") {
								let enabledTranslations = this.disableTranslationOfChat(key);
								var arrayToSet7 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: currentVisitor.message_to_show } }, { key: "chatTranslator", value: enabledTranslations }];
								this.context.updateGlobalData("array", arrayToSet7);
							}
						}

						let chtMsg = msg;

						var showli = 1;
						var msgReadStatusTmp = msg_read_status;
						if (msg_type === 1 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46 || msg_type === 61) {
							var tmpchtMsg = String(chtMsg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							chtMsg = checkUrl(tmpchtMsg);
						} else if (showMessagesTypes.indexOf(msg_type) !== -1) {
							showli = 1;
						} else if (msg_type === 8 && msg !== '') {
							var checkEmpty2 = true;
							msg.forEach((msgData, i) => {
								if (msgData.A !== "") {
									checkEmpty2 = false;
								}
							});
							if (checkEmpty2) {
								showli = 0;
							}
						} else if (msg_type === 6) {
							var agentId = docData.agent_id;
							if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
								showli = 0;
							} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "VISITOR_END_CHAT" || docData.action === "WELCOME_PAID_VISITOR")) {
								showli = 0;
							} else {
								if (docData.action === "addagent") {
									if (fromVisitor !== "served") {
										if (docData.agent_id_of_added_agent === this.agent.agentId) {
											if (chtMsg.indexOf('added agent') > -1) {
												chtMsg = "You've been added to chat by " + chtMsg.split('added agent')[0];
											}
										} else if (agentId === this.agent.agentId) {
											if (chtMsg.indexOf('added agent') > -1) {
												chtMsg = "You've added " + chtMsg.split('added agent')[1];
											}
										}
									}
								} else if (docData.action === "transferagent" && agentId !== this.agent.agentId) {
									if (chtMsg.indexOf('transferred chat to agent') !== -1 && fromVisitor !== "served") {
										chtMsg = chtMsg.split('transferred chat to agent')[0] + "has transferred the chat to you";
									}
								} else {
									if (agentId === this.agent.agentId) {
										chtMsg = docData.msg_alias !== undefined ? docData.msg_alias : chtMsg;
									} else {
										chtMsg = docData.msg_otheragent !== undefined ? docData.msg_otheragent : chtMsg;
									}
								}
							}
						} else {
							showli = 0;
						}

						if (docData.hasOwnProperty("msg_deleted") && showli === 1) {
							showli = 0;
						}

						if (showli === 1) {

							var div = document.getElementById(docId) || this.createAndInsertMessage(docId, docData, parentID,false,load_id_old);
							if (!div) {
								return false;
							}
							var tickIcon = "";
							if (msgReadStatusTmp === 1) {
								tickIcon = singleTick;
							} else if (msgReadStatusTmp === 2) {
								tickIcon = doubleTick;
							} else if (msgReadStatusTmp === 3) {
								tickIcon = blueTick;
							}

							var msgTime = this.getTimeFromDate(addedon);
							var stringUrl = '';
							var msgNew = '';
							var formatedSize = '';
							if (msg_type === 6) {
								//var messageElement = div.querySelector('.text-muted');
								div.querySelector('.date-agent').textContent = msgTime;
								div.querySelector('.text-muted').textContent = chtMsg;
							} else if (msg_type === 8) {
								var tmpchtMsg = "";
								var kppp = 0;
								var msgString = '<ul class="liveChat-question-answer">';
								msg.forEach((msgData, i) => {
									kppp = i;
									//tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
									tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
									msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + parse(tmpchtMsg) + '</span></p></li>';
								});
								msgString = msgString + '</ul>';
								//var messageElement = div.querySelector('.message-content-new');
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = msgString;
								try {
									document.getElementById("formAnswer_" + kppp).textContent = tmpchtMsg;
								} catch (errr) { }
							} else if (msg_type === 2 || msg_type === 16 || msg_type === 22 || msg_type === 42 || msg_type === 44) {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								try {
									div.querySelector('.attachment').src = chtMsg;
									div.querySelector('.openattachment').href = chtMsg;
								} catch (err) { }
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 3) {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								stringUrl = msg_url.replace(/[0-9]/g, '');
								msgNew = stringUrl;
								if (stringUrl.length > 15) {
									msgNew = stringUrl.substring(0, 15) + '...';
								}
								formatedSize = this.formatBytes(filesize, 2);
								let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
								div.querySelector('.docattach').href = msg;
								div.querySelector('.docattach').src = msg;
								div.querySelector('.docattach').download = msg_url;
								div.querySelector('.filesNameView').textContent = msgNew;
								div.querySelector('.fileSizeView').textContent = formatedSize;
								div.querySelector('.whichFileName').textContent = contenttypeTmp;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 4 || msg_type === 17 || msg_type === 23 || msg_type === 43) {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.videomsg').href = msg;
								div.querySelector('.videomsg').src = msg;
								div.querySelector('.videomsg').download = msg_url;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
								if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									div.querySelector('.filesNameView').textContent = msgNew;
									formatedSize = this.formatBytes(filesize, 2);
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.downloadattach').href = msg;
								}
							} else if (msg_type === 45) {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								let contentType = await this.contentTypeFromURL(msg);
								if (contentType === 'video/mp4') {
									let messageToAppend = '<video class="videomsg" src="' + msg + '" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="' + msg + '" download="' + msg + '"></video>'

									div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
								}
								else {
									let messageToAppend = '<a href="' + msg + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + msg + '"></a>';

									div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
								}
								div.querySelector('.message-content-new').innerHTML = docData.storyMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
								if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									div.querySelector('.filesNameView').textContent = msgNew;
									formatedSize = this.formatBytes(filesize, 2);
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.downloadattach').href = msg;
								}
							} else if (msg_type === 41 || msg_type === 46) {
								if (docData.msg_deleted === 1) {

								} else {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									if (docData.repliedToMessage && docData.repliedToMessage.message) {
										let messageToAppend = "";
										if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
											messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.repliedToMessage.message + '"></a>';
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										} else if (docData.repliedMessageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 42) {
											messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.repliedToMessage.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="max-width: 50px; max-height: 50px" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
										}
										else {
											messageToAppend = docData.repliedToMessage.message;
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										}
									} else if (msg_type === 46) {
										try {
											div.querySelector('.message_reply').style.display = "none";
										} catch (err) {
										}

									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-new').innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								}
							} else if (msg_type === 47) {
								if (docData.msg_deleted === 1) {

								} else {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									if (docData.reactOnMessageId && docData.reactOnMessageId.message) {
										let messageToAppend = "";
										if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
											messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										} else if (docData.repliedMessageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 42) {
											messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										}
										else {
											messageToAppend = docData.reactOnMessageId.message;
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										}
									} else if (msg_type === 47) {
										try {
											div.querySelector('.message_reply').style.display = "none";
										} catch (err) {
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-new').innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								}
							} else if (msg_type === 48) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.instaaudio').src = msg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 7) {
								div.querySelector('.text-muted').textContent = msg;
								div.querySelector('.date-agent').textContent = msgTime;
							} else if (msg_type === 11) {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								var messageElement = div.querySelector('.message-content-new');
								div.querySelector('.opacity-65').textContent = msgTime;
								messageElement.innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 12) {
								div.querySelector('.call-muted').innerHTML = msg;
								div.querySelector('.date-agent').textContent = msgTime;
								if (docData.recordingUrl) {
									div.querySelector('.call-record-audio').innerHTML = `<audio controls preload="none"><source src='${docData.recordingUrl}' type='audio/ogg'><source src='${docData.recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`;
								}
							} else {
								if (docData.agent_id) {
									div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								//var messageElement = div.querySelector('.message-content-new');
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							}
						}
					}
					try {
						addChatData(key, tmpChatDataForLF);
						this.scrollChatScreen(lastMessageAgentId);
						//this.clearChatData(key);
					} catch (err) { }
				});
			}
		} else {
			console.log("snapshotLive_before");
			this.chatSnapShots[key] = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).collection('chat_data').orderBy('addedon', 'desc').onSnapshot(async (docs) => {
				// this.getMsgList(4);

				console.log("snapshotLive_after",this.isActive)
				if (this.state.liveChatDocId !== key) {
					console.log("this.state.liveChatDocId",this.state.liveChatDocId, key,"STOPPED DUE TO CLIENTID")
					return false;
				}
				///////// To detect language Start ///////////
				if (currentVisitor.client_query !== undefined && typeof currentVisitor.client_query !== undefined && currentVisitor.client_query !== "" && currentVisitor.visitor_status === "ongoing" && fromVisitor !== "served") {
					this.detectLanguage(key, currentVisitor.client_query);
				}
				///////// To detect language End ///////////

				var totalChats = docs.size;
				var localStorageLang = 'en';
				var lastMessageAgentId = null;
				var lastChatAction = null;
				var isTranslaterOn = false;

				if (Object.keys(this.context.chatTranslator).length > 0) {
					if (this.context.chatTranslator.hasOwnProperty(key)) {
						var translater = this.context.chatTranslator[key];
						if (translater.status && translater.translating) {
							isTranslaterOn = true;
						}
					}
				}

				if (totalChats > 0) {
					lastMessageAgentId = docs.docs[0].data().agent_id;
					lastChatAction = docs.docs[0].data().action;
				}

				if (this.isActive) {
					this.isActive = false;

					const load_id = Date.now();
					this.load_id = load_id;

					if (isTranslaterOn) {
						try {
							document.getElementById('messagesdata').style.display = 'block';
						} catch (eee) { }
					}
					console.log(docs.docs,"all_messagesL_list")
					for (const index in docs.docs) {
						const change = docs.docs[index];

						/* For stoping another chat data append in current chat start */
						const parentID = change.ref.parent.parent.id ? change.ref.parent.parent.id : "";
						if (parentID !== this.state.liveChatDocId) {
							return false;
						}
						/* For stoping another chat data append in current chat End */

						this.scrollChatScreen(lastMessageAgentId);

						const docData = change.data();
						const docId = change.id;
						const {
							addedon,
							agent_id,
							msg,
							msg_url,
							contenttype,
							filesize,
							msg_read_status
						} = docData;

						const msg_type = docData.msg_type ? docData.msg_type : 'NA';

						if (msg_type === 6) {
							const kk = docData.msg.indexOf('joined chat with you');
							if (kk > 5 && docData.agent_id !== this.agent.agentId && fromVisitor !== "served") {
								firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).get().then((visitor) => {
									if (visitor.exists) {
										var _data = visitor.data();
										if (!_data.agent_ids.includes(this.agent.agentId)) {
											if (lastMessageAgentId === this.agent.agentId && lastChatAction === "leavechat") {
												this.clearChatData(key);
												let enabledTranslations = this.disableTranslationOfChat(key);
												var arrayToSet8 = [{ key: "chatTranslator", value: enabledTranslations }];
												this.context.updateGlobalData("array", arrayToSet8);
											} else {
												if (this.context.chatPickStatusTransaction.status === "chatting" && this.context.chatPickStatusTransaction.key === key) {
													// Do Nothing and Continue
												} else {
													if (parentID === this.state.liveChatDocId && key === parentID) {
														this.clearChatData(key);
														let enabledTranslations = this.disableTranslationOfChat(key);
														var arrayToSet9 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Chat has been picked by another agent" } }, { key: "chatTranslator", value: enabledTranslations }];
														this.context.updateGlobalData("array", arrayToSet9);
													}
												}
											}
											try {
												document.getElementById('Testing').style.height = 'calc(100vh - 215px)';
											} catch (error) { }
										}
									}
								});
							}

							var pp4 = docData.msg.search('end the chat');
							if (pp4 > -1) {
								if (docData.agent_id !== this.agent.agentId) {
									this.clearChatData(key);
									this.hideCenter(9, 'temp');
								} else {
									let enabledTranslations = this.disableTranslationOfChat(key);
									var arrayToSet10 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "You have ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }];
									this.context.updateGlobalData("array", arrayToSet10);
								}
								isChatEnd = true;
							}

							//You ended the chat.
							var pp22 = docData.msg.search('ended the chat');
							if (pp22 > -1 && docData.action === "VISITOR_END_CHAT" && docData.agent_id === null) {
								saveInActiveChat(this.agent.ownerId, key, currentVisitor.visitor_status);
								let enabledTranslations = this.disableTranslationOfChat(key);
								var arrayToSet11 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Visitor has ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }];
								this.context.updateGlobalData("array", arrayToSet11);
								this.clearChatData(key);
								isChatEnd = true;
							}

							if (docData.action === "EndChat1" || docData.action === "EndChat" || docData.action === "END_CHAT") {
								saveInActiveChat(this.agent.ownerId, key, currentVisitor.visitor_status);
								let enabledTranslations = this.disableTranslationOfChat(key);
								var arrayToSet12 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: docData.msg } }, { key: "chatTranslator", value: enabledTranslations }];
								this.context.updateGlobalData("array", arrayToSet12);
								this.clearChatData(key);
								isChatEnd = true;
							}
						}

						var isTranslate = false;
						if (Object.keys(this.context.chatTranslator).length > 0 && (msg_type === 1 || msg_type === 7 || msg_type === 8 || msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46)) {
							if (this.context.chatTranslator.hasOwnProperty(key)) {
								var translater2 = this.context.chatTranslator[key];
								if (translater2.status && translater2.translating && agent_id === null) {
									isTranslate = true;
								}
							}
						}

						let chtMsg = "";
						if (isTranslate) {
							if (msg_type === 8) {
								const msgFromLocalStorage = await getMessage(docId, key, "array");
								if (msgFromLocalStorage) {
									chtMsg = msgFromLocalStorage;
								} else {
									var dt = [];
									var checkEmpty3 = true;
									for (const formKey in msg) {
										if (msg.hasOwnProperty(formKey)) {
											const element = msg[formKey];
											if (element.A !== "") {
												checkEmpty3 = false;
											}
											dt.push(element.Q);
											dt.push(element.A);
										}
									}

									if (checkEmpty3) {
										chtMsg = msg;
									} else {
										var datab = {
											"q": dt,
											"target": localStorageLang,
											//source:'en'
										};

										var fData_tr = [];
										await fetch("https://translation.googleapis.com/language/translate/v2?key=" + GOOGLE_TRANSLATION_KEY, {
											method: 'POST',
											mode: 'cors',
											cache: 'no-cache',
											credentials: 'same-origin',
											headers: {
												'Content-Type': 'application/json'
											},
											redirect: 'follow',
											referrer: 'no-referrer',
											body: JSON.stringify(datab)
										}).then(res => res.json()).then((result) => {
											const errorCode = result.error ? result.error.code : false;
											var trText = (result.data && !errorCode) ? result.data.translations : [];
											for (var i = 0; i < trText.length; i += 2) {
												fData_tr.push({ Q: trText[i].translatedText, A: decodeEntities(trText[i + 1].translatedText) });
											}
											chtMsg = fData_tr;
											addMessage(key, docId, fData_tr, "array");
										}).catch(err => { });
									}
								}
							} else {
								const msgFromLocalStorage = await getMessage(docId, key, 'string');
								if (msgFromLocalStorage) {
									chtMsg = msgFromLocalStorage;
								} else {
									await fetch("https://translation.googleapis.com/language/translate/v2?q=" + msg + "&format=text&key=" + GOOGLE_TRANSLATION_KEY + "&target=" + localStorageLang).then(res => res.json()).then((result) => {
										const errorCode = result.error ? result.error.code : false;
										var content = (result.data && !errorCode) ? result.data.translations[0].translatedText : msg;
										chtMsg = content;
										addMessage(key, docId, content, 'string');
									}).catch(err => { });
								}
							}
						} else {
							chtMsg = msg;
						}

						var showli = 1;
						var msgReadStatusTmp = msg_read_status;
						if (msg_type === 1 || msg_type === 2 || msg_type === 3 || msg_type === 4) {
							var thisMsgs = {
								addedon,
								agent_id,
								msg,
								msg_url,
								contenttype,
								filesize,
								msg_read_status
							};
							totalMsgs.push(thisMsgs);
						}

						if (msg_type === 1 || msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46 || msg_type === 61) {
							var tmpchtMsg = String(chtMsg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							///////// To detect language Start ///////////
							if (docData.agent_id === null && fromVisitor !== "served") {
								this.detectLanguage(key, tmpchtMsg);
							}
							///////// To detect language End ///////////
							chtMsg = checkUrl(tmpchtMsg);
						} else if (showMessagesTypes.indexOf(msg_type) !== -1) {
							showli = 1;
						} else if (msg_type === 8 && msg !== '') {
							var checkEmpty4 = true;
							msg.forEach((msgData, i) => {
								if (msgData.A !== "") {
									checkEmpty4 = false;
								}
							});
							if (checkEmpty4) {
								showli = 0;
							}
						} else if (msg_type === 6) {
							var agentId = docData.agent_id;
							if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
								showli = 0;
							} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "VISITOR_END_CHAT" || docData.action === "WELCOME_PAID_VISITOR")) {
								showli = 0;
							} else {
								if (docData.action === "addagent") {
									if (fromVisitor !== "served") {
										if (docData.agent_id_of_added_agent === this.agent.agentId) {
											if (chtMsg.indexOf('added agent') > -1) {
												chtMsg = "You've been added to chat by " + chtMsg.split('added agent')[0];
											}
										} else if (agentId === this.agent.agentId) {
											if (chtMsg.indexOf('added agent') > -1) {
												chtMsg = "You've added " + chtMsg.split('added agent')[1];
											}
										}
									}
								} else if (docData.action === "transferagent" && agentId !== this.agent.agentId) {
									if (chtMsg.indexOf('transferred chat to agent') !== -1 && fromVisitor !== "served") {
										chtMsg = chtMsg.split('transferred chat to agent')[0] + "has transferred the chat to you";
									}
								} else {
									if (agentId === this.agent.agentId) {
										chtMsg = docData.msg_alias !== undefined ? docData.msg_alias : chtMsg;
									} else {
										chtMsg = docData.msg_otheragent !== undefined ? docData.msg_otheragent : chtMsg;
									}
								}
							}
						} else {
							showli = 0;
						}

						if (docData.hasOwnProperty("msg_deleted") && showli === 1) {
							showli = 0;
						}

						if (showli === 1) {
							var div = document.getElementById(docId) || this.createAndInsertMessage(docId, docData, parentID,false,load_id);
							if (!div) {
								return false;
							}
							var tickIcon = "";
							if (msgReadStatusTmp === 1) {
								tickIcon = singleTick;
							} else if (msgReadStatusTmp === 2) {
								tickIcon = doubleTick;
							} else if (msgReadStatusTmp === 3) {
								tickIcon = blueTick;
							}

							var msgTime = this.getTimeFromDate(addedon);
							var stringUrl = '';
							var msgNew = '';
							var formatedSize = '';
							let agentProfileImg = docData.agent_profile_img ? docData.agent_profile_img : "";
							if (msg_type === 6) {
								//var messageElement = div.querySelector('.text-muted');
								div.querySelector('.date-agent').textContent = msgTime;
								div.querySelector('.text-muted').textContent = chtMsg;
							} else if (msg_type === 8) {
								var msgString = '<ul class="liveChat-question-answer">';
								var tmpchtMsg = "";
								var kppp = 0;
								chtMsg.forEach((msgData, i) => {
									kppp = i;
									//tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
									tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
									msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + parse(tmpchtMsg) + '</span></p></li>';
								});
								msgString = msgString + '</ul>';

								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = msgString;
								try {
									document.getElementById("formAnswer_" + kppp).textContent = tmpchtMsg;
								} catch (errr) { }
							} else if (msg_type === 2 || msg_type === 16 || msg_type === 22 || msg_type === 42 || msg_type === 44 || msg_type === 71) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								try {
									div.querySelector('.attachment').src = chtMsg;
									div.querySelector('.openattachment').href = chtMsg;
								} catch (err) { }
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
								if (msg_type === 71) {
									try {
										setTimeout(() => {
											document.getElementById(docId).style.display = '';
											//this.scrollChatScreen(lastMessageAgentId);
										}, 1000);
									} catch (err) { }
								}
							} else if (msg_type === 3) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								stringUrl = msg_url.replace(/[0-9]/g, '');
								msgNew = stringUrl;
								if (stringUrl.length > 15) {
									msgNew = stringUrl.substring(0, 15) + '...';
								}
								formatedSize = this.formatBytes(filesize, 2);
								let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
								div.querySelector('.docattach').href = msg;
								div.querySelector('.docattach').src = msg;
								div.querySelector('.docattach').download = msg_url;
								div.querySelector('.filesNameView').textContent = msgNew;
								div.querySelector('.fileSizeView').textContent = formatedSize;
								div.querySelector('.whichFileName').textContent = contenttypeTmp;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 4 || msg_type === 17 || msg_type === 23 || msg_type === 43 || msg_type === 73) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.videomsg').href = msg;
								div.querySelector('.videomsg').src = msg;
								div.querySelector('.videomsg').download = msg_url;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
								if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									div.querySelector('.filesNameView').textContent = msgNew;
									formatedSize = this.formatBytes(filesize, 2);
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.downloadattach').href = msg;
								}
							} else if (msg_type === 45) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								let contentType = await this.contentTypeFromURL(msg);
								if (contentType === 'video/mp4') {
									let messageToAppend = '<video class="videomsg" src="' + msg + '" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="' + msg + '" download="' + msg + '"></video>'

									div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
								}
								else {
									let messageToAppend = '<a href="' + msg + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + msg + '"></a>';

									div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
								}
								div.querySelector('.message-content-new').innerHTML = docData.storyMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
								if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									div.querySelector('.filesNameView').textContent = msgNew;
									formatedSize = this.formatBytes(filesize, 2);
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.downloadattach').href = msg;
								}
							} else if (msg_type === 7) {
								div.querySelector('.text-muted').textContent = msg;
								div.querySelector('.date-agent').textContent = msgTime;
							} else if (msg_type === 11) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
								}
								var messageElement = div.querySelector('.message-content-new');
								div.querySelector('.opacity-65').textContent = msgTime;
								messageElement.innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 12) {
								div.querySelector('.call-muted').innerHTML = msg;
								div.querySelector('.date-agent').textContent = msgTime;
								if (docData.recordingUrl) {
									div.querySelector('.call-record-audio').innerHTML = `<audio controls preload="none"><source src='${docData.recordingUrl}' type='audio/ogg'><source src='${docData.recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`;
								}
							} else if (msg_type === 31) {
								const host_join_url = docData.host_join_url ? docData.host_join_url : '';
								div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-zoom').innerHTML = `<span title="${msg_url}" placeholder="${msg_url}" value="${msg_url}" class="copyjoinurl">` + chtMsg + '</span>';

								if (docData.agent_id === this.agent.agentId) {
									div.querySelector('.join-meeting-link').href = host_join_url;
								} else {
									div.querySelector('.join-meeting-link').href = msg_url;
								}

								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 41 || msg_type === 46) {
								if (docData.msg_deleted === 1) {

								} else {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									if (docData.repliedToMessage && docData.repliedToMessage.message) {
										let messageToAppend = "";
										if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
											messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.repliedToMessage.message + '"></a>';
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										} else if (docData.repliedMessageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 42) {
											messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.repliedToMessage.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 44) {
											let messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + docData.repliedToMessage.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.repliedToMessage.messageType === 48) {
											messageToAppend = '<audio controls><source class="instaaudio" src="' + docData.repliedToMessage.message + '" type="audio/ogg"><source class="instaaudio" src="' + docData.repliedToMessage.message + '" type="audio/mpeg">Your browser does not support the audio element.</audio>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
										} else {
											messageToAppend = docData.repliedToMessage.message;
											div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
										}
									} else if (msg_type === 46) {
										try {
											div.querySelector('.message_reply').style.display = "none";
										} catch (err) {
										}

									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-new').innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								}
							} else if (msg_type === 47) {
								if (docData.msg_deleted === 1) {

								} else {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									if (docData.reactOnMessageId && docData.reactOnMessageId.message) {
										let messageToAppend = "";
										if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
											messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										} else if (docData.repliedMessageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 42) {
											messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 43) {
											messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 44) {
											let messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 45) {
											messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else if (docData.reactOnMessageId.messageType === 48) {
											messageToAppend = '<audio controls><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/ogg"><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/mpeg">Your browser does not support the audio element.</audio>';
											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
										} else {
											messageToAppend = docData.reactOnMessageId.message;
											div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
										}
									} else if (msg_type === 47) {
										try {
											div.querySelector('.message_reply').style.display = "none";
										} catch (err) {
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-new').innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								}
							} else if (msg_type === 48) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.instaaudio').src = msg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 72) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.instaaudio').src = msg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else if (msg_type === 74 || msg_type === 75) {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								div.querySelector('.opacity-65').textContent = msgTime;
								stringUrl = msg_url.replace(/[0-9]/g, '');
								msgNew = stringUrl;
								if (stringUrl.length > 15) {
									msgNew = stringUrl.substring(0, 15) + '...';
								}
								formatedSize = this.formatBytes(filesize, 2);
								let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
								div.querySelector('.docattach').href = msg;
								div.querySelector('.docattach').src = msg;
								div.querySelector('.docattach').download = msg_url;
								div.querySelector('.filesNameView').textContent = msgNew;
								div.querySelector('.fileSizeView').textContent = formatedSize;
								div.querySelector('.whichFileName').textContent = contenttypeTmp;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							} else {
								if (docData.agent_id) {
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
								} else {
									if (currentVisitor.visitor_type === "voip") {
										div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
									} else {
										div.querySelector('.avshort').textContent = currentVisitor.client_short;
									}
								}
								//var messageElement = div.querySelector('.message-content-new');
								div.querySelector('.opacity-65').textContent = msgTime;
								div.querySelector('.message-content-new').innerHTML = chtMsg;
								if (tickIcon) {
									div.querySelector('.tickimg').src = tickIcon;
								}
							}
						}
					}

					/* added by dharmesh for ticket 1309 start here */
					totalMsgs.sort(function (x, y) {
						let a = x.addedon ? x.addedon : '',
							b = y.addedon ? y.addedon : '';
						return a === b ? 0 : a > b ? 1 : -1;
					});
					let msgNo = 0;
					let prevAgent;

					if (currentVisitor && currentVisitor.start_url !== "incoming_insta") {
						totalMsgs.forEach((msgs) => {
							if (prevAgent !== msgs.agent_id) {
								msgNo++;
								prevAgent = msgs.agent_id
							}
						})
					}

					if (msgNo >= 3 && !this.context.paymentDetails.paymentStatus) {
						this.context.updateGlobalData("showPaywall", true);
					} else {
						this.context.updateGlobalData("showPaywall", false);
					}

					// if(msgsCount.visitor.length > 0 && msgsCount.agents.length > 0 && parseInt(msgsCount.visitor.length + msgsCount.agents.length) >= 3 && isChatEnd===false) {
					// 	this.setState({
					// 		isShowPaidButtonSec: true
					// 	})
					// } else {
					// 	this.setState({
					// 		isShowPaidButtonSec: false
					// 	})
					// }

					/* added by dharmesh for ticket 1309 ends here */

					try {
						if (isTranslaterOn) {
							document.getElementById('messagesdata').style.display = 'block';
						}
						if (this.visitorSnapShotsId.length > totalRunSnapshot) {
							let lastKey = this.visitorSnapShotsId[totalRunSnapshot];
							this.clearChatData(lastKey);
							this.visitorSnapShotsId.splice(totalRunSnapshot, 1);
						}
						this.scrollChatScreen(lastMessageAgentId);
					} catch (err) { }
				} else {
					const docChanges = docs.docChanges();
					for (const index in docChanges) {
						const docChange = docChanges[index];
						const docChange2 = docChange.doc;
						const docId = docChange2.id;


						/* For stoping another chat data append in current chat start */
						const parentID = docChange2.ref.parent.parent.id ? docChange2.ref.parent.parent.id : "";
						if (parentID !== this.state.liveChatDocId) {
							return false;
						}
						/* For stoping another chat data append in current chat End */

						if (docChange.type === 'added') {
							try {
								this.context.updateGlobalData("showInfo", false); // hide visitor info from clearbit
								document.getElementById('messagesdata').style.display = 'block';
								this.goToUnreadChats();
							} catch (errrr) { }
						}

						if (docChange.type === 'removed') {
							/// Do nothing
						} else {
							const docData = docChange2.data();
							const {
								addedon,
								agent_id,
								msg,
								msg_url,
								contenttype,
								filesize,
								msg_read_status
							} = docData;

							const msg_type = docData.msg_type ? docData.msg_type : 'NA';
							const msg_deleted = docData.msg_deleted ? docData.msg_deleted : 0;

							if (msg_type === 6) {
								const kk = docData.msg.indexOf('joined chat with you');
								if (kk > 5 && docData.agent_id !== this.agent.agentId && fromVisitor !== "served") {
									firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).get().then((visitor) => {
										if (visitor.exists) {
											var _data = visitor.data();
											if (!_data.agent_ids.includes(this.agent.agentId)) {
												if (lastMessageAgentId === this.agent.agentId && lastChatAction === "leavechat") {
													this.clearChatData(key);
													let enabledTranslations = this.disableTranslationOfChat(key);
													var arrayToSet13 = [{ key: "chatTranslator", value: enabledTranslations }];
													this.context.updateGlobalData("array", arrayToSet13);
													return false;
												} else {
													if (this.context.chatPickStatusTransaction.status === "chatting" && this.context.chatPickStatusTransaction.key === key) {
														// Do Nothing and Continue
													} else {
														if (parentID === this.state.liveChatDocId && key === parentID) {
															this.clearChatData(key);
															let enabledTranslations = this.disableTranslationOfChat(key);
															var arrayToSet14 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Chat has been picked by another agent" } }, { key: "chatTranslator", value: enabledTranslations }];
															this.context.updateGlobalData("array", arrayToSet14);
															return false;
														}
													}
												}
												try {
													document.getElementById('Testing').style.height = 'calc(100vh - 215px)';
												} catch (error) { }
											}
										}
									});
								}

								var pp5 = docData.msg.search('end the chat');
								if (pp5 > -1) {
									if (docData.agent_id !== this.agent.agentId) {
										this.clearChatData(key);
										this.hideCenter(9, 'temp');
									} else {
										let enabledTranslations = this.disableTranslationOfChat(key);
										var arrayToSet15 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "You have ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }];
										this.context.updateGlobalData("array", arrayToSet15);
									}
									isChatEnd = true;
								}
								//You ended the chat.

								var pp23 = docData.msg.search('ended the chat');
								if (pp23 > -1 && docData.action === "VISITOR_END_CHAT" && docData.agent_id === null) {
									saveInActiveChat(this.agent.ownerId, key, currentVisitor.visitor_status);
									let enabledTranslations = this.disableTranslationOfChat(key);
									var arrayToSet16 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: "Visitor has ended the chat" } }, { key: "chatTranslator", value: enabledTranslations }, { key: "rightSectionItemToShow", value: "visitorslist" }, { key: "isCloseBtnClicked", value: "" }];
									this.context.updateGlobalData("array", arrayToSet16);
									this.clearChatData(key);
									isChatEnd = true;
								}

								if (docData.action === "EndChat1" || docData.action === "EndChat" || docData.action === "END_CHAT") {
									saveInActiveChat(this.agent.ownerId, key, currentVisitor.visitor_status);
									let enabledTranslations = this.disableTranslationOfChat(key);
									var arrayToSet17 = [{ key: "chatPickStatusTransaction", value: { key: key, status: "close", Text: "Close window", msgToShow: docData.msg } }, { key: "chatTranslator", value: enabledTranslations }];
									this.context.updateGlobalData("array", arrayToSet17);
									this.clearChatData(key);
									isChatEnd = true;
								}
							}

							var isTranslate2 = false;
							if (Object.keys(this.context.chatTranslator).length > 0 && (msg_type === 1 || msg_type === 7 || msg_type === 8 || msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46)) {
								if (this.context.chatTranslator.hasOwnProperty(key)) {
									var translater3 = this.context.chatTranslator[key];
									if (translater3.status && translater3.translating && agent_id === null) {
										isTranslate2 = true;
									}
								}
							}

							let chtMsg = "";
							if (isTranslate2) {
								if (msg_type === 8) {
									const msgFromLocalStorage = await getMessage(docId, key, "array");
									if (msgFromLocalStorage) {
										chtMsg = msgFromLocalStorage;
									} else {
										var dt = [];
										var checkEmpty5 = true;
										for (const formKey in msg) {
											if (msg.hasOwnProperty(formKey)) {
												const element = msg[formKey];
												if (element.A !== "") {
													checkEmpty5 = false;
												}
												dt.push(element.Q);
												dt.push(element.A);
											}
										}
										if (checkEmpty5) {
											chtMsg = msg;
										} else {
											var datab = {
												"q": dt,
												"target": localStorageLang,
												//source:'en'
											};
											var fData_tr = [];
											await fetch("https://translation.googleapis.com/language/translate/v2?key=" + GOOGLE_TRANSLATION_KEY, {
												method: 'POST',
												mode: 'cors',
												cache: 'no-cache',
												credentials: 'same-origin',
												headers: {
													'Content-Type': 'application/json'
												},
												redirect: 'follow',
												referrer: 'no-referrer',
												body: JSON.stringify(datab)
											}).then(res => res.json()).then((result) => {
												const errorCode = result.error ? result.error.code : false;
												var trText = (result.data && !errorCode) ? result.data.translations : [];
												for (var i = 0; i < trText.length; i += 2) {
													fData_tr.push({ Q: trText[i].translatedText, A: decodeEntities(trText[i + 1].translatedText) });
												}
												chtMsg = fData_tr;
												addMessage(key, docId, fData_tr, "array");
											}).catch(err => { });
										}
									}
								} else {
									const msgFromLocalStorage = await getMessage(docId, key, 'string');
									if (msgFromLocalStorage) {
										chtMsg = msgFromLocalStorage;
									} else {
										await fetch("https://translation.googleapis.com/language/translate/v2?q=" + msg + "&format=text&key=" + GOOGLE_TRANSLATION_KEY + "&target=" + localStorageLang).then(res => res.json()).then((result) => {
											const errorCode = result.error ? result.error.code : false;
											var content = (result.data && !errorCode) ? result.data.translations[0].translatedText : msg;
											chtMsg = content;
											addMessage(key, docId, content, "string");
										}).catch(err => { });
									}
								}
							} else {
								chtMsg = msg;
							}

							var showli = 1;
							var msgReadStatusTmp = msg_read_status;

							if (msg_type === 1 || msg_type === 2 || msg_type === 3 || msg_type === 4) {
								var thisMsgs = {
									addedon,
									agent_id,
									msg,
									msg_url,
									contenttype,
									filesize,
									msg_read_status
								};
								totalMsgs.push(thisMsgs);
							}

							if (msg_type === 1 || msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46 || msg_type === 61) {
								var tmpchtMsg = String(chtMsg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
								///////// To detect language Start ///////////
								if (docData.agent_id === null && fromVisitor !== "served") {
									this.detectLanguage(key, tmpchtMsg);
								}
								///////// To detect language End ///////////
								chtMsg = checkUrl(tmpchtMsg);
							} else if (showMessagesTypes.indexOf(msg_type) !== -1) {
								showli = 1;
							} else if (msg_type === 8 && msg !== '') {
								var checkEmpty6 = true;
								msg.forEach((msgData, i) => {
									if (msgData.A !== "") {
										checkEmpty6 = false;
									}
								});
								if (checkEmpty6) {
									showli = 0;
								}
							} else if (msg_type === 6) {
								var agentId = docData.agent_id;
								if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
									showli = 0;
								} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "VISITOR_END_CHAT" || docData.action === "WELCOME_PAID_VISITOR")) {
									showli = 0;
								} else {
									if (docData.action === "addagent") {
										if (fromVisitor !== "served") {
											if (docData.agent_id_of_added_agent === this.agent.agentId) {
												if (chtMsg.indexOf('added agent') > -1) {
													chtMsg = "You've been added to chat by " + chtMsg.split('added agent')[0];
												}
											} else if (agentId === this.agent.agentId) {
												if (chtMsg.indexOf('added agent') > -1) {
													chtMsg = "You've added " + chtMsg.split('added agent')[1];
												}
											}
										}
									} else if (docData.action === "transferagent" && agentId !== this.agent.agentId) {
										if (chtMsg.indexOf('transferred chat to agent') !== -1 && fromVisitor !== "served") {
											chtMsg = chtMsg.split('transferred chat to agent')[0] + "has transferred the chat to you";
										}
									} else {
										if (agentId === this.agent.agentId) {
											chtMsg = docData.msg_alias !== undefined ? docData.msg_alias : chtMsg;
										} else {
											chtMsg = docData.msg_otheragent !== undefined ? docData.msg_otheragent : chtMsg;
										}
									}
								}
							} else {
								showli = 0;
							}

							if (showli === 1) {

								var div = document.getElementById(docId) || this.createAndInsertMessage(docId, docData, parentID,true,-10);
								if (!div) {
									return false;
								}
								var tickIcon = "";
								if (msgReadStatusTmp === 1) {
									tickIcon = singleTick;
								} else if (msgReadStatusTmp === 2) {
									tickIcon = doubleTick;
								} else if (msgReadStatusTmp === 3) {
									tickIcon = blueTick;
								}

								var msgTime = this.getTimeFromDate(addedon);
								var stringUrl = '';
								var msgNew = '';
								var formatedSize = '';
								let agentProfileImg = docData.agent_profile_img ? docData.agent_profile_img : "";
								if (msg_type === 6) {
									//var messageElement = div.querySelector('.text-muted');
									div.querySelector('.date-agent').textContent = msgTime;
									div.querySelector('.text-muted').textContent = chtMsg;
								} else if (msg_type === 8) {
									var tmpchtMsg = "";
									var kppp = 0;
									var msgString = '<ul class="liveChat-question-answer">';
									chtMsg.forEach((msgData, i) => {
										kppp = i;
										//tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
										tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
										msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + parse(tmpchtMsg) + '</span></p></li>';
									});
									msgString = msgString + '</ul>';
									//var messageElement = div.querySelector('.message-content-new');
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-new').innerHTML = msgString;
									try {
										document.getElementById("formAnswer_" + kppp).textContent = tmpchtMsg;
									} catch (errr) { }
								} else if (msg_type === 2 || msg_type === 16 || msg_type === 22 || msg_type === 42 || msg_type === 44 || msg_type === 71) {

									if (msg_type === 42 && msg_deleted === 1) {
										setTimeout(() => {
											try {
												$('#' + docId).remove();
												//document.getElementById(docId).style.display = "none";
											} catch (catchError) {
												console.log('error', catchError);
											}
										}, 200);
									}

									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									try {
										div.querySelector('.attachment').src = chtMsg;
										div.querySelector('.openattachment').href = chtMsg;
										if (tickIcon) {
											div.querySelector('.tickimg').src = tickIcon;
										}
									} catch (err) { }
									if (msg_type === 71) {
										try {
											setTimeout(() => {
												document.getElementById(docId).style.display = '';
												this.scrollChatScreen(lastMessageAgentId);
											}, 1000);
										} catch (err) { }
									}
								} else if (msg_type === 3) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									formatedSize = this.formatBytes(filesize, 2);
									let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
									div.querySelector('.docattach').href = msg;
									div.querySelector('.docattach').src = msg;
									div.querySelector('.docattach').download = msg_url;
									div.querySelector('.filesNameView').textContent = msgNew;
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.whichFileName').textContent = contenttypeTmp;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else if (msg_type === 4 || msg_type === 17 || msg_type === 23 || msg_type === 43 || msg_type === 73) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.videomsg').href = msg;
									div.querySelector('.videomsg').src = msg;
									div.querySelector('.videomsg').download = msg_url;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
									if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
										stringUrl = msg_url.replace(/[0-9]/g, '');
										msgNew = stringUrl;
										if (stringUrl.length > 15) {
											msgNew = stringUrl.substring(0, 15) + '...';
										}
										div.querySelector('.filesNameView').textContent = msgNew;
										formatedSize = this.formatBytes(filesize, 2);
										div.querySelector('.fileSizeView').textContent = formatedSize;
										div.querySelector('.downloadattach').href = msg;
									}
								} else if (msg_type === 45) {
									if (msg_deleted === 1) {
										setTimeout(() => {
											try {
												$('#' + docId).remove();
												//document.getElementById(docId).style.display = "none";
											} catch (catchError) {
												console.log('error', catchError);
											}
										}, 200);
									} else {
										if (docData.agent_id) {
											div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
										} else {
											if (currentVisitor.visitor_type === "voip") {
												div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
											} else {
												div.querySelector('.avshort').textContent = currentVisitor.client_short;
											}
										}
										div.querySelector('.opacity-65').textContent = msgTime;
										let contentType = await this.contentTypeFromURL(msg);
										if (contentType === 'video/mp4') {
											let messageToAppend = '<video class="videomsg" src="' + msg + '" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="' + msg + '" download="' + msg + '"></video>'

											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
										}
										else {
											let messageToAppend = '<a href="' + msg + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + msg + '"></a>';

											div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small>' + messageToAppend;
										}
										div.querySelector('.message-content-new').innerHTML = docData.storyMsg;
										if (tickIcon) {
											div.querySelector('.tickimg').src = tickIcon;
										}
										if (msg_url.indexOf(".wmv") !== -1 && docData.agent_id === null) {
											stringUrl = msg_url.replace(/[0-9]/g, '');
											msgNew = stringUrl;
											if (stringUrl.length > 15) {
												msgNew = stringUrl.substring(0, 15) + '...';
											}
											div.querySelector('.filesNameView').textContent = msgNew;
											formatedSize = this.formatBytes(filesize, 2);
											div.querySelector('.fileSizeView').textContent = formatedSize;
											div.querySelector('.downloadattach').href = msg;
										}
									}
								} else if (msg_type === 7) {
									div.querySelector('.text-muted').textContent = msg;
									div.querySelector('.date-agent').textContent = msgTime;
								} else if (msg_type === 11) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									var messageElement = div.querySelector('.message-content-new');
									div.querySelector('.opacity-65').textContent = msgTime;
									messageElement.innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else if (msg_type === 12) {
									div.querySelector('.call-muted').innerHTML = msg;
									div.querySelector('.date-agent').textContent = msgTime;
									if (docData.recordingUrl) {
										div.querySelector('.call-record-audio').innerHTML = `<audio controls preload="none"><source src='${docData.recordingUrl}' type='audio/ogg'><source src='${docData.recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`;
									}
								} else if (msg_type === 31) {
									const host_join_url = docData.host_join_url ? docData.host_join_url : '';
									div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.message-content-zoom').innerHTML = `<span title="${msg_url}" placeholder="${msg_url}" value="${msg_url}" class="copyjoinurl">` + chtMsg + '</span>';
									if (docData.agent_id === this.agent.agentId) {
										div.querySelector('.join-meeting-link').href = host_join_url;
									} else {
										div.querySelector('.join-meeting-link').href = msg_url;
									}
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else if (msg_type === 41 || msg_type === 46 || msg_type === 47) {
									if (msg_deleted === 1) {
										setTimeout(() => {
											try {
												$('#' + docId).remove();
												//document.getElementById(docId).style.display = "none";
											} catch (catchError) {
												console.log('error', catchError);
											}
										}, 500)
									} else {
										if (docData.agent_id) {
											div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
										} else {
											if (currentVisitor.visitor_type === "voip") {
												div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
											} else {
												div.querySelector('.avshort').textContent = currentVisitor.client_short;
											}
										}

										if (docData.repliedToMessage && docData.repliedToMessage.message) {
											let messageToAppend = "";
											if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
												messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.repliedToMessage.message + '"></a>';
												div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
											} else if (docData.repliedMessageType === 43) {
												messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
												div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
											} else if (docData.repliedToMessage.messageType === 42) {
												messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.repliedToMessage.message + '"></a>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
											} else if (docData.repliedToMessage.messageType === 43) {
												messageToAppend = '<video class="videomsg" src="' + docData.repliedToMessage.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.repliedToMessage.message + '" download="' + docData.repliedToMessage.message + '"></video>'
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>' + messageToAppend;
											} else if (docData.repliedToMessage.messageType === 44) {
												let messageToAppend = '<a href="' + docData.repliedToMessage.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + docData.repliedToMessage.message + '"></a>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else {
												messageToAppend = docData.repliedToMessage.message;
												div.querySelector('.message_reply').textContent = 'Replied to - ' + messageToAppend;
											}
										} else if (docData.reactOnMessageId && docData.reactOnMessageId.message) {
											let messageToAppend = "";
											if (docData.repliedMessageType === 42 || docData.repliedMessageType === 44) {
												messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="' + docData.reactOnMessageId.message + '"></a>';
												div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
											} else if (docData.repliedMessageType === 43) {
												messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 100%; height: 100%;" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
												div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
											} else if (docData.reactOnMessageId.messageType === 42) {
												messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else if (docData.reactOnMessageId.messageType === 43) {
												messageToAppend = '<video class="videomsg" src="' + docData.reactOnMessageId.message + '" preload="auto" controls="" style="width: 130px; height: 100px" href="' + docData.reactOnMessageId.message + '" download="' + docData.reactOnMessageId.message + '"></video>'
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else if (docData.reactOnMessageId.messageType === 44) {
												messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="' + docData.reactOnMessageId.message + '"></a>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else if (docData.reactOnMessageId.messageType === 45) {
												messageToAppend = '<a href="' + docData.reactOnMessageId.message + '" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="' + docData.reactOnMessageId.message + '"></a>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else if (docData.reactOnMessageId.messageType === 48) {
												messageToAppend = '<audio controls><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/ogg"><source class="instaaudio" src="' + docData.reactOnMessageId.message + '" type="audio/mpeg">Your browser does not support the audio element.</audio>';
												div.querySelector('.mt-0').innerHTML = '<small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>' + messageToAppend;
											} else {
												messageToAppend = docData.reactOnMessageId.message;
												div.querySelector('.message_reply').textContent = 'React on - ' + messageToAppend;
											}
										} else if (msg_type === 46 || msg_type === 47) {
											try {
												div.querySelector('.message_reply').style.display = "none";
											} catch (err) {
											}

										}
										//var messageElement = div.querySelector('.message-content-new');
										div.querySelector('.opacity-65').textContent = msgTime;
										//messageElement.textContent = chtMsg;
										div.querySelector('.message-content-new').innerHTML = chtMsg;
										if (tickIcon) {
											div.querySelector('.tickimg').src = tickIcon;
										}
									}
								} else if (msg_type === 48) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.instaaudio').src = msg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else if (msg_type === 72) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									div.querySelector('.instaaudio').src = msg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else if (msg_type === 74 || msg_type === 75) {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									div.querySelector('.opacity-65').textContent = msgTime;
									stringUrl = msg_url.replace(/[0-9]/g, '');
									msgNew = stringUrl;
									if (stringUrl.length > 15) {
										msgNew = stringUrl.substring(0, 15) + '...';
									}
									formatedSize = this.formatBytes(filesize, 2);
									let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
									div.querySelector('.docattach').href = msg;
									div.querySelector('.docattach').src = msg;
									div.querySelector('.docattach').download = msg_url;
									div.querySelector('.filesNameView').textContent = msgNew;
									div.querySelector('.fileSizeView').textContent = formatedSize;
									div.querySelector('.whichFileName').textContent = contenttypeTmp;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
								} else {
									if (docData.agent_id) {
										div.querySelector('.avshort').innerHTML = agentProfileImg ? '<img src = "' + agentProfileImg + '" />' : (docData.agent_short ? docData.agent_short : "VI");
									} else {
										if (currentVisitor.visitor_type === "voip") {
											div.querySelector('.avshort').textContent = docData.agent_short ? docData.agent_short : "VI";
										} else {
											div.querySelector('.avshort').textContent = currentVisitor.client_short;
										}
									}
									//var messageElement = div.querySelector('.message-content-new');
									div.querySelector('.opacity-65').textContent = msgTime;
									//messageElement.textContent = chtMsg;
									div.querySelector('.message-content-new').innerHTML = chtMsg;
									if (tickIcon) {
										div.querySelector('.tickimg').src = tickIcon;
									}
									// Replace all line breaks by <br>.
									//messageElement.innerHTML = messageElement.innerHTML.replace(/\n/g, '<br>');
								}
							}
						}
					}


					/* added by dharmesh for ticket 1309 start here */
					totalMsgs.sort(function (x, y) {
						let a = x.addedon ? x.addedon : '',
							b = y.addedon ? y.addedon : '';
						return a === b ? 0 : a > b ? 1 : -1;
					});
					// console.log('----totalMsgs----', totalMsgs);
					let msgNo = 0;
					let prevAgent;

					if (currentVisitor && currentVisitor.start_url !== "incoming_insta") {
						totalMsgs.forEach((msgs) => {
							if (prevAgent !== msgs.agent_id) {
								msgNo++;
								prevAgent = msgs.agent_id
							}
						})
					}

					// console.log('msgNo ', msgNo);
					if (msgNo >= 3 && !this.context.paymentDetails.paymentStatus) {
						this.context.updateGlobalData("showPaywall", true);
					} else {
						this.context.updateGlobalData("showPaywall", false);
					}

					/* added by dharmesh for ticket 1309 ends here */

					try {
						if (this.visitorSnapShotsId.length > totalRunSnapshot) {
							let lastKey = this.visitorSnapShotsId[totalRunSnapshot];
							this.clearChatData(lastKey);
							this.visitorSnapShotsId.splice(totalRunSnapshot, 1);
						}
						this.scrollChatScreen(lastMessageAgentId);
					} catch (err) { }
				}

			}, (error) => {
				try {
					if (Object.keys(this.context.currentVisitor).length > 0) {
						this.getChatSnapshot(this.context.currentVisitor);
					}
					var addedon = Date.now();
					var docId = 'SLOG' + addedon;
					firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('snapshotLogs').doc(docId).set({
						addedon: addedon,
						agent_id: this.agent.agentId,
						chat_id: key,
						error_from: "chat",
						error: error,
					});
				} catch (err) { }
			});
		}

		///////////// To getting chat messages End /////////////

		///////////// To showing typing Status Start /////////////

		this.typingSnapshots[key] = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(key).collection('typing_status').onSnapshot((docs) => {
			var num_typing = 0;
			var t_status = '';
			var agent_name = this.agent.name;

			try {
				const callData1 = getCallStatus();
				if (callData1 && callData1.isCallStarted && callData1.visitorId === key) {
					docs.forEach((doc2) => {
						if (agent_name !== doc2.id) {
							var _doc2 = doc2.data();
							if (_doc2.hasOwnProperty("isCalling") && _doc2.isCalling === false) {
								rejectCall();
								if (this.isFirstTime) {
									this.isFirstTime = false;
									setTimeout(() => {
										try {
											endCall();
										} catch (errr) { }
									}, 1000);
								}
							}
						}
					});
				}
			} catch (errrr) { }

			if (this.state.liveChatDocId === key) {
				docs.forEach((doc) => {
					if (agent_name !== doc.id) {
						var _doc = doc.data();
						if (_doc.isTyping) {
							num_typing++;
							if (t_status === '') {
								t_status = t_status + doc.id;
							} else {
								t_status = t_status + " and " + doc.id;
							}
						}
					}
				});

				if (num_typing === 0) {
					//var tmp = {};
					//tmp[key] = "Type here to chat";
					this.context.updateGlobalData("placeHolder", "Type here to chat");
					//this.setState({ pholder:  });
				} else if (num_typing === 1) {
					var tmp = {};
					t_status = t_status + ' is typing';
					//tmp[key] = t_status;
					//this.setState({ pholder: tmp });
					this.context.updateGlobalData("placeHolder", t_status);
				} else {
					var tmp = {};
					t_status = t_status + ' are typing';
					//tmp[key] = t_status;
					this.context.updateGlobalData("placeHolder", t_status);
					//this.setState({ pholder: tmp });
				}
			}
		});

		///////////// To showing typing Status End /////////////
		if(currentVisitor.current_pageUrl=="Whatsapp" && currentVisitor.message_to_show=="Chat ended due to inactivity" && currentVisitor.isChatend==1 ){
		this.autocloseWhatsAppChat(currentVisitor?.operating,currentVisitor?.id)
		}
	}
	autocloseWhatsAppChat= (id, chatId)=>{
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).get()
		.then(async(chatData)=>{
			// console.log(chatData.data().message_to_show,"------------",chatId);
			if(chatData.data().message_to_show=="Chat ended due to inactivity" && chatData.data().isChatend==1){
			firebaseServices.db.collection("Whatsapp_Integrations")
			.where('owner_id', '==', this.agent.ownerId)
			.limit(1)
			.get()
			.then((querySnapshot) => {

			  querySnapshot.forEach((doc) => {
			
			firebaseServices.db.collection("Whatsapp_Integrations")
			.doc(doc.id)
			.collection("Visitor")
			.doc(id + "")
			.get()
			.then((docSnapshot) => {
			  if (docSnapshot.exists && docSnapshot.data().visitorId === chatId) {
				firebaseServices.db.collection("Whatsapp_Integrations")
				  .doc(doc.id)
				  .collection("Visitor")
				  .doc(id + "")
				  .delete()
				  .then(() => {
					console.log("Visitor document deleted successfully.");
				  })
				  .catch((error) => {
					console.error("Error deleting visitor document: ", error);
				  });
			  } else {
				console.log("No matching visitor document found or visitorId doesn't match.");
			  }
			})
			.catch((error) => {
			  console.error("Error fetching visitor document: ", error);
			});
		  
		})      
		})
	}
   })
	}

	disableTranslationOfChat = (chatId) => {
		deleteMessage(chatId);
		var tmpData = this.context.chatTranslator ? this.context.chatTranslator : {};
		if (Object.keys(tmpData).length > 0) {
			if (tmpData.hasOwnProperty(chatId)) {
				delete tmpData[chatId];
			}
		}

		/* For chat translator on when coming from another tab or refresh page Start */

		try {
			let storageChatTranslator = localStorage.getItem('chatTranslator') ? JSON.parse(localStorage.getItem('chatTranslator')) : false;
			let storagechangeTextLangArr = localStorage.getItem('changeTextLangArr') ? JSON.parse(localStorage.getItem('changeTextLangArr')) : [];
			if (storageChatTranslator && Object.keys(storageChatTranslator).length > 0) {
				Object.keys(storageChatTranslator).forEach(trnasKey => {
					if (trnasKey === chatId) {
						delete storageChatTranslator[trnasKey];
					}
				});
				localStorage.setItem('chatTranslator', JSON.stringify(storageChatTranslator));
			}
			if (storagechangeTextLangArr.length > 0) {
				const filteredTransArray = storagechangeTextLangArr.filter(objVal => {
					return (objVal.sKey !== chatId) ? true : false;
				});
				localStorage.setItem('changeTextLangArr', JSON.stringify(filteredTransArray));
			}
		} catch (errorr) {
			console.log("Error in removing translation data from lacalstorage");
		}

		/* For chat translator on when coming from another tab or refresh page end */
		return tmpData;
	}

	///// Technique Two End ////////////////////

	/* Get All Chat Data End */

	getTimeFromDate = (timestamp) => {
		if (timestamp === null) {
			return;
		}
		var todayDate = new Date().getTime();
		todayDate = Math.floor(todayDate / 1000);
		var todayUTCDate = moment.tz(todayDate, "X", moment.tz.guess(true)).tz("UTC").format("YYYYMMDD");
		var current = parseInt(todayUTCDate);

		timestamp = Math.floor(timestamp / 1000);
		var previousUTCDate = moment.tz(timestamp, "X", "UTC").tz("UTC").format("YYYYMMDD");
		var previous = parseInt(previousUTCDate);

		var dateString = '';
		if (current === previous) {
			dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("hh:mm A");
		} else {
			dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("DD/MM/YYYY hh:mm A");
		}

		return dateString;
	}

	formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	popupView = (url) => {
		window.open(url, '_blank');
		return;
	}

	hideCenter = (type, chatId, msg) => {
		if (type === 11) {
			/* agent ended the chat */
			if (chatId === this.state.liveChatDocId) {
				document.getElementById('messageendChatPara').innerHTML = "We've closed inactive chat window . You can either select another ongoing chat or select an incoming chat";
				document.getElementById('enddedChatSection').style.display = 'block';
			}
		} else if (type === 1) {
			/* agent ended the chat */
			document.getElementById('messageendChatPara').innerHTML = "You've ended an ongoing chat. You can either select another ongoing chat or select an incoming chat";
			document.getElementById('enddedChatSection').style.display = 'block';
		}
		else if (type === 2) {
			/* agent leave the chat */
			document.getElementById('messageendChatPara').innerHTML = "You've left the chat. You can either select another ongoing chat or select an incoming chat.";
			document.getElementById('enddedChatSection').style.display = 'block';
		}
		else if (type === 3) {
			/* chat served by other user */
			document.getElementById('messageendChatPara').innerHTML = "The visitor is being attended by another agent. You can either select one from incoming chat or click on a visitor from visitor panel.";
			document.getElementById('enddedChatSection').style.display = 'block';
		} else if (type === 4) {
			/*there are no ongoing chats in the account */
			var object11 = this.state.windowDetails;
			if (object11['isOpen']) {
				return;
			}
			document.getElementById('messageendChatPara').innerHTML = "You don't have any ongoing chat. Either select one from incoming chat or click on a visitor from visitor panel.";
			document.getElementById('enddedChatSection').style.display = 'block';
		} else if (type === 5) {
			/* agent leave the chat */
			document.getElementById('messageendChatPara').innerHTML = "You've transfered the chat. You can either select another ongoing chat or select an incoming chat.";
			document.getElementById('enddedChatSection').style.display = 'block';
		} else if (type === 6) {
			if (this.state.liveChatDocId === chatId) {
				document.getElementById('messageendChatPara').innerHTML = "Visitor has ended the ongoing chat. You can either select another ongoing chat or select an incoming chat.";
				document.getElementById('enddedChatSection').style.display = 'block';
			}
		} else if (type === 7) {
			//chat picked by other agent in race condition
			document.getElementById('messageendChatPara').innerHTML = "Sorry this chat is picked by some other agent. You can either select another ongoing chat or select an incoming chat";
			document.getElementById('enddedChatSection').style.display = 'block';
		} else if (type === 8) {
			this.context.updateGlobalData("chatPickStatusTransaction", { key: chatId, status: "close", Text: "Close window", msgToShow: msg });
			if (this.state.liveChatDocId === chatId) {
				try {
					document.getElementById('Testing').style.height = 'calc(100vh - 215px)';
				} catch (error) { }
			}
		} else if (type === 9) {
			/* agent ended the chat */
			setTimeout(() => {
				var arrayToSet = [];
				var Obj1 = {
					key: "currentVisitor",
					value: {}
				}

				var Obj2 = {
					key: "midHeadercountry",
					value: "NOT_AVAILABLE"
				}

				var Obj3 = {
					key: "midHeaderbrowser",
					value: ""
				}

				var Obj4 = {
					key: "midHeaderoperating",
					value: ""
				}

				var Obj5 = {
					key: "chatPickStatusTransaction",
					value: { "key": "", "status": "", "Text": "", "msgToShow": null }
				}

				var Obj6 = {
					key: "showMidHeader",
					value: false
				}

				var Obj7 = {
					key: "liveChatDocId",
					value: ""
				}

				var Obj8 = {
					key: "blankChatScreenMsg",
					value: "Another Agent have ended an ongoing chat. You can either select another ongoing chat or select an incoming chat"
				}
				var Obj13 = {
					key: "telegram_data",
					value: ""
				  }

				arrayToSet.push(Obj1);
				arrayToSet.push(Obj2);
				arrayToSet.push(Obj3);
				arrayToSet.push(Obj4);
				arrayToSet.push(Obj5);
				arrayToSet.push(Obj6);
				arrayToSet.push(Obj7);
				arrayToSet.push(Obj8);
				arrayToSet.push(Obj13);

				this.context.updateGlobalData("array", arrayToSet);
			}, 500);
		}

		try {
			//this.refs.rightdata.hideAll();
		} catch (error) {

		}
	}

	clearChatData = (chatId) => {

		try {
			var arrayToSet = [];
			this.typingSnapshots[chatId]();
			this.chatSnapShots[chatId]();
			let changeTextLangArr = this.context.changeTextLangArr ? this.context.changeTextLangArr : [];
			if (chatId) {
				delete changeTextLangArr[chatId];
			}
			let obj1 = {
				key: "placeHolder",
				value: "Type here to chat"
			}
			let obj2 = {
				key: "changeTextLangArr",
				value: changeTextLangArr
			}
			arrayToSet.push(obj1);
			arrayToSet.push(obj2);
			this.context.updateGlobalData("array", arrayToSet);
			let indexOfKey = this.visitorSnapShotsId.indexOf(chatId);

			if (indexOfKey !== -1) {
				this.visitorSnapShotsId.splice(indexOfKey, 1); // remove from this array also
			}
			setTimeout(() => {
				if (this.chatSnapShots.hasOwnProperty(chatId)) {
					delete this.chatSnapShots[chatId];
				}
				if (this.typingSnapshots.hasOwnProperty(chatId)) {
					delete this.typingSnapshots[chatId];
				}
			}, 10);
		} catch (err) { }

		try {
			//// Delete data from Local Storage DB Start ////
			deleteChatData(chatId);
			// deleteMessage(chatId);
			deletePlanData(chatId);
			//// Delete data from Local Storage DB End ////
		} catch (error) { }
	}

	deleteMultipleSnapshots = () => {
		const allChatIds = this.context.chatIdsToClose;
		if (allChatIds.length > 0) {
			allChatIds.forEach(chatId => {
				this.clearChatData(chatId);
			});
		}
		this.context.updateGlobalData("chatIdsToClose", []);
	}


	listenScrollEvent = (e) => {
		let pointer = e.target.scrollTop;
		scrollPosition = e.target.scrollHeight - e.target.clientHeight;
		let difference = scrollPosition - pointer;
		try {
			//this.readCount(this.state.liveChatDocId);
			var noOfCount = document.getElementById("totalNotifications").innerHTML;
			if (difference > 110) {
				if (noOfCount.trim() !== "") {
					document.getElementById("countNotification").style.display = "block";
				} else {
					document.getElementById("countNotification").style.display = "none";
				}
				document.getElementById("upDownNotify").style.display = "block";
			} else {
				document.getElementById("upDownNotify").style.display = "none";
				if (noOfCount.trim() !== "") {
					document.getElementById("totalNotifications").innerHTML = "";
				}
			}
		} catch (error) { }
	}

	scrollChatScreen = (agentId = null) => {
		try {
			let isnone = document.getElementById("upDownNotify").style.display;
			if (scrollPosition === 0 || isnone === "none") {
				var s = document.getElementById('Testing');
				s.scrollTo({
					top: s.scrollHeight,
					left: 100,
				});
			} else {
				if (isnone === "block") {
					let totalCount = (this.context.currentVisitor.agent_read_count && this.context.currentVisitor.agent_read_count > 0) ? this.context.currentVisitor.agent_read_count : 0;
					if (agentId === null && totalCount > 0) {
						document.getElementById("countNotification").style.display = "block";
						document.getElementById("totalNotifications").innerHTML = this.context.currentVisitor.agent_read_count;
					}
				} else {
					var s = document.getElementById('Testing');
					s.scrollTo({
						top: s.scrollHeight,
						left: 100,
					});
					document.getElementById("countNotification").style.display = "none";
					document.getElementById("totalNotifications").innerHTML = "";
				}
			}
		} catch (error) { }
	}

	goToUnreadChats = () => {
		try {
			var s = document.getElementById('Testing');
			s.scrollTo({
				top: (scrollPosition + 50),
				left: 100,
			});
			setTimeout(() => {
				document.getElementById("upDownNotify").style.display = "none";
				document.getElementById("countNotification").style.display = "none";
				document.getElementById("totalNotifications").innerHTML = "";
				scrollPosition = s.scrollHeight - s.clientHeight;
			}, 0);
		} catch (error) { }
	}

	/*when drag enter in center panel */
	onDragEnterDiv = (e) => {
		e.preventDefault();
		e.stopPropagation();
		var allItems = e.dataTransfer.items;
		var countFiles = 0;
		for (var i = 0; i < allItems.length; i += 1) {
			if (allItems[i].kind === 'file') {
				//var f = allItems[i].getAsFile();
				countFiles++;
			}
		}
		if (countFiles > 0) {
			try {
				document.getElementById('App').style.display = 'block';
				document.getElementById('DragShowHide').style.display = 'block';
				document.getElementById('dropZoneSection').style.display = 'block';
			} catch (e) { }
		} else {
			return false;
		}
	}

	/* Translation functions start */

	detectLanguage = async (key, text) => {
		try {

			if (text === undefined || typeof text === undefined || text.trim() === "") {
				return false;
			}

			if (Object.keys(this.state.detected_lang).length > 0) {
				if (this.state.detected_lang[key]) {
					return;
				}
			}

			if (text.length > 200) {
				text = text.substring(0, 200);
			}

			var dataObj = [`q=${text}&key=${GOOGLE_TRANSLATION_KEY}`];
			await fetch("https://translation.googleapis.com/language/translate/v2/detect", {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: dataObj
			})
				.then(res => res.json())
				.then((result) => {
					const detectedLang = result.data.detections[0][0].language;
					const confidence = result.data.detections[0][0].confidence;
					if (confidence > 0.65) {
						const checkindex = lang_code_list.findIndex(x => x.Code === detectedLang);
						if (checkindex !== -1) {
							const detLanguage = lang_code_list[checkindex];
							this.state.detected_lang[key] = detLanguage.Name;
							this.setState({
								detectedLanguageCode: detLanguage.Code
							})
						}
					}
				}).catch(err => { });
		} catch (err) { }
	};

	startTranslating = () => {

		if (this.context.goneOffline) {
			return false;
		}

		var allTranslaters = {};

		var tmpData = this.context.chatTranslator ? this.context.chatTranslator : {};
		if (Object.keys(tmpData).length > 0) {
			for (var key in tmpData) {
				if (tmpData.hasOwnProperty(key)) {
					allTranslaters[key] = tmpData[key];
				}
			}
		}
		let changeTextLangArr = this.context.changeTextLangArr ? this.context.changeTextLangArr : {};
		let liveChatDocId = this.context.liveChatDocId ? this.context.liveChatDocId : false;
		if (liveChatDocId) {
			changeTextLangArr[liveChatDocId] = this.state.detectedLanguageCode;
		}

		let storageArr = [];
		if (Object.keys(changeTextLangArr).length > 0) {
			for (const key22222 in changeTextLangArr) {
				storageArr.push({ sKey: key22222, sValue: changeTextLangArr[key22222] });
			}
		}

		localStorage.setItem('changeTextLangArr', JSON.stringify(storageArr));

		if (Object.keys(allTranslaters).length > 0) {
			allTranslaters[this.context.liveChatDocId] = { "status": true, "translating": true };
			let arrayToSet = [];
			let obj1 = {
				key: "chatTranslator",
				value: allTranslaters
			}
			let obj2 = {
				key: "changeTextLangArr",
				value: changeTextLangArr
			}
			arrayToSet.push(obj1);
			arrayToSet.push(obj2);

			localStorage.setItem('chatTranslator', JSON.stringify(allTranslaters));

			this.context.updateGlobalData("array", arrayToSet);
		} else {
			let arrayToSet = [];
			let obj1 = {
				key: "chatTranslator",
				value: { [this.context.liveChatDocId]: { "status": true, "translating": true } }
			}
			let obj2 = {
				key: "changeTextLangArr",
				value: changeTextLangArr
			}
			arrayToSet.push(obj1);
			arrayToSet.push(obj2);

			localStorage.setItem('chatTranslator', JSON.stringify({ [this.context.liveChatDocId]: { "status": true, "translating": true } }));

			this.context.updateGlobalData("array", arrayToSet);
		}
		console.log("---translated --------------")
		//update visitor data in db
		firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ translated_lang : this.state.detectedLanguageCode });
		
		this.getChatSnapshot(this.context.currentVisitor, true);
	}

	stopTranslating = () => {

		var allTranslaters = {};
		var tmpData = this.context.chatTranslator ? this.context.chatTranslator : {};
		if (Object.keys(tmpData).length > 0) {
			for (var key in tmpData) {
				if (key === this.context.liveChatDocId) {
					try { delete this.state.detected_lang[this.context.liveChatDocId]; } catch (err) { }
					allTranslaters[key] = { "status": false, "translating": false };
				} else {
					if (tmpData.hasOwnProperty(key)) {
						allTranslaters[key] = tmpData[key];
					}
				}
			}
		}

		let changeTextLangArr = this.context.changeTextLangArr ? this.context.changeTextLangArr : [];
		let liveChatDocId = this.context.liveChatDocId ? this.context.liveChatDocId : false;
		if (liveChatDocId) {
			delete changeTextLangArr[liveChatDocId];
		}
		this.context.updateGlobalData("changeTextLangArr", changeTextLangArr);

		if (Object.keys(allTranslaters).length > 0) {
			let arrayToSet = [];
			let obj1 = {
				key: "chatTranslator",
				value: allTranslaters
			}
			let obj2 = {
				key: "changeTextLangArr",
				value: changeTextLangArr
			}
			arrayToSet.push(obj1);
			arrayToSet.push(obj2);
			this.context.updateGlobalData("array", arrayToSet);
		} else {
			let arrayToSet = [];
			let obj1 = {
				key: "chatTranslator",
				value: { [this.context.liveChatDocId]: { "status": false, "translating": false } }
			}
			let obj2 = {
				key: "changeTextLangArr",
				value: changeTextLangArr
			}
			arrayToSet.push(obj1);
			arrayToSet.push(obj2);
			this.context.updateGlobalData("array", arrayToSet);
		}

		/* For chat translator on when coming from another tab or refresh page Start */

		try {
			let storageChatTranslator = localStorage.getItem('chatTranslator') ? JSON.parse(localStorage.getItem('chatTranslator')) : false;
			let storagechangeTextLangArr = localStorage.getItem('changeTextLangArr') ? JSON.parse(localStorage.getItem('changeTextLangArr')) : [];
			if (storageChatTranslator && Object.keys(storageChatTranslator).length > 0) {
				Object.keys(storageChatTranslator).forEach(trnasKey => {
					if (trnasKey === this.context.liveChatDocId) {
						delete storageChatTranslator[trnasKey];
					}
				});
				localStorage.setItem('chatTranslator', JSON.stringify(storageChatTranslator));
			}
			if (storagechangeTextLangArr.length > 0) {
				const filteredTransArray = storagechangeTextLangArr.filter(objVal => {
					return (objVal.sKey !== this.context.liveChatDocId) ? true : false;
				});
				localStorage.setItem('changeTextLangArr', JSON.stringify(filteredTransArray));
			}
		} catch (errorr) {
			console.log("Error in removing translation data from lacalstorage");
		}

		/* For chat translator on when coming from another tab or refresh page end */
		firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).update({ translated_lang : "" });
		this.getChatSnapshot(this.context.currentVisitor, true);

		try {
			deleteMessage(this.context.liveChatDocId);
		} catch (err) { }
	}

	setDetectedLang = (e) => {
		var allTranslaters = {};
		var tmpData = this.state.detected_lang ? this.state.detected_lang : {};
		if (Object.keys(tmpData).length > 0) {
			for (var key in tmpData) {
				if (key === this.context.liveChatDocId) {
					allTranslaters[key] = e.target.value;
				} else {
					if (tmpData.hasOwnProperty(key)) {
						allTranslaters[key] = tmpData[key];
					}
				}
			}
		}

		if (!allTranslaters.hasOwnProperty(this.context.liveChatDocId)) {
			allTranslaters[this.context.liveChatDocId] = e.target.value;
		}
		console.log("helllo", allTranslaters)
		if (Object.keys(allTranslaters).length > 0) {
			this.setState({ detected_lang: allTranslaters, detectedLanguageCode: lang_code[e.target.value] });
		} else {
			this.setState({ detected_lang: { [this.context.liveChatDocId]: e.target.value }, detectedLanguageCode: lang_code[e.target.value] });
		}
	}

	/* Translation Functions End */

	/////////////  Private functions End /////////////

	getSnapshotBeforeUpdate = (prevProps, prevState) => {
		// this.getMsgList(1);
		if (this.state.liveChatDocId !== this.context.liveChatDocId) {
			if (this.context.liveChatDocId !== "") {
				//this.isVisitorActive(this.state.liveChatDocId);
				console.log("getSnapshotBeforeUpdate", this.context.liveChatDocId, this.state.liveChatDocId)
				this.state.liveChatDocId = this.context.liveChatDocId
				this.load_id = 420;
				this.setState({ chatdetail: [], liveChatDocId: this.context.liveChatDocId });
				return "reload";
			} else {
				this.state.liveChatDocId= this.context.liveChatDocId
				this.setState({ liveChatDocId: this.context.liveChatDocId });
			}
		}

		if ((this.context.chatIdsToClose).length > 0) {
			return "DeleteSnapshot";
		}

		if (this.context.clearSnapshotChatId !== "") {
			const tmpChatId = this.context.clearSnapshotChatId;
			this.context.updateGlobalData("clearSnapshotChatId", "");
			this.clearChatData(tmpChatId);
		}

		if (this.context.pickedFromVisitorId === "start_snapshot") {
			this.context.updateGlobalData("pickedFromVisitorId", "");
			return "reload";
		}

		///////////// When internet re-connects /////////////////////////
		if (this.context.goneOffline !== this.state.checkReload) {
			if (!this.context.goneOffline && this.state.checkReload) {
				this.state.checkReload = this.context.goneOffline;
				return "reload";
			}
			this.state.checkReload = this.context.goneOffline;
		}
		///////////// When internet re-connects /////////////////////////

		if (this.context.endChatVisitorId && this.context.endChatVisitorId !== this.state.endChatVisitorId) {
			this.state.endChatVisitorId = this.context.endChatVisitorId;
			return "flush_translation";
		}

		return null;
	}

	async componentDidMount() {
		if (document.getElementById('messagesdata') !== null && document.getElementById('messagesdata') !== undefined) {
			if (this.context.showInfo === false)
				document.getElementById('messagesdata').style.display = 'block';

			else if (document.getElementById('messagesdata').style.display === 'block')
				this.context.updateGlobalData("showInfo", false) // Hide Information when messagesdata is not empty

			else
				this.context.updateGlobalData("showInfo", true) // Show Information when messagesdata is empty
		}


		/* For chat translator on when coming from another tab or refresh page start */

		let storageChatTranslator = localStorage.getItem('chatTranslator') ? JSON.parse(localStorage.getItem('chatTranslator')) : false;
		let storagechangeTextLangArr = localStorage.getItem('changeTextLangArr') ? JSON.parse(localStorage.getItem('changeTextLangArr')) : [];

		if (storageChatTranslator) {
			this.context.updateGlobalData("chatTranslator", storageChatTranslator);
		}

		if (storagechangeTextLangArr.length > 0) {
			let tmpChangeTextLangArr = {};
			let tmpDetectedLang = {};
			storagechangeTextLangArr.forEach(objVal => {
				tmpChangeTextLangArr[objVal.sKey] = objVal.sValue;
				const checkindex = lang_code_list.findIndex(x => x.Code === objVal.sValue);
				if (checkindex !== -1) {
					const detLanguage = lang_code_list[checkindex];
					tmpDetectedLang[objVal.sKey] = detLanguage.Name;
				}
			});
			this.setState({ detected_lang: tmpDetectedLang });
			this.context.updateGlobalData("changeTextLangArr", tmpChangeTextLangArr);
		}

		/* For chat translator on when coming from another tab or refresh page end */

		var body = document.getElementById('root');
		// body.classList.add("plan-upgrade")
		await firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then(async (docs) => {
			if (docs.exists) {
				let userDetails = localStorage.getItem("agent") ? JSON.parse(localStorage.getItem("agent")) : false;
				if (userDetails) {
					userDetails.revealDataEnabled = docs.data().revealDataEnabled ? true : false;
					localStorage.setItem("agent", JSON.stringify(userDetails));
				}
				this.setState({ revealDataEnabled: docs.data().revealDataEnabled });
				let paymentStatus = await checkPaymentStatus(docs.data());
				await this.getOwnerDetails(docs.data(), docs.id);
				if (paymentStatus === false) {
					this.setState({
						loader: true
					})
					let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
					if (userDetails) {
						await fetch('https://snappy.appypie.com/webservices/ClientDetails.php', {
							method: "get",
							headers: {
								Accept: 'application/x-www-form-urlencoded',
								'Content-Type': 'application/json'
							}
						}).then((response) => response.json())
							.then((responseJson) => {
								if (responseJson.status === 1) {
									const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(responseJson.CountryCode) > -1 ? false : true;
									this.setState({ country_code: responseJson.CountryCode, showTryNowButon: showTryNowButon });
								} else {

									this.setState({ country_code: 'US' });
								}
							});

						await this.getPlanData();
						await firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('payment_log').get().then((docs) => {
							var payment_data = [];
							var i;
							if (docs.size >= 1) {
								docs.forEach((doc) => {
									payment_data.push(doc.data())
									i++;
								});
							}
							this.setState({ payment_list: payment_data, historyCount: docs.size });
						})
					}
				}
			}
		})
		return false;
	}

	validateStatusData = () => {
		var checkNumber = isNaN(this.state.totalAgents);

		var billingData = this.state.billing_information.split('_');
		if (checkNumber) {
			this.setState({ loader: false, errormessage: 'Please enter valid no of Agent' });
			return false;
		}
		else if (this.state.totalAgents < 1) {
			this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be greater than 0' });
			return false;
		}
		else if ((this.state.totalAgents) > 999) {
			this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be less than 1000' });
			return false;
		}
		/* else if (parseInt(this.state.totalAgents) <= parseInt(this.state.current_agent)) {
			this.setState({ loader: false, errormessage: 'Agent count cannot be less than count of agent for which subscription has already been bought' });
			return false;
		}
		else if(( this.state.current_plan_period ===  billingData[2] ) && ( parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent )) )
		{
			this.setState({loader:false,errormessage:'Please plan and no of agent are same, either increase no of agent and change plan period'});	
			return false;
		}*/
		else {
			return true;
		}
	}

	getRequestKey = async (requestData) => {
		//const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
		const response = await fetch(GATEWAY_URL + "/generateKey", {
			method: "post",
			body: JSON.stringify(requestData),
			headers: {
				"X-API-Key": FUNCTION_API_KEY,
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status === 200) {
					this.setState({ requestKey: responseJson.stringdata });
					return true;
				} else {

					return false
				}
			});
		return response;
	}

	handleSubmit = async (e, paymentType = 'pay') => {
		e.preventDefault();
		this.setState({ loader: true });
		var no_of_agent = this.state.no_of_agent;
		var billing_information = this.state.billing_information;
		var checkCondition = await this.validateStatusData();
		if (checkCondition === false) {
			return false;
		}
		else {
			var agentData = JSON.parse(localStorage.getItem('agent'));
			var ownerEmail;
			var ownerId;
			var companyName;
			var curentPlanId;
			var currentPlanPeriod;

			if (billing_information === undefined || billing_information === '') {
				billing_information = "livechat_6_monthly_$_10";
			}
			var billingData = billing_information.split('_');

			var uniqueId = Date.now() + ((Math.random() * 100000).toFixed());
			var orderId = 'livechat_' + uniqueId;

			if (curentPlanId === "undefined" || curentPlanId === undefined) {
				curentPlanId = "";
				currentPlanPeriod = "";
			}

			/* Added for script chat start */
			let success_url = DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/install-widget?type=success';
			if (paymentType === "pay") {
				success_url += '&ptype=paid&cpId=' + this.state.current_plan_id;
			} else {
				success_url += '&ptype=trial&cpId=' + this.state.current_plan_id;
			}
			if (this.state.plan === "Free") {
				no_of_agent = 0;
			}
			var agentString = "Current no of agent " + no_of_agent;
			var myArray = JSON.stringify({ "1": agentString });
			/* Added for script chat end */
			if (this.state.plan === "Free" && this.agent.payment_method !== "trialWithoutCC" && this.agent.plan_price !== "trialWithoutCC") {
				// logic to give free trial without credit card details
				console.log('------------new url----------->')
				var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"initial","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
				if (paymentType === "pay") {
					priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"initial","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
				}
			}
			else {
				console.log('------------older url----------->')
				var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
				if (paymentType === "pay") {
					priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
				}
			}
			var netprice = parseInt(billingData[4]) * parseInt(no_of_agent);

			var requestResponse = await this.getRequestKey({ stringdata: priceKey, action: 'encrypt', no_of_agents: no_of_agent, netprice: netprice, currency: billingData[3], plan_name: 'Premium', plan_id: billingData[1], product_name: billingData[0], plan_period: billingData[2], order_id: orderId, product_id: this.state.owner_id, countryCode: this.state.countryCode, originalCountryCode: this.state.originalCountryCode, currencyCode: this.state.currencyCode });

			if (requestResponse === true) {
			
				logActivityInFirestore('Subscription upgrade plan button clicked', "upgrade_plan_btn");
				var form = document.getElementById('checkout');
				form.submit();
			}
			else {
				return false;

			}
		}
	}

	// handlePaymentChange = (e) => {
	// 	let target = e.target;
	// 	let values;
	// 	if (target.name === "billing_information") {
	// 		var billingData = target.value.split('_');
	// 		if (this.state.totalAgents == '') {
	// 			var netprice = parseInt(billingData[4]);
	// 			this.setState({
	// 				billing_information: target.value, planprice: billingData[4], totalprice: netprice
	// 			})
	// 		}
	// 		else {
	// 			var netprice = parseInt(billingData[4]) * parseInt(this.state.no_of_agent);
	// 			this.setState({
	// 				billing_information: target.value, planprice: billingData[4], totalprice: netprice, errormessage: ''
	// 			})
	// 		}
	// 	}
	// 	else if (target.name === "no_of_agent") {

	// 		var charCode = (e.which) ? e.which : e.keyCode;
	// 		var valueData = target.value.replace(/\+|-/ig, '');;
	// 		valueData = valueData.replace('+', '');
	// 		valueData = valueData.replace('-', '');
	// 		valueData = valueData.replace('.', '');
	// 		var billingData = this.state.billing_information.split('_');
	// 		var multiplyNumber = parseInt(valueData);
	// 		var checkNumber = isNaN(valueData);
	// 		if (checkNumber === true) {
	// 			valueData = this.state.no_of_agent;
	// 			multiplyNumber = 1;
	// 		}
	// 		else if (valueData < 1) {
	// 			multiplyNumber = 1;

	// 		}
	// 		var netprice = parseInt(billingData[4]) * parseInt(multiplyNumber);
	// 		this.setState({ no_of_agent: valueData, totalprice: netprice, errormessage: '', totalAgents: valueData ? parseInt(valueData) + 1 : 1 });
	// 	} else {
	// 		values = target.value;
	// 	}
	// }

	getPlanData = async () => {
		var agentData = JSON.parse(localStorage.getItem('agent'));
		var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };

		//await fetch(MAIL_FUNCTION_URL + "/payment/plandetail", {
		await fetch(GATEWAY_URL + "/plandetail", {
			method: "post",
			body: JSON.stringify(body),
			headers: {
				"X-API-Key": FUNCTION_API_KEY,
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json'
			}
		}).then((response) => response.json())
			.then((responseJson) => {
				if (responseJson.status === 200) {
					var priceData = responseJson.priceData;
					// alert(priceData.length);
					if (priceData.length > 0) {
						var billing_information = '';
						var planPrice;
						var agent = parseInt(this.state.no_of_agent);
						var planPriceYearly = parseInt(priceData[1].planPrice);
						var planPriceYearlyMonth = parseInt(planPriceYearly / 12);
						var planPriceMonthly = parseInt(priceData[0].planPrice);
						var PlanDiscountYear = parseInt(planPriceMonthly - planPriceYearlyMonth);

						if (this.state.current_plan_period === 'yearly') {
							let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[1].currencySign;
							billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
							planPrice = priceData[1].planPrice;
						} else {
							let currencySign = priceData[0].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[0].currencySign;
							billing_information = priceData[0].productName + '_' + priceData[0].planId + '_' + priceData[0].planPeriod + '_' + currencySign + '_' + priceData[0].planPrice;
							planPrice = priceData[0].planPrice;
						}

						var netprice = parseInt(planPrice) * parseInt(agent);
						//  var history_link = PAYMENT_CHECKOUT_URL+"/billing/show/" + responseJson.history_link
						var planList = [];
						var optionData;

						for (const [index, value] of responseJson.priceData.entries()) {
							optionData = { name: value.planPeriod, keyValue: value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + this.state.currencyData[value.currencyCode] + '_' + value.planPrice };
							if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {
								// Do nothing
							} else {
								planList.push(optionData);
							}
						}

						// planList.sort(function (x, y) {
						//     let a = x.name ? x.name.toUpperCase() : '',
						//         b = y.name ? y.name.toUpperCase() : '';
						//     return a === b ? 0 : a > b ? -1 : 1;
						// });

						// console.log('planList >>> ', planList);

						var history_link = responseJson.history_link
						this.setState({ totalprice: netprice, history_link: history_link, currency: this.state.currencyData[priceData[0].currencyCode], planprice: planPrice, billing_information: billing_information, plan_data: responseJson.priceData, plan_list: planList, planPriceMonthly: planPriceMonthly, planPriceYearly: planPriceYearly, planPriceDiscount: PlanDiscountYear, countryCode: priceData[0].country, originalCountryCode: priceData[0].originalCountryCode, currencyCode: priceData[0].currencyCode });
					}
					return true
				} else {
					return false;
				}
			}).then(() => {
				this.setState({
					loader: false
				})
				return true;
			});
	}

	getOwnerDetails = async (userData, docsId) => {
		return new Promise(async (res, rej) => {
			var userDetails = this.agent;
			// await firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then(async (docs) => {
			// 	if (docs.exists) {
			// 		let userData = docs.data();
			let currentUnixTimeStamp = await getDateInUTC();
			var currentStatus = '0';
			var plan_type = 'Free';
			var billing_cycle = '';
			var cost = '';
			var next_billing_date = '';
			var no_of_agents = 1;
			var plan_id = '';
			var user_id = userData.user_id;
			var no_of_agent = 1;
			var owner_email = '';
			var payment_status = '';
			var payment_enabled = this.state.payment_enabled;
			var chat_count = 1;
			var show_currency = '';
			var totalAgents = 1;

			if (userDetails.ownerEmail === 'undefined' || userDetails.ownerEmail === undefined) {
				owner_email = userData.email;
			}
			else {
				owner_email = userDetails.ownerEmail;
			}
			if (userData.payment_status === 'undefined' || userData.payment_status === undefined) {
				currentStatus = '0'
				payment_status = 'complete'
				payment_enabled = 0;
				chat_count = 1;
				show_currency = '';
				totalAgents = parseInt(no_of_agents) + 1;
			}
			else {
				let splitDate = userData.next_billing_date ? userData.next_billing_date.split('-') : '';
				let splitDay = parseInt(splitDate[2]);
				var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
				paymentDate = paymentDate + 86400000;

				var billingDate = new Date(userData.next_billing_date);
				var dateString = '';
				if (billingDate.getDate() < 10) {
					dateString = '0' + billingDate.getDate();
				}
				else {
					dateString = billingDate.getDate();
				}
				if (billingDate.getMonth() + 1 < 10) {
					dateString = dateString + '-0' + (billingDate.getMonth() + 1);
				}
				else {
					dateString = dateString + '-' + (billingDate.getMonth() + 1);
				}
				dateString = dateString + '-' + billingDate.getFullYear();
				if (userData.payment_status === 'complete') {
					show_currency = this.state.currencyData[userData.currency]
					if (userData.currency === 'undefined' || userData.currency === undefined) {
						show_currency = this.state.currencyData.USD;
					}
					payment_enabled = 0;
					chat_count = 0;
					payment_status = userData.payment_status;
					currentStatus = 1;
					plan_type = userData.plan_type;
					billing_cycle = userData.billing_cycle;
					cost = userData.plan_price;
					next_billing_date = dateString;
					no_of_agents = userData.no_of_agents;
					plan_id = userData.plan_id;
					user_id = userData.user_id;
					no_of_agent = parseInt(no_of_agents) + 1;
				}
				else if (paymentDate >= currentUnixTimeStamp) {
					show_currency = this.state.currencyData[userData.currency]
					if (userData.currency === 'undefined' || userData.currency === undefined) {
						show_currency = this.state.currencyData.USD;
					}
					payment_enabled = 1;
					chat_count = 0;
					payment_status = userData.payment_status;
					currentStatus = 1;
					plan_type = userData.plan_type;
					billing_cycle = userData.billing_cycle;
					cost = userData.plan_price;
					next_billing_date = dateString;
					no_of_agents = userData.no_of_agents;
					plan_id = userData.plan_id;
					user_id = userData.user_id;
					no_of_agent = parseInt(no_of_agents) + 1;
				}
				else {
					show_currency = '';
					payment_enabled = 0;
					chat_count = 1;
				};
			}
			var no_chat_served = userData.no_chat_served;
			var chat_limited = userData.chat_limited;
			if (userData.no_chat_served === 'undefined' || userData.no_chat_served === undefined) {
				no_chat_served = 0;
			}

			if (userData.chat_limited === 'undefined' || userData.chat_limited === undefined) {
				chat_limited = 500;
			}


			// billing_cycle = billing_cycle ? billing_cycle : 'yearly';
			//console.log('--no_of_agents --- ', no_of_agent);
			// let showOldFreePlan = userData.show_old_free_plan === true ? true : false;
			let showOldFreePlan = true
			let noChatServed = userData.no_chat_served ? userData.no_chat_served : 0;
			// this.context.updateGlobalData("showOldFreePlan", showOldFreePlan);
			var arrayToSet2 = [{ key: "showOldFreePlan", value: showOldFreePlan }, { key: "noChatServed", value: noChatServed }];
			this.context.updateGlobalData("array", arrayToSet2);

			this.setState({
				payment_enabled: payment_enabled,
				payment_status: payment_status,
				owner_id: docsId,
				company_name: userData.company_name,
				owner_email: owner_email,
				current_plan_id: plan_id,
				current_agent: parseInt(no_of_agents),
				current_plan_period: billing_cycle,
				plan: plan_type,
				billing_cycle: billing_cycle,
				amount: cost,
				agents: no_of_agents,
				chats_completed: no_chat_served + '/' + chat_limited,
				commonlogin_user_id: user_id,
				next_billing_date: next_billing_date,
				no_of_agent: no_of_agent,
				chat_count: chat_count,
				show_currency: show_currency,
				ownerEmail: userData.email,
				ownername: userData.company_name,
				totalAgents: totalAgents,
				trialStatus: userData.trialStatus === true ? true : false
			}, () => {
				res();
			});
			// 	}
			// })
			// return;
		})

	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		// this.getMsgList(2);
		console.log("didupdate",snapshot)
		if (snapshot === "reload") {
			if (Object.keys(this.context.currentVisitor).length > 0) {
				this.getChatSnapshot(this.context.currentVisitor);
			}
		}

		if (this.context.reloadCurrentVisitor) {
			this.context.updateGlobalData("reloadCurrentVisitor", false);
			if (Object.keys(this.context.currentVisitor).length > 0) {
				this.getChatSnapshot(this.context.currentVisitor);
			}
		}

		if (snapshot === "DeleteSnapshot") {
			this.deleteMultipleSnapshots();
		}

		if (snapshot === "flush_translation" && this.state.endChatVisitorId !== "") {
			deleteMessage(this.state.endChatVisitorId);
			this.state.endChatVisitorId = "";
		}

		let showOldFreePlan = this.context.showOldFreePlan === true && this.context.noChatServed < 500 ? true : false;
		if (this.context.showPaywall === true && !this.context.paymentDetails.paymentStatus && showOldFreePlan === false) {
			try {
				document.getElementById('shadowMasking1').style.display = "block";
				var body = document.getElementById('root');
				body.classList.add("plan-upgrade")
			} catch (err) { }
		} else {
			try {
				document.getElementById('shadowMasking1').style.display = "none";
				var body = document.getElementById('root');
				body.classList.remove("plan-upgrade")
			} catch (err) { }
		}
		if(this.context.currentVisitor.current_pageUrl=="Whatsapp" && this.context.currentVisitor.message_to_show=="Chat ended due to inactivity" && this.context.currentVisitor.isChatend==1 ){
		this.autocloseWhatsAppChat(this.context.currentVisitor?.operating,this.context.currentVisitor.id)
		}
	}

	iolp = () => {
		var iolPanel = document.getElementById('SideBarIO');
		iolPanel.classList.add('IOLP');
	}
	vrp = () => {
		var vrpPanel = document.getElementById('new-Main-Chat');
		vrpPanel.classList.add('VRP');
	}

	/* Unmount all mounted data start */

	componentWillUnmount = () => {
		var body = document.getElementById('root');
		body.classList.remove("plan-upgrade")
		try {
			if (Object.keys(this.chatSnapShots).length > 0) {
				for (let chatId in this.chatSnapShots) {
					this.chatSnapShots[chatId]();
					this.typingSnapshots[chatId]();
				}
			}
		} catch (err) { }
		try {
			document.getElementById('shadowMasking1').style.display = "none";
		} catch (err) { }
	}

	/* Unmount all mounted data end */


	endGuideTour = (docId, context) => {
		firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
			status: true
		})
		this.context.updateGlobalData(context, true);
	}

	guidenextStep = (docId, step, context) => {
		firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
			step: step,
		})
		this.context.updateGlobalData(context, step);
	}

	launchLivechat = () => {
		
		var arrayToSet = [];
		var Obj1 = {
			key: "launchlivechatclicked",
			value: true
		}
		arrayToSet.push(Obj1);
		var Obj2 = {
			key: "launchedLiveChat",
			value: true
		}
		arrayToSet.push(Obj2);
		this.context.updateGlobalData("array", arrayToSet);
		this.updateLaunchedLiveChat();
		let organization_alias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
		let win = window.open("/" + organization_alias + "/test-drive?widget_type=drive", '_blank');
		win.focus();
		if (this.context.livechat_guide === false && this.context.livechat_step === 0) {
			this.guidenextStep('livechat', 1, 'livechat_step');
		}
	}

	updateLaunchedLiveChat = () => {
		let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
		if (userDetails === false) {
			return;
		}
		firebaseServices.db.collection("users").doc(userDetails.ownerId).update({
			havelaunchedLiveChat: true
		});
	}
	clearbitVisitorInfo = () => {
		// console.log("💡🛠 -> file: chat.js -> line 3225 -> this.state.liveChatDocId", this.state.liveChatDocId);
		// Clearbit Enrichement API is called HERE				

		let visitor_email = (this.context.currentVisitor.visitor_email !== 'noreply@appypie.com' && this.context.currentVisitor.visitor_email !== '') ? this.context.currentVisitor.visitor_email : this.context.currentVisitor.client_email;
		// console.log("💡🛠 -> file: chat.js -> line 3188 -> visitor_email", visitor_email);
		let enrichmentData = {};
		try {
			if (visitor_email !== 'noreply@appypie.com' && visitor_email !== '' && (!visitor_email.includes('VISITOR'))) {
				firebaseServices.db.collection('enrichmentUsers').doc(visitor_email).get()
					.then((doc) => {
						if (doc.exists) {
							enrichmentData = doc.data();
							enrichmentData.visitorId = this.state.liveChatDocId;
							// console.log("💡🛠 -> file: index.js -> line 278 -> enrichmentData from DB", enrichmentData);
							this.context.updateGlobalData("enrichmentData", enrichmentData)
							this.forceUpdate()
						}
						else {
							
							this.context.updateGlobalData("enrichmentData", "");
						}
					})
					.catch((err) => {
						console.log('ERROR at clearbit enrichmentData DB: ' + err.message);
						this.context.updateGlobalData("enrichmentData", "");
					});
			}
		} catch (err) {
			console.log('ERROR at clearbit enrichmentData: ' + err.message);
			this.context.updateGlobalData("enrichmentData", "");
		}
		// Clearbit Enrichement API is called ENDS HERE
		this.context.updateGlobalData("showInfo", true)
		document.getElementById('messagesdata').style.display = 'none';
		try {
			firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.state.liveChatDocId).get().then((docData) => {
				if (docData.exists) {
					try {
						firebaseServices.db.collection('premiumUsers').doc(docData.data().ip).get()
							.then((docPremium) => {
								let revealData = "";
								if (docPremium.exists) {
									revealData = docPremium.data();
									revealData.visitorId = this.state.liveChatDocId;
								}
								this.context.updateGlobalData("revealData", revealData)
							}).catch((err) => {
								console.log('ERROR at clearbit revealData: ' + err.message);
								this.context.updateGlobalData("revealData", "");
							});
					} catch (err) {
						console.log('ERROR at clearbit revealData: ' + err.message);
						this.context.updateGlobalData("revealData", "")
					}
				}
			})
		} catch (err) {
			console.log('ERROR at clearbit revealData: ' + err.message);
			this.context.updateGlobalData("revealData", "")
		}
	}

	replaceImageWithText(e) {
		e.target.onError = null;
		e.target.outerHTML = (this.agent.agent_name.trim().split(" ")[0][0] + (this.agent.agent_name.trim().split(" ")[1] ? this.agent.agent_name.trim().split(" ")[1][0] : this.agent.agent_name.trim().split(" ")[0][1])).toUpperCase();
	}

	render() {
		console.log("renderer chatjs")
		let visitorInfo = {};
		visitorInfo.reveal = this.context.revealData;
		visitorInfo.enrich = this.context.enrichmentData;

		if (!this.state.showMidChat) {
			
			return false;
		}
		console.log("====this.state.liveChatDocId==",this.state.liveChatDocId , this.context.liveChatDocId)
		if (this.state.liveChatDocId !== this.context.liveChatDocId) {                      
		// this.setState({liveChatDocId: this.state.liveChatDocId})
			return false;
		}

		let visitorOrChatId = this.state.liveChatDocId;  //this client id example CLIENT1676004879647
		// console.log("💡🛠 -> file: chat.js -> line 3250 -> visitorOrChatId", visitorOrChatId);
		let chatWnH = 'calc(100vh - 215px)';
		let isShowEndedChatSec = visitorOrChatId ? "none" : "block";
		let loginType = this.agent.roleType ? this.agent.roleType : "agent";
		let showOldFreePlan = this.context.showOldFreePlan === true && this.context.noChatServed < 500 ? true : false;

		var isShowPaidButtonSec = this.context.showPaywall === true && !this.context.paymentDetails.paymentStatus && showOldFreePlan === false ? "block" : "none";
		var widgetVerify = this.context.widgetVerify;
		// var totalAgents = parseInt(this.state.no_of_agent + 1);

		var backgroundColor = this.context.launchlivechatclicked ? '#eaeaea' : "#0984f6";
		var textColor = this.context.launchlivechatclicked ? '#000' : "#fff";

		// console.log("\n\n\n💡🛠 -> file: chat.js -> line 3262 -> visitorInfo", visitorInfo);
		if (this.context.widgetVerify || this.context.currentVisitor.start_url === "TESTDRIVE" || this.context.currentVisitor.start_url === "incoming_insta") {
			return (<>
				<div id="shadowMasking1" className="popupShadow-upgradePlan"></div>
				<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
				<form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
					<input type="hidden" name="request" value={this.state.requestKey} />
				</form>
				{this.state.revealDataEnabled && (visitorInfo.reveal.company !== null || visitorInfo.enrich.person !== null) && (visitorInfo.reveal !== '' || visitorInfo.enrich !== '') && this.context.ongoingChatIdes.includes(visitorOrChatId) ? (<>
					{this.context.showInfo === false ?
						(<div className="visitor-btnGrp">
							<button className='active' onClick={() => { this.context.updateGlobalData("showInfo", false); document.getElementById('messagesdata').style.display = 'block'; this.scrollChatScreen(); }}>Chat</button>
							<button className='' onClick={() => { this.clearbitVisitorInfo() }}>Info</button>
						</div>) :
						(<div className="visitor-btnGrp">
							<button className='' onClick={() => { this.context.updateGlobalData("showInfo", false); document.getElementById('messagesdata').style.display = 'block'; this.scrollChatScreen(); }}>Chat</button>
							<button className='active' onClick={() => { this.clearbitVisitorInfo() }}>Info</button>
						</div>)
					}
				</>) : (null)}
				{visitorOrChatId ? (<div className="border-bottom px-lg-5">
					<div className="main-listing">
						<div className="container-xxl  input-group px-2">
							<div className="py-3 py-lg-5 input-group-append custom-active-chat-user scroll-3">

								<div className="chat-ID-feilds active">
									{visitorOrChatId}
									<div style={{ display: this.context.currentVisitor.agent_read_count > 0 ? 'block' : 'none' }} className="badge badge-circle badge-primary badge-border-light badge-top-right">
										<span>{this.context.currentVisitor.agent_read_count ? this.context.currentVisitor.agent_read_count : ''}</span>
									</div>
								</div>


								{/* <ul>
									<li className="active">{visitorOrChatId}<div style={{ display: this.context.currentVisitor.agent_read_count > 0 ? 'block' : 'none' }} className="badge badge-circle badge-primary badge-border-light badge-top-right">
										<span>{this.context.currentVisitor.agent_read_count ? this.context.currentVisitor.agent_read_count : ''}</span>
									</div></li>
								</ul> */}
								{/* 
                                <div className="aroow-left-right">
                                    <div className="left-arrow arrow-user-id"><img alt="" src={require("../../../../assets/images/livechat/left-arrow-id.svg")} /></div>
                                    <div className="right-arrow arrow-user-id"><img alt="" src={require("../../../../assets/images/livechat/right-arrow-id.svg")} /></div>
                                </div> */}
							</div>
						</div>
					</div>
				</div>) : (null)}

				<div style={{ display: isShowPaidButtonSec, zIndex: 999, background: 'transparent' }} id="freePlanMsgss" className="ong-inc-chat-ended">


					<div className="popupInstallLivechat popupLivechat planPopup" style={{ display: 'block' }}>
						<div className="free_user">
							<a className="close_icon"></a>
							<img src={require('../../../../assets/img/icon/inactive-chatbot.svg')} alt="inactive chatbot" />
							<h4 className="mt-4">Your Livechat is inactive!</h4>
							<p className="mb-5">
								BuyNow today to edit the Livechat to your liking, and deploy it on your website or app.
							</p>
							<div style={{ color: 'red' }}>{this.state.errormessage}</div>
							<div className={this.state.showTryNowButon && !this.state.trialStatus ? "my-4 try-now" : "my-4"}>
								<button onClick={this.handleSubmit} className="btnBlueSettings add-agent-clik">Buy Now</button>
								{this.state.showTryNowButon && !this.state.trialStatus ? (<button onClick={(e) => { this.handleSubmit(e, 'trial') }} className="btnBlueSettings add-agent-clik">Try Now</button>) : (null)}
							</div>
						</div>
					</div>
				</div>

				<div style={{ display: isShowEndedChatSec }} id="enddedChatSection" className="ong-inc-chat-ended">


					{/* <div className="mobile-leftRightPanelDisplay">
						<div className="py-4 px-4 k-flex align-items-center">
							<span className="mr-auto cursor-pointer" onClick={this.iolp}>
								<svg xmlns="http://www.w3.org/2000/svg" width="25.454" height="20.379" viewBox="0 0 30.454 25.379">
									<path id="arrow" d="M37.186,23.42H11.871l9.693-9.233a1.269,1.269,0,0,0-1.75-1.838L8.744,22.894a2.537,2.537,0,0,0,.022,3.611L19.814,37.028a1.269,1.269,0,1,0,1.75-1.837l-9.734-9.233H37.186a1.269,1.269,0,0,0,0-2.538Z" transform="translate(-8 -12)"/>
								</svg>
							</span>
							<span className="cursor-pointer" onClick={this.vrp}>
								<svg xmlns="http://www.w3.org/2000/svg" width="25.454" height="20.379" viewBox="0 0 30.454 25.379">
									<path id="arrow" d="M9.269,23.42H34.584L24.89,14.187a1.269,1.269,0,0,1,1.75-1.838l11.07,10.545a2.537,2.537,0,0,1-.022,3.611L26.64,37.028a1.269,1.269,0,1,1-1.75-1.837l9.734-9.233H9.269a1.269,1.269,0,0,1,0-2.538Z" transform="translate(-8 -12)"/>
								</svg>
							</span>
						</div>
					</div> */}

					<div className="ong-inc-chat">
						<div className="avatar mr-3 mb-4">
							{this.context.userProfileImage ? (<img className="avatar-img" src={this.context.userProfileImage} alt="" onError={(e) => this.replaceImageWithText(e)} />) : (
								<span>{this.state.agent_short}</span>
							)}

						</div>
						<p className="small text-screen1" id="messageendChatPara">
							{this.context.blankChatScreenMsg !== "" ? (this.context.blankChatScreenMsg) : "No ongoing chat is assigned to you. Select an incoming chat or initiate a chat by clicking on a visitor from visitor panel."}
						</p>
					</div>
				</div>

				{/* For Translate visitor chat start */}

				{this.context.chatTranslator[visitorOrChatId] === undefined ? (null) : (<>
					{this.context.chatTranslator[visitorOrChatId].status ? (
						<div>{this.context.chatTranslator[visitorOrChatId].translating ? (
							<div className="translateChat">
								<div className="languageFilter">
									<p className="detactLanguage"><img alt="" src={require('../../../../assets/img/icon/language.svg')} className="translateIcon" />translating from {this.state.detected_lang[visitorOrChatId] ? this.state.detected_lang[visitorOrChatId] : "Afrikaans"} Language</p>
									<div className="detactLanguageBTN">
										<button onClick={() => this.stopTranslating()} className="stopTranslating">Stop Translating</button>
									</div>
									{/* <div className="googleTranslateIconImage">
										<span><img alt="" src={require('../../../../assets/images/google.png')} />Translate</span>
									</div> */}
								</div>
								<span className="google-translate-new">
									<img alt="" src={require('../../../../assets/images/google_translate_new.svg')} />
								</span>
							</div>) : (
							<div className="translateChat">
								<div className="languageFilter">
									<p className="detactLanguage"><img alt="" src={require('../../../../assets/img/icon/language.svg')} className="translateIcon" />Detected Language</p>
									<div className="selectOption selectOptionDetactLanguage">
										<select value={this.state.detected_lang[visitorOrChatId]} onChange={this.setDetectedLang} >
											{lang_code_list.map((e, idex) => {
												return <option key={idex} value={e.Name} >{e.Name}</option>;
											})}
										</select>
									</div>
									<div className="detactLanguageBTN">
										<button onClick={this.startTranslating} className="translate">Translate</button>
									</div>
									<div className="googleTranslateIconImage">
										{/* <span><img alt="" src={require('../../../../assets/images/google.png')} />Translate</span> */}
										<span className="dismissSpan">
											<img alt="" onClick={() => this.stopTranslating()} className="dismissIconImg" src={require('../../../../assets/images/dismiss2.svg')} />
										</span>
									</div>
								</div>
								<span className="google-translate-new">
									<img alt="" src={require('../../../../assets/images/google_translate_new.svg')} />
								</span>
							</div>)}

						</div>) : (null)}
				</>)}

				{/* For Translate visitor chat End */}
				{/* .chat-content replace to scroll-4 */}
				<div className={this.context.chatPickStatusTransaction.status === "start" ? "container-xxl chat-content-New px-lg-8 py-5 py-lg-5 scroll-4 mb-9" : "container-xxl chat-content-New px-lg-8 py-5 py-lg-5 scroll-4"} style={{ height: chatWnH }} id="Testing" onScroll={this.listenScrollEvent.bind(this)} onDragEnter={this.onDragEnterDiv}>
					
					{this.context.chatLoader?<Loader />:
					
					this.state.revealDataEnabled && (visitorInfo.reveal !== null || visitorInfo.enrich !== null) &&
						(!this.context.ongoingChatIdes.includes(visitorOrChatId) || this.context.showInfo === true) &&
						(visitorOrChatId === visitorInfo.reveal.visitorId || visitorOrChatId === visitorInfo.enrich.visitorId) ?
						(<div>

							<h5 className='text-center mb-4'>Visitor Information</h5>
							<div className='visitor-info'>
								{(visitorInfo.enrich.person !== null && visitorInfo.enrich.person !== undefined && visitorOrChatId === visitorInfo.enrich.visitorId) ? (<>
									<div className='user-iconVisitor'>
										{visitorInfo.enrich.person.avatar !== null && visitorInfo.enrich.person.avatar !== undefined ? (<><img src={visitorInfo.enrich.person.avatar} alt="Avatar" width="30" height="30" /> &nbsp;</>) : (null)}
										{visitorInfo.enrich.person.name.fullName !== null ? visitorInfo.enrich.person.name.fullName : ('No data')}
									</div>
									<ul className='d-flex flex-wrap mt-4'>
										{visitorInfo.enrich.person.employment !== null && visitorInfo.enrich.person.employment !== undefined ? (<>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16"> <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" /> </svg>
												Email: {visitorInfo.enrich.person.email !== null ? visitorInfo.enrich.person.email : ('No data')}
											</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
													<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
												</svg>
												LinkedIn: {visitorInfo.enrich.person.linkedin.handle !== null ? (<a href={"https://www.linkedin.com/" + visitorInfo.enrich.person.linkedin.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase-fill" viewBox="0 0 16 16">
													<path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
													<path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
												</svg>
												Employment: {visitorInfo.enrich.person.employment.title !== null ? visitorInfo.enrich.person.employment.title : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
													<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
												</svg>
												Facebook: {visitorInfo.enrich.person.facebook.handle !== null ? (<a href={'https://www.facebook.com/' + visitorInfo.enrich.person.facebook.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
													<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
												</svg>
												Role: {visitorInfo.enrich.person.employment.role !== null ? visitorInfo.enrich.person.employment.role : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
													<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
												</svg>
												Twitter: {visitorInfo.enrich.person.twitter.handle !== null ? (<a href={'https://www.twitter.com/' + visitorInfo.enrich.person.twitter.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												Seniority: {visitorInfo.enrich.person.employment.seniority !== null ? visitorInfo.enrich.person.employment.seniority : ('No data')}</li>
										</>) : (null)}
									</ul><br />
								</>) : (null)}

								{(visitorInfo.reveal.company !== null && visitorInfo.reveal.company !== undefined && visitorOrChatId === visitorInfo.reveal.visitorId) ? (<>
									<div className='user-iconVisitor mb-4'>
										{(visitorInfo.reveal.company.logo !== null && visitorInfo.reveal.company.logo !== undefined) ? (<><img src={visitorInfo.reveal.company.logo} alt="Logo" /> &nbsp;</>) : (null)}
										{visitorInfo.reveal.company.name !== null && visitorInfo.reveal.company.name !== undefined ? visitorInfo.reveal.company.name : ('No data')}
									</div>
									{visitorInfo.reveal.company.description !== null && visitorInfo.reveal.company.description !== undefined ? visitorInfo.reveal.company.description : ('No data')}
									<ul className='d-flex flex-wrap mt-4'>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
												<path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z" />
											</svg>
											Legal Name: {visitorInfo.reveal.company.legalName !== undefined && visitorInfo.reveal.company.legalName !== null ? visitorInfo.reveal.company.legalName : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
												<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
											</svg>
											LinkedIn: {visitorInfo.reveal.company.linkedin !== undefined && visitorInfo.reveal.company.linkedin.handle !== undefined && visitorInfo.reveal.company.linkedin.handle !== null ? (<a href={"https://www.linkedin.com/" + visitorInfo.reveal.company.linkedin.handle} target="_blank">See the profile</a>) : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
												<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
											</svg>
											Founded Year: {visitorInfo.reveal.company.foundedYear !== undefined && visitorInfo.reveal.company.foundedYear !== null ? visitorInfo.reveal.company.foundedYear : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
												<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
											</svg>
											Facebook: {visitorInfo.reveal.company.facebook !== undefined && visitorInfo.reveal.company.facebook.handle !== undefined && visitorInfo.reveal.company.facebook.handle !== null ? (<a href={'https://www.facebook.com/' + visitorInfo.reveal.company.facebook.handle} target="_blank">See the profile</a>) : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
												<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
											</svg>
											Type: {visitorInfo.reveal.company.type !== undefined && visitorInfo.reveal.company.type !== null ? visitorInfo.reveal.company.type : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
												<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
											</svg>
											Twitter: {visitorInfo.reveal.company.twitter !== undefined && visitorInfo.reveal.company.twitter.handle !== undefined && visitorInfo.reveal.company.twitter.handle !== null ? (<a href={'https://www.twitter.com/' + visitorInfo.reveal.company.twitter.handle} target="_blank">See the profile</a>) : ('No data')}</li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
												<path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
												<path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
												<path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
											</svg>
											Employees: {visitorInfo.reveal.company.metrics !== undefined && visitorInfo.reveal.company.metrics.employees !== undefined && visitorInfo.reveal.company.metrics.employees !== null ? visitorInfo.reveal.company.metrics.employees : ('No data')}</li>
										<li></li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
												<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
											</svg>
											Raised: {visitorInfo.reveal.company.metrics !== undefined && visitorInfo.reveal.company.metrics.raised !== undefined && visitorInfo.reveal.company.metrics.raised !== null ? visitorInfo.reveal.company.metrics.raised : ('No data')}</li>
										<li></li>
										<li>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
												<path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
												<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
												<path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
											</svg>
											Estimated Annual Revenue: {visitorInfo.reveal.company.metrics !== undefined && visitorInfo.reveal.company.metrics.estimatedAnnualRevenue !== undefined && visitorInfo.reveal.company.metrics.estimatedAnnualRevenue !== null ? visitorInfo.reveal.company.metrics.estimatedAnnualRevenue : ('No data')}</li>
									</ul>
								</>) : (
									(visitorInfo.enrich.company !== null && visitorInfo.enrich.company !== undefined && visitorOrChatId === visitorInfo.enrich.visitorId) ? (<>
										<div className='user-iconVisitor mb-4'>
											{(visitorInfo.enrich.company.logo !== null && visitorInfo.enrich.company.logo !== undefined) ? (<><img src={visitorInfo.enrich.company.logo} alt="Logo" /> &nbsp;</>) : (null)}
											{visitorInfo.enrich.company.name !== null && visitorInfo.enrich.company.name !== undefined ? visitorInfo.enrich.company.name : ('No data')}
										</div>
										{visitorInfo.enrich.company.description !== null && visitorInfo.enrich.company.description !== undefined ? visitorInfo.enrich.company.description : ('No data')}
										<ul className='d-flex flex-wrap mt-4'>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
													<path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z" />
												</svg>
												Legal Name: {visitorInfo.enrich.company.legalName !== null ? visitorInfo.enrich.company.legalName : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
													<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
												</svg>
												LinkedIn: {visitorInfo.enrich.company.linkedin.handle !== null ? (<a href={"https://www.linkedin.com/" + visitorInfo.enrich.company.linkedin.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
													<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
												</svg>
												Founded Year: {visitorInfo.enrich.company.foundedYear !== null ? visitorInfo.enrich.company.foundedYear : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
													<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
												</svg>
												Facebook: {visitorInfo.enrich.company.facebook.handle !== null ? (<a href={'https://www.facebook.com/' + visitorInfo.enrich.company.facebook.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
													<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
												</svg>
												Type: {visitorInfo.enrich.company.type !== null ? visitorInfo.enrich.company.type : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
													<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
												</svg>
												Twitter: {visitorInfo.enrich.company.twitter.handle !== null ? (<a href={'https://www.twitter.com/' + visitorInfo.enrich.company.twitter.handle} target="_blank">See the profile</a>) : ('No data')}</li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
													<path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
													<path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
													<path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
												</svg>
												Employees: {visitorInfo.enrich.company.metrics.employees !== null ? visitorInfo.enrich.company.metrics.employees : ('No data')}</li>
											<li></li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												Raised: {visitorInfo.enrich.company.metrics.raised !== null ? visitorInfo.enrich.company.metrics.raised : ('No data')}</li>
											<li></li>
											<li>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
													<path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
													<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
													<path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
												</svg>
												Estimated Annual Revenue: {visitorInfo.enrich.company.metrics.estimatedAnnualRevenue !== null ? visitorInfo.enrich.company.metrics.estimatedAnnualRevenue : ('No data')}</li>



										</ul>
									</>) : (null))}
							</div><br /></div>) : (null)}
							
							<>
					<div id="messagesdata" >
						

					</div>
					<div style={{ width: 0, height: 0 }} className="DragShowHide" id="DragShowHide">&nbsp;</div>
					<div className="end-of-chat"></div></>
					
				</div>
				<div style={{ display: "none" }} onClick={() => this.goToUnreadChats()} id="upDownNotify" className="upDownNotify">
					<svg xmlns="http://www.w3.org/2000/svg" width="21.096" height="11.427" viewBox="0 0 21.096 11.427">
						<g id="multimedia_1_" data-name="multimedia (1)" transform="translate(0 -117.336)">
							<g id="Group_2112" data-name="Group 2112" transform="translate(0 117.336)">
								<path id="Path_2132" data-name="Path 2132" d="M20.837,117.594a.878.878,0,0,0-1.243,0l-9.047,9.047L1.5,117.594a.879.879,0,0,0-1.243,1.243l9.668,9.668a.879.879,0,0,0,1.243,0l9.668-9.668A.878.878,0,0,0,20.837,117.594Z" transform="translate(0 -117.336)" />
							</g>
						</g>
					</svg>
					<div id="countNotification" className="badge badge-circle badge-primary badge-top-right"><span id="totalNotifications"></span></div>
				</div>
				
			</>
			);

		} else {
			if (loginType === "owner" || loginType === "Owner") {
				if (this.context.launchedLiveChat === true) {
					return (
						<WidgetInstallationComponent context={this.context} endGuideTour={this.endGuideTour} guidenextStep={this.guidenextStep} />
					)
				} else {
					return (<>
						<div className='chat-middle position-top'>
							<div className="overlay-guide" style={{ display: this.context.livechat_guide === false && this.context.livechat_step === 0 && !widgetVerify ? 'block' : 'none' }}></div>
							<div className='d-flex h-100 flex-column position-relative'>
								<div className="bg-white text-center px-8 test-drive pt-6 launch-live">
									<img alt="" src={require('../../../../assets/images/livechat/test-drive.svg')} />
									<h5 className="card-title mt-8"><strong> Want to test drive your livechat?</strong></h5>
									<button className="mt-3 upper-div" onClick={this.launchLivechat} style={{ background: backgroundColor, color: textColor, borderRadius: 2, padding: '5px 8px', border: 'none' }} onMouseEnter={() => this.setState({ onButton: true })} onMouseLeave={() => { this.setState({ onButton: false }) }}>Launch livechat</button>
									{this.context.livechat_guide === false && this.context.livechat_step === 0 ? (<div className="tour_guide">
										<p className="title">Step 1 of 4</p>
										<p className="text-center">Click on this button to test livechat.</p>
										<button className="btn-next" onClick={() => { this.guidenextStep('livechat', 1, 'livechat_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('livechat', 'livechat_guide') }}>End tour</button></div>) : (null)}

								</div>
							</div>
						</div>
					</>
					)
				}
			} else {
				return (
					<WidgetInstallationInfo />
				)
			}
		}
	}
}

export default React.memo(Chat);