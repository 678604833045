import React,{useEffect} from "react";
import ErrorBoundary from "./layout/ErrorBoundary";
import Main from "./layout/index";

import {
  BrowserRouter,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";

function App() {
  let pathname = window.location.pathname;
  let agent = localStorage.getItem("agent");
  let [headerStrip, setHeaderStrip] = React.useState(false);
  let [showHeaderStrip, setShowHeaderStrip] = React.useState(false);
  if (agent && showHeaderStrip === false) {
    agent = JSON.parse(agent);
    console.log(agent, "-----agent");
    //check if agent.email and agent.ownerEmail includes appypie.io and roleType is Owner then make headerStrip true
    if (agent.email.includes("appypie.io") && agent.roleType === "Owner" && agent.ownerEmail.includes("appypie.io") && pathname !== `/${agent.organization_alias}/upgrade-account`) {
      setHeaderStrip(true);
    }
    setShowHeaderStrip(true);
  }
  useEffect(() => {
    const checkBuildVersion = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await fetch(`https://us-central1-livechat-production.cloudfunctions.net/open/version?ts=${timestamp}`); // Replace with your API endpoint
        const data = await response.json();
        const apiBuildVersion = data.version;
        const localBuildVersion = localStorage.getItem('BUILD_VERSION');
        console.log('API Build Version:', apiBuildVersion);
        console.log('Local Build Version:', localBuildVersion);
        if(!localBuildVersion){
          localStorage.setItem('BUILD_VERSION',apiBuildVersion);
        }
        if (localBuildVersion &&apiBuildVersion !== localBuildVersion ) {
          localStorage.clear();
          window.location.reload(true);
          localStorage.setItem('BUILD_VERSION',apiBuildVersion);
        }
      } catch (error) {
        console.error('Error fetching build version:', error);
      }
    };

    checkBuildVersion();
  }, []);
  return (
    <>
      {headerStrip ? <div className="verifyEmail-strip d-flex align-items-center">
        <span>Alert!
          <svg xmlns="http://www.w3.org/2000/svg" width="12.019" height="15.797" viewBox="0 0 18.019 15.797">
            <g id="Group_95595" data-name="Group 95595" transform="translate(-84.663 -15.601)">
              <path id="Path_141591" data-name="Path 141591" d="M9.132,5.1l-6.6,11.011a1.557,1.557,0,0,0,1.332,2.336H17.06a1.557,1.557,0,0,0,1.332-2.336L11.8,5.1a1.557,1.557,0,0,0-2.663,0Z" transform="translate(83.208 12.105)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7" />
              <path id="Path_141592" data-name="Path 141592" d="M18,13.5v3.115" transform="translate(75.672 7.704)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7" />
            </g>
          </svg>

        </span>
        <span>
          Please verify your email to never miss any updates and notifications from your livechat
        </span>
        <span>
          <button className="btn" onClick={() => {
            //redirect to another page on new tab
            window.open("https://accounts.appypie.com/personal-info", "_blank");
          }}>Add Email Address </button>
        </span>
      </div> : null}
      <ErrorBoundary>
        <BrowserRouter>
          <Main headerStrip={headerStrip} />
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
