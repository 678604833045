import React from 'react';
import { withRouter } from "react-router-dom";
import {getSessionCheck}  from "../config/SignupLogin";
import { getCookie } from '../services/service';

class Sessioncheck extends React.Component {
    constructor() {
        super();
       
    }

    componentDidUpdate() {
      
    }

    
    async componentDidMount() {
		try {
                setTimeout(() => {
                    this.callGetSession();
                }, 1000);

                const setInterValTime = window.location.hostname === "localhost" ? 10000 : 10000;
				setInterval(async () => {
					this.callGetSession();
				}, setInterValTime);
			  } catch(e) {
				console.log(e);
		  }
    }

    callGetSession = async () => {
        var loginStatus = 0;
        var loginType = "";
        if(window.location.hostname === "localhost") {
            loginStatus = 1
            loginType = "both";
        } else {
            var agentLoginStatus = await getCookie("61be0d8c3c195df8308def9fac389355");
            if(agentLoginStatus === "1" || agentLoginStatus === 1) {
                loginStatus = 1;
                loginType = "agent";
            } else {
                loginStatus = await getCookie('79fefaf2564aabb19d44fe324844c86b');
                loginType = "owner";
            }
        } 
        if(loginStatus === "1" || loginStatus === 1) {
            await getSessionCheck(true, loginType);
        }
    }
    render() {
        return (
            <div></div>
        );
    }

}

export default withRouter(Sessioncheck);
