/**
 * @description Create A registaion page with AWS Acogniti Authentication Porces 
 * @version 1.0
 * @author Ruchi kapil <ruchi@appypiellp.com>
 * @file CompanyRegistaion.js
 */
import React, { Component } from 'react';
import './Login.scss';
import { DOMAIN_NAME, COMMON_LOGIN_URL, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
//import { mailUrl } from "../../config/mailFunctions.js";
import {
	signupLoginCheck,
	isUserExistWithEmail,
	getSessionCheck,
	logInWithCustomToken
} from "./../../config/SignupLogin";
// import db from './../../config/firebase';

import {
	createNewUser, updateSessioninCollection,
	getOwnerUserDataForLogin,
	updateAgentData,
	checkCompanyAliasAndCreateUnique
} from './Model/DbModel'
import { checkIsUserOrOwnerLogin, getUTCTimeDate, cleanupString } from './OnboardHelper';
///import data from "../../localization/login-registration.json";
import { activityLog } from "../../services/service";
import { saveAgentSession } from "../livechat/components/Comman";
import { deleteFilters } from "../livechat/components/Dblovefield";
import firebaseServices from '../../firebase';

const firebaseAuthKey = "firebaseAuthInProgress";
const appTokenKey = "appToken";

const moment = require("moment-timezone");

const md5 = require("md5");
const url = new URL(window.location.href);

// Step 2: Create a URLSearchParams object
const params = new URLSearchParams(url.search);

// Step 3: Retrieve the 'company' parameter
const orgName = params.get('org');
if(orgName){
	localStorage.setItem('orgName',orgName)
}
class CommonSession extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loader: true
		}
	}

	sendEmail = (body) => {
		fetch(GATEWAY_URL + '/sendEmail', {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
		})
			.then()
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e, "ERROR");
			});
	}
	/**
	 * check if login or not
	 */
	componentDidMount = async () => {
		try {
			deleteFilters();
		} catch(errrr){}
		localStorage.removeItem('loggingout');
		this.setState({ loader: true });
		//window.addEventListener("visibilitychange", this.sendMessage, false);
		/**
		 * on this component first check user login then redirect inner page
		 *  if user not loggedin and  company name not set on this page then user is comming from direct here then redirect 
		 *  to first step of on-boarding		 */

		// setTimeout(async () => {
		localStorage.removeItem('logoutowner');
		const islogin = await checkIsUserOrOwnerLogin();
		const returnData = await signupLoginCheck();
		//alert(returnData);

		console.log("---------  islogin   ---------->>>> "+ islogin);
		console.log("---------  returnData   ---------->>>> "+ returnData);

		if (islogin === 'COMMONLOGIN' || returnData === 'sessionexist') {
			////alert('kkkkkkkkkkkkkkk');
			var userCommonLoginData = localStorage.getItem('userdata') ? JSON.parse(localStorage.getItem('userdata')) : false;

			console.log("-----------------    userCommonLoginData   1111111  ------------------");
			console.log(userCommonLoginData);

			if (!userCommonLoginData) {
				//console.log('------97-----');
				await getSessionCheck(false);
			}
			setTimeout(async () => {
				userCommonLoginData = localStorage.getItem('userdata') ? JSON.parse(localStorage.getItem('userdata')) : false;
				//console.log('userCommonLoginData >>>> ', userCommonLoginData);
				console.log("-----------------  setTimeout  userCommonLoginData   222222222222  ------------------");
				console.log(userCommonLoginData);
				console.log(userCommonLoginData.email,"EMAIL--------->>>>>>>>>.",userCommonLoginData.user_mobile)
				if (!userCommonLoginData.email&&userCommonLoginData.user_mobile){
					// window.location.href="https://commonlogin.pbodev.info/verifycheck?frompage=https:%2F%2Fdev-desk.pbodev.info%2Fticket%2Fverifyauthtication&website=https:%2F%2Fdev-desk.pbodev.info%2Fticket%2Fverifyauthtication"
					// window.location.href = COMMON_LOGIN_URL + "/verifycheck?frompage=" + DOMAIN_NAME + "/verify-email&website=" + DOMAIN_NAME;
					this.props.history.push('/verify-email')
					this.setState({ loader: false });
					return;
				}
				let IsEmail = '';
				if (userCommonLoginData) {
					IsEmail = await isUserExistWithEmail(md5(userCommonLoginData.email));
				}
				console.log('IsEmail ------>>>>>> 33333333333', IsEmail);

				// return;
				//
				if (IsEmail) {
					//console.log('----103----');
					localStorage.setItem("accessToken", userCommonLoginData.access_token ? userCommonLoginData.access_token : '');
					//updateSessioninCollection(userCommonLoginData.access_token, userCommonLoginData.email);
					let euserId = IsEmail[0].id
					const getloginData = await getOwnerUserDataForLogin(euserId, userCommonLoginData.email);

					getloginData.oldFileName = userCommonLoginData.pic ? userCommonLoginData.pic : '';
					getloginData.oldThumbnailName = userCommonLoginData.thumbnail ? userCommonLoginData.thumbnail : '';
					getloginData.imageurl = userCommonLoginData.imageurl ? userCommonLoginData.imageurl : '';
					getloginData.profile_img_orig = userCommonLoginData.pic && userCommonLoginData.pic !== 'notexist' ? userCommonLoginData.imageurl + '/' + userCommonLoginData.pic : '';
					getloginData.profile_img = userCommonLoginData.thumbnail && userCommonLoginData.thumbnail !== 'notexist' ? userCommonLoginData.imageurl + '/' + userCommonLoginData.thumbnail : '';
					getloginData.first_name = userCommonLoginData.first_name ? userCommonLoginData.first_name : '';
					getloginData.last_name = userCommonLoginData.last_name ? userCommonLoginData.last_name : '';
					getloginData.alias = userCommonLoginData.alias ? userCommonLoginData.alias : '';
					getloginData.ownerEmail = userCommonLoginData.email;
					getloginData.email = userCommonLoginData.email;
					getloginData.timezone = getloginData.timezone ? getloginData.timezone : (moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess());
					getloginData.agent_name = getloginData.last_name && getloginData.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? getloginData.first_name + ' ' + getloginData.last_name : getloginData.first_name;
					getloginData.name = getloginData.agent_name;
					getloginData.phone_call = getloginData.phone_call ? getloginData.phone_call : 0;
					getloginData.voip_call = getloginData.voip_call ? getloginData.voip_call : 0;
					getloginData.widget_voip_call = getloginData.widget_voip_call ? getloginData.widget_voip_call : 0;
					getloginData.whatsapp_msg = getloginData.whatsapp_msg ? getloginData.whatsapp_msg : 0;
					getloginData.realmId = userCommonLoginData._id ? userCommonLoginData._id : '';
					getloginData.isInHouseAccount = getloginData.isInHouseAccount ? getloginData.isInHouseAccount : 0;
					getloginData.fb_page_token = getloginData.fb_page_token ? getloginData.fb_page_token : "";
					getloginData.tdm_tokens = getloginData.tdm_tokens ? getloginData.tdm_tokens : "";
					getloginData.zoom_status = getloginData.zoom_status ? getloginData.zoom_status : 0;
					getloginData.tdm_status = getloginData.tdm_status ? getloginData.tdm_status: 0;
					getloginData.visitedUpgrade = true; // updating visitedupgrade on login so that user can not see upgrade page again
					getloginData.smart_compose = getloginData.smart_compose ? getloginData.smart_compose: 0;
					getloginData.triggers_status = getloginData.triggers_status ? getloginData.triggers_status: 0;
					/* Add Agent session start */
					saveAgentSession(getloginData, "login");
					/* Add Agent session end */

					await updateSessioninCollection(userCommonLoginData.access_token, euserId);
					localStorage.setItem('agent', JSON.stringify(getloginData));
					var agentId;
					const ownerId = getloginData.ownerId;
					if (getloginData.agentId) {
						agentId = getloginData.agentId;
					} else {
						agentId = Date.now() + ((Math.random() * 100000).toFixed());
						getloginData.agentId = agentId;
					}

					const newAgentData = {
						last_login: getUTCTimeDate(),
						online_status: 1
					}

					/* For extra security sign in with token start */
					const isLogedInInSecurity = await logInWithCustomToken(ownerId, agentId);
					/* For extra security sign in with token end */
					
					await updateAgentData(ownerId, agentId, newAgentData)
					await activityLog('Login');
					let companyURL = getloginData.company_url ? getloginData.company_url : getloginData.organization_alias;

					if (companyURL) {
						if (getloginData.deactivated && getloginData.deactivated === true) {
							window.location.href = '/' + companyURL + '/account-deactivated';
						} else {
							const redirectTo = localStorage.getItem('redirect_to');
							if (redirectTo) {
								localStorage.removeItem('redirect_to');
								window.location.href = redirectTo;
							} else {
								window.location.href = '/' + getloginData.ownerIdentifire;
							}
						}
					} else {
						if (userCommonLoginData.company && userCommonLoginData.company_identifier) {
							firebaseServices.db.collection('users').doc(getloginData.ownerId).update({
								company_name: userCommonLoginData.company,
								organization_alias: userCommonLoginData.company_identifier,
								company_url: userCommonLoginData.company_identifier
							}).then(async () => {
								getloginData.ownerIdentifire = userCommonLoginData.company_identifier;
								getloginData.company_name = userCommonLoginData.company;
								getloginData.organization_alias = userCommonLoginData.company_identifier;
								localStorage.setItem('agent', JSON.stringify(getloginData));
								if (getloginData.deactivated && getloginData.deactivated === true) {
									window.location.href = '/' + companyURL + '/account-deactivated';
								} else {
									const redirectTo = localStorage.getItem('redirect_to');
									if (redirectTo) {
										localStorage.removeItem('redirect_to');
										window.location.href = redirectTo;
									} else {
										window.location.href = '/' + getloginData.ownerIdentifire;
									}
								}
							})
						} else {
							window.location.href = '/';
						}
					}

					// const getUserLogin = localStorage.getItem('redirectPage');			    
					// 	this.setState({ loader: false });
					/*if (getUserLogin) {
						localStorage.removeItem('redirectPage');
						this.props.history.push(getUserLogin);
						return
					} else {
						window.location = '/'
						// this.props.history.push("/");
						return
					}*/
				}
				else if (userCommonLoginData) {
					let localStorageCompany = localStorage.getItem('companyName') ? localStorage.getItem('companyName') : false;
					let commonloginCompany = userCommonLoginData.company ? userCommonLoginData.company : false;
					const customizeWidget = localStorage.getItem('settingrgbColor');
					//
					if (!localStorageCompany && !commonloginCompany) {
						
						window.location = '/company-info'
						return
					} else if (!localStorageCompany && commonloginCompany) {
						localStorage.setItem('companyName', commonloginCompany);
						window.location = '/customize-widget'
						return
					}
					else {

						const companyName = userCommonLoginData.company ? userCommonLoginData.company : localStorage.getItem('companyName');
						if (userCommonLoginData && userCommonLoginData.email) {
							const accessToken = userCommonLoginData.access_token ? userCommonLoginData.access_token : '';
							var email = userCommonLoginData.email;
							let mobile = userCommonLoginData.mobile_number
							let country_code = userCommonLoginData.mobile_country_code;
							let mobile_verified = userCommonLoginData.mobile_verified;
							localStorage.setItem("accessToken", accessToken);
							const companyName = userCommonLoginData.company ? userCommonLoginData.company : localStorage.getItem('companyName');
							const settingrgbColor = localStorage.getItem('settingrgbColor')
							const settingbgColor = localStorage.getItem('settingbgColor')
							const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
							const settingChatIcon = localStorage.getItem('settingChatIcon')
							const chatBlackImage = localStorage.getItem('chatBlackImage')
							const whiteImage = localStorage.getItem('whiteImage')
							const fbwtimage = localStorage.getItem('fbwtimage')


							let neworgAlias = userCommonLoginData.company_identifier;
							const company_name = companyName
							if (neworgAlias === '' || neworgAlias === undefined) {
								// const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
								var company_namewithoutSPace = cleanupString(company_name)
								if (company_namewithoutSPace === '') {
									let cEmails = email.split('@');
									company_namewithoutSPace = cleanupString(cEmails[0]);
								}
								const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
								if (uniqueResponce) {
									let randValu = Math.floor(Math.random() * 10000);
									neworgAlias = company_namewithoutSPace + randValu;
								} else {
									neworgAlias = company_namewithoutSPace
								}
							}

							// initil Data to create User
							const updateData = {
								companyName: companyName ? companyName : '',
								organization_alias: neworgAlias ? neworgAlias : '',
								company_url: neworgAlias ? neworgAlias : '',
								settingrgbColor: settingrgbColor ? settingrgbColor : '',
								settingbgColor: settingbgColor ? settingbgColor : '',
								clientBackgroundColor: clientBackgroundColor ? clientBackgroundColor : '',
								settingChatIcon: settingChatIcon ? settingChatIcon : '',
								email: email ? email : '',
								whiteImage: whiteImage ? whiteImage : '',
								chatBlackImage: chatBlackImage ? chatBlackImage : '',
								fbwtimage: fbwtimage ? fbwtimage : '',
								//	password: password,
								accessToken: accessToken ? accessToken : '',
								sendButtonClr: settingbgColor ? settingbgColor : '',
								mobile_number: mobile ? mobile : '',
								mobile_country_code: country_code ? country_code : '',
								mobile_verified: mobile_verified ? mobile_verified : false,
							}
							// two field or mandatory
							//  Email : accessToken
							// UserDocID get User table Doc ID 
							
							console.log('updateData >>> ', updateData);

							const UserDocID = await createNewUser(updateData, 3)

							if (UserDocID) {
								// const updateProductData = await updateCompanyDetails(UserDocID,email, neworgAlias, companyName);
								// TODO: login User here In system
								// Add User DataENtry Through ThisAPi 
								localStorage.removeItem("password");
								// localStorage.removeItem("OwnerName");
								localStorage.removeItem('settingrgbColor')
								localStorage.removeItem('settingbgColor')
								localStorage.removeItem('clientBackgroundColor')
								localStorage.removeItem('settingChatIcon')
								localStorage.removeItem('chatBlackImage')
								localStorage.removeItem('whiteImage')
								localStorage.removeItem('fbwtimage')
								localStorage.removeItem("_email");
								localStorage.removeItem("companyName");
								updateSessioninCollection(accessToken, UserDocID);
								const getloginData = await getOwnerUserDataForLogin(UserDocID, email);
								delete getloginData.password
								getloginData.ownerEmail = email;
								getloginData.email = email;
								getloginData.agent_name = getloginData.last_name && getloginData.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? getloginData.first_name + ' ' + getloginData.last_name : getloginData.first_name;
								getloginData.name = getloginData.agent_name;
								getloginData.first_name = getloginData.agent_name;
								getloginData.last_name = "";
								getloginData.realmId = userCommonLoginData._id ? userCommonLoginData._id : '';

								localStorage.setItem('agent', JSON.stringify(getloginData));
								await activityLog('Signup');
								// valid user
								let chatURL = DOMAIN_NAME + '/' + neworgAlias;
								var body = [`email=${email.toLowerCase()}&&type=welcome_email&&data=${"data"}&&req_ip=${this.state.IPv4}&&chatURL=${chatURL}`];
								this.sendEmail(body);
								this.props.history.push("/agent-setup");
								this.setState({ loader: false });
							}
						}
						else {
							//
							window.location.href = COMMON_LOGIN_URL + "/register?frompage=" + DOMAIN_NAME + "/common-session&website=" + DOMAIN_NAME;
							this.setState({ loader: false });
						}
					}
				} else {
					if(orgName){
						window.location.href = "/"+orgName+"/login";
					}
					else{
						console.log("----------     COMING In Else   ------------")
						let redirectPageCommon = localStorage.getItem('companyName') ? 'register' : 'login';
						window.location.href = COMMON_LOGIN_URL + "/" + redirectPageCommon + "?frompage=" + DOMAIN_NAME + "/common-session&website=" + DOMAIN_NAME;
						this.setState({ loader: false });
					}
				}
			}, 1000);
		}
		else if (islogin) {
			return
		}
		else {
			const companyName = localStorage.getItem('companyName');
			
			if (!companyName) {
				window.location = '/company-info'
				return
			}
		}
		// }, 500);
		this.setState({ loader: false });
	}

	render() {
		return (
			<div className="login-container loginBG">
				<div className="loading" >Loading&#8230;</div>
			</div>
		);
	}
}

export default CommonSession;
