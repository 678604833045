import React from 'react';
// import db, { storage } from "../../config/firebase";
import { toast } from "react-toastify";
import Resizer from 'react-image-file-resizer';
import md5 from 'md5';
import { UpdatePassword } from '../../config/SignupLogin';
import { editAgentInAws, updateAgentPassword, getAgentFromId, editOwnerInAws, activityLog } from '../../services/service';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig";


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import firebaseServices from '../../firebase';
import { removeHtmlTags } from '../livechat/components/Comman';

var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class Profile extends React.Component {

    constructor() {
        super();
        this.state = {
            ownerId: '',
            agentId: '',
            name: '',
            name_error: '',
            profile_pic: '',
            profile_pic_remove: '',
            loader: true,
            showProfile: '',
            pic_ref: '',
            base64file: '',
            profile_pic_avatar: '',
            alias: '',
            email: '',
            showPassword: false,
            currPassword: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            old_password_error: '',
            new_password_error: '',
            confirm_password_error: '',
            role: '',
            deletePic: false,
            roleType: '',
            oldFileName: '',
            oldThumbnailName: '',
            timezone: '',
            file_error: ''
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId: userDetails.ownerId,
            agentId: userDetails.agentId
        }, () => {
            this.getAgentValue();
        })
    }

    getAgentValue = () => {
        let promise1 = new Promise((res, rej) => {
            getAgentFromId(this.state.agentId).then((results) => {
                res(results)
            })
        })

        let promise2 = new Promise((Resolve, Reject) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").doc(this.state.agentId).get().then((docs) => {
                if (docs.exists) {
                    let data = docs.data();
                    Resolve(data);
                    // let userDetails = localStorage.getItem('agent') ?  JSON.parse(localStorage.getItem('agent')) : false;

                    // if(userDetails) {
                    //     this.setState({
                    //         first_name:userDetails.first_name,
                    //         last_name:userDetails.last_name,
                    //         profile_pic:userDetails.profile_img_orig,
                    //         pic_ref:userDetails.pic_ref,
                    //         profile_pic_avatar:userDetails.profile_img,
                    //         alias:userDetails.alias,
                    //         email:userDetails.email,
                    //         timezone:userDetails.timezone
                    //     })
                    // } else {
                    //     this.setState({
                    //         first_name:data.first_name,
                    //         last_name:data.last_name,
                    //         profile_pic:data.profile_img_orig!==undefined ? data.profile_img_orig : '',
                    //         pic_ref:data.pic_ref,
                    //         profile_pic_avatar:data.profile_img,
                    //         alias:data.alias,
                    //         email:data.email,
                    //         timezone:data.timezone
                    //     })
                    // }
                    // this.setState({
                    //     currPassword:data.password,
                    //     deletePic:false,
                    //     loader:false,
                    //     roleType:data.roleType ? data.roleType : ''
                    // })
                } else {
                    console.log('data does not exist');
                }
            }).catch((err) => {
                console.log('Error at line no 83 on profile - index >>> ', err);
            })
        })

        Promise.all([promise1, promise2]).then((docs) => {
            let agentAws = docs[0];
            console.log(docs[1].roleType);
            if (docs[1].roleType === 'Owner') {
                let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
                console.log('userDetails >> ', userDetails);
                this.setState({
                    first_name: userDetails.first_name,
                    last_name: userDetails.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetails.last_name : '',
                    profile_pic: userDetails.profile_img,
                    pic_ref: userDetails.pic_ref,
                    profile_pic_avatar: userDetails.profile_img,
                    alias: userDetails.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetails.alias : '',
                    email: userDetails.email,
                    timezone: userDetails.timezone,
                    oldFileName: userDetails.oldFileName,
                    oldThumbnailName: userDetails.oldThumbnailName
                })
            } else {
                if (agentAws.status === 200) {
                    let profile_pic = "";
                    if (agentAws.data.thumbnail && agentAws.data.thumbnail !== '' && agentAws.data.thumbnail !== "notexist" && agentAws.data.thumbnail !== 'notexist') {
                        profile_pic = agentAws.data.imageurl + '/' + agentAws.data.thumbnail
                    }
                    this.setState({
                        first_name: agentAws.data.first_name,
                        last_name: agentAws.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentAws.data.last_name : '',
                        profile_pic: profile_pic,
                        pic_ref: '',
                        profile_pic_avatar: profile_pic,
                        alias: agentAws.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentAws.data.alias : '',
                        email: agentAws.data.email,
                        timezone: agentAws.data.timezone,
                        oldFileName: agentAws.data.pic,
                        oldThumbnailName: agentAws.data.thumbnail
                    })
                    let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
                    if (userDetails) {
                        userDetails.first_name = agentAws.data.first_name
                        userDetails.last_name = agentAws.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentAws.data.last_name : ''
                        userDetails.profile_img = profile_pic
                        userDetails.profile_img_orig = profile_pic
                        if (agentAws.data.alias !== '' && agentAws.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD) {
                            userDetails.name = agentAws.data.alias
                        } else {
                            userDetails.name = agentAws.data.last_name && agentAws.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentAws.data.first_name + ' ' + agentAws.data.last_name : agentAws.data.first_name
                        }
                        userDetails.agent_name = agentAws.data.last_name && agentAws.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentAws.data.first_name + ' ' + agentAws.data.last_name : agentAws.data.first_name;
                        userDetails.timezone = agentAws.data.timezone
                        localStorage.setItem('agent', JSON.stringify(userDetails));
                    }
                }
            }
            this.setState({
                deletePic: false,
                loader: false,
                roleType: docs[1].roleType ? docs[1].roleType : ''
            })
        })
    }

    changehandle = (e) => {
        let target = e.target;
        let value = target.value;
        value = removeHtmlTags(value);
        console.log('value >>181 ', value.length,target.value !== '',target.value.length >24);
        this.setState({
            [target.name]: value
        })
        let errorName = target.name + '_error';
        if (target.value === '' && target.name !== 'alias' && target.name !== 'last_name') {
            this.setState({
                [errorName]: 'This field can not be blank!'
            })
        } else if (target.name === 'new_password' && re.test(target.value) === false) {
            this.setState({
                [errorName]: "Password must contain at least one number, one lowercase, one uppercase letter and consist min of 8 characters"
            })
        } else if (target.value !== ''  && target.value.length >= 36) {
            console.log('target.value.length >> ', target.value.length);
            this.setState({
                // return max length error
                [errorName]: 'You have reached your maximum limit of characters allowed'
            })
        }
        else {
            this.setState({
                [errorName]: ''
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let validate = this.validateForm();
        console.log('validate >> ', validate);
        if (validate) {
            this.setState({
                loader: true
            })
            if (this.state.alias !== '') {
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("alias", "==", this.state.alias).where("deletetime", "==", 0).get().then(async (docs) => {
                    let OldData = [];
                    docs.forEach((data) => {
                        OldData.push(data.id);
                    })
                    if (docs.empty === true || OldData.indexOf(this.state.agentId) > -1) {
                        if (this.state.showPassword && this.state.roleType === 'Owner') {
                            let upPwd = await UpdatePassword(this.state.email, this.state.old_password, this.state.new_password);

                            if (upPwd.res.forgotPasswordVerification.status === '401') {
                                this.setState({
                                    old_password_error: 'Incorrect Old Password',
                                    loader: false
                                })
                                return false;
                            }
                        }
                        const filesData1 = document.getElementById("profile_picture");
                        var filesData = filesData1.files;
                        if (this.state.roleType === 'Owner') {
                            let file = '';
                            let fileName = ''
                            if (filesData.length > 0) {
                                file = filesData[0];
                                fileName = file.name;
                            }
                            this.saveOwner(file, fileName);
                        } else {
                            let file = '';
                            let fileName = ''
                            if (filesData.length > 0) {
                                file = filesData[0];
                                fileName = file.name;
                            }
                            this.saveAgent(file, fileName);
                        }
                        
                    } else {
                        this.setState({
                            loader: false,
                            alias_error: 'Alias is not available'
                        })
                    }
                });
            } else {
                if (this.state.showPassword && this.state.role === 'Owner') {
                    let upPwd = await UpdatePassword(this.state.email, this.state.old_password, this.state.new_password);
                    if (upPwd.res.forgotPasswordVerification.status === '401') {
                        this.setState({
                            old_password_error: 'Incorrect Old Password',
                            loader: false
                        })
                        return false;
                    }
                }
                const filesData1 = document.getElementById("profile_picture");
                var filesData = filesData1.files;
                if (this.state.roleType === 'Owner') {
                    let file = '';
                    let fileName = ''
                    if (filesData.length > 0) {
                        file = filesData[0];
                        fileName = file.name;
                    }
                    this.saveOwner(file, fileName);
                } else {
                    let file = '';
                    let fileName = ''
                    if (filesData.length > 0) {
                        file = filesData[0];
                        fileName = file.name;
                    }
                    this.saveAgent(file, fileName);
                }
            }
        }
    }

    deleteFile = () => {
        const ref = firebaseServices.storageConnection;
        ref.child('profiles').child(this.state.pic_ref).delete();
        ref.child('profiles').child('avatar').child(this.state.pic_ref).delete();
        this.saveAgent('', '', '');
    }

    saveOwner(file, fileName) {
        // remove tags
        // let alias = this.state.alias ? this.state.alias : 
        this.state.first_name = this.removeTags(this.state.first_name);
        this.state.last_name = this.removeTags(this.state.last_name);
        this.state.alias = this.removeTags(this.state.alias);
        // this.state.email = this.removeTags(this.state.email);
        let alias = this.state.alias? this.state.alias : DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
        this.validateForm();
        editOwnerInAws(alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName, 0, false, this.state.deletePic).then((docs) => {
            // console.log(docs);
            if (docs.status === 200) {
                let responseData = docs.response;
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                    name: this.state.first_name + ' ' + this.state.last_name,
                    alias: this.state.alias,
                    updatedon: new Date().getTime()
                })
                    .then((ref) => {
                        var localStorageValue = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
                        if (localStorageValue) {
                            localStorageValue.alias = this.state.alias;
                            localStorageValue.first_name = this.state.first_name;
                            localStorageValue.last_name = this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.last_name : '';
                            localStorageValue.profile_img = responseData.data?.pic && responseData.data?.pic !== 'notexist' ? responseData.data.imageurl + '/' + responseData.data?.pic : '';
                            localStorageValue.profile_img_orig = responseData.data?.pic && responseData.data?.pic !== 'notexist' ? responseData.data.imageurl + '/' + responseData.data?.pic : '';
                            localStorageValue.oldThumbnailName = responseData.data.thumbnail;
                            localStorageValue.oldFileName = responseData.data?.pic;
                            localStorageValue.pic_ref = '';
                            localStorageValue.timezone = responseData.time_zone;
                            if (this.state.alias !== '' && this.state.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD) {
                                localStorageValue.name = this.state.alias
                            } else {
                                localStorageValue.name = this.state.last_name && this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.first_name + ' ' + this.state.last_name : this.state.first_name
                            }
                            localStorageValue.agent_name = this.state.last_name && this.state.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.first_name + ' ' + this.state.last_name : this.state.first_name;
                            localStorage.setItem('agent', JSON.stringify(localStorageValue));
                        }
                        this.getAgentValue();
                        document.getElementById("profile_picture").value = null;
                        this.setState({
                            loader: false,
                            name_error: ''
                        })
                        this.props.updateProfile();
                        toast.success("Agent has been updated successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        activityLog('Updated profile');
                    }).catch((error) => {
                        this.setState({
                            loader: false,
                            name_error: ''
                        })
                        console.log("Error adding document:---------- ", error);
                        toast.error("Something went wrong! Please try again after some time");
                    });
            } else {
                this.setState({
                    loader: false
                })
                toast.error("Something went wrong! Please try again after some time");
            }
        })
        return;
    }

    removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    saveAgent = async (file, fileName) => {
        this.state.first_name = this.removeTags(this.state.first_name);
        this.state.last_name = this.removeTags(this.state.last_name);
        this.state.alias = this.removeTags(this.state.alias);
        // this.state.email = this.removeTags(this.state.email);
        let name = this.state.first_name + ' ' + this.state.last_name;
        let alias = this.state.alias ? this.state.alias : DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
        var localStorageValue = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        var pwdResult = { status: 200 }
        if (pwdResult.status === 200) {
            editAgentInAws(alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName, 0, false, this.state.deletePic)
                .then((results) => {
                    if (localStorageValue) {
                        localStorageValue.alias = alias;
                        localStorageValue.first_name = this.state.first_name;
                        localStorageValue.last_name = this.state.last_name;
                        localStorage.setItem('agent', JSON.stringify(localStorageValue));
                    }
                    if (results.status === 200) {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                            name: name,
                            alias: alias,
                            updatedon: new Date().getTime()
                        }).then(async (ref) => {
                            if (this.state.showPassword) {
                                pwdResult = await updateAgentPassword(this.state.old_password, this.state.new_password, localStorageValue.ownerIdentifire, this.state.email);
                                if(pwdResult.status === 200) {
                                    window.location.href = '/'+localStorageValue.ownerIdentifire+'/logout';
                                    toast.success("Agent has been updated successfully", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                } else {
                                    const alertMsg = pwdResult.msg ? pwdResult.msg : 'Something went wrong, Please try again after some time';
                                    toast.error(alertMsg, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            } else {
                                toast.success("Agent has been updated successfully", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                            this.getAgentValue();
                            document.getElementById("profile_picture").value = null;
                            this.setState({
                                loader: false,
                                name_error: ''
                            })
                            this.props.updateProfile();
                            
                            activityLog('Updated profile');
                        }).catch((error) => {
                            this.setState({
                                loader: false,
                                name_error: ''
                            })
                            toast.error('Something went wrong, Please try again after some time', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            console.log("Error adding document: ", error);
                        });
                    } else {
                        toast.log("Something went wrong! Please try again after some time");
                        this.setState({
                            loader: false
                        })
                    }
                });
        } else {
            toast.error(pwdResult.msg);
            this.setState({
                loader: false
            })
        }
        return false;
    }

    handleFile = async (files) => {
        this.setState({ 'uploadFileError': '', deletePic : false })
        const tempFile = files[0];
        if (tempFile) {
            var fileExtension = tempFile.name.substring(
                tempFile.name.lastIndexOf('.') + 1).toLowerCase();

            console.log('fileExtension>>>');
            if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'gif') {
                Resizer.imageFileResizer(
                    tempFile,
                    100,
                    100,
                    'PNG',
                    100,
                    0,
                    uri => {
                        this.setState({
                            base64file: uri,
                            file_error: ''
                        })
                    },
                    'base64'
                );
            } else {
                this.setState({
                    file_error: 'Please upload valid image',
                    base64file: ''
                })
            }
        } else {
            this.setState({
                file_error: '',
                base64file: ''
            })
        }
    }

    sendEmail = (pwd) => {
        var body = [
            `email=${this.state.email.toLowerCase()}&&pass=${pwd}&&link=${"https://chatbot.appypie.com/agentlogin"}`
        ];
        fetch("https://chatbottest.appypie.com/api", {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    deleteProfilePic = () => {
        this.setState({
            profilePic: '',
            deletePic: true,
            base64file: ''
        })
    }

    validateForm = () => {
        let validForm = true;
        if (this.state.first_name === '') {
            validForm = false;
            this.setState({
                first_name_error: 'This field can not be blank!'
            })
        } else {
            this.setState({
                first_name_error: ''
            })
        }
        if (this.state.showPassword && this.state.old_password === '') {
            validForm = false;
            this.setState({
                old_password_error: 'This field can not be blank!'
            })
        } else {
            this.setState({
                old_password_error: ''
            })
        }

        if (this.state.showPassword && this.state.new_password === '') {
            validForm = false;
            this.setState({
                new_password_error: 'This field can not be blank!'
            })
        } else if (this.state.showPassword && re.test(this.state.new_password) === false) {
            validForm = false;
            this.setState({
                new_password_error: "Password must contain at least one number, one lowercase, one uppercase letter and consist min of 8 characters"
            })
        } else {
            this.setState({
                new_password_error: ''
            })
        }

        if (this.state.showPassword && this.state.confirm_password === '') {
            validForm = false;
            this.setState({
                confirm_password_error: 'This field can not be blank!'
            })
        } else if (this.state.showPassword && this.state.confirm_password !== this.state.new_password) {
            validForm = false;
            this.setState({
                confirm_password_error: 'Incorrect confirm password'
            })
        } else {
            this.setState({
                confirm_password_error: ''
            })
        }

        return validForm;
    }

    updateLoader = (value) => {
        this.setState({
            loader: value
        })
    }

    cancelFormSubmit = (e) => {
        e.preventDefault();
        this.getAgentValue();
        this.props.hideProfile(false);
    }

    hideEditProfile = (e) => {
        this.props.hideProfile(false);
    }

    render() {
        let profilePic = ''
        if (this.state.profile_pic_avatar !== '' && this.state.profile_pic_avatar !== undefined) {
            profilePic = <img alt="" src={this.state.profile_pic_avatar} />
        }
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <div className="rightPanelSection add-agent" style={{ display: this.props.showEditProfile ? 'block' : 'none' }}>
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Update Profile</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.hideEditProfile}>
                                            <img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input">
                                                <TextField className="form-control" value={this.state.first_name ? this.state.first_name : ""} label="First Name" variant="outlined" name="first_name" onChange={this.changehandle} inputProps={{maxLength: 36}}/>
                                                {this.state.first_name_error ? (<p className="error">{this.state.first_name_error}</p>) : (null)}
                                            </div>
                                            <div className="input-group material-input mt-7">
                                                <TextField className="form-control" value={this.state.last_name ? this.state.last_name : ""} label="Last Name" variant="outlined" name="last_name" onChange={this.changehandle} inputProps={{maxLength: 36}}/>
                                                {/* <label className="mb-1 small text-screen1">Last Name</label> */}
                                                {/* <input type="text" placeholder="Last name" className="w-100" name="last_name" value={this.state.last_name} onChange={this.changehandle} /> */}
                                                {this.state.last_name_error ? (<p className="error">{this.state.last_name_error}</p>) : (null)}
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input mt-7">
                                                <TextField className="form-control" value={this.state.alias ? this.state.alias : ""} label="Alias" variant="outlined" name="alias" onChange={this.changehandle} inputProps={{maxLength : 36}} />

                                                {/* <label className="mb-1 small text-screen1">Alias</label>
                                        <input type="text" placeholder="Alias" className="w-100" name="alias" value={this.state.alias} onChange={this.changehandle} /> */}
                                                {this.state.alias_error ? (<p className="error">{this.state.alias_error}</p>) : (null)}
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">

                                            <div className="input-group material-input">
                                                <input type="file" accept="image/*" id="profile_picture" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} style={{ display: "none" }} />

                                                {(profilePic === '' && this.state.base64file === '') || this.state.deletePic ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                                    <span><img src={require('../../assets/images/avtare-user.svg')} alt="profile pic" /></span>
                                                </div>) : (null)}

                                                {((this.state.base64file !== '' || profilePic !== '') && !this.state.deletePic) ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                                    {this.state.base64file === '' && !this.state.deletePic ? (<span className='editpictureInner'>{profilePic}<span className="clearIMG" onClick={this.deleteProfilePic}>X</span></span>) : null}
                                                    {this.state.base64file !== '' ? (<span className="editpictureInner"><img alt="" src={this.state.base64file} /><span className="clearIMG" onClick={this.deleteProfilePic}>X</span></span>) : null}
                                                </div>) : (null)}
                                                <Button variant="contained" onClick={() => { document.getElementById("profile_picture").click() }} color="default" startIcon={<CloudUploadIcon />}>Upload Profile Picture</Button>
                                                {this.state.file_error ? (<p className="error">{this.state.file_error}</p>) : (null)}
                                            </div>


                            



                                        </div>

                                        <div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input mt-7">
                                                <TextField className="form-control" value={this.state.email ? this.state.email : ""} label="Email" variant="outlined" name="Email" inputProps={{maxLength: 130}} disabled />
                                            </div>
                                        </div>
                                        {this.state.roleType !== 'Owner' ? (<div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Password &nbsp;&nbsp;&nbsp;{this.state.showPassword ? (<img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ showPassword: false })} />) : (<img alt="" src={require('../../assets/img/icon/agent_profile.svg')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ showPassword: true })} />)}</label>
                                                {!this.state.showPassword ? (<input type="text" placeholder="Password*" className="w-100" value='***********' style={{ marginTop: 20 }} readOnly />) : (null)}
                                            </div>
                                        </div>) : (null)}

                                        {this.state.showPassword ? (<><div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input">
                                                <TextField type="password" className="form-control" value={this.state.old_password} label="Old Password" variant="outlined" name="old_password" onChange={this.changehandle} />
                                                {this.state.old_password_error ? (<p className="error">{this.state.old_password_error}</p>) : (null)}
                                            </div>
                                            {/* <div className="wrapperInfo">
                                        <input type="password" placeholder="Old Password" className="w-100" name="old_password" value={this.state.old_password} onChange={this.changehandle} />
                                        {this.state.old_password_error ? (<p className="error">{this.state.old_password_error}</p>) : (null)}
                                    </div> */}
                                        </div>
                                            <div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input">
                                                <TextField type="password" className="form-control" value={this.state.new_password} label="New Password" variant="outlined" name="new_password" onChange={this.changehandle} />
                                                {this.state.new_password_error ? (<p className="error">{this.state.new_password_error}</p>) : (null)}
                                            </div>
                                                {/* <div className="wrapperInfo">
                                                    <input type="password" placeholder="New Password" className="w-100" name="new_password" value={this.state.new_password} onChange={this.changehandle} />
                                                    {this.state.new_password_error ? (<p className="error">{this.state.new_password_error}</p>) : (null)}
                                                </div> */}
                                            </div>
                                            <div className="form-feilds px-5 py-4">
                                            <div className="input-group material-input">
                                                <TextField type="password" className="form-control" value={this.state.confirm_password} label="Confirm Password" variant="outlined" name="confirm_password" onChange={this.changehandle} />
                                                {this.state.confirm_password_error ? (<p className="error">{this.state.confirm_password_error}</p>) : (null)}
                                            </div>
                                                {/* <div className="wrapperInfo">
                                                    <input type="password" placeholder="Confirm Password" className="w-100" name="confirm_password" value={this.state.confirm_password} onChange={this.changehandle} />
                                                    {this.state.confirm_password_error ? (<p className="error">{this.state.confirm_password_error}</p>) : (null)}
                                                </div> */}
                                            </div></>) : (null)}
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <button className="mr-2 btnBlue">Update</button>
                                                <button className="btnWhite" onClick={this.cancelFormSubmit}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Profile;