import { commonLoginApi, MAIL_FUNCTION_URL, CLOSE_INACTIVE_CHATS_FROM, COMMON_LOGIN_URL, DOMAIN_NAME, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, TEST_LOGIN_TOKEN, OPEN_AI_KEY, CHATBOT_API_KEY_URL, CHATBOT_API_LIST_URL, REACT_APP_REALM_API_ID, REACT_APP_REALM_API_KEY, GATEWAY_URL, FUNCTION_API_KEY } from './../config/siteConfig';
import { getApiAccessToken } from "../layout/utils";
import moment from "moment-timezone";
// import db, { firebaseAuth } from '../config/firebase';
import { saveAgentSession, getDateInUTC } from "../modules/livechat/components/Comman";
import { ObjectId } from 'bson';
import * as Realm from "realm-web";
import { v4 as uuidv4 } from 'uuid';
import firebaseServices from '../firebase';
const JSONWEBTOKEN = require('jsonwebtoken');
const credentials = Realm.Credentials.serverApiKey(`${REACT_APP_REALM_API_KEY}`);
const md5 = require("md5");
const dateFormat = 'X';
const JWTKey = "live!@#$commonJ)(&^$#*tok43$^&@#en";
const appyPieCbId = "https://intigrationchat.web.app/";

/* Update Password for agent after first login from Aws */
export const setNewPassword = (oldPassword, NewPassword, ownerIdentifire, email, userType = 'agent', i = 0) => {
    console.log(i);
    return new Promise((resolve, reject) => {
        if (oldPassword && NewPassword && ownerIdentifire && email) {
            var fileName = getCookie('APPYID');
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `email=${email.toLowerCase()}&company_identifier=${ownerIdentifire}&login_type=${userType}&existing_password=${oldPassword}&file_name=${encodeURIComponent(fileName)}&password=${md5(NewPassword)}`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/newpassword', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    console.log(response);
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            console.log('returnValue >> 29');
                            await getApiAccessToken();
                            const newResponse = await setNewPassword(oldPassword, NewPassword, ownerIdentifire, email, userType, i);
                            resolve(newResponse);
                        } else {
                            console.log('returnValue >> 32');
                            let returnValue = { status: 401, msg: "Something Went Wrong, Please try again after some time" };
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 400) {
                        let returnValue = { status: 400, msg: response.message };
                        resolve(returnValue);
                    } else if (parseInt(response.status) === 200) {
                        let returnValue = { status: 200, msg: "PassWord Updated Successfully!" };
                        console.log('returnValue >> 40', returnValue);
                        resolve(returnValue);
                    } else {
                        let returnValue = { status: 401, msg: "Something Went Wrong, Please try again after some time" };
                        resolve(returnValue);
                    }
                }).catch((err) => {
                    console.log('err >>>> ', err);
                    let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                    resolve(returnValue);
                })
        } else {
            let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
            resolve(returnValue);
        }
    })
}

/* Update Password for agent from Aws */
export const updateAgentPassword = (oldPassword, NewPassword, ownerIdentifire, email, userType = 'agent', i = 0) => {
    return new Promise(async (resolve, reject) => {
        if (oldPassword && NewPassword && ownerIdentifire && email) {
            const fileName = getCookie('APPYID');
            const userToken = await getSecurityToken();
            if (userToken) {
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                var body = [
                    `email=${email.toLowerCase()}&company_identifier=${ownerIdentifire}&login_type=${userType}&old_password=${md5(oldPassword)}&new_password=${md5(NewPassword)}&user_token=${userToken}&file_name=${encodeURIComponent(fileName)}`
                ];
                fetch(commonLoginApi + '/api/agentcustomer/changepassword', {
                    method: "post",
                    body: body,
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                    // headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = updateAgentPassword(oldPassword, NewPassword, ownerIdentifire, email, userType, i);
                                resolve(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something Went Wrong, Please try again after some time" };
                                resolve(returnValue);
                            }
                        } else if (parseInt(response.status) === 400) {
                            let returnValue = { status: 400, msg: response.message };
                            resolve(returnValue);
                        } else if (parseInt(response.status) === 200) {
                            let returnValue = { status: 200, msg: "PassWord Updated Successfully!" };
                            resolve(returnValue);
                        } else {
                            let returnValue = { status: 401, msg: "Something Went Wrong, Please try again after some time" };
                            resolve(returnValue);
                        }
                    }).catch((err) => {
                        let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                        resolve(returnValue);
                    })
            } else {
                let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
                resolve(returnValue);
            }
        } else {
            let returnValue = { status: 500, msg: "Something Went Wrong, Please try again after some time" }
            resolve(returnValue);
        }
    })
}

/* Agent logout from AWS */
export const userLogout = (userType = 'agent', i = 0) => {
    return new Promise((resolve, reject) => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        var fileName = getCookie('APPYID');
        // fileName = 'Admin';
        var body = [
            `login_type=${userType}&file_name=${encodeURIComponent(fileName)}`
        ];
        fetch(commonLoginApi + 'api/agentcustomer/useroutses', {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
        })
            .then(response => response.json())
            .then(async (response) => {
                if (parseInt(response.status) === 401) {
                    if (i < 4) {
                        i++;
                        await getApiAccessToken();
                        const newResponse = userLogout(userType, i);
                        resolve(newResponse);
                    } else {
                        resolve("Something Went Wrong, Please try again after some time");
                    }
                } else if (parseInt(response.status) === 200) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err) => {
                resolve(false);
            })
    })
}

/* agent Login from AWS */
export const agentLogin = (email, companyIdentifier, password, userType = 'agent', i = 0) => {
    return new Promise((resolve, reject) => {
        if (email === '' || email === undefined || companyIdentifier === '' || companyIdentifier === undefined || password === '' || password === undefined) {
            resolve(false);
        } else {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var fileName = getCookie('APPYID');
            var body = [
                `login_type=${userType}&file_name=${encodeURIComponent(fileName)}&email=${email}&company_identifier=${companyIdentifier}&password=${password}`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/login', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = agentLogin(email, companyIdentifier, password, userType, i);
                            resolve(newResponse);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(response);
                    }
                }).catch((err) => {
                    resolve(false);
                })
        }
    })
}

/* get usersession for Owner, Agent */
export const getUserSession = (userType = 'agent') => {
    return new Promise((resolve, reject) => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        var fileName = getCookie('APPYID');
        // fileName = 'Admin'
        fetch(commonLoginApi + 'api/agentcustomer/owneragent', {
            method: "post",
            body: JSON.stringify({ file_name: fileName, login_type: userType }),
            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then(async (response) => {
                if (response) {
                    if (parseInt(response.status) === 401) {
                        await getApiAccessToken();
                        const newResponse = getUserSession(userType);
                        console.log('newResponse >> 218', newResponse);

                        resolve(newResponse);
                    } else if (parseInt(response.status) === 200) {

                        console.log('newResponse >> 2223', JSON.parse(response.userdata));
                        resolve(response);
                    } else {

                        resolve(response);
                    }
                } else {
                    let returnValue = { "status_msg": 500, "userdata": "" }
                    resolve(returnValue);
                }
            }).catch((err) => {
                resolve(false);
            })
    })
}

/* ForgetPassword for agent from AWS */
export const sendAgentResetCode = (email, ownerIdentifire, userType = 'agent', i = 0) => {
    return new Promise((resolve, reject) => {
        if (email && ownerIdentifire) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `login_type=${userType}&company_identifier=${ownerIdentifire}&email=${email.toLowerCase()}`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/forgotpassword', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = sendAgentResetCode(email, userType, i);
                            resolve(newResponse);
                        } else {
                            let returnValue = { status: 401, response: "Something Went Wrong, Please try again after some time" }
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        let returnValue = { status: 200, response: response }
                        resolve(returnValue);
                    } else {
                        let returnValue = { status: 400, response: response }
                        resolve(returnValue);
                    }
                }).catch((err) => {
                    let returnValue = { status: 402, "msg": "something went wrong! Please try again after some time", "err": err }
                    resolve(returnValue);
                })
        } else {
            let returnValue = { status: 403, "msg": "email or company identifier is missing" }
            resolve(returnValue);
        }
    })
}

/* Reset Password after forget Password with verification code */
export const resetPasswordAws = (org_alias, email, NewPassword, verificationCode, userType = 'agent', i = 0) => {
    return new Promise((resolve, reject) => {
        if (email) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `login_type=${userType}&company_identifier=${org_alias}&email=${email.toLowerCase()}&verfication_code=${verificationCode}&newpasword=${md5(NewPassword)}`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/confirmresetpassword', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = resetPasswordAws(org_alias, email, verificationCode, NewPassword, userType, i);
                            resolve(newResponse);
                        } else {
                            let returnValue = { status: 401, message: "Something Went Wrong, Please try again after some time" }
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        resolve(response);
                    } else {
                        resolve(response);
                    }
                }).catch((err) => {
                    let returnValue = { status: 403, message: "Something Went Wrong, Please try again after some time", error: err }
                    resolve(returnValue);
                })
        } else {
            let returnValue = { status: 405, message: "Invalid paramters" }
            resolve(returnValue);
        }
    })
}

/* ForgetPassword for owner from AWS */
export const sendOwnerResetCode = (email, i = 0) => {
    return new Promise((resolve, reject) => {
        if (email) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `email=${email.toLowerCase()}`
            ];
            fetch(commonLoginApi + 'api/common/forgotpassword', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = sendOwnerResetCode(email, i);
                            resolve(newResponse);
                        } else {
                            let returnValue = { status: 401, response: "Something Went Wrong, Please try again after some time" }
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        let returnValue = { status: 200, response: response }
                        resolve(returnValue);
                    } else {
                        let returnValue = { status: 400, response: response }
                        resolve(returnValue);
                    }
                }).catch((err) => {
                    let returnValue = { status: 402, "msg": "something went wrong! Please try again after some time", "err": err }
                    resolve(returnValue);
                })
        } else {
            let returnValue = { status: 403, "msg": "email or company identifier is missing" }
            resolve(returnValue);
        }
    })
}

/* Reset Password of Owner after forget Password with verification code */
export const resetPasswordOwner = (email, NewPassword, verificationCode, userType = 'agent', i = 0) => {
    return new Promise((resolve, reject) => {
        if (email) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `email=${email.toLowerCase()}&verfication_code=${verificationCode}&newpasword=${NewPassword}`
            ];
            fetch(commonLoginApi + 'api/common/confirmresetpassword', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = resetPasswordOwner(email, NewPassword, verificationCode, i);
                            resolve(newResponse);
                        } else {
                            let returnValue = { status: 401, message: "Something Went Wrong, Please try again after some time" }
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        resolve(response);
                    } else {
                        resolve(response);
                    }
                }).catch((err) => {
                    let returnValue = { status: 403, message: "Something Went Wrong, Please try again after some time", error: err }
                    resolve(returnValue);
                })
        } else {
            let returnValue = { status: 405, message: "Invalid paramters" }
            resolve(returnValue);
        }
    })
}

/* get List of all Agents based on company identifier */
export const getAwsAgents = async (agentLimit = 60) => {
    var allAgentss = [];
    return new Promise((res, rej) => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails) {
            var body = [
                `company_identifier=${userDetails.ownerIdentifire}&paginationtoken=&limit=${agentLimit}&login_type=agent`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/listuser', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(response => {
                    var agentList = JSON.parse(response.data).Users;
                    agentList.forEach((agentDetails) => {
                        var agentAttributes = agentDetails.Attributes;
                        var newattr = {};
                        agentAttributes.forEach((attr) => {
                            var attrName = attr.Name.replace('custom:', '');
                            newattr = { ...newattr, [attrName]: attr.Value }
                        })
                        allAgentss.push(newattr);
                    })
                    res(allAgentss);
                }).catch((err) => {
                    res(allAgentss);
                })
        } else {
            res(allAgentss);
        }
    })
}


/* get List of all Agents based on company identifier */
export const getAllAwsAgents_OLD = async (allAgentss = [], PaginationToken = '') => {
    return new Promise((res, rej) => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        var agentLimit = 60;
        if (userDetails) {
            var body = [
                `company_identifier=${userDetails.ownerIdentifire}&paginationtoken=${PaginationToken}&limit=${agentLimit}&login_type=agent`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/listuser', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(response => {
                    if (response.status === 200) {
                        if (response.data) {
                            var responseData = JSON.parse(response.data);
                            if (responseData.Users) {
                                var agentList = JSON.parse(response.data).Users;
                                agentList.forEach((agentDetails) => {
                                    var agentAttributes = agentDetails.Attributes;
                                    var newattr = {};
                                    agentAttributes.forEach((attr) => {
                                        var attrName = attr.Name.replace('custom:', '');
                                        newattr = { ...newattr, [attrName]: attr.Value }
                                    })
                                    allAgentss.push(newattr);
                                })
                                if (responseData.PaginationToken) {
                                    let newResp = getAllAwsAgents_OLD(allAgentss, encodeURIComponent(responseData.PaginationToken));
                                    res(newResp);
                                } else {
                                    res(allAgentss);
                                }
                            } else {
                                res(allAgentss);
                            }
                        } else {
                            res(allAgentss);
                        }
                    } else {
                        res(allAgentss);
                    }
                }).catch((err) => {
                    res(allAgentss);
                })
        } else {
            res(allAgentss);
        }
    })
}

/* Check owner details from product db */
export const checkOwner = (companyId, email) => {
    return new Promise((res, rej) => {
        if (companyId && email) {
            var body = [
                `companyId=${companyId}&email=${email}`
            ]
            //fetch(MAIL_FUNCTION_URL+'/loginApi/checkOwner', {
            fetch(GATEWAY_URL + '/checkOwner', {
                method: "post",
                body: body,
                headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
            })
                .then(response => response.json())
                .then((response) => {
                    res(response);
                }).catch((err) => {
                    console.log(err);
                    res(err);
                })
        } else {
            res(false);
        }
    })
}

/* add agent in AWS */
export const addAgentInAws = (alias, firstName, lastName, email, timeZone, file, cfileName, verificationLink, code, i = 0, agentDetails = false) => {
    if (agentDetails === false) {
        agentDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    }
    return new Promise(async (resolve, reject) => {
        if (agentDetails && email && timeZone && firstName) {
            const getJwtToken = await getSecurityToken();
            // console.log('getJwtToken >>>> ', getJwtToken, userDetails);
            if (getJwtToken) {
                const fileName = await getCookie('APPYID');
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                var formdata = new FormData();
                const loginType = agentDetails.roleType === 'Owner' ? 'owner' : 'agent';
                formdata.append("email", email.toLowerCase());
                formdata.append("company_identifier", agentDetails.ownerIdentifire);
                formdata.append("login_type", loginType);
                formdata.append("company_name", agentDetails.company_name);
                formdata.append("alias", alias);
                formdata.append("timezone", timeZone);
                formdata.append("first_name", firstName);
                formdata.append("last_name", lastName ? lastName : DEFAULT_COMMONLOGIN_BLANK_KEYWORD);
                formdata.append("verify_link", verificationLink);
                formdata.append("code", md5(code));
                formdata.append("user_token", getJwtToken);
                formdata.append("file_name", encodeURIComponent(fileName));
                formdata.append("login_email", agentDetails.email);
                if (file === '') {
                    formdata.append("profile_pic", file);
                } else {
                    formdata.append("profile_pic", file, cfileName);
                }
                fetch(commonLoginApi + 'api/agentcustomer/signup', {
                    method: "post",
                    body: formdata
                    // headers: { "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {
                        console.log(response);
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = addAgentInAws(alias, firstName, lastName, email, timeZone, file, cfileName, i);
                                resolve(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                resolve(returnValue);
                            }
                        } else if (response.status == 200) {
                            let returnValue = { status: 200, response: response }
                            resolve(returnValue);
                        } else {
                            let returnValue = { status: response.status, response: response }
                            resolve(returnValue);
                        }
                    }).catch((err) => {
                        let returnValue = { status: 402, error: err }
                        resolve(returnValue);
                    })
            }
        } else {
            console.log('----345----');
            let returnValue = { status: 403 }
            resolve(returnValue);
        }
    });
}

/* edit agent in AWS */
export const editAgentInAws = (alias, firstName, lastName, email, timeZone, file, cfileName, oldFileName = '', oldThumbnailName = '', i = 0, agentDetails = false, deletePic = false) => {
    if (agentDetails === false) {
        agentDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    }
    return new Promise(async (resolve, reject) => {
        if (agentDetails && email && timeZone && firstName) {
            const getJwtToken = await getSecurityToken();
            const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
            console.log('getJwtToken >>>> ', getJwtToken, userDetails);
            if (getJwtToken && userDetails) {
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                const fileName = await getCookie('APPYID');
                const loginType = userDetails.roleType === 'Owner' ? 'owner' : 'agent';
                var formdata = new FormData();
                formdata.append("email", email.toLowerCase());
                formdata.append("company_identifier", agentDetails.ownerIdentifire);
                formdata.append("login_type", loginType);
                formdata.append("company_name", agentDetails.company_name);
                formdata.append("alias", alias);
                formdata.append("timezone", timeZone);
                formdata.append("first_name", firstName);
                formdata.append("last_name", lastName ? lastName : DEFAULT_COMMONLOGIN_BLANK_KEYWORD);
                formdata.append("user_token", getJwtToken);
                formdata.append("file_name", encodeURIComponent(fileName));
                formdata.append("login_email", userDetails.email);
                console.log(file, cfileName);
                if (file === '' || file === 'notexist') {
                    formdata.append("profile_pic", '');
                } else {
                    formdata.append("profile_pic", file, cfileName);
                }
                if (deletePic === true) {
                    formdata.append("deletepic", "yes");
                }
                formdata.append("pic", oldFileName ? oldFileName : 'notexist');
                formdata.append("thumbnail", oldThumbnailName ? oldThumbnailName : 'notexist');
                fetch(commonLoginApi + 'api/agentcustomer/updateuser', {
                    method: "post",
                    body: formdata
                    // headers: { "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = addAgentInAws(alias, firstName, lastName, email, timeZone, file, cfileName, i);
                                resolve(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                resolve(returnValue);
                            }
                        } else if (response.status == 200) {
                            let returnValue = { status: 200, response: response }
                            resolve(returnValue);
                        } else {
                            let returnValue = { status: response.status, response: response }
                            resolve(returnValue);
                        }
                    }).catch((err) => {
                        let returnValue = { status: 402, msg: "Something went wrong, Please try again after some time", response: err }
                        resolve(returnValue);
                    })
            } else {
                let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
                resolve(returnValue);
            }
        } else {
            let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
            resolve(returnValue);
        }
    });

}

/* edit owner in AWS */
export const editOwnerInAws = (alias, firstName, lastName, email, timeZone, file, fileName, oldFileName = '', oldThumbnailName = '', i = 0, agentDetails = false, deletePic = false) => {
    if (agentDetails === false) {
        agentDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    }
    return new Promise(async (resolve, reject) => {
        if (email && firstName) {
            var cookieId = await getCookie('APPYID');
            const getUserToken = await getSecurityToken(email);
            if (getUserToken) {
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                var formdata = new FormData();
                formdata.append("email", email.toLowerCase());
                formdata.append("alias", alias);
                formdata.append("timezone", timeZone ? timeZone : (moment.tz.guess() === 'Asia/Calcutta' ? 'Asia/Kolkata' : moment.tz.guess()));
                formdata.append("first_name", firstName);
                formdata.append("last_name", lastName ? lastName : DEFAULT_COMMONLOGIN_BLANK_KEYWORD);
                formdata.append("user_token", getUserToken);
                formdata.append("type","companyUpdate");
                if (file === '' || file === 'notexist') {
                    formdata.append("profile_pic", '');
                } else {
                    formdata.append("profile_pic", file, fileName);
                }
                if (deletePic === true) {
                    formdata.append("deletepic", "yes");
                }
                formdata.append("pic", oldFileName ? oldFileName : 'notexist');
                formdata.append("thumbnail", oldThumbnailName ? oldThumbnailName : 'notexist');
                formdata.append("file_name", encodeURIComponent(cookieId));
                formdata.append("user",localStorage.alias)
                formdata.append("productname", "livechat");

                fetch(commonLoginApi + 'api/common/company/ownerCompanyUpdate', { //kdapi
                    method: "post",
                    body: formdata
                    // headers: { "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {  
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = addAgentInAws(alias, firstName, lastName, email, timeZone, file, fileName, i);
                                resolve(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                resolve(returnValue);
                            }
                        } else if (response.status == 200) {
                            let returnValue = { status: 200, response: response }
                            resolve(returnValue);
                        } else {
                            let returnValue = { status: response.status, response: response }
                            resolve(returnValue);
                        }
                    }).catch((err) => {
                        let returnValue = { status: 402, msg: "Something went wrong, Please try again after some time", response: err }
                        resolve(returnValue);
                    })
            } else {
                let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
                resolve(returnValue);
            }
        } else {
            let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
            resolve(returnValue);
        }
    });

}

/* Added by AG Start */
export const checkAgentSession = (ownerIdentifire, email, password) => {
    return new Promise(res => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        let fileName = getCookie('APPYID');
        var body = [
            `company_identifier=${ownerIdentifire}&login_type=agent&password=${password}&file_name=${encodeURIComponent(fileName)}&email=${email.toLowerCase()}`
        ];
        // fetch(commonLoginApi + 'api/agentcustomer/logincheck', {
        fetch(commonLoginApi + 'api/agentcustomer/company/login', {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
        })
            .then(response => response.json())
            .then((response) => {
                const helpdeskUser = response.data?.apps_access.find(item => item.product === 'helpdesk');
                if (helpdeskUser) {
                console.log(helpdeskUser.user_id); 
                localStorage.setItem("deskId",helpdeskUser.user_id)
                }
                if(response && response.data){
                    localStorage.setItem("alias",response.data)
                }
                res(response);
            }).catch((err) => {
                res(err);
            });
    });
}
/* Added by AG End */

/* agent login from AWS */
export const agentLoginFromAWS = (ownerIdentifire, email, password, loginType = "agent") => {
    return new Promise((res, rej) => {
        var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        let fileName = getCookie('APPYID');
        var body = [
            `company_identifier=${ownerIdentifire}&login_type=${loginType}&password=${password}&file_name=${encodeURIComponent(fileName)}&email=${email.toLowerCase()}`
        ];
        fetch(commonLoginApi + 'api/agentcustomer/login', {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
        })
            .then(response => response.json())
            .then((response) => {
                res(response);
            }).catch((err) => {
                res(err);
            })
    })
}

/* re-send agent invitation link */
export const resendAgentInvitationLink = (ownerIdentifire, companyName, firstName, lastName, email, verificationLink) => {
    return new Promise(async (res, rej) => {
        const userToken = await getSecurityToken(email);
        if (userToken) {
            // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            const fileName = await getCookie('APPYID');
            var body = [
                `company_identifier=${ownerIdentifire}&company_name=${companyName}&first_name=${firstName}&last_name=${lastName}&verify_link=${verificationLink}&product_name=livechat&email=${email.toLowerCase()}&user_token=${userToken}&file_name=${encodeURIComponent(fileName)}`
            ];
            fetch(commonLoginApi + 'api/agentcustomer/resendverifyurl', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
                // headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then((response) => {
                    res(response);
                }).catch((err) => {
                    res(err);
                })
        } else {
            res({ "status": 400, "status_msg": 400, "msg": "Invalid Data" })
        }
    })
}

/* get Agent Data from product db */
export const getAgentData = (userData) => {
    return new Promise((res, rej) => {
        const usernotexist = localStorage.getItem('usernotexist');
        try {
            if (usernotexist === 'true') {

            } else {
                var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                if (laodingEl[0]) {
                    laodingEl[0].style.display = "block";
                }
            }
        } catch (err) { }

        let identifire = userData['custom:company_identifier'];
        let sub = userData.preferred_username ? userData.preferred_username : userData.sub;
        if (identifire && sub) {
            var body = [
                `identifire=${identifire}&sub=${sub}`
            ]
            //fetch(MAIL_FUNCTION_URL+'/loginApi/getAgentFromCompany', {
            fetch(GATEWAY_URL + '/getAgentFromCompany', {
                method: 'post',
                body: body,
                headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
            }).then(response => response.json())
                .then((response) => {
                    if (response.status === 200) {
                        let _data = response.data;
                        console.log('_data >>> ', _data);
                        _data.agent_name = _data.name;
                        if (_data.alias !== "") {
                            _data.agent_name = _data.name;
                            _data.name = _data.alias;
                        }
                        _data.email = userData.email;
                        localStorage.setItem('accessToken', _data.jwToken);
                        localStorage.setItem('redirectAgent', 'false');
                        delete _data.jwToken;
                        _data.ownerIdentifire = identifire;
                        if (userData.pic && userData.pic !== '' && userData.pic !== 'notexist') {
                            _data.profile_img = userData.imageurl + '/' + userData.pic;
                            _data.profile_img_orig = userData.imageurl + '/' + userData.pic;
                        } else {
                            _data.profile_img = '';
                            _data.profile_img_orig = ''
                        }
                        _data.first_name = userData['custom:first_name'];
                        _data.last_name = userData['custom:last_name'];
                        _data.agent_name = _data.last_name && _data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? _data.first_name + ' ' + _data.last_name : _data.first_name;
                        _data.alias = userData['custom:alias'] && userData['custom:alias'] !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userData['custom:alias'] : '';
                        if (_data.alias !== "") {
                            _data.name = _data.alias;
                        }
                        getOwnerDetails(identifire).then(async (results) => {
                            if (results.status === 200) {
                                let data = results.data;
                                _data.ownerDetails = data;
                                _data.ownerEmail = data.email;
                                localStorage.removeItem('usernotexist');

                                /* For security rules req.auth != null */
                                await logInWithCustomToken2(_data.ownerId, _data.agentId);
                                /* For security rules req.auth != null */

                                localStorage.setItem('agent', JSON.stringify(_data));
                                window.location.href = "/" + identifire;
                            } else {
                                try {
                                    localStorage.setItem('usernotexist', 'true');
                                    var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                                    if (laodingEl[0]) {
                                        laodingEl[0].style.display = "none";
                                    }
                                } catch (err) { }
                                res(false);
                            }
                        }).catch((err) => {
                            console.log(err);
                            localStorage.setItem('usernotexist', 'true');
                            try {
                                var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                                if (laodingEl[0]) {
                                    laodingEl[0].style.display = "none";
                                }
                            } catch (err) { }
                            res(false);
                        })
                    } else if (response.status === 400) {
                        if (usernotexist !== 'true') {
                            localStorage.setItem("showUnAuthorizedToast", "true");
                            localStorage.setItem('usernotexist', 'true');
                            window.location.href = "/" + identifire + "/login";
                        }
                    } else {
                        localStorage.setItem('usernotexist', 'true');
                        try {
                            var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                            if (laodingEl[0]) {
                                laodingEl[0].style.display = "none";
                            }
                        } catch (err) { }
                        res(false);
                    }
                }).catch((err) => {
                    console.log(err)
                    localStorage.setItem('usernotexist', 'true');
                    try {
                        var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                        if (laodingEl[0]) {
                            laodingEl[0].style.display = "none";
                        }
                    } catch (err) { }
                    res(false);
                });
        } else {
            try {
                localStorage.setItem('usernotexist', 'true');
                var laodingEl = document.getElementsByClassName('loadingMain') ? document.getElementsByClassName('loadingMain') : [];
                if (laodingEl[0]) {
                    laodingEl[0].style.display = "none";
                }
            } catch (err) { }
            res(false);
        }
    })
}

export const getUserDetailsFromEmail = async (email, userDetails = false, i = 0) => {
    return new Promise(async (res, rej) => {
        if (!userDetails) {
            userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        }
        if (userDetails) {
            const userEmail = userDetails.email ? userDetails.email : email;
            const userToken = await getSecurityToken(userEmail);
            if (userToken) {
                const fileName = await getCookie('APPYID');
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                const loginType = userDetails.roleType === 'Owner' ? 'owner' : 'agent';
                var body = [
                    `email=${email}&company_identifier=${userDetails.ownerIdentifire}&login_type=${loginType}&user_token=${userToken}&file_name=${encodeURIComponent(fileName)}&login_email=${userEmail}`
                ]
                fetch(commonLoginApi + 'api/agentcustomer/checkagentexist', {
                    method: "post",
                    body: body,
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                    // headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = getUserDetailsFromEmail(email, userDetails, i);
                                res(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                res(returnValue);
                            }
                        } else if (parseInt(response.status) === 200) {
                            if (parseInt(response.email_exist) === 200) {
                                let userDetails = response.user ? response.user : {};
                                let returnValue = { status: 200, data: userDetails }
                                res(returnValue);
                            } else {
                                let returnValue = { status: 400, msg: 'User does not exist' }
                                res(returnValue);
                            }
                        } else if (parseInt(response.status === 400)) {
                            let returnValue = { status: 400, msg: 'User does not exist' }
                            res(returnValue);
                        } else {
                            let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                            res(returnValue);
                        }
                    }).catch((err) => {
                        console.log(err);
                        let returnValue = { status: 402, msg: 'Something went wrong! Please try again after some time' }
                        res(returnValue);
                    })
            } else {
                let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
                res(returnValue);
            }
        } else {
            let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time" }
            res(returnValue);
        }
    });
}

/* get Agent data based on sub */
export const getAgentFromId_OLD = (agentId, i = 0) => {
    return new Promise((res, rej) => {
        if (agentId) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `sub=${agentId}`
            ]
            fetch(commonLoginApi + 'api/agentcustomer/checkuserid', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = getAgentFromId_OLD(agentId, i);
                            res(newResponse);
                        } else {
                            let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                            res(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        if (parseInt(response.email_exist) === 200) {
                            let userAttributes = response.user.Attributes;
                            let userDetails = {};
                            userAttributes.forEach((attr) => {
                                var attrName = attr.Name.replace('custom:', '');
                                userDetails = { ...userDetails, [attrName]: attr.Value }
                            })
                            let returnValue = { status: 200, data: userDetails }
                            res(returnValue);
                        } else {
                            let returnValue = { status: 400, msg: 'User does not exist' }
                            res(returnValue);
                        }
                    } else if (parseInt(response.status === 400)) {
                        let returnValue = { status: 400, msg: 'User does not exist' }
                        res(returnValue);
                    } else {
                        let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                        res(returnValue);
                    }
                });
        } else {
            let returnValue = { status: 401, msg: "Please provide agent Id" }
            res(returnValue);
        }
    })
}

/* get Agent data based on preferred_id */
export const getAgentFromId_PREFERRED = (agentId, i = 0) => {
    return new Promise((res, rej) => {
        if (agentId) {
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = [
                `preferred_id=${agentId}`
            ]
            fetch(commonLoginApi + 'api/agentcustomer/checkpreferred', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = getAgentFromId(agentId, i);
                            res(newResponse);
                        } else {
                            let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                            res(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        if (parseInt(response.email_exist) === 200) {
                            let userAttributes = response.user.Attributes;
                            let userDetails = {};
                            userAttributes.forEach((attr) => {
                                var attrName = attr.Name.replace('custom:', '');
                                userDetails = { ...userDetails, [attrName]: attr.Value }
                            })
                            let returnValue = { status: 200, data: userDetails }
                            res(returnValue);
                        } else {
                            let returnValue = { status: 400, msg: 'User does not exist' }
                            res(returnValue);
                        }
                    } else if (parseInt(response.status === 400)) {
                        let returnValue = { status: 400, msg: 'User does not exist' }
                        res(returnValue);
                    } else {
                        let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                        res(returnValue);
                    }
                });
        } else {
            let returnValue = { status: 401, msg: "Please provide agent Id" }
            res(returnValue);
        }
    })
}

export const getAgentFromId = (agentId, i = 0) => {
    return new Promise(async (res, rej) => {
        if (agentId) {
            const userToken = await getSecurityToken();
            const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
            const email = userDetails.email ? userDetails.email : '';
            if (userToken) {
                const fileName = await getCookie('APPYID');
                // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
                var body = [
                    `preferred_id=${agentId}&user_token=${userToken}&file_name=${encodeURIComponent(fileName)}&email=${email}`
                ]
                fetch(commonLoginApi + 'api/agentcustomer/checkagentdata', {
                    method: "post",
                    body: body,
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                    // headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
                })
                    .then(response => response.json())
                    .then(async (response) => {
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = getAgentFromId(agentId, i);
                                res(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                res(returnValue);
                            }
                        } else if (parseInt(response.status) === 200) {
                            if (parseInt(response.email_exist) === 200) {
                                let userAttributes = response.user;
                                // let userDetails = {} ;
                                // userAttributes.forEach((attr) => {
                                //     var attrName = attr.Name.replace('custom:','');
                                //     userDetails = {...userDetails, [attrName]:attr.Value}
                                // })
                                userAttributes.sub = userAttributes.subid;
                                let returnValue = { status: 200, data: userAttributes }
                                res(returnValue);
                            } else {
                                let returnValue = { status: 400, msg: 'User does not exist' }
                                res(returnValue);
                            }
                        } else if (parseInt(response.status === 400)) {
                            let returnValue = { status: 400, msg: 'User does not exist' }
                            res(returnValue);
                        } else {
                            let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                            res(returnValue);
                        }
                    });
            } else {
                let returnValue = { status: 401, msg: "Please provide agent Id" }
                res(returnValue);
            }
        } else {
            let returnValue = { status: 401, msg: "Please provide agent Id" }
            res(returnValue);
        }
    })
}

export const getOwnerAfterLogin = () => {
    return new Promise(async (res, rej) => {
        // if (agentId) {
        const userToken = await getSecurityToken();
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        const email = userDetails.email ? userDetails.email : '';
        if (userToken) {
            const fileName = await getCookie('APPYID');
            // var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            const userType = userDetails.roleType && userDetails.roleType === 'Owner' ? 'owner' : 'agent';
            var body = [
                `user_token=${userToken}&file_name=${encodeURIComponent(fileName)}&login_email=${email}&login_type=${userType}`
            ]
            fetch(commonLoginApi + 'api/agentcustomer/getowner', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
                // headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 200) {
                        let returnValue = { status: 200, data: JSON.parse(atob(response.userdata)) }
                        res(returnValue);
                    } else if (parseInt(response.status === 400)) {
                        let returnValue = { status: 400, msg: 'User does not exist' }
                        res(returnValue);
                    } else {
                        let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                        res(returnValue);
                    }
                });
        } else {
            let returnValue = { status: 401, msg: "Please provide agent Id" }
            res(returnValue);
        }
        // } else {
        //     let returnValue = { status: 401, msg: "Please provide agent Id" }
        //     res(returnValue);
        // }
    })
}

export const getAppId = () => {
    let appid = localStorage.getItem('APPYID');
    if (!appid) {
        appid = uuidv4();
        localStorage.setItem('APPYID', appid);
    }
    return appid;
}
export const getCookie = (cname) => {
    if(cname === 'APPYID' && localStorage.getItem('orgName') && localStorage.getItem('orgName')==="pts-solutions-spa"){
       return  getAppId();
    }
    var name = cname + "=";
    var docCookie = unescape(document.cookie);
    var decodedCookie = decodeURIComponent(docCookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const addProductUser = (user_id, email, organizationAlias, companyName, i = 0) => {
    return new Promise(async (res, rej) => {
        if (user_id && email && organizationAlias && companyName) {
            const fileName = await getCookie('APPYID');
            fetch(commonLoginApi + 'api/product/productuser', {
                method: 'POST',
                headers: {
                    Accept: 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('api_access_token')
                },
                body: JSON.stringify({ productname: 'livechat', email: email, user_id: user_id, company_identifier: organizationAlias, company: companyName, file_name: fileName }),
            })
                .then((response) => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = addProductUser(user_id, email, organizationAlias, companyName, i);
                            res(newResponse);
                        } else {
                            let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                            res(returnValue);
                        }
                    }
                    else if (response.status == 200) {
                        localStorage.setItem("app_access", 'yes');
                        let returnValue = { status: 200 }
                        res(returnValue);
                    } else {
                        let returnValue = { status: response.status, response: response }
                        res(returnValue);
                    }
                })
                .catch((error) => {
                    let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time", error: error }
                    res(returnValue);
                });
        } else {
            let returnValue = { status: 405, msg: "Invalid parameter" }
            res(returnValue);
        }
    })
}

export const getOwnerDetails_OLD = (ownerIdentifire, i = 0) => {
    return new Promise((resolve, reject) => {
        if (ownerIdentifire) {
            let myStr ="alias,"+
            "status,"+
            "pic,"+
            "imageurl,"+
            "email,"+
            "first_name,"+
            "last_name,"+
            "thumbnail,"+
            "imageurl,"+
            "time_zone,"+
            "user_role,"+
            "addedon,"+
            "name,"+
            "status,"+
            "last_login,"+
            "owner_id,"+
            "company_identifier"
            var accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            var body = {email:this.agent.ownerEmail,user:localStorage.alias};
            // JSON.stringify({
            //     user:localStorage.alias,
            //     fields:myStr.toString()
            // })
            fetch(commonLoginApi + 'api/common/getcompanynew', {
                method: "post",
                body: body,
                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + accessToken }
            })
                .then(response => response.json())
                .then(async (response) => {
                    if (parseInt(response.status) === 401) {
                        if (i < 4) {
                            i++;
                            await getApiAccessToken();
                            const newResponse = getOwnerDetails(ownerIdentifire, i);
                            resolve(newResponse);
                        } else {
                            let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                            resolve(returnValue);
                        }
                    } else if (parseInt(response.status) === 200) {
                        let userDetails = response.userdata;
                        let returnValue = { status: 200, data: userDetails }
                        resolve(returnValue);
                    } else if (parseInt(response.status === 400)) {
                        let returnValue = { status: 400, msg: 'User does not exist' }
                        resolve(returnValue);
                    } else {
                        let returnValue = { status: 401, msg: 'Something went wrong! Please try again after some time' }
                        resolve(returnValue);
                    }
                }).catch((err) => {
                    let returnValue = { status: 402, msg: 'Something went wrong! Please try again after some time', err: err }
                    resolve(returnValue);
                });
        } else {
            let returnValue = { status: 405, msg: "Invalid parameters" }
            resolve(returnValue);
        }
    })
}

export const updateCompanyDetails = (user_id, email, organizationAlias, companyName, i = 0) => {
    return new Promise(async (res, rej) => {
        if (user_id && email && organizationAlias && companyName) {
            const getUserToken = await getSecurityToken(email);
            if (getUserToken) {
                const fileName = await getCookie('APPYID');
                fetch(commonLoginApi + 'api/common/company/ownerCompanyUpdate', { //kdapi
                    method: 'POST',
                    headers: {
                        Accept: 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/json'
                        // "Authorization": "Bearer " + localStorage.getItem('api_access_token')
                    },
                    body: JSON.stringify({ 
                        productname: 'livechat',
                         email: email,
                          user_id: user_id, 
                          company_identifier: organizationAlias,
                           company: companyName, 
                           file_name: fileName,
                            user_token: getUserToken,
                            type:"companyUpdate",
                            user:localStorage.alias
                         }),
                })
                    .then((response) => response.json())
                    .then(async (response) => {
                        
                        if (parseInt(response.status) === 401) {
                            if (i < 4) {
                                i++;
                                await getApiAccessToken();
                                const newResponse = updateCompanyDetails(user_id, email, organizationAlias, companyName, i);
                                res(newResponse);
                            } else {
                                let returnValue = { status: 401, msg: "Something went wrong, Please try again after some time" }
                                res(returnValue);
                            }
                        }
                        else if (response.status == 200) {
                            localStorage.setItem("app_access", 'yes');
                            let returnValue = { status: 200 }
                            res(returnValue);
                        } else {
                            let returnValue = { status: response.status, response: response }
                            res(returnValue);
                        }
                    })
                    .catch((error) => {
                        let returnValue = { status: 403, msg: "Something went wrong, Please try again after some time", error: error }
                        res(returnValue);
                    });
            } else {
                let returnValue = { status: 405, msg: "Invalid parameter" }
                res(returnValue);
            }
        } else {
            let returnValue = { status: 405, msg: "Invalid parameter" }
            res(returnValue);
        }
    })
}

export const getOwnerData = (ownerId, response) => {
    if (ownerId) {
        firebaseServices.db.collection('users').doc(ownerId).onSnapshot((docs) => {
            if (docs.exists) {
                let _data = docs.data();
                let returnValue = { status: 200, data: _data };
                response(returnValue);
            } else {
                let returnValue = { status: 400 }
                response(returnValue);
            }
        })
    } else {
        let returnValue = { status: 500 }
        response(returnValue);
    }
}

export const ownerLogoutCommon = async (redirect = false) => {
    if (localStorage.getItem('agent') != null) {
        localStorage.removeItem('login_type');
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let ownerId = userDetails.ownerId;
        let agentId = userDetails.agentId;
        let ownerIdentifire = userDetails.company_url ? userDetails.company_url : userDetails.ownerIdentifire;

        /* Add Agent session start */
        saveAgentSession(userDetails, "logout");
        /* Add Agent session end */

        ////// Saving all inactive chats for automatically flushing Start ////////////
        if (CLOSE_INACTIVE_CHATS_FROM === "all" || CLOSE_INACTIVE_CHATS_FROM === "logout") {
            try {
                var body = [`ownerId=${ownerId}&agentId=${agentId}`];
                //fetch(MAIL_FUNCTION_URL+"/app/closeinactivechats", {
                fetch(GATEWAY_URL + "/closeinactivechats", {
                    method: "post",
                    body: body,
                    headers: {
                        "X-API-Key": FUNCTION_API_KEY,
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json",
                        "Origin": window.location.origin
                    }
                })
                    .then(res => res.json())
                    .then(response => { }).catch(e => { });
            } catch (err) { }
        }
        ////// Saving all inactive chats for automatically flushing End ////////////

        let logOutAt = Date.now();
        var todayUTCDate = moment.tz(logOutAt, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
        todayUTCDate = parseInt(todayUTCDate);
        localStorage.setItem('loggingout', 'true');
        /* commented logs on 26-03-2021 start */
        // firebaseServices.db.collection('users').doc(ownerId).collection('agents').doc(agentId).collection('logs').doc(todayUTCDate.toString()).set({online_status:1,time:todayUTCDate,ref:'logout'}).then((docs) => {

        // });
        /* commented logs on 26-03-2021 ends */
        firebaseServices.db.collection("users").doc(ownerId).collection("agents").doc(agentId).update({ online_status: 0 }).then(async (ref) => {
            await activityLog('Logout');
            localStorage.removeItem('agent');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('agentData');
            localStorage.removeItem('common_redirect');
            localStorage.removeItem('current_login');
            localStorage.removeItem('userdata');
            localStorage.removeItem('loggingout');
            localStorage.removeItem('companyName');
            localStorage.removeItem('owner_app_access');
            localStorage.removeItem('usernotexist');
            if (redirect === true) {
                window.location.href = COMMON_LOGIN_URL + "/logout?frompage=" + DOMAIN_NAME + "/company-info&website=" + DOMAIN_NAME + "/company-info";
            } else {
                window.location.href = COMMON_LOGIN_URL + "/logout?frompage=" + DOMAIN_NAME + "/" + ownerIdentifire + "/login&website=" + DOMAIN_NAME + "/" + ownerIdentifire + "/login";
            }
        })
    } else {
        await activityLog('Logout');
        localStorage.removeItem('login_type');
        localStorage.removeItem('agent');
        localStorage.removeItem('agentData');
        localStorage.removeItem('common_redirect');
        localStorage.removeItem('current_login');
        localStorage.removeItem('loggingout');
        localStorage.removeItem('companyName');
        localStorage.removeItem('owner_app_access');
        localStorage.removeItem('usernotexist');
        window.location.href = COMMON_LOGIN_URL + "/logout?frompage=" + DOMAIN_NAME + "/company-info&website=" + DOMAIN_NAME + "/company-info";
    }
}

export const autoCompleteAI = (sentence = false) => {
    return new Promise((resolve, reject) => {
        if (sentence) {
            let stop = "\n";
            let body = {
                "prompt": `Poor English: ${sentence} 
                        Corrected English:`,
                "temperature": 0.5,
                "max_tokens": 100,
                "stop": stop
            }
            fetch("https://api.openai.com/v1/engines/davinci/completions", {
                method: "post",
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/json", "Authorization": "Bearer " + OPEN_AI_KEY }
            }).then(response => response.json())
                .then(response => {
                    if (response && response.choices) {
                        resolve({ status: 200, choices: response.choices });
                    } else {
                        resolve({ status: 400 });
                    }
                }).catch(err => {
                    console.log("errrrr => ", err);
                    resolve({ status: 400 });
                })
        } else {
            resolve({ status: 400 });
        }
    })
}


export const checkApis = (sentence = false) => {
    return new Promise((resolve, reject) => {
        if (sentence) {
            let stop = "\n";
            let body = {
                "text": sentence,
                "language": "en-US"
            }
            fetch("https://grammarbot.p.rapidapi.com/check", {
                method: "post",
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/x-www-form-urlencoded", "x-rapidapi-key": "cbdc1d4144msh8e2552fc05f8256p104e06jsn0e6c9e619f3d", "x-rapidapi-host": "grammarbot.p.rapidapi.com", "useQueryString": true }
            }).then(response => response.json())
                .then(response => {
                    if (response && response.choices) {
                        resolve({ status: 200, choices: response.choices });
                    } else {
                        resolve({ status: 400 });
                    }
                }).catch(err => {
                    console.log("errrrr => ", err);
                    resolve({ status: 400 });
                })
        } else {
            resolve({ status: 400 });
        }
    })
}

export const getChatBotToken = (ownerEmail) => {
    return new Promise((resolve, reject) => {
        if (ownerEmail) {
            fetch(CHATBOT_API_KEY_URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: ownerEmail }),
            })
                .then((res) => res.json())
                .then((res) => {
                    resolve({ status: 200, data: res });
                }).catch((err) => {
                    resolve({ status: 400 });
                })
        } else {
            resolve({ status: 400 });
        }
    })
}

export const getChatBots = (token) => {
    return new Promise((resolve, reject) => {
        if (token) {
            fetch(CHATBOT_API_LIST_URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token: token }),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status === 200) {
                        resolve({ status: 200, data: res });
                    } else {
                        resolve({ status: 401 });
                    }
                }).catch((err) => {
                    resolve({ status: 400 });
                })
        } else {
            resolve({ status: 400 });
        }
    })
}

export const getAllAwsAgents = async (allAgentsList = [], page = 1) => {
    return new Promise(async (resolve, reject) => {
        const app = new Realm.App({ id: REACT_APP_REALM_API_ID });
        var useragent = await app.logIn(credentials);
        const limit = 0;
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        const company_identifier = userDetails.ownerIdentifire;
        const allAgentss = await useragent.functions.getData(company_identifier, limit, page);
        if (allAgentss.status && allAgentss.status === 400) {
            console.log('calling listuser api for agents');
            let OldAgents = await getAllAwsAgents_OLD();
            resolve(OldAgents);
        } else {
            resolve(allAgentss);
        }
    })
}
export const getTypeByEmail = async (companyIdentifier,email) => {
    return new Promise(async (resolve, reject) => {

        var body = {email:email,companyIdentifier:companyIdentifier};
        // const company_identifier = ownerIdentifire;
        fetch( 'https://us-central1-livechat-production.cloudfunctions.net/open/checkuserType', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                if (response.userType ) {
                        resolve({status:200,data:response});
                } else {
                    reject({ status: 400, data:response })
                }
            }).catch(async (err) => {
                resolve({ status: 400, data:err })
            });
    })
}
export const getOwnerDetails = async (ownerIdentifire) => {
    return new Promise(async (resolve, reject) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if(localStorage.alias){
        var body = {email:userDetails.ownerEmail,user:localStorage.alias};
        // const company_identifier = ownerIdentifire;
        fetch(commonLoginApi + 'api/common/getcompanynew', {
            method: "post",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(async (response) => {
                if (response.status === 200 && response.userdata) {
                        let returnValue = { status: 200, data: response.userdata }
                        resolve(returnValue);
                } else {
                    resolve({ status: 200, data:await getCompanyData(getCookie('APPYID')) })
                }
            }).catch(async (err) => {
                resolve({ status: 200, data:await getCompanyData(getCookie('APPYID')) })
            });}
            else{
               resolve({ status: 200, data:await getCompanyData(getCookie('APPYID')) })
            }

    })
}
 const getCompanyData = (ID) => {
    console.log("FORGET")
    return new Promise((resolve, reject) => {
            fetch("https://backendaccounts.appypie.com/api/common/getuserdetailnew", {
                method: "post",
                body: JSON.stringify({"file_name":ID}),
                headers: { "Content-Type": "application/json"}
            }).then(response => response.json())
                .then(response => {
                    resolve(response.userdata);
                }).catch(err => {
                    resolve({ status: 400 });
                })
    })
}

export const addUserLogs = () => {

}

export const getRealMApp = () => {
    const app = new Realm.App({ id: `${REACT_APP_REALM_API_ID}` });
    return app
}
// No longer Used as Common login desable the inster log relm function commented the use of code line 1721 to 1766 and getLogs are also desabled 
export const activityLog = async (action) => {
    return new Promise(async (res, rej) => {
        res(true);
        // let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        // if (userDetails) {
        //     let userType = userDetails.roleType && userDetails.roleType === 'Owner' ? 'owner' : 'agent';
        //     let userId = userType === 'owner' ? (userDetails.realmId ? userDetails.realmId : false) : userDetails.agentId;
        //     let companyName = userDetails.company_name ? userDetails.company_name : "";
        //     if (userId) {
        //         let app = await getRealMApp()
        //         // await app.currentUser.logOut();
        //         const user = await app.currentUser
        //         console.log('userss ', user);
        //         let logData = {
        //             product_name: 'livechat',
        //             action: action,
        //             user_id: parseInt(userId),
        //             company_name: companyName,
        //             user_type: userType,
        //             partition_key: Date.now().toString(),
        //             addedon: Date.now(),
        //             "_id": ObjectId()
        //         };

        //         if (user) {
        //             return new Promise(async (resolve, reject) => {
        //                 const result1 = await user.functions.insertLog(logData);
        //                 console.log(result1, "result2")
        //                 resolve(result1)
        //             }).then((result1) => {
        //                 res(true);
        //             })
        //         } else {
        //             var useragent = await app.logIn(credentials);
        //             await app.currentUser.refreshCustomData();
        //             return new Promise(async (resolve, reject) => {
        //                 const result1 = await useragent.functions.insertLog(logData);
        //                 console.log(result1, "result1")
        //                 resolve(result1)
        //             }).then((result1) => {
        //                 res(true);
        //             })
        //         }
        //     } else {
        //         res(false);
        //     }
        // } else {
        //     res(false);
        // }
    })
}

// export const getLogs = () => {
//     let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
//     let userType = userDetails.roleType && userDetails.roleType === 'Owner' ? 'owner' : 'agent';
//     let userId = userType === 'owner' ? (userDetails.realmId ? userDetails.realmId : false) : userDetails.agentId;
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

//     var urlencoded = new URLSearchParams();
//     urlencoded.append("page", "1");
//     urlencoded.append("product_name", "livechat");
//     urlencoded.append("limit", "250");
//     urlencoded.append("login_type", userType);
//     urlencoded.append("user_id", userId);

//     var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: urlencoded,
//         redirect: 'follow'
//     };

//     fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/activitylog?access_token=wfwefergergergergergdgegrgergrgrgrg", requestOptions)
//         .then(response => response.text())
//         .then(result => console.log(result))
//         .catch(error => console.log('error', error));
// }

export const logActivityInFirestore = async (action, page) => {
    return new Promise(async (res, rej) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails) {
            let ownerId = userDetails.ownerId;
            if (ownerId && page) {
                let serverTimeStamp = await getDateInUTC();
                let collectionName = "";
                if (page === "subscription") {
                    collectionName = 'views';
                } else if (page === "upgrade_action_btn") {
                    collectionName = 'upgrade_action_btn';
                } else if (page === "upgrade_plan_btn") {
                    collectionName = 'upgrade_plan_btn';
                } else if (page === "try_now_btn") {
                    collectionName = 'try_now_btn';
                }
                
                if(collectionName) {
                    firebaseServices.db.collection('reports').doc("subscription_page").collection(collectionName).doc('LOG' + serverTimeStamp + Math.ceil(Math.random() * 999999)).set({
                        ownerId : ownerId,
                        action : action,
                        addedon : parseInt(serverTimeStamp)
                    }).then((result1) => {
                        res(true);
                    });
                }

            } else {
                res(false);
            }
        } else {
            res(false);
        }
    })
}

export const checkWidgetStatus = async () => {
    return new Promise((resolve, reject) => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if (userDetails.ownerId) {
            let widgetStatus = false;
            firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then((docs) => {
                if (docs.exists) {
                    let _data = docs.data();
                    widgetStatus = _data.widget_status ? _data.widget_status : false;
                }
                resolve(widgetStatus);
            }).catch((err) => {
                console.log(err);
                resolve(false);
            })
        } else {
            resolve(false);
        }
    })
}

export const elasticSearchBannedUsers = (index, page = 1, per_page = 50, searchWords = '', searchBy = 0, orderBy = 'addedon', orderType = 'desc') => {
    return new Promise((res, rej) => {
        if (index) {
            var body = [`index=${index}&page=${page}&per_page=${per_page}&searchWords=${searchWords}&searchBy=${parseInt(searchBy)}&orderBy=${orderBy}&orderType=${orderType}`];
            //fetch(MAIL_FUNCTION_URL+"/getbannedUsers/elasticsearch", {
            fetch(GATEWAY_URL + "/elasticsearch", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(response => {
                    const data = { status: 200, data: response }
                    res(data);
                }).catch(e => { });
        } else {
            const data = { status: 400, msg: "Fields are missing" };
            res(data);
        }
    })
}

export const elasticSearchArchives = (index, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, searchOption, page = 1, per_page = 100, searchWords = '', fromDateValue = '', toDateValue = '', orderBy = 'addedon', orderType = 'desc', ratingsProduct, ratingsAgent, country, sourceTextChecked, sourceCallChecked) => {
    return new Promise((res, rej) => {
        if (index) {
            var body = [`index=${index}&searchAgent=${searchAgent}&ongoingChecked=${ongoingChecked}&completeChecked=${completeChecked}&missedChecked=${missedChecked}&paidChecked=${paidChecked}&trialChecked=${trialChecked}&visitorChecked=${visitorChecked}&agentChecked=${agentChecked}&searchOption=${searchOption}&page=${page}&per_page=${per_page}&searchWords=${searchWords}&fromDateValue=${fromDateValue}&toDateValue=${toDateValue}&orderBy=${orderBy}&orderType=${orderType}&ratingsProduct=${JSON.stringify(ratingsProduct)}&ratingsAgent=${JSON.stringify(ratingsAgent)}&country=${country}&sourceTextChecked=${sourceTextChecked}&sourceCallChecked=${sourceCallChecked}`];
            //fetch(MAIL_FUNCTION_URL + "/getbannedUsers/elasticsearchArchive", {
            fetch(GATEWAY_URL + "/elasticsearchArchive", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(response => {
                    const data = { status: 200, data: response }
                    res(data);
                }).catch(e => { });
        } else {
            const data = { status: 400, msg: "Fields are missing" };
            res(data);
        }
    })
}

export const elasticArchivesHistory = (index, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, searchOption, page = 1, per_page = 5000, searchWords = '', fromDateValue = '', toDateValue = '', ratingsProduct, ratingsAgent, orderBy = 'addedon', orderType = 'desc', allResults = [], country, sourceTextChecked, sourceCallChecked) => {
    return new Promise((res, rej) => {
        if (index) {
            const perPageRecord = 4000;
            var body = [`index=${index}&searchAgent=${searchAgent}&ongoingChecked=${ongoingChecked}&completeChecked=${completeChecked}&missedChecked=${missedChecked}&paidChecked=${paidChecked}&trialChecked=${trialChecked}&visitorChecked=${visitorChecked}&agentChecked=${agentChecked}&searchOption=${searchOption}&page=${page}&per_page=${perPageRecord}&searchWords=${searchWords}&fromDateValue=${fromDateValue}&toDateValue=${toDateValue}&orderBy=${orderBy}&orderType=${orderType}&ratingsProduct=${JSON.stringify(ratingsProduct)}&ratingsAgent=${JSON.stringify(ratingsAgent)}&country=${country}&sourceTextChecked=${sourceTextChecked}&sourceCallChecked=${sourceCallChecked}`];
            //fetch(MAIL_FUNCTION_URL + "/getbannedUsers/elasticsearchDownload", {
            fetch(GATEWAY_URL + "/elasticsearchDownload", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const resp = { success: 'retry' }
                        return resp;
                    }
                })
                .then(async response => {
                    if (response.success === true) {
                        const totalRecords = response.totalRecords;
                        const finalData = response.data;
                        const allFinalData = allResults.concat(finalData);
                        if ((parseInt(perPageRecord) * parseInt(page)) < totalRecords) {
                            const promResp = await elasticArchivesHistory(index, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, searchOption, parseInt(page) + 1, per_page, searchWords, fromDateValue, toDateValue, ratingsProduct, ratingsAgent, orderBy, orderType, allFinalData, country, sourceTextChecked, sourceCallChecked)
                            res(promResp);
                        } else {
                            const data = { status: 200, data: allFinalData };
                            res(data);
                        }
                    }
                    // else if(response.success === 'retry') {
                    //     // const promResp = await elasticArchivesHistory(index, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, searchOption, parseInt(page), per_page, searchWords, fromDateValue, toDateValue, orderBy, orderType, allResults)
                    //     res(promResp);
                    // } 
                    else {
                        const NotMsg = response.msg ? response.msg : 'Something went wrong, Please try again after some time!';
                        const data = { status: 400, msg: NotMsg };
                        res(data);
                    }
                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, msg: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, msg: "Fields are missing" };
            res(data);
        }
    })
}

export const ownerCommongLogin = (email, pwd) => {
    return new Promise(async (res, rej) => {
        if (email && pwd) {
            const fileName = await getCookie('APPYID');
            var body = {
                "email": email,
                "password": pwd,
                "file_name": encodeURIComponent(fileName)
            }
            fetch(commonLoginApi + 'api/logses/productlogin', {
                method: "post",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    
                    res(response);

                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, message: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, message: "Fields are missing" };
            res(data);
        }
    })
}

export const ownerCommongLoginWithMobileNumber = (mobile, country_code, pwd) => {
    return new Promise(async (res, rej) => {
        if (mobile && pwd && country_code) {
            const fileName = await getCookie('APPYID');
            var body = {   
                    "user_mobile": country_code + mobile,
                    "mobile_number": mobile,
                    "mobile_country_code": country_code,
                    "password": pwd,
                    "file_name":encodeURIComponent(fileName) 
            }
            fetch(commonLoginApi + 'api/logses/productlogin', {
                method: "post",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    
                    res(response);

                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, message: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, message: "Fields are missing" };
            res(data);
        }
    })
}

export const MFAVerify = (email, otp) => {
    return new Promise(async (res, rej) => {
        if (email && otp) {
            const fileName = await getCookie('APPYID');
            var body = [`email=${email}&otp=${otp}&file_name=${encodeURIComponent(fileName)}`];
            fetch(commonLoginApi + 'api/logses/producttwostep', {
                method: "post",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    res(response);
                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, message: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, message: "Fields are missing" };
            res(data);
        }
    })
}

export const emailVerifyAWS = (email, otp) => {
    return new Promise(async (res, rej) => {
        if (email && otp) {
            const fileName = await getCookie('APPYID');
            var body = [`email=${email}&otp=${otp}&file_name=${encodeURIComponent(fileName)}`];
            fetch(commonLoginApi + 'api/logses/emailverify', {
                method: "post",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    res(response);
                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, message: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, message: "Fields are missing" };
            res(data);
        }
    })
}

export const resendEmailOTP = (email) => {
    return new Promise(async (res, rej) => {
        if (email) {
            const fileName = await getCookie('APPYID');
            var body = [`email=${email}`];
            fetch(commonLoginApi + 'api/logses/resendemailcode', {
                method: "post",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    res(response);
                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, message: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, message: "Fields are missing" };
            res(data);
        }
    })
}

export const setCookies = (cookies = {}, expiry = '', domain = 'appypie.com') => {
    console.log(cookies);
    return new Promise((res, rej) => {
        if (Object.keys(cookies).length > 0) {
            console.log('cookies >>> ', cookies);
            var expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);
            expiry = expiry !== '' ? expiry : expiryDate;
            for (var key in cookies) {
                console.log('----key---', key, cookies[key], expiry, domain);
                document.cookie = key + "=" + cookies[key] + ";expires=" + expiry + ";path=/;domain=" + domain;
            }
            res();
        } else {
            res();
        }
    })
}


export const getSecurityToken = (email = '') => {
    return new Promise(async (resolve, reject) => {
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (email === '') {
            email = userDetails && userDetails.email ? userDetails.email : false;
        }
        if (email) {
            const fileName = await getCookie('APPYID');
            let accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
            if (!accessToken) {
                accessToken = await getApiAccessToken();
            }
            var body = [`file_name=${encodeURIComponent(fileName)}&email=${email}`];
            fetch(commonLoginApi + "api/agentcustomer/validuser?access_token=" + accessToken, {
                method: "post",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then(res => res.json())
                .then(async response => {
                    if (response.status === 200) {
                        resolve(response.user_token);
                    } else {
                        resolve(false);
                    }
                }).catch(err => {
                    console.log('---err---', err);
                    resolve(false);
                });
        } else {
            resolve(false);
        }
    })
}

export const jwtVerify_OLD = async (token, key = JWTKey) => {
    return new Promise((res, rej) => {
        JSONWEBTOKEN.verify(token, key, (err, decryptToken) => {
            if (err) {
                res(false);
            }
            res(decryptToken);
        })
    })
}

export const jwtVerify = (token, ownerIdentifire) => {
    // 
    return new Promise((res, rej) => {
        if (token && ownerIdentifire) {
            var body = [`reqToken=${token}&ownerIdentifire=${ownerIdentifire}`];
            // fetch("http://localhost:5001/reacttest-29b23/us-central1/loginApi/jwtVerify", {
            fetch(GATEWAY_URL + "/jwtVerify", {
                method: "post",
                body: body,
                headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
            })
                .then((res) => res.json())
                .then(async response => {
                    localStorage.setItem("otpEmail", response.data.email)
                    res(response);
                }).catch(err => {
                    const data = { status: 400, msg: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, msg: "Fields are missing" };
            res(data);
        }
    })
}

export const addCannedText = (ownerId, agentId, cannedText, cannedHashtag, applicableOn, userType) => {
    return new Promise((res, rej) => {
        const body = [
            `ownerId=${ownerId}&agentId=${agentId}&cannedText=${encodeURIComponent(cannedText)}&cannedHashtag=${encodeURIComponent(cannedHashtag)}&applicableOn=${applicableOn}&userType=${userType}`
        ]
        //fetch(MAIL_FUNCTION_URL+'/cannedText/addText', {
        fetch(GATEWAY_URL + "/addText", {
            method: 'post',
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then((resp) => resp.json())
            .then((response) => {
                res(response);
            }).catch((err) => {
                console.log(err);
                res({ success: false, msg: 'Something went wrong, please try again after some time!' });
            })
    })
}

export const deleteCannedText = (ownerId, docId) => {
    return new Promise((res, rej) => {
        const body = [
            `ownerId=${ownerId}&docId=${docId}`
        ]
        //fetch(MAIL_FUNCTION_URL+'/cannedText/deleteText', {
        fetch(GATEWAY_URL + "/deleteText", {
            method: 'post',
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then((resp) => resp.json())
            .then((response) => {
                res(response);
            }).catch((err) => {
                console.log(err);
                res({ success: false, msg: 'Something went wrong, please try again after some time!' });
            })
    })
}

export const editCannedText = (ownerId, agentId, cannedText, cannedHashtag, applicableOn, userType, docId, addedon) => {
    console.log(applicableOn);
    return new Promise((res, rej) => {
        const body = [
            `ownerId=${ownerId}&agentId=${agentId}&cannedText=${encodeURIComponent(cannedText)}&cannedHashtag=${encodeURIComponent(cannedHashtag)}&applicableOn=${applicableOn}&userType=${userType}&docId=${docId}&addedon=${addedon}`
        ]
        //fetch(MAIL_FUNCTION_URL+'/cannedText/editText', {
        fetch(GATEWAY_URL + "/editText", {
            method: 'post',
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then((resp) => resp.json())
            .then((response) => {
                res(response);
            }).catch((err) => {
                console.log(err);
                res({ success: false, msg: 'Something went wrong, please try again after some time!' });
            })
    })
}

export const getCannedText = (index, agentId, agentCheck = true, searchWords = '', page = 1, perPageRecord = 50, fromPage = "", orderBy = 'addedon', orderType = 'desc') => {
    return new Promise((res, rej) => {
        if (index) {
            var body = [`index=${index}&agentId=${agentId}&agentCheck=${agentCheck}&page=${page}&per_page=${perPageRecord}&searchWords=${searchWords}&orderBy=${orderBy}&orderType=${orderType}&fromPage=${fromPage}`];
            //fetch(MAIL_FUNCTION_URL+"/cannedText", {
            fetch(GATEWAY_URL + "/cannedText", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        const resp = { success: 'retry' }
                        return resp;
                    }
                })
                .then(async response => {
                    if (response.success === true) {
                        const finalData = response.data;
                        const data = { status: 200, data: finalData, totalRecords: response.totalRecords ? response.totalRecords : 0 };
                        res(data);
                    }
                    else {
                        const NotMsg = response.msg ? response.msg : 'Something went wrong, Please try again after some time!';
                        const data = { status: 400, msg: NotMsg };
                        res(data);
                    }
                }).catch(err => {
                    console.log('---err---', err);
                    const data = { status: 400, msg: "Something went wrong, Please try again after some time!" };
                    res(data);
                });
        } else {
            const data = { status: 400, msg: "Fields are missing" };
            res(data);
        }
    })
}

export const getZoomURL = (userDetails = false) => {
    return new Promise((res, rej) => {
        if (userDetails) {
            let body = [`appypie-partner-cb=${appyPieCbId}&pp-id=khag29cb0dtsff756bam9fij&appId=k3pplzjk0c7s11f3ce9la56o&clientId=${userDetails.ownerId}&email=${userDetails.ownerEmail}&pc-full-name=${userDetails.organization_alias}`]
            fetch("http://localhost:5000/reacttest-29b23/us-central1/integrations/integration-url", {
                method: "post",
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Origin": window.location.origin
                }
            }).then((resp) => resp.json())
                .then((response) => {
                    const resData = response.data;
                    res(resData);
                }).catch((err) => {
                    console.log(err);
                    res({ success: false, msg: 'Something went wrong, please try again after some time!' });
                })
        }
    })
}

export const getLocalesVars = (lang = "en") => {
    return new Promise((res, rej) => {
        // const URL = `https://d23i99usg1q4tk.cloudfront.net/accounts/language/livechat/${lang}.json`;
        const URL = `${DOMAIN_NAME}/widget/${lang}.json`;
        fetch(URL, {
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Origin": window.location.origin
            }
        }).then((resp) => resp.json())
            .then((response) => {
                res({ success: true, data: response });
            }).catch((err) => {
                console.log(err);
                res({ success: false, msg: 'Something went wrong, please try again after some time!' });
            })
    })
}

/* For create custom token for security start */

const logInWithCustomToken2 = async (ownerId, agentId) => {
    return new Promise(resolve => {
        var body = [`ownerId=${ownerId}&agentId=${agentId}`];
        fetch(GATEWAY_URL + "/getdbaccesstoken", {
            method: "POST",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(async response => {
                if(response.status === "success") {
					firebaseServices.firebaseAuth().signInWithCustomToken(response.token).then((userCredential) => {
						console.log("---------------    userCredential   ----------------");
						console.log(userCredential);
						//var user = userCredential.user;
						resolve(true);
					})
					.catch((error) => {
						///var errorCode = error.code;
						///var errorMessage = error.message;
						resolve(false);
					});
                } else {
                    resolve(false);
                }
            })
            .catch(e => {
                resolve(false);
            });
    });
}
