import React from 'react';
import LeftPanel from "../views/LeftPanel";
// import db from '../../../config/firebase';
import ReactTooltip from "react-tooltip";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { getDateInUTC } from '../../livechat/components/Comman';
import firebaseServices from '../../../firebase';


const defaultEQ = ["hour_of_day", "day_of_week", "visitor_ip", "visitor_hostname", "visitor_city", "visitor_region", "visitor_country_code", "visitor_country_name", "visitor_previous_chats", "visitor_previous_visits", "visitor_page_url", "visitor_page_title", "visitor_page_count", "referrer", "visitor_name", "visitor_email", "visitor_referrer", "visitor_search_engine", "visitor_search_terms", "visitor_department_id", "visitor_user_agent", "visitor_browser", "visitor_os", "account_status", "visitor_status"];
const defaultText = ["still_on_site", "still_on_page"];
const defaultBoolian = ["visitor_triggered", "visitor_is_chatting", "visitor_requesting_chat", "visitor_served"];

const operater_options = [
    { value: "eq", label: "= Equals" },
    { value: "neq", label: "≠ Not equal" },
    { value: "lt", label: "< Less than" },
    { value: "gt", label: "> Greater than" },
    { value: "lte", label: "≤ Less than or equal to" },
    { value: "gte", label: "≥ Greater than or equal to" }
];

const operater_options2 = [
    { value: "eq", label: "= Equals" },
    { value: "neq", label: "≠ Not equal" },
    { value: "icontains", label: "Contains" },
    { value: "contains", label: "Contains (case sensitive)" },
    { value: "nicontains", label: "Does not contain" },
    { value: "iregex", label: "Regex" },
    { value: "regex", label: "Regex matches (case-sensitive)" }
];

const operater_options3 = [
    { value: "true", label: "Is true" },
    { value: "false", label: "Is false" }
];

const operater_options4 = [
    { value: "contains", label: "Contains (case sensitive)" },
    { value: "ncontains", label: "Does not contain (case-sensitive)" }
];

const operater_options5 = [
    { value: "eq", label: "= Equals" },
    { value: "neq", label: "≠ Not equal" }
];

const optionsObjj = {
    "hour_of_day": operater_options,
    "day_of_week": operater_options,
    "still_on_site": null,
    "still_on_page": null,
    "visitor_previous_chats": operater_options,
    "visitor_previous_visits": operater_options,
    "visitor_ip": operater_options2,
    "visitor_hostname": operater_options2,
    "visitor_city": operater_options2,
    "visitor_region": operater_options2,
    "visitor_country_code": operater_options2,
    "visitor_country_name": operater_options2,
    "visitor_page_url": operater_options2,
    "visitor_page_title": operater_options2,
    "visitor_page_count": operater_options,
    "referrer": operater_options2,
    "visitor_name": operater_options2,
    "visitor_triggered": operater_options3,
    "visitor_email": operater_options2,
    "visitor_referrer": operater_options2,
    "visitor_search_engine": operater_options2,
    "visitor_search_terms": operater_options2,
    "visitor_tags": operater_options4,
    "visitor_department_id": operater_options5,
    "visitor_user_agent": operater_options2,
    "visitor_browser": operater_options2,
    "visitor_os": operater_options2,
    "account_status": operater_options5,
    "department_status": null,
    "visitor_status": operater_options5,
    "visitor_is_chatting": operater_options3,
    "visitor_requesting_chat": operater_options3,
    "visitor_served": operater_options3,
    "queue_size": operater_options,
};

const optionsObjHelperText = {
    "hour_of_day": "Hour of day (UTC timezone). 0 to 23",
    "day_of_week": "Day of the week. 0 = Sunday, 6 = Saturday",
    "still_on_site": "Delay (in secs) before checking next condition. Max 3600 seconds",
    "still_on_page": "Delay (in secs) before checking next condition. Max 3600 seconds",
    "visitor_ip": "IP address of the visitor (xxx.xxx.xxx.xxx)",
    "visitor_hostname": "Host name of visitor's IP address",
    "visitor_city": "City the visitor is from (new york)",
    "visitor_region": "Region the visitor is from",
    "visitor_country_code": "2-letter country code of visitor",
    "visitor_country_name": "Country the visitor is from",
    "visitor_previous_chats": "No. of times visitor chatted with agents. New chats = 0",
    "visitor_previous_visits": "No. of times visitor previously visited. New visits = 0",
    "visitor_page_url": "URL of the page the visitor is currently on",
    "visitor_page_title": "Title of the page the visitor is currently on",
    "visitor_page_count": "# of pages visitor has viewed. First page = 1",
    "referrer": "URL of the previous page visitor was on",
    "visitor_name": "Name of the visitor",
    "visitor_triggered": "'true' if visitor has been triggered (set by the action 'Set triggered')",
    "visitor_email": "Email of the visitor",
    "visitor_referrer": "Referrer URL of the visitor (when he entered your site)",
    "visitor_search_engine": "Referring search engine, if applicable (Google / Yahoo / Bing)",
    "visitor_search_terms": "Referring search term, if applicable",
    "visitor_tags": "Name of tag (set by the action 'Set tag')",
    "visitor_department_id": "Visitor's department (set by the action 'Set visitor department' or Javascript API)",
    "visitor_user_agent": "Visitor's browser User-Agent string",
    "visitor_browser": "Visitor's browser (Internet Explorer / Firefox / Chrome / Safari / etc)",
    "visitor_os": "Visitor's OS/Platform (Win7 / WinXP / MacOSX / etc)",
    "visitor_is_chatting": "'true' if visitor is currently in active chat session",
    "visitor_requesting_chat": "'true' if visitor is requesting chat",
    "visitor_served": "'true' if visitor is currently served by agents",
    "queue_size": "Total number of incoming chat requests for the account",
};

const actionOperaterPH = {
    "sendMessageToVisitor": "Name of agent",
    "wait": "Duration (in secs)",
    "setName": "Name",
    "blockVisitor": "Reason",
    "endChat": "Message",
    "addTag": "Tag",
    "removeTag": "Tag",
    "setNote": "Note",
    "appendNote": "Note"
};

const actionValuePH = {
    "sendMessageToVisitor": "Message"
};

class Triggers extends React.Component {
    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            trigger_id: "",
            triggerList: [],
            displayTriggerList: [],
            globalTriggerList: [],
            status: true,
            trigger_name: "",
            error_trigger_name: "",
            one_per_visitor: true,
            description: "",
            error_description: "",
            show_form: 'list',
            trigger_event: "page_enter",
            condition_operater: "and",
            conditions_list: [[]],
            error_conditions: [],
            conditionHelperText: [],
            actions_list: [[""]],
            actionOpPH: [],
            actionValuePH: [],
            error_val_actions: [],
            error_op_actions: [],
            error_location_actions: [],
            error_actions: [],
            error_con_fields: [],
            checkedBoxesList: [],
            trigger_type: "custom",
            search_trigger: "",
            checkall: false,
            guide: true,
            guide_step: 0
        }

        if (this.agent.triggers_status === 0) {
            props.history.push("/" + this.agent.ownerIdentifire);
        }
    }

    /* Private functions start */

    addTrigger = () => {
        this.setState({
            show_form: 'add', trigger_name: "", error_trigger_name: "", one_per_visitor: true, description: "", error_description: "", trigger_event: "page_enter", condition_operater: "and", conditions_list: [[]], error_conditions: [], conditionHelperText: [], actions_list: [[""]], actionOpPH: [], actionValuePH: [], error_val_actions: [], error_op_actions: [], error_actions: [], error_con_fields: [], trigger_type: "custom"
        });
    }

    editTrigger = (triggerData) => {
        let actLists = triggerData.actions_list ? JSON.parse(triggerData.actions_list) : [];
        let tmpActionOpPH = [];
        actLists.forEach((actionArray, indx) => {
            if (actionOperaterPH.hasOwnProperty(actionArray[0])) {
                tmpActionOpPH[indx] = actionOperaterPH[actionArray[0]];
            }
        });
        let condLists = triggerData.conditions_list ? JSON.parse(triggerData.conditions_list) : [];
        let tmpConditionHelperText = [];
        condLists.forEach((condArray, indx) => {
            if (optionsObjHelperText.hasOwnProperty(condArray[0])) {
                tmpConditionHelperText[indx] = optionsObjHelperText[condArray[0]];
            }
        });
        this.setState({
            show_form: 'edit', status: triggerData.status, trigger_id: triggerData.id, trigger_name: triggerData.trigger_name, error_trigger_name: "", one_per_visitor: triggerData.one_per_visitor, description: triggerData.description, error_description: "", trigger_event: triggerData.trigger_event, condition_operater: triggerData.condition_operater, conditions_list: JSON.parse(triggerData.conditions_list), error_conditions: [], conditionHelperText: tmpConditionHelperText, actions_list: JSON.parse(triggerData.actions_list), actionOpPH: tmpActionOpPH, actionValuePH: [], error_val_actions: [], error_op_actions: [], error_actions: [], error_con_fields: [], trigger_type: triggerData.type ? triggerData.type : "custom"
        });
    }

    /* Private functions end */

    /* List functions start */

    getGlobalTriggers = () => {
        return new Promise(resolve => {
            let triggerList = [];
            firebaseServices.db.collection('triggers').get().then(docs => {
                if (docs.size > 0) {
                    docs.forEach((doc) => {
                        let trigger = doc.data();
                        trigger.id = doc.id;
                        triggerList.push(trigger);
                    });
                }
                this.setState({ globalTriggerList: triggerList }, () => {
                    resolve(true);
                });
            });
        });
    }

    componentDidMount = async () => {
        await this.getGlobalTriggers();
        let triggerList = [];
        let triggerIdesList = [];
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('triggers').get().then(docs => {
            if (docs.size > 0) {
                docs.forEach((doc) => {
                    let trigger = doc.data();
                    trigger.id = doc.id;
                    triggerIdesList.push(doc.id);
                    triggerList.push(trigger);
                });
            }
            if (triggerList.length > 0) {
                let trList = [];
                this.state.globalTriggerList.forEach(glTrigger => {
                    if (triggerIdesList.indexOf(glTrigger.id) === -1) {
                        trList.push(glTrigger);
                    }
                });
                triggerList = trList.concat(triggerList);
                triggerList = triggerList.filter(function (item, index) {
                    return item.is_deleted !== true;
                });
            } else {
                triggerList = this.state.globalTriggerList;
            }
            this.setState({ triggerList: triggerList, loader: false }, () => {
                this.searchTrigger();
            });
        });

        this.getGuide();
    }

    /* List functions end */

    /* Condition functions Start */

    addNewCondition = (index) => {
        let condList = this.state.conditions_list;
        let pos = index + 1;
        condList.splice(pos, 0, []);
        this.setState({ conditions_list: condList });
    }

    removeCondition = (index) => {
        let condList = this.state.conditions_list;
        if (condList.length > 1) {
            condList.splice(index, 1);
            this.setState({ conditions_list: condList });
        }
    }

    handleChange = (e, index, type) => {
        let condList = this.state.conditions_list;
        let selectedVal = e.target.value;
        if (type === "field") {
            let tmpArrr = [selectedVal];
            let helperTexts = this.state.conditionHelperText;
            if (optionsObjHelperText.hasOwnProperty(selectedVal)) {
                helperTexts[index] = optionsObjHelperText[selectedVal];
            }

            if (defaultEQ.indexOf(selectedVal) !== -1) {
                tmpArrr.push("eq");
                tmpArrr.push("");
            } else if (defaultText.indexOf(selectedVal) !== -1) {
                tmpArrr.push("not_require");
                tmpArrr.push("");
            } else if (defaultBoolian.indexOf(selectedVal) !== -1) {
                tmpArrr.push("not_require");
                tmpArrr.push("true");
            } else {
                tmpArrr.push("not_require");
                tmpArrr.push("not_require");
            }
            condList.splice(index, 1, tmpArrr);
            this.setState({ conditions_list: condList, conditionHelperText: helperTexts });
        } else if (type === "operater") {
            let oldData = condList[index];
            oldData[1] = selectedVal;
            condList.splice(index, 1, oldData);
            this.setState({ conditions_list: condList });
        } else if (type === "value") {
            let oldData = condList[index];
            oldData[2] = selectedVal;
            condList.splice(index, 1, oldData);
            this.setState({ conditions_list: condList });
        }
    }

    validateConditionValue = (e, index) => {
        let errorList = this.state.error_conditions;
        if (e.target.value === "") {
            errorList[index] = 'Please enter a value';
        } else {
            errorList[index] = null;
        }
        this.setState({ error_conditions: errorList });
    }

    /* Condition functions End */

    /* Actions functions Start */

    addNewAction = (index) => {
        let actionList = this.state.actions_list;
        let pos = index + 1;
        actionList.splice(pos, 0, [""]);
        this.setState({ actions_list: actionList });
    }

    removeAction = (index) => {
        let actionList = this.state.actions_list;
        if (actionList.length > 1) {
            actionList.splice(index, 1);
            this.setState({ actions_list: actionList });
        }
    }

    handleActionChange = (e, index, type) => {
        let actionList = this.state.actions_list;
        let selectedVal = e.target.value;
        if (type === "field") {
            let tmpArrr = [selectedVal];
            let opPlaceHolders = this.state.actionOpPH;
            let actionValuePHs = this.state.actionValuePH;
            if (actionOperaterPH.hasOwnProperty(selectedVal)) {
                opPlaceHolders[index] = actionOperaterPH[selectedVal];
            }

            if (actionValuePH.hasOwnProperty(selectedVal)) {
                actionValuePHs[index] = actionValuePH[selectedVal];
            }

            if (selectedVal === "sendMessageToVisitor") {
                tmpArrr.push("Customer Support");
                tmpArrr.push("");
                tmpArrr.push("widget");
            } else {
                tmpArrr.push("");
                tmpArrr.push("not_require");
            }
            actionList.splice(index, 1, tmpArrr);
            this.setState({ actions_list: actionList, actionOpPH: opPlaceHolders, actionValuePH: actionValuePHs });
        } else if (type === "operater") {
            let oldData = actionList[index];
            oldData[1] = selectedVal;
            actionList.splice(index, 1, oldData);
            this.setState({ actions_list: actionList });
        } else if (type === "value") {
            let oldData = actionList[index];
            oldData[2] = selectedVal;
            actionList.splice(index, 1, oldData);
            this.setState({ actions_list: actionList });
        } else if (type === "location") {
            let oldData = actionList[index];
            oldData[3] = selectedVal;
            actionList.splice(index, 1, oldData);
            this.setState({ actions_list: actionList });
        }
    }

    validateActionOpValue = (e, index, type) => {
        if (type === "operater") {
            let errorList = this.state.error_op_actions;
            if (e.target.value === "") {
                errorList[index] = 'Please enter a value';
            } else {
                errorList[index] = null;
            }
            this.setState({ error_op_actions: errorList });
        } else if (type === "value") {
            let errorList = this.state.error_val_actions;
            if (e.target.value === "") {
                errorList[index] = 'Please enter a value';
            } else {
                errorList[index] = null;
            }
            this.setState({ error_val_actions: errorList });
        }
    }

    /* Actions functions End */

    handleSubmit = async () => {
        let isValidForm = this.validateForm();
        if (isValidForm) {
            this.setState({ loader: true });
            if (this.state.status) {
               
            }
            var serverTimestamp = await getDateInUTC();
            if (this.state.show_form === "edit" && this.state.trigger_id !== "") {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('triggers').doc(this.state.trigger_id).set({
                    status: this.state.status,
                    trigger_name: this.state.trigger_name,
                    one_per_visitor: this.state.one_per_visitor,
                    description: this.state.description,
                    type: this.state.trigger_type,
                    trigger_event: this.state.trigger_event,
                    condition_operater: this.state.condition_operater,
                    conditions_list: JSON.stringify(this.state.conditions_list),
                    actions_list: JSON.stringify(this.state.actions_list),
                    updatedon: serverTimestamp
                }).then(res => {
                    this.setState({ show_form: "list" });
                    this.componentDidMount();
                });
            } else {
                const docId = 'TRIGGER' + serverTimestamp + Math.ceil(Math.random() * 999999);
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('triggers').doc(docId).set({
                    addedon: serverTimestamp,
                    status: this.state.status,
                    trigger_name: this.state.trigger_name,
                    one_per_visitor: this.state.one_per_visitor,
                    description: this.state.description,
                    type: this.state.trigger_type,
                    trigger_event: this.state.trigger_event,
                    condition_operater: this.state.condition_operater,
                    conditions_list: JSON.stringify(this.state.conditions_list),
                    actions_list: JSON.stringify(this.state.actions_list),
                    updatedon: serverTimestamp
                }).then(res => {
                    this.setState({ show_form: "list" });
                    this.componentDidMount();
                });
            }
        }
    }

    validateForm = () => {
        let error = false;
        let upObj = {};
        upObj.error_con_fields = [];
        upObj.error_actions = [];
        upObj.error_conditions = [];
        upObj.error_val_actions = [];
        upObj.error_op_actions = [];
        upObj.error_location_actions = [];
        if (this.state.trigger_name.trim() === "") {
            error = true;
            upObj.error_trigger_name = "Please enter name!";
        } else {
            upObj.error_trigger_name = "";
        }

        if (this.state.description.trim() === "") {
            error = true;
            upObj.error_description = "Please enter description!";
        } else {
            upObj.error_description = "";
        }

        let conditions_list = this.state.conditions_list;
        let actions_list = this.state.actions_list;

        conditions_list.forEach((valArr, i) => {
            if (valArr.length > 0) {
                valArr.forEach((valueInArr, k) => {
                    if (k === 2 && valueInArr.trim() === "") {
                        error = true;
                        upObj.error_conditions[i] = "Please enter value!";
                    }
                });
            } else {
                error = true;
                upObj.error_con_fields[i] = "Please select condition!";
            }
        });

        actions_list.forEach((valArr2, j) => {
            if (valArr2.length === 1 && valArr2[0] === "") {
                error = true;
                upObj.error_actions[j] = "Please select action!";
            } else {
                valArr2.forEach((valueInArr2, nn) => {
                    if (nn === 1 && valueInArr2.trim() === "") {
                        error = true;
                        upObj.error_op_actions[j] = "Please enter value!";
                    }
                    if (nn === 2 && valueInArr2.trim() === "") {
                        error = true;
                        upObj.error_val_actions[j] = "Please enter value!";
                    }
                    if (this.state.trigger_event === "page_enter" && valArr2[0] === "sendMessageToVisitor" && nn === 3 && valueInArr2.trim() === "") {
                        error = true;
                        upObj.error_location_actions[j] = "Please choose one!";
                    }
                });
            }
        });

        this.setState(upObj);
        return !error;
    }

    deleteTriggers = () => {
        let chkList = this.state.checkedBoxesList;
        if (chkList.length > 0) {
            this.setState({ loader: true });
            let counter = 0;
            chkList.forEach(triggerId => {
                var tmpTrigger = this.state.triggerList.reduce(function (all, item, index) {
                    if (item.id === triggerId) {
                        all = item;
                    }
                    return all;
                }, {});

                if (Object.keys(tmpTrigger).length > 0 && tmpTrigger.type === "global") {
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('triggers').doc(triggerId).update({ is_deleted: true, status: false }).then(res => {
                        counter++;
                    });
                } else {
                    firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('triggers').doc(triggerId).delete().then(res => {
                        counter++;
                    });
                }
            });
            let delInterval = setInterval(() => {
                if (counter === chkList.length) {
                    clearInterval(delInterval);
                    this.setState({ checkedBoxesList: [] }, () => {
                        this.componentDidMount();
                    });
                }
            }, 200);
        }
    }

    handleAllCheck = (e) => {
        let chkList = this.state.checkedBoxesList;
        let triggerList = this.state.displayTriggerList;
        if (e.target.checked) {
            triggerList.forEach(trgData => {
                if (chkList.indexOf(trgData.id) === -1) {
                    chkList.push(trgData.id);
                }
            });
        } else {
            chkList = [];
        }
        this.setState({ checkedBoxesList: chkList, checkall: chkList.length > 0 ? true : false });
    }

    handleSingleCheck = (e) => {
        let triggerId = e.target.value;
        let chkList = this.state.checkedBoxesList;
        if (e.target.checked) {
            chkList.push(triggerId);
        } else {
            chkList.splice(chkList.indexOf(triggerId), 1);
        }
        this.setState({ checkedBoxesList: chkList, checkall: (this.state.displayTriggerList.length === chkList.length) ? true : false });
    }

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    searchTrigger = () => {
        let tList = this.state.triggerList;
        let tmpTriggerList = [];

        if ((this.state.search_trigger).trim() !== "") {
            tList.forEach(tData => {
                if (tData.trigger_name.toLowerCase().search(this.state.search_trigger.toLowerCase()) !== -1 || tData.description.toLowerCase().search(this.state.search_trigger.toLowerCase()) !== -1) {
                    tmpTriggerList.push(tData);
                }
            });
        } else {
            tmpTriggerList = tList;
        }
        this.setState({ displayTriggerList: tmpTriggerList, checkedBoxesList: [], checkall: false });
    }

    handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.searchTrigger();
        }
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue');
    }

    /* Onboarding Guide Start */

    getGuide = () => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("triggers").get().then((getResult) => {
            if (getResult.exists) {
                let _dataResult = getResult.data();
                this.setState({
                    guide: _dataResult.status,
                    guide_step: _dataResult.step ? _dataResult.step : 0
                });
            } else {
                firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("triggers").set({
                    status: false,
                    step: 0
                });
                this.setState({
                    guide: false,
                    guide_step: 0
                });
            }
        })
    }

    endGuideTour = (context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("triggers").update({
            status: true
        })
        this.setState({
            [context]: true
        })
    }

    guidenextStep = (step, context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("triggers").update({
            step: step,
        })
        this.setState({
            [context]: step
        })
    }

    /* Onboarding Guide End */

    render() {
        let fulView = "full-view";
        if (this.state.checkedBoxesList.length > 0) {
            fulView = "";
        }

        return (
            <>
                <ReactTooltip />
                <LeftPanel />
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                    <h3 className="heding-text">Triggers</h3>
                    <div className="text-center k-flex align-items-center ml-auto">
                        <button className="btnBlueSettings mr-2 py-2 px-6 d-none">Save changes</button>
                        <button className="btnBorderSettings mr-2 py-2 px-4 d-none">Cancel</button>
                    </div>
                    <div className="mobile-menu" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>

                <div className={`page-wrapper sidebar-collapse`} data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        {(this.state.show_form === 'add') && (<div className="breadcrumbs">
                            <p className="pt-6">
                                <img src={require("../../../assets/img/icon/home-icon.svg")} style={{ 'marginTop': '-4px' }} /><span className="link-style text-underline pl-3" onClick={() => this.setState({ show_form: 'list' })}>Triggers</span>
                                {this.state.show_form === 'add' && (<> &gt; Add Triggers</>)}
                            </p>
                        </div>)}

                        <div className="chatform-Preview">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                        <div className="main-heading main-heading-title py-5">
                                            <h3 className="heding-text">Triggers <span className="info-icon">
                                                <img src={require('../../../assets/img/icon/info.png')} data-tip="Set parameters to create and trigger responses" />
                                            </span></h3>
                                        </div>

                                        {this.state.show_form === 'list' && (<div className="bg-white left-widgetsetings-box mb-foter-Fixed triggers-design">
                                            <div className="setting-section idle-section pb-1">
                                                <div className="mx-8 mob-mx-4 py-5 addTextArea">

                                                    <div className="agent-table mt-8">
                                                        <div className="agent-table-header row mb-4 user-table-main-header mobShow">
                                                            <div className={`pr-0 user-table-live search-bar material-input mb-5 ${fulView}`}>
                                                                <div className='search-div'>
                                                                    <TextField className="form-control form-control-lg" id="search_trigger" label="Search" variant="outlined" name="search_trigger" value={this.state.search_trigger} onChange={this.handleSearch} onKeyUp={this.handleKeyUp} />
                                                                    <div className="input-group-append">
                                                                        <button onClick={this.searchTrigger} className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="button">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                                                <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {this.state.checkedBoxesList.length > 0 && (<button className="btnBlueSettings ml-3" onClick={this.deleteTriggers}><span>Delete Selected</span></button>)}
                                                            </div>

                                                            {/* {this.state.checkedBoxesList.length > 0 && (<div className="fltr-view">
                                                                <div className="ml-5">
                                                                    <div className="aside-agent">
                                                                        <button className="btnBlueSettings" onClick={this.deleteTriggers}><span>Delete Selected</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>)} */}

                                                            <div className="fltr-view">
                                                                <div className="aside-agent k-flex align-items-center justify-content-end">
                                                                    <div className="aside-agent">
                                                                        <button className="btnBlueSettings" onClick={this.addTrigger}>+ <span>Add Triggers</span></button>
                                                                        {/* {this.state.checkedBoxesList.length > 0 && ( <button className="btnBlueSettings ml-3" onClick={this.deleteTriggers}><span>Delete Selected</span></button>)} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <table className="table table-borderless mb-0 liveCgatUserTable">
                                                            <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                                <tr>
                                                                    <th width="50">
                                                                        <span className="main-checkbox-all triggers-check">
                                                                            <label className="containerCheckBox containerafterchecked">
                                                                                <input type="checkbox" name="checkall" checked={this.state.checkall} onChange={this.handleAllCheck} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </span>
                                                                    </th>
                                                                    <th width="200">Name</th>
                                                                    <th>Description </th>
                                                                    <th width="100">Enabled</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="outline-1-black">
                                                                {this.state.displayTriggerList.length === 0 && (<tr>
                                                                    <td colSpan="4" style={{ textAlign: "center" }}>No triggers found.</td>
                                                                </tr>)}
                                                                {this.state.displayTriggerList.map((triggerData, indx) => {
                                                                    return <tr key={triggerData.id}>
                                                                        <td>
                                                                            <span className="main-checkbox-all triggersTop-0">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input checked={(this.state.checkedBoxesList.indexOf(triggerData.id) !== -1) ? true : false} type="checkbox" name="chktrigger" value={triggerData.id} onChange={this.handleSingleCheck} />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ cursor: "pointer" }} onClick={() => this.editTrigger(triggerData)}>{triggerData.trigger_name}</td>
                                                                        <td>{triggerData.description}</td>
                                                                        <td>{triggerData.status ? (<span className="checkIcon"></span>) : (null)}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}

                                        {/* html form section design start */}

                                        {(this.state.show_form === 'add' || this.state.show_form === 'edit') ? (<div className="bg-white left-widgetsetings-box mb-foter-Fixed triggers-design">
                                            {!this.state.guide && this.state.guide_step < 8 && (<div className="overlay-guide"></div>)}
                                            <div className="setting-section idle-section pb-1">
                                                <div className="mx-8 mob-mx-4 py-7 border-bottom mb-5">
                                                    <div className="d-flex align-items-center">
                                                        <strong>Trigger Status</strong>
                                                        <div className="material-switch pull-right pl-10">
                                                            <input id="status" name="status" type="checkbox" value={this.state.status} onChange={(e) => this.setState({ status: e.target.checked })} checked={this.state.status} />
                                                            <label htmlFor="status" className="label-default"></label>
                                                            <span className="pl-4">Enable trigger</span>
                                                        </div>
                                                    </div>
                                                    <div className="material-select mt-5 position-relative position-top">
                                                        <TextField className={!this.state.guide && this.state.guide_step === 0 ? "pad-0 w-100 upper-div" : "pad-0 w-100"} id="trigger_name" label="Name" variant="outlined" name="trigger_name" value={this.state.trigger_name} onChange={(e) => this.setState({ trigger_name: e.target.value })} error={this.state.error_trigger_name ? true : false} helperText={this.state.error_trigger_name ? this.state.error_trigger_name : ""} />
                                                        {!this.state.guide && this.state.guide_step === 0 && (<div className="tour_guide">
                                                            <p className="title">Step 1 of 8</p>
                                                            <p className="text-center">Enter the name of the trigger</p>
                                                            <button className="btn-next" onClick={() => { this.guidenextStep(1, 'guide_step') }}>Next</button>
                                                            <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                        </div>)}
                                                    </div>
                                                    <div className="material-select mt-8 position-relative top-area bg-area position-top">
                                                        <TextField className={!this.state.guide && this.state.guide_step === 1 ? "pad-0 w-100 upper-div" : "pad-0 w-100"} rows={2} label="Description" name="description" variant="outlined" value={this.state.description} multiline onChange={(e) => this.setState({ description: e.target.value })} error={this.state.error_description ? true : false} helperText={this.state.error_description ? this.state.error_description : ""} />
                                                        {!this.state.guide && this.state.guide_step === 1 && (<div className="tour_guide">
                                                            <p className="title">Step 2 of 8</p>
                                                            <p className="text-center">Enter a brief description of the trigger</p>
                                                            <button className="btn-next" onClick={() => { this.guidenextStep(2, 'guide_step') }}>Next</button>
                                                            <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                        </div>)}
                                                    </div>
                                                    <div className="material-select mt-8 d-flex align-items-center">
                                                        <span><strong>Fire only once per visitor</strong></span>
                                                        <span className="main-checkbox-all triggersTop-0 pl-6">
                                                            <label className="containerCheckBox containerafterchecked mb-0">
                                                                <input type="checkbox" name="one_per_visitor" id="one_per_visitor" value={this.state.one_per_visitor} onChange={(e) => this.setState({ one_per_visitor: e.target.checked })} checked={this.state.one_per_visitor} />
                                                                <span className="checkmark"></span>
                                                                Each visitor will receive this messages only once
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mx-8 mob-mx-4 py-3 py-4">
                                                    <strong>Customise your Trigger</strong>
                                                    <div className="row mt-2">
                                                        <div className="col-md-6">
                                                            <div className="material-select mt-3 position-relative bg-area position-top">
                                                                {!this.state.guide && this.state.guide_step === 2 && (<div className="tour_guide">
                                                                    <p className="title">Step 3 of 8</p>
                                                                    <p className="text-center">You can select the flags to initiate the trigger response</p>
                                                                    <button className="btn-next" onClick={() => { this.guidenextStep(3, 'guide_step') }}>Next</button>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                <FormControl variant="outlined" className={!this.state.guide && this.state.guide_step === 2 ? "material-outlined w-100 upper-div" : "material-outlined w-100"}>
                                                                    <InputLabel id="trigger_event-select-outlined-label">Run Trigger</InputLabel>
                                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="trigger_event-select-outlined-label" id="trigger_event" name="trigger_event" label="Run Trigger" value={this.state.trigger_event} onChange={(e) => this.setState({ trigger_event: e.target.value })}>
                                                                        <MenuItem value="start_chat">When a visitor start a chat</MenuItem>
                                                                        <MenuItem value="page_enter">When a visitor has loaded the chat widget</MenuItem>
                                                                        <MenuItem value="chat_message">When a chat message is sent</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="material-select mt-3 position-relative bg-area position-top">
                                                                {!this.state.guide && this.state.guide_step === 3 && (<div className="tour_guide">
                                                                    <p className="title">Step 4 of 8</p>
                                                                    <p className="text-center">Here you can select the number of conditions that need to be checked</p>
                                                                    <button className="btn-next" onClick={() => { this.guidenextStep(4, 'guide_step') }}>Next</button>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                <FormControl variant="outlined" className={!this.state.guide && this.state.guide_step === 3 ? "material-outlined w-100 upper-div" : "material-outlined w-100"}>
                                                                    <InputLabel id="condition_operater-select-outlined-label">Check Conditions</InputLabel>
                                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="condition_operater-select-outlined-label" id="condition_operater" name="condition_operater" label="Check Conditions" value={this.state.condition_operater} onChange={(e) => this.setState({ condition_operater: e.target.value })}>
                                                                        <MenuItem value="and">Check all of the following conditions</MenuItem>
                                                                        <MenuItem value="or">Check any of the following conditions</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.conditions_list.map((valuArr, index) => {
                                                        return (<div key={"conditionarea_" + index} id={"conditionarea_" + index} className="card-info mt-4">
                                                            <div className="crossAbs">
                                                                <svg onClick={() => this.removeCondition(index)} xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243"><g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)"><g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#2c3049" stroke="#2c3049" strokeWidth="1"><circle cx="9.622" cy="9.622" r="9.622" stroke="none"></circle><circle cx="9.622" cy="9.622" r="9.122" fill="none"></circle></g><path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#fff"></path></g></svg>
                                                            </div>
                                                            <div className="row">
                                                                {!this.state.guide && index === 0 && this.state.guide_step === 4 && (<div className="tour_guide condation-view">
                                                                    <p className="title">Step 5 of 8</p>
                                                                    <p className="text-center">Now select the conditions that need to be met</p>
                                                                    <button className="btn-next" onClick={() => { this.guidenextStep(5, 'guide_step') }}>Next</button>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                {!this.state.guide && index === 0 && this.state.guide_step === 5 && (<div className="tour_guide condation-view add-new">
                                                                    <p className="title">Step 6 of 8</p>
                                                                    <p className="text-center">You can also add a new condition and repeat the process</p>
                                                                    <button className="btn-next" onClick={() => { this.guidenextStep(6, 'guide_step') }}>Next</button>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                <div className="col-md-3">
                                                                    <div className="material-select mt-3 position-relative bg-area position-top">
                                                                        <FormControl variant="outlined" className={!this.state.guide && this.state.guide_step === 4 ? "material-outlined w-100 upper-div" : "material-outlined w-100"}>
                                                                            <InputLabel id={"condition-select-outlined-label-" + index}>Condition</InputLabel>
                                                                            <Select MenuProps={{ disableScrollLock: true }} labelId={"condition-select-outlined-label-" + index} id={"condition_" + index} name={"condition_" + index} label="Condition" onChange={(e) => this.handleChange(e, index, "field")} value={valuArr.length > 0 ? valuArr[0] : ""} error={this.state.error_con_fields.length > 0 && this.state.error_con_fields[index] ? true : false}>
                                                                                <ListSubheader>Date/Time</ListSubheader>
                                                                                <MenuItem value="hour_of_day">Hour of day</MenuItem>
                                                                                <MenuItem value="day_of_week">Day of week</MenuItem>
                                                                                <MenuItem value="still_on_site">Still on site</MenuItem>
                                                                                {/* <MenuItem value="still_on_page">Still on page</MenuItem> */}
                                                                                <ListSubheader>Location of visitor</ListSubheader>
                                                                                <MenuItem value="visitor_ip">Visitor IP</MenuItem>
                                                                                <MenuItem value="visitor_hostname">Visitor host name</MenuItem>
                                                                                <MenuItem value="visitor_city">Visitor city</MenuItem>
                                                                                <MenuItem value="visitor_region">Visitor region</MenuItem>
                                                                                <MenuItem value="visitor_country_code">Visitor country code</MenuItem>
                                                                                <MenuItem value="visitor_country_name">Visitor country name</MenuItem>
                                                                                <ListSubheader>Previous visit information</ListSubheader>
                                                                                <MenuItem value="visitor_previous_chats">Visitor previous chats</MenuItem>
                                                                                {/* <MenuItem value="visitor_previous_visits">Visitor previous visits</MenuItem> */}
                                                                                <ListSubheader>Page information</ListSubheader>
                                                                                <MenuItem value="visitor_page_url">Visitor page URL</MenuItem>
                                                                                <MenuItem value="visitor_page_title">Visitor page title</MenuItem>
                                                                                <ListSubheader>Visitor information</ListSubheader>
                                                                                <MenuItem value="visitor_name">Visitor name</MenuItem>
                                                                                <MenuItem value="visitor_email">Visitor email</MenuItem>
                                                                                {/* <MenuItem value="visitor_search_engine">Visitor search engine</MenuItem> */}
                                                                                <ListSubheader>Software/Computer of visitor</ListSubheader>
                                                                                <MenuItem value="visitor_os">Visitor OS</MenuItem>
                                                                                <MenuItem value="visitor_browser">Visitor browser</MenuItem>
                                                                                <ListSubheader>Chat-related information</ListSubheader>
                                                                                {/* <MenuItem value="visitor_is_chatting">Visitor is chatting</MenuItem>
                                                                                <MenuItem value="visitor_starting_chat">Visitor starting chat</MenuItem> */}
                                                                                <MenuItem value="visitor_served">Visitor served</MenuItem>
                                                                                {/* <ListSubheader>Teams</ListSubheader>
                                                                                <MenuItem value="linked_team">Linked Team</MenuItem>
                                                                                <MenuItem value="team_agent">Team Agent</MenuItem> */}
                                                                            </Select>
                                                                            {this.state.error_con_fields.length > 0 && this.state.error_con_fields[index] && (<FormHelperText style={{ color: "red" }}>{this.state.error_con_fields[index]}</FormHelperText>)}
                                                                        </FormControl>
                                                                    </div>
                                                                    {this.state.conditionHelperText.length > 0 && this.state.conditionHelperText[index] && (<p className="helper-info">{this.state.conditionHelperText[index]}</p>)}
                                                                </div>

                                                                {valuArr.length > 0 && valuArr[1] && (valuArr[1] !== "not_require") && (<div className="col-md-3">
                                                                    <div className="material-select mt-3" id={"operater_" + index}>
                                                                        {valuArr.length > 0 && valuArr[1] && (<FormControl variant="outlined" className="material-outlined w-100">
                                                                            <InputLabel id="action-select-outlined-label">Action</InputLabel>
                                                                            <Select MenuProps={{ disableScrollLock: true }} labelId="action-select-outlined-label" id={"operater_" + index} name={"operater_" + index} label="Action" value={valuArr[1]} onChange={(e) => this.handleChange(e, index, "operater")}>
                                                                                {valuArr[0] && optionsObjj.hasOwnProperty(valuArr[0]) && optionsObjj[valuArr[0]].map((optObj, indx2) => {
                                                                                    return (<MenuItem key={"opt_" + indx2} value={optObj.value}>{optObj.label}</MenuItem>)
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>)}
                                                                    </div>
                                                                </div>)}

                                                                {valuArr.length > 0 && valuArr[2] !== "not_require" && (<>{defaultBoolian.indexOf(valuArr[0]) !== -1 ? (<div className="col-md-3">
                                                                    <div className="material-select mt-3" id={"field_val_" + index}>
                                                                        <FormControl variant="outlined" className="material-outlined w-100">
                                                                            <InputLabel id={"condition_value_" + index}>Value</InputLabel>
                                                                            <Select MenuProps={{ disableScrollLock: true }} labelId={"condition_value_" + index} id={"condition_value_" + index} name={"operater_" + index} label="Value" value={valuArr[2]} onChange={(e) => this.handleChange(e, index, "value")}>
                                                                                {valuArr[2] && optionsObjj.hasOwnProperty(valuArr[0]) && optionsObjj[valuArr[0]].map((optObj2, indx3) => {
                                                                                    return (<MenuItem key={"value_" + indx3} value={optObj2.value}>{optObj2.label}</MenuItem>)
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>) : (<div className="col-md-3">
                                                                    <div className="material-select mt-3" id={"field_val_" + index}>
                                                                        <TextField className="pad-0 w-100" id={"condition_value_" + index} label="Value" variant="outlined" name={"condition_value_" + index} value={valuArr[2]} onChange={(e) => this.handleChange(e, index, "value")} onBlur={(e) => this.validateConditionValue(e, index)} error={(this.state.error_conditions.length > 0 && this.state.error_conditions[index]) ? true : false} helperText={(this.state.error_conditions.length > 0 && this.state.error_conditions[index]) ? this.state.error_conditions[index] : ""} />
                                                                    </div>
                                                                </div>)}</>)}

                                                                <div className={(valuArr.length > 0 && valuArr[0] && (valuArr[0] === "still_on_site" || valuArr[0] === "still_on_page" || defaultBoolian.indexOf(valuArr[0]) !== -1)) ? "col-md-6 bg-area position-top" : (valuArr.length === 0) ? "col-md-9 bg-area position-top" : "col-md-3 bg-area position-top"}>
                                                                    <a className={!this.state.guide && index === 0 && this.state.guide_step === 5 ? "link-style upper-div" : "link-style"} onClick={() => this.addNewCondition(index)}>Add new condition</a>
                                                                </div>
                                                            </div>
                                                        </div>);
                                                    })}
                                                </div>
                                                <div className="mx-8 mob-mx-4 py-7">
                                                    <strong>Perform the following actions</strong>
                                                    {this.state.actions_list.map((valuArr2, index2) => {
                                                        return (<div className="card-info mt-4 following-actions" id={"acttt_" + index2} key={"acttt_" + index2}>
                                                            <div className="crossAbs">
                                                                <svg onClick={() => this.removeAction(index2)} xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243"><g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)"><g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#2c3049" stroke="#2c3049" strokeWidth="1"><circle cx="9.622" cy="9.622" r="9.622" stroke="none"></circle><circle cx="9.622" cy="9.622" r="9.122" fill="none"></circle></g><path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#fff"></path></g></svg>
                                                            </div>
                                                            <div className="row">
                                                                {!this.state.guide && index2 === 0 && this.state.guide_step === 6 && (<div className="tour_guide condation-view">
                                                                    <p className="title">Step 7 of 8</p>
                                                                    <p className="text-center">Finally select the actions that need to be performed when the conditions are fulfilled</p>
                                                                    <button className="btn-next" onClick={() => { this.guidenextStep(7, 'guide_step') }}>Next</button>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                {!this.state.guide && index2 === 0 && this.state.guide_step === 7 && (<div className="tour_guide condation-view add-new">
                                                                    <p className="title">Step 8 of 8</p>
                                                                    <p className="text-center">You can also add a new action and repeat the process</p>
                                                                    <button className="end-btn" onClick={() => { this.endGuideTour('guide') }}>End tour</button>
                                                                </div>)}
                                                                <div className="col-md-3">
                                                                    <div className="material-select mt-3 bg-area position-top">
                                                                        <FormControl variant="outlined" className={!this.state.guide && this.state.guide_step === 6 ? "material-outlined w-100 upper-div" : "material-outlined w-100"}>
                                                                            <InputLabel id="action-select-outlined-label">Action</InputLabel>
                                                                            <Select MenuProps={{ disableScrollLock: true }} labelId="action-select-outlined-label" id="action" name="action" label="Action" onChange={(e) => this.handleActionChange(e, index2, "field")} value={valuArr2.length > 0 ? valuArr2[0] : ""} error={this.state.error_actions.length > 0 && this.state.error_actions[index2] ? true : false}>
                                                                                <MenuItem value="sendMessageToVisitor">Send message to visitor</MenuItem>
                                                                                <MenuItem value="wait">Wait</MenuItem>
                                                                                <MenuItem value="setName">Set name of visitor</MenuItem>
                                                                                <MenuItem value="blockVisitor">Block visitor</MenuItem>
                                                                                <MenuItem value="endChat">End chat</MenuItem>
                                                                                {/* <MenuItem value="addTag">Add tag</MenuItem>
                                                                                <MenuItem value="removeTag">Remove tag</MenuItem>
                                                                                <MenuItem value="setNote">Replace note (shows on dashboard)</MenuItem>
                                                                                <MenuItem value="appendNote">Append note (shows on dashboard)</MenuItem> */}
                                                                            </Select>
                                                                            {this.state.error_actions.length > 0 && this.state.error_actions[index2] && (<FormHelperText style={{ color: "red" }}>{this.state.error_actions[index2]}</FormHelperText>)}
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                {valuArr2.length > 0 && valuArr2[1] !== undefined && valuArr2[1] !== "not_require" && (<div className="col-md-3">
                                                                    <div className="material-select mt-3">
                                                                        <TextField className="pad-0 w-100" id={"action_op_" + index2} label={(this.state.actionOpPH.length > 0 && this.state.actionOpPH[index2]) ? this.state.actionOpPH[index2] : ""} variant="outlined" name={"action_op_" + index2} value={valuArr2[1]} onChange={(e) => this.handleActionChange(e, index2, "operater")} onBlur={(e) => this.validateActionOpValue(e, index2, "operater")} placeholder={(this.state.actionOpPH.length > 0 && this.state.actionOpPH[index2]) ? this.state.actionOpPH[index2] : ""} error={(this.state.error_op_actions.length > 0 && this.state.error_op_actions[index2]) ? true : false} helperText={(this.state.error_op_actions.length > 0 && this.state.error_op_actions[index2]) ? this.state.error_op_actions[index2] : ""} />
                                                                    </div>
                                                                </div>)}
                                                                {this.state.trigger_event === "page_enter" && valuArr2[0] === "sendMessageToVisitor" && (<div className='col-md-4'>
                                                                    <div className="col-md-12">
                                                                        <span className="main-checkbox-all"><label className="containerRadio containerafterchecked font-12 mt-4"> Show pop-up message with widget
                                                                            <input type="radio" checked={valuArr2[3] === "popup" ? true : false} error={(this.state.error_location_actions.length > 0 && this.state.error_location_actions[index2]) ? "true" : "false"} name={"message_location_" + index2} value="popup" onChange={(e) => this.handleActionChange(e, index2, "location")} />
                                                                            <span className="radioCheckmark" /></label>
                                                                        </span>

                                                                        {/* <input type="radio" checked={valuArr2[3] === "popup" ? true : false} error={(this.state.error_location_actions.length > 0 && this.state.error_location_actions[index2]) ? "true" : "false"} name={"message_location_" + index2} value="popup" onChange={(e) => this.handleActionChange(e, index2, "location")} /> Show pop-up message with widget */}
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <span className="main-checkbox-all"><label className="containerRadio containerafterchecked font-12"> Open chat widget with trigger message
                                                                            <input type="radio" checked={valuArr2[3] === "widget" ? true : false} error={(this.state.error_location_actions.length > 0 && this.state.error_location_actions[index2]) ? "true" : "false"} name={"message_location_" + index2} value="widget" onChange={(e) => this.handleActionChange(e, index2, "location")} />
                                                                            <span className="radioCheckmark" /></label>
                                                                        </span>

                                                                        {/* <input type="radio" checked={valuArr2[3] === "widget" ? true : false} error={(this.state.error_location_actions.length > 0 && this.state.error_location_actions[index2]) ? "true" : "false"} name={"message_location_" + index2} value="widget" onChange={(e) => this.handleActionChange(e, index2, "location")} /> Open chat widget with trigger message */}
                                                                    </div>
                                                                    {(this.state.error_location_actions.length > 0 && this.state.error_location_actions[index2]) ? (<p style={{ color: "red" }}>{this.state.error_location_actions[index2]}</p>) : ""}
                                                                </div>)}

                                                                <div className={valuArr2.length > 0 && ((valuArr2[1] === undefined && valuArr2[2] === undefined) || (valuArr2[1] === "not_require" && valuArr2[2] === "not_require")) ? "col-md-9 bg-area position-top" : (valuArr2[0] === "sendMessageToVisitor" && this.state.trigger_event === "page_enter") ? "col-md-2 bg-area position-top" : "col-md-6 bg-area position-top"}><a className={!this.state.guide && index2 === 0 && this.state.guide_step === 7 ? "link-style upper-div" : "link-style"} onClick={() => this.addNewAction(index2)}>Add new action</a></div>


                                                            </div>
                                                            <div className='row'>
                                                                {valuArr2.length > 0 && valuArr2[2] !== undefined && valuArr2[2] !== "not_require" && (<div className="col-md-12">
                                                                    <div className="material-select mt-3">

                                                                        <TextField className="pad-0 w-100" id={"action_value_" + index2} label="Message" variant="outlined" name={"action_value_" + index2} value={valuArr2[2]} onChange={(e) => this.handleActionChange(e, index2, "value")} onBlur={(e) => this.validateActionOpValue(e, index2, "value")} placeholder="Message" error={(this.state.error_val_actions.length > 0 && this.state.error_val_actions[index2]) ? true : false} helperText={(this.state.error_val_actions.length > 0 && this.state.error_val_actions[index2]) ? this.state.error_val_actions[index2] : ""} multiline rows={2} />

                                                                        {/* <TextField className="pad-0 w-100" id={"action_value_" + index2} label={(this.state.actionValuePH.length > 0 && this.state.actionValuePH[index2]) ? this.state.actionValuePH[index2] : ""} variant="outlined" name={"action_value_" + index2} value={valuArr2[2]} onChange={(e) => this.handleActionChange(e, index2, "value")} onBlur={(e) => this.validateActionOpValue(e, index2, "value")} placeholder={(this.state.actionValuePH.length > 0 && this.state.actionValuePH[index2]) ? this.state.actionValuePH[index2] : ""} error={(this.state.error_val_actions.length > 0 && this.state.error_val_actions[index2]) ? true : false} helperText={(this.state.error_val_actions.length > 0 && this.state.error_val_actions[index2]) ? this.state.error_val_actions[index2] : ""} multiline rows={2} /> */}
                                                                    </div>
                                                                </div>)}
                                                            </div>
                                                        </div>);
                                                    })}
                                                </div>
                                            </div>
                                        </div>) : (null)}
                                        {/* html form section design end */}
                                        {(this.state.show_form === 'add' || this.state.show_form === 'edit') && (<div className="setting-section">
                                            <div className="chat-feilds py-6 px-8 btn-chat-feilds px-5 setting-footer-fixed">
                                                <div className="text-center k-flex align-items-center">
                                                    <button className="btnBlueSettings mr-2" onClick={this.handleSubmit}>Save changes</button>
                                                    <button className="btnBorderSettings mr-2" onClick={() => this.setState({ show_form: "list" })}>Cancel</button>
                                                    {/* <button className="btnRestore ml-auto">Restore Default</button> */}
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Triggers;