import React from 'react';
import ReactTooltip from "react-tooltip";
// import db from '../../../config/firebase';
import { toast } from 'react-toastify';
import { mailUrl } from "../../../config/mailFunctions.js";
import "@lottiefiles/lottie-player";
import Device from 'react-device';
import { MAIL_FUNCTION_URL, DOMAIN_NAME, WIDGET_DOMAIN, PAYMENT_CHECKOUT_URL, currencyData, GATEWAY_URL, FUNCTION_API_KEY, HIDE_TRY_NOW_COUNTRIES, PAYMENT_TRIAL_PERIOD } from '../../../config/siteConfig';
import { activityLog, logActivityInFirestore } from '../../../services/service';
import moment from 'moment-timezone';
import { getDateInUTC } from "../../livechat/components/Comman";
import queryString from 'query-string'

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../../firebase';


var youtubeVideos = {
    'wordpress': "02lzuhgUtNY",
    'shopify': 'U9KyTKUo700',
    'magento': '',
    'wix': 'XsVR7kl09dc',
    'squarespace': 'itjTnH6FomE',
    'weebly': 'AzLo9kw9eDo',
    'godaddy': 'bTBVaGO7fiQ',
    'webflow': ''
};

var docLinks = {
    'wordpress': "https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-wordpress",
    'shopify': 'https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-shopify',
    'magento': 'https://www.appypie.com',
    'wix': 'https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-wix',
    'squarespace': 'https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-squarespace-website',
    'weebly': 'https://www.appypie.com/faqs/how-to-deploy-livechat-s-widget-in-a-website-created-in-weebly',
    'godaddy': 'https://www.appypie.com/faqs/how-to-deploy-livechat-s-widget-in-a-website-created-in-godaddy',
    'webflow': 'https://www.appypie.com'
}


class PaymentForm extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            ownerId: '',
            chatBot: '',
            developerEmail: '',
            instructions: 'We wish to start using Appy Pie Livechat and I want you to install its widget code, enclosed in this email along with instructions, in our website.',
            email_error: '',
            loader: false,
            openEmailBox: false,
            userCollection: '',
            verified: '',
            allDomains: [],
            currentWebsite: '',
            successMessage: false,
            domainCollection: '',
            liveChatUrl: '',
            filePath: window.location.origin + '/lf20_sTumYD.json',
            UA: '',
            myBrowser: '',
            OS: '',
            isDevice: false,
            ownerEmail: '',
            ownername: '',
            IPv4: '',
            agentName: '',
            showType: 1,
            widgetOnCustomWebsite: false,
            selectedCMS: '',
            showTroubleShoot: true,
            setup_guide: true,
            setup_guide_step: 0,
            selectedPlanType: 0,

            plan: '',
            modalVisible: false,
            billing_cycle: '',
            amount: '',
            agents: '',
            chats_completed: '',
            next_billing_date: '',
            billing_information: '',
            plan_data: [],
            loader: false,
            billingList: '',
            no_of_agent: 1,
            requestKey: '',
            errormessage: '',
            currency: '$',
            errormessage: '',
            planprice: '',
            totalprice: '',
            payment_list: [],
            historyCount: 0,
            history_link: '',
            owner_id: '',
            company_name: '',
            owner_email: '',
            current_plan_id: '',
            current_plan_period: '',
            current_agent: 1,
            commonlogin_user_id: '',
            payment_status: '',
            payment_enabled: 0,
            chat_count: 1,
            currencyData: currencyData,
            show_currency: '',
            country_code: 'US',
            plan_list: [],
            planPriceYearly: 84,
            planPriceMonthly: 10,
            planPriceDiscount: 3,
            showGoToDashboard: true,
            totalAgents: 1,
            showTryNowButon: false,
            trialStatus: true,
            countryCode: '',
            originalCountryCode: '',
            currencyCode: ''
        }
        var w = window.innerWidth;
        var h = window.innerHeight;
    }

    async componentDidMount() {
        const urlValues = queryString.parse(window.location.search);

        var body = document.getElementById('root');
        body.classList.add("plan-upgrade")
        if (body) {
            body.addEventListener("click", this.hideTourPopup, false);
        }
        let userDetails = JSON.parse(localStorage.getItem('agent'));

        this.setState({
            ownerId: userDetails.ownerId,
            loader: true,
            liveChatUrl: '/' + userDetails.ownerIdentifire
        }, async () => {
            this.getWidget();
            this.getBotId();
            this.getDomainsList();
            await fetch('https://snappy.appypie.com/webservices/ClientDetails.php', {
                method: "get",
                headers: {
                    Accept: 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.IP) {
                        const showTryNowButon = HIDE_TRY_NOW_COUNTRIES.indexOf(responseJson.CountryCode) > -1 ? false : true;
                        this.setState({ country_code: responseJson.CountryCode, showTryNowButon: showTryNowButon });
                    } else {

                        this.setState({ country_code: 'US' });
                    }
                });
            await this.getOwnerDetails();
            console.log('commonlogin_user_id', this.state.commonlogin_user_id);
            await this.getPlanData();

            await firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('payment_log').get().then((docs) => {
                var payment_data = [];
                var i;
                if (docs.size >= 1) {
                    docs.forEach((doc) => {
                        payment_data.push(doc.data())
                        i++;
                    });
                }
                this.setState({ payment_list: payment_data, historyCount: docs.size });
            })
        })
        this.getVisitorGuide(userDetails);
        this.setState({});
        activityLog("Opened setup page");
    }

    getPlanData = async () => {
        var agentData = JSON.parse(localStorage.getItem('agent'));
        var body = { ownerIdentifire: agentData.ownerIdentifire, email: this.state.owner_email, product_type: 'livechat', userid: this.state.commonlogin_user_id, country_code: this.state.country_code };
        //await fetch(MAIL_FUNCTION_URL + "/payment/plandetail", {
        await fetch(GATEWAY_URL + "/plandetail", {
            method: "post",
            body: JSON.stringify(body),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    var priceData = responseJson.priceData;
                    // alert(priceData.length);
                    if (priceData.length > 0) {
                        var billing_information = '';
                        var planPrice;
                        var agent = parseInt(this.state.no_of_agent);
                        var planPriceYearly = parseInt(priceData[1].planPrice);
                        var planPriceYearlyMonth = parseInt(planPriceYearly / 12);
                        var planPriceMonthly = parseInt(priceData[0].planPrice);
                        var PlanDiscountYear = parseInt(planPriceMonthly - planPriceYearlyMonth);
                        if (this.state.current_plan_period === 'yearly') {
                            let currencySign = priceData[1].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[1].currencySign;
                            billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + currencySign + '_' + priceData[1].planPrice;
                            planPrice = priceData[1].planPrice;
                        } else {
                            let currencySign = priceData[0].currencySign == "<span class='appyicon-indian-rupee'></span>" ? "₹" : priceData[0].currencySign;
                            billing_information = priceData[0].productName + '_' + priceData[0].planId + '_' + priceData[0].planPeriod + '_' + currencySign + '_' + priceData[0].planPrice;
                            planPrice = priceData[0].planPrice;
                        }

                        var netprice = parseInt(planPrice) * parseInt(agent);
                        //  var history_link = PAYMENT_CHECKOUT_URL+"/billing/show/" + responseJson.history_link
                        var planList = [];
                        var optionData;

                        for (const [index, value] of responseJson.priceData.entries()) {
                            optionData = { name: value.planPeriod, keyValue: value.productName + '_' + value.planId + '_' + value.planPeriod + '_' + this.state.currencyData[value.currencyCode] + '_' + value.planPrice };
                            if ((this.state.current_plan_period === 'yearly') && value.planPeriod === 'monthly') {
                                // Do Nothing
                            } else {
                                planList.push(optionData);
                            }
                        }
                        var history_link = responseJson.history_link
                        this.setState({ totalprice: netprice, history_link: history_link, currency: this.state.currencyData[priceData[0].currencyCode], planprice: planPrice, billing_information: billing_information, plan_data: responseJson.priceData, plan_list: planList, planPriceMonthly: planPriceMonthly, planPriceYearly: planPriceYearly, planPriceDiscount: PlanDiscountYear, countryCode: priceData[0].country, originalCountryCode: priceData[0].originalCountryCode, currencyCode: priceData[0].currencyCode });
                    }
                    return true
                } else {
                    return false;
                }
            }).then(() => {
                this.setState({
                    loader: false
                })
                return true;
            });
    }

    hideTourPopup = (e) => {
        let ePath = e.path || (e.composedPath && e.composedPath());
        let hidePopup = true;
        if (ePath) {
            ePath.forEach((path) => {
                if (path.className && (path.className === 'tour_guide' || path.id === 'tour_guide')) {
                    hidePopup = false;
                }
            })
        }
        if (hidePopup === true && this.state.setup_guide === false && this.state.openEmailBox === false && this.state.showType === 2) {
            this.endGuideTour('setup', 'setup_guide');
        }
    }

    getVisitorGuide = (agent2) => {
        firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("setup").get().then((getRsult) => {
            if (getRsult.exists) {
                let _dataResult = getRsult.data();
                this.setState({
                    setup_guide: _dataResult.status,
                    setup_guide_step: _dataResult.step ? _dataResult.step : 0
                });
            }
        })
    }

    getOwnerDetails = async () => {
        var userDetails = this.agent;
        await firebaseServices.db.collection('users').doc(this.state.ownerId).get().then(async (docs) => {
            if (docs.exists) {
                let userData = docs.data();
                let currentUnixTimeStamp = await getDateInUTC();
                var currentStatus = '0';
                var plan_type = 'Free';
                var billing_cycle = '';
                var cost = '';
                var next_billing_date = '';
                var no_of_agents = 1;
                var plan_id = '';
                var user_id = userData.user_id;
                var no_of_agent = 1;
                var owner_email = '';
                var payment_status = '';
                var payment_enabled = this.state.payment_enabled;
                var chat_count = 1;
                var show_currency = '';
                var totalAgents = 1;

                if (userDetails.ownerEmail === 'undefined' || userDetails.ownerEmail === undefined) {
                    owner_email = userData.email;
                }
                else {
                    owner_email = userDetails.ownerEmail;
                }
                if (userData.payment_status === 'undefined' || userData.payment_status === undefined) {
                    currentStatus = '0'
                    payment_status = 'complete'
                    payment_enabled = 0;
                    chat_count = 1;
                    show_currency = '';
                    totalAgents = parseInt(no_of_agents) + 1;
                }
                else {
                    let splitDate = userData.next_billing_date ? userData.next_billing_date.split('-') : '';
                    let splitDay = parseInt(splitDate[2]);
                    var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                    paymentDate = paymentDate + 86400000;

                    var billingDate = new Date(userData.next_billing_date);
                    var dateString = '';
                    if (billingDate.getDate() < 10) {
                        dateString = '0' + billingDate.getDate();
                    }
                    else {
                        dateString = billingDate.getDate();
                    }
                    if (billingDate.getMonth() + 1 < 10) {
                        dateString = dateString + '-0' + (billingDate.getMonth() + 1);
                    }
                    else {
                        dateString = dateString + '-' + (billingDate.getMonth() + 1);
                    }
                    dateString = dateString + '-' + billingDate.getFullYear();
                    if (userData.payment_status === 'complete') {
                        show_currency = this.state.currencyData[userData.currency]
                        if (userData.currency === 'undefined' || userData.currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 0;
                        chat_count = 0;
                        payment_status = userData.payment_status;
                        currentStatus = 1;
                        plan_type = userData.plan_type;
                        billing_cycle = userData.billing_cycle;
                        cost = userData.plan_price;
                        next_billing_date = dateString;
                        no_of_agents = userData.no_of_agents;
                        plan_id = userData.plan_id;
                        user_id = userData.user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = no_of_agent;
                    }
                    else if (paymentDate >= currentUnixTimeStamp) {
                        show_currency = this.state.currencyData[userData.currency]
                        if (userData.currency === 'undefined' || userData.currency === undefined) {
                            show_currency = this.state.currencyData.USD;
                        }
                        payment_enabled = 1;
                        chat_count = 0;
                        payment_status = userData.payment_status;
                        currentStatus = 1;
                        plan_type = userData.plan_type;
                        billing_cycle = userData.billing_cycle;
                        cost = userData.plan_price;
                        next_billing_date = dateString;
                        no_of_agents = userData.no_of_agents;
                        plan_id = userData.plan_id;
                        user_id = userData.user_id;
                        no_of_agent = parseInt(no_of_agents) + 1;
                        totalAgents = no_of_agent;
                    }
                    else {
                        show_currency = '';
                        payment_enabled = 0;
                        chat_count = 1;
                    };
                }
                var no_chat_served = userData.no_chat_served;
                var chat_limited = userData.chat_limited;
                if (userData.no_chat_served === 'undefined' || userData.no_chat_served === undefined) {
                    no_chat_served = 0;
                }

                if (userData.chat_limited === 'undefined' || userData.chat_limited === undefined) {
                    chat_limited = 500;
                }


                // billing_cycle = billing_cycle ? billing_cycle : 'yearly';

                this.setState({
                    payment_enabled: payment_enabled,
                    payment_status: payment_status,
                    owner_id: docs.id,
                    company_name: userData.company_name,
                    owner_email: owner_email,
                    current_plan_id: plan_id,
                    current_agent: parseInt(no_of_agents),
                    current_plan_period: billing_cycle,
                    plan: plan_type,
                    billing_cycle: billing_cycle,
                    amount: cost,
                    agents: no_of_agents,
                    chats_completed: no_chat_served + '/' + chat_limited,
                    commonlogin_user_id: user_id,
                    next_billing_date: next_billing_date,
                    no_of_agent: no_of_agent,
                    chat_count: chat_count,
                    show_currency: show_currency,
                    ownerEmail: userData.email,
                    ownername: userData.company_name,
                    totalAgents: totalAgents,
                    trialStatus: userData.trialStatus === true ? true : false
                });
            }
        })

        firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").where("roleType", '==', 'Owner').get().then((datas) => {
            if (datas.empty === false) {
                let agentName = '';
                datas.forEach((doc) => {
                    agentName = doc.data().name;
                })
                this.setState({
                    agentName: agentName
                })
            }
        }).catch((err) => {
            console.log(err);
        })
        return;
    }

    onChangee = (deviceInfo) => {
        this.setState({
            UA: deviceInfo.ua,
            myBrowser: deviceInfo.browser.name,
            OS: deviceInfo.os.name
        })
    }

    getWidget = () => {
        this.state.userCollection = firebaseServices.db.collection('users').doc(this.state.ownerId).onSnapshot((docs) => {
            if (docs.exists) {
                let data = docs.data();
                this.setState({
                    verified: data.widget_status
                })
                if ((data.verified === undefined || data.verified === false) && data.widget_status === true) {
                    //// Updated By AG Start /////////
                    firebaseServices.db.collection('users').doc(this.state.ownerId).update({ verified: true }).then(() => {
                        let userDetails = JSON.parse(localStorage.getItem('agent'));
                        window.location.href = '/' + userDetails.ownerIdentifire + '/thankyou';
                        return false;
                    });
                    //// Updated By AG End /////////
                }
            }
        })
    }

    getBotId = () => {
        var defBot = firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').where('status', '==', 1).onSnapshot((chDocs) => {
            if (!chDocs.empty) {
                let defaultChatBot = '';
                chDocs.forEach((doc) => {
                    defaultChatBot = doc.id;
                })
                this.setState({
                    chatBot: defaultChatBot,
                    widgestUrl: `<script id="appyWidget" src="${WIDGET_DOMAIN}/widget/build.js?cid=${this.state.ownerId}-${defaultChatBot}"></script>`
                })
                defBot();
            }
        })
    }



    getDomainsList = () => {
        this.state.domainCollection = firebaseServices.db.collection('reports').doc('widget').collection('installation').doc(this.state.ownerId).collection('domains').onSnapshot((domains) => {
            if (!domains.empty) {
                let allDomains = [];
                domains.forEach((domain) => {
                    allDomains.push(domain.data().domain);
                })
                this.setState({
                    allDomains: allDomains
                })
            }
        })
    }

    componentWillUnmount() {
        this.state.userCollection();
        this.state.domainCollection();
        var body = document.getElementById('root');
        body.classList.remove("plan-upgrade")
    }

    copyCode = (e) => {
        var copyText = document.getElementById("myInputCopy");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
        // if (document.selection) { //IE
        //     var range = document.body.createTextRange();
        //     range.moveToElementText(document.getElementById('widgetCode'));
        //     range.select();
        // } else if (window.getSelection) { //others
        //     var range = document.createRange();
        //     range.selectNode(document.getElementById('widgetCode'));
        //     window.getSelection().addRange(range);
        // }
        // var copyText = document.getElementById("widgetCode");

        // /* Select the text field */
        // copyText.select();
        // copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        // /* Copy the text inside the text field */
        // document.execCommand("copy");
        // document.execCommand('copy');
        // window.getSelection().empty();
        toast.success('Code Copied!');
        this.guidenextStep('setup', 1, 'setup_guide_step');
    }

    sendEmail = (body = '', showToast = true) => {
        let mailType = 'widget_setup';
        let email = this.state.developerEmail;
        let instruction = this.state.instructions;
        let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : this.state.ownerEmail;
        let urlToSend = 'id="appyWidget" src="' + WIDGET_DOMAIN + '/widget/build.js?cid=' + this.state.ownerId + '-' + this.state.chatBot + '"';
        if (body === '') {
            body = [`URL=${urlToSend}&&instruction=${instruction}&&email=${email}&&type=${mailType}&&ownername=${this.state.ownername}&&ownerEmail=${ownerEmail}&&ownerAgentName=${this.state.agentName}`];
        }
        fetch(mailUrl + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                this.closeEmailBox();
                this.setState({
                    loader: false
                }, () => {
                    if (showToast) {
                        toast.success('Email has been sent to developer email id');
                    }
                })
            })
            .catch(e => {
                this.setState({
                    loader: false
                });
                console.log(e, "ERROR");
            });
    }

    sendEmailDeveloper = () => {
        let validate = this.validateForm();
        if (this.state.email_error === '' && validate) {
            this.setState({
                loader: true
            })
            this.sendEmail();
            activityLog("Sent instructions to developer");
        }
    }

    validateForm = () => {
        let returnValue = true;
        if (this.state.developerEmail === '') {
            returnValue = false;
            this.setState({
                email_error: 'Email can not be blank'
            })
        }
        return returnValue;
    }

    handleDeveloperMessage = (e) => {
        this.setState({
            instructions: e.target.value
        })
    }

    validateEmail = email => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    onChangeHandler = (e) => {
        let email = e.target.value;
        if (!this.validateEmail(email)) {
            this.setState({
                email_error: 'Incorrect Email',
                developerEmail: email
            })
        } else {
            this.setState({
                email_error: '',
                developerEmail: email
            })
        }
    }

    openEmailBox = () => {
        this.setState({
            openEmailBox: true
        })
        this.guidenextStep('setup', 2, 'setup_guide_step')
    }

    validateURL = (e) => {
        let returnValue = true;
        if (this.state.currentWebsite === '') {
            returnValue = false
            this.setState({
                url_error: 'Please Enter a Valid URL!'
            })
        } else if (this.state.currentWebsite.match(/(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) === null && this.state.currentWebsite.match(/(http(s)?:\/\/.)?(localhost)/g) === null) {
            returnValue = false
            this.setState({
                url_error: 'Please Enter a Valid URL!'
            })
        } else {
            this.setState({
                url_error: ''
            })
        }
        return returnValue;
    }

    verifyWebsite = () => {
        let validate = this.validateURL();
        if (this.state.currentWebsite !== '' && validate) {
            firebaseServices.db.collection('reports').doc('widget').collection('installation').doc(this.state.ownerId).collection('domains').where('domain', '==', this.state.currentWebsite).get().then((docs) => {
                if (docs.empty === true) {
                    let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : this.state.ownerEmail;
                    var emailBody = [
                        `email=${ownerEmail.toLowerCase()}&&type=widget_not_installed&&vip=${this.state.IPv4}&&vsystem=${this.state.UA}&&currnt_url=${DOMAIN_NAME}&&check=` + true
                    ];
                    this.sendEmail(emailBody, false);
                    toast.error('Widget not installed on this domain');
                } else {
                    toast.success('‘Widget has been successfully installed on the mentioned website');
                }
            })
        }
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]: target.value
        })
    }

    closeEmailBox = () => {
        this.setState({
            openEmailBox: false,
            developerEmail: '',
            email_error: '',
            instructions: 'We wish to start using Appy Pie Livechat and I want you to install its widget code, enclosed in this email along with instructions, in our website.'
        })
    }

    hideSuceesMessage = () => {
        /*window.location.href = this.state.liveChatUrl;
        this.setState({
            successMessage:false
        }); */
    }

    endGuideTour = (docId, context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
            status: true
        })
        this.setState({
            [context]: true
        })
    }

    guidenextStep = (docId, step, context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
            step: step,
        })
        this.setState({
            [context]: step
        })
    }

    openWidget = () => {
        var x = document.getElementById('Appypie').contentWindow.document.getElementsByClassName('iconBotdynamic');
        if (x && x[0]) {
            x[0].click();
        }
    }

    openPopup = () => {
        var billingData = this.state.billing_information.split('_');
        var agent = this.state.no_of_agent;
        if (parseInt(this.state.no_of_agent) < parseInt(this.state.current_agent) || this.state.no_of_agent === '') {
            agent = parseInt(this.state.current_agent) + 1;
        }
        var netprice = parseInt(billingData[4]) * parseInt(agent);
        this.setState({
            totalprice: netprice, no_of_agent: agent, modalVisible: true, errormessage: ''
        })
        logActivityInFirestore('Subscription upgrade button clicked', "upgrade_action_btn");
    }

    // handlePaymentChange = (e) => {
    //     let target = e.target;
    //     let values;
    //     if (target.name === "billing_information") {
    //         var billingData = target.value.split('_');
    //         //alert(this.state.no_of_agent);
    //         if (this.state.totalAgents == '') {
    //             var netprice = parseInt(billingData[4]);
    //             this.setState({
    //                 billing_information: target.value, planprice: billingData[4], totalprice: netprice
    //             })
    //         }
    //         else {
    //             var netprice = parseInt(billingData[4]) * parseInt(this.state.no_of_agent);
    //             this.setState({
    //                 billing_information: target.value, planprice: billingData[4], totalprice: netprice, errormessage: ''
    //             })
    //         }
    //     }
    //     else if (target.name === "no_of_agent") {

    //         var charCode = (e.which) ? e.which : e.keyCode;
    //         //alert(charCode);
    //         //alert(e.target.value);
    //         var valueData = target.value.replace(/\+|-/ig, '');
    //         valueData = valueData.replace('+', '');
    //         valueData = valueData.replace('-', '');
    //         valueData = valueData.replace('.', '');
    //         var billingData = this.state.billing_information.split('_');
    //         var multiplyNumber = parseInt(valueData);
    //         var checkNumber = isNaN(valueData);
    //         //alert(checkNumber)
    //         if (checkNumber === true) {
    //             valueData = this.state.no_of_agent;
    //             multiplyNumber = 1;
    //         }
    //         else if (valueData < 1) {
    //             multiplyNumber = 1;

    //         }
    //         var netprice = parseInt(billingData[4]) * parseInt(multiplyNumber);
    //         this.setState({ no_of_agent: valueData, totalprice: netprice, errormessage: '', totalAgents: valueData ? parseInt(valueData) + 1 : 1 });
    //         /*if(( charCode === 'undefined' || charCode === undefined ) && valueData === '' )
    //         {
    //             var netprice = parseInt(billingData[4])*parseInt(1);
    //             this.setState({no_of_agent:valueData,totalprice:netprice});
    //         }
    //         else if(valueData === '' )
    //         {
    //             e.preventDefault();		
    //         }
    //         else
    //         {
    //             var netprice = parseInt(billingData[4])*parseInt(valueData);
    //             this.setState({no_of_agent:valueData,totalprice:netprice});	 
    //         }
    //               */
    //     } else {
    //         values = target.value;
    //     }

    // }

    handleSubmit = async (paymentType = 'pay') => {
        this.setState({ loader: true });
        var no_of_agent = this.state.no_of_agent;
        var billing_information = this.state.billing_information;
        var checkCondition = await this.validateStatusData();
        if (checkCondition === false) {
            return false;
        }
        else {
            var agentData = JSON.parse(localStorage.getItem('agent'));
            var ownerEmail;
            var ownerId;
            var companyName;
            var curentPlanId;
            var currentPlanPeriod;
            
            if (billing_information === undefined || billing_information === ''){
				billing_information = "livechat_6_monthly_$_10";
			}
            var billingData = billing_information.split('_');            

            var uniqueId = Date.now() + ((Math.random() * 100000).toFixed());
            var orderId = 'livechat_' + uniqueId;

            if (curentPlanId === "undefined" || curentPlanId === undefined) {
                curentPlanId = "";
                currentPlanPeriod = "";
            }

            console.log('DOMAIN_NAME >>> ', DOMAIN_NAME);

            /* Added for script chat start */
            let success_url = DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/install-widget?type=success';
            if (paymentType === "pay") {
                success_url += '&ptype=paid&cpId=' + this.state.current_plan_id;
            } else {
                success_url += '&ptype=trial&cpId=' + this.state.current_plan_id;
            }
            if (this.state.plan === "Free") {
                no_of_agent = 0;
            }
            var agentString = "Current no of agent " + no_of_agent;
            var myArray = JSON.stringify({ "1": agentString });
            /* Added for script chat end */
            if (this.state.plan === "Free" && this.agent.payment_method !== "trialWithoutCC" && this.agent.plan_price !== "trialWithoutCC") {
                // logic to give free trial without credit card details
                console.log('------------new url----------->')
                var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","trialSuccessUrl":"' + success_url + '", "trialIpnUrl":"' + GATEWAY_URL + '/subscription","displayAgent":true}';
                }
            }
            else {
                console.log('------------older url----------->')
                var priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + PAYMENT_TRIAL_PERIOD + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';
                if (paymentType === "pay") {
                    priceKey = '{"displayMessage":' + myArray + ',"curentPlanId":"' + this.state.current_plan_id + '","currentPlanPeriod":"' + this.state.current_plan_period + '","orderId":"' + orderId + '","addonType":"' + no_of_agent + '","noOfAgents":"' + no_of_agent + '","productName":"' + billingData[0] + '","planId":"' + billingData[1] + '","planPeriod":"' + billingData[2] + '","emailId":"' + this.state.owner_email + '","productId":"' + this.state.owner_id + '","paymentType":"' + paymentType + '","trialDays":' + 0 + ',"subscriptionType":"subscription","successUrl":"' + success_url + '","cancelUrl":"' + DOMAIN_NAME + '/' + agentData.ownerIdentifire + '/paymentfail","notifyUrl":"' + GATEWAY_URL + '/subscription","resetUrl":"' + success_url + '","currencyCode":"' + this.state.currencyCode + '","countryCode": "' + this.state.countryCode + '","originalCountryCode": "' + this.state.originalCountryCode + '","requestState":"","displayAgent":true}';                
                }
            }

            var netprice = parseInt(billingData[4]) * parseInt(no_of_agent);            
            var requestResponse = await this.getRequestKey({ stringdata: priceKey, action: 'encrypt', no_of_agents: no_of_agent, netprice: netprice, currency: billingData[3], plan_name: 'Premium', plan_id: billingData[1], product_name: billingData[0], plan_period: billingData[2], order_id: orderId, product_id: this.state.owner_id, countryCode: this.state.countryCode, originalCountryCode: this.state.originalCountryCode, currencyCode: this.state.currencyCode });

            if (requestResponse === true) {
              
                logActivityInFirestore('Subscription upgrade plan button clicked', "upgrade_plan_btn");
                var form = document.getElementById('checkout');
                form.submit();
            }
            else {
                return false;

            }
        }
    }

    validateStatusData = () => {
        var checkNumber = isNaN(this.state.totalAgents);

        var billingData = this.state.billing_information.split('_');
        if (checkNumber) {
            this.setState({ loader: false, errormessage: 'Please enter valid no of Agent' });
            return false;
        }
        else if (this.state.totalAgents < 1) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be greater than 0' });
            return false;
        }
        else if (this.state.totalAgents > 999) {
            this.setState({ loader: false, errormessage: 'Please enter valid number of Agent, agent should be less than 1000' });
            return false;
        }
        // else if (parseInt(this.state.totalAgents) <= parseInt(this.state.current_agent)) {
        //     this.setState({ loader: false, errormessage: 'Agent count cannot be less than count of agent for which subscription has already been bought' });
        //     return false;
        // }
        /*else if(( this.state.current_plan_period ===  billingData[2] ) && ( parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent )) )
        {
            this.setState({loader:false,errormessage:'Please plan and no of agent are same, either increase no of agent and change plan period'});	
            return false;
        }*/
        else {
            return true;
        }
    }

    getRequestKey = async (requestData) => {
        //const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
        const response = await fetch(GATEWAY_URL + "/generateKey", {
            method: "post",
            body: JSON.stringify(requestData),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({ requestKey: responseJson.stringdata });
                    return true;
                } else {

                    return false
                }
            });
        return response;
    }

    closeModal = () => this.setState({
        modalVisible: false
    });

    goToDashboard = () => {
        this.props.history.push("/" + this.agent.ownerIdentifire);
    }

    goToTab = (showType) => {
        const urlValues = queryString.parse(window.location.search);
        let urlType = urlValues.type ? urlValues.type : '';
        if (urlType === 'success') {
            this.props.history.push("/" + this.agent.ownerIdentifire + "/install-widget");
            this.setState({
                showType: showType
            })
        } else {
            this.setState({
                showType: showType
            })
        }
    }

    // selectPlanType = (planType) => {
    //     if (this.state.plan_list.length > 1) {
    //         let priceData = this.state.plan_data;
    //         let billing_information = document.getElementById('billing_information').value;
    //         let billArray = billing_information.split('_');
    //         let planPrice;

    //         if (planType === 0) {
    //             billing_information = priceData[1].productName + '_' + priceData[1].planId + '_' + priceData[1].planPeriod + '_' + billArray[3] + '_' + priceData[1].planPrice;
    //             planPrice = priceData[1].planPrice;

    //         } else {
    //             billing_information = priceData[0].productName + '_' + priceData[0].planId + '_' + priceData[0].planPeriod + '_' + billArray[3] + '_' + priceData[0].planPrice;
    //             planPrice = priceData[0].planPrice;
    //         }

    //         if (this.state.no_of_agent == '') {
    //             var netprice = parseInt(planPrice);
    //             this.setState({
    //                 billing_information: billing_information, planprice: planPrice, totalprice: netprice, selectedPlanType: planType
    //             })
    //         }
    //         else {
    //             var netprice = parseInt(planPrice) * parseInt(this.state.no_of_agent);
    //             this.setState({
    //                 billing_information: billing_information, planprice: planPrice, totalprice: netprice, errormessage: '', selectedPlanType: planType
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             selectedPlanType: planType
    //         })
    //     }
    // }

    render() {
        let widgetInstalledMessage;
        if (this.state.verified === true) {
            widgetInstalledMessage = <span className="pl-4">
                <img alt="" src={require('../../../assets/img/icon/checked.png')} className="widgetWarningImage" />
                <span className="widgetsucess">Widget installed successfully</span>
            </span>
        } else if (this.state.verified === false) {
            widgetInstalledMessage = <span className="pl-4">
                <img alt="" src={require('../../../assets/images/danger.png')} className="widgetWarningImage" />
                <span className="widgetWarning">Widget hasn’t been implemented</span>
            </span>
        }

        let widgetMessages;
        if (this.state.verified === true) {
            widgetMessages = <span className="k-flex" style={{ alignItems: 'center' }}>
                <img alt="" src={require('../../../assets/img/icon/checked.png')} className="widgetWarningImage" />
                <span className="pl-3">Widget has been successfully installed on the mentioned website</span>
            </span>
        } else if (this.state.verified === false) {
            widgetMessages = <span>
                <img alt="" src={require('../../../assets/images/danger.png')} className="widgetWarningImage" />
                <span className="widgetWarning no-border">Widget hasn’t been implemented</span>
            </span>
        }

        let currentSelectedCMS = this.state.selectedCMS ? this.state.selectedCMS : "wordpress";
        let currentSelectedVideo = youtubeVideos[currentSelectedCMS] ? "https://www.youtube.com/embed/" + youtubeVideos[currentSelectedCMS] : "";
        let currentSelectedDoc = docLinks[currentSelectedCMS];

        let onOwnClass;
        let takehelpClass;
        if (this.state.showType === 1 || this.state.showType === 5) {
            takehelpClass = 'active';
            onOwnClass = '';
        } else {
            takehelpClass = '';
            onOwnClass = 'active';
        }
        return (
            <>

                <Device onChange={this.onChangee} />
                <ReactTooltip />
                <form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
                    <input type="hidden" name="request" value={this.state.requestKey} />
                </form>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>

                <div className="popupShadow-upgradePlan" style={{ display: 'block' }}></div>

                <div className="popupInstallLivechat popupLivechat planPopup" style={{ display: 'block' }}>
                    <div className="free_user">
                        <a className="close_icon"></a>
                        <img src={require('../../../assets/img/icon/inactive-chatbot.svg')} alt="inactive chatbot" />
                        <h4 className="mt-4">Your Livechat is inactive!</h4>
                        <p className="mb-5">
                            BuyNow today to edit the Livechat to your liking, and deploy it on your website or app.
                        </p>
                        <div className={this.state.showTryNowButon && !this.state.trialStatus ? "my-4 try-now" : "my-4"}>
                            <button onClick={this.handleSubmit} className="btnBlueSettings">Buy Now</button>
                            {this.state.showTryNowButon && !this.state.trialStatus ? (<button onClick={() => { this.handleSubmit('trial') }} className="btnBlueSettings add-agent-clik">Try Now</button>) : (null)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PaymentForm;