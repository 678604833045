import React from 'react';
import { getCookie, getUserSession, ownerCommongLogin, setCookies, MFAVerify } from "../../services/service";
import { MAIL_FUNCTION_URL, FUNCTION_API_KEY, GATEWAY_URL } from '../../config/siteConfig';
import { toast, ToastContainer } from 'react-toastify';
import qs from 'query-string';
import TextField from '@material-ui/core/TextField';

class Authorize extends React.Component {

    loginInterval = null;

    constructor() {
        super();
        //this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            loader: false,
            email: "",
            password: "",
            error: "",
            error_data: "",
            error_email: "",
            error_password: "",
            response_type: "",
            client_id: "",
            redirect_uri: "",
            isMFAEnabled:false,
            cookiesList: null,
            mfa_otp:"",
            error_mfa_otp:""
        }
    }

    redirectOnSuccess = (email) => {
        var body = [`email=${encodeURIComponent(email)}&client_id=${this.state.client_id}&response_type=${this.state.response_type}`];
        fetch(MAIL_FUNCTION_URL + "/oauth/gettoken", {
            //fetch(GATEWAY_URL + "/oauth/gettoken", {
            method: "POST",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(response => {
                if (response.status === "success") {
                    window.location.href = this.state.redirect_uri + "?error=false&token=" + response.token;
                    return false;
                } else {
                    window.location.href = this.state.redirect_uri + "?error=true&message=Please check your data";
                    return false;
                }
            }).catch(error => {
                this.setState({ loader: false });
                toast.error(error.message);
            });
    }

    getSessionCheck = (loginType = 'both') => {
        return false;
        return new Promise( resolve => {
            getUserSession(loginType).then((results) => {
                if(results && results.status_msg === 200 && results.status === 200) {
                    let resultUserData = results.userdata !== '' ? JSON.parse(results.userdata) : false;
                    if(resultUserData && resultUserData.login_status === 'yes' && resultUserData.user_type === 'owner') {
                        resolve({ status: 'success', data : resultUserData.userdata });
                    } else {
                        resolve({ status: 'failed', data : null });
                    }
                } else {
                    resolve({ status: 'failed', data : null });
                }
            });
        });
    }

    callGetSession = async () => {
        return false;
        var loginStatus = 0;
        var loginType = "owner";
        loginStatus = getCookie('79fefaf2564aabb19d44fe324844c86b');
        if (parseInt(loginStatus) === 1) {
            let loginData = await this.getSessionCheck(loginType);
            if(loginData.status === "success") {
                this.setState({ loader: true });
                try {
                    clearInterval(this.loginInterval); 
                } catch(err) {}
                let ownerData = loginData.data;
                /* let company_name = ownerData.company;
                let company_identifier = ownerData.company_identifier;
                let first_name = ownerData.first_name;
                let login_type = ownerData.login_type; */
                let email = ownerData.email;
                let apps_access = ownerData.apps_access;
                console.log("---------------     apps_access     -----------------");
                console.log(apps_access);
                var livechatAccess=false;
                if(apps_access && apps_access.length > 0) {
                    apps_access.forEach(accss => {
                        if(accss.product === "livechat") {
                            livechatAccess = true;
                        }
                    });
                }

                if(livechatAccess) {
                    this.redirectOnSuccess(email);
                } else {
                    this.setState({ loader: false });
                    toast.error("You did not complete onboarding process in livechat yet!");
                    setTimeout(() => {
                        window.location.href = '/company-info';
                        return false;
                    }, 5000);
                }
            }
        }
    }

    componentDidMount() {
        const urlValues = qs.parse(window.location.search);
        let response_type = urlValues.response_type ? urlValues.response_type : false;
        let client_id = urlValues.client_id ? urlValues.client_id : false;
        let redirect_uri = urlValues.redirect_uri ? urlValues.redirect_uri : false;

        if (!response_type) {
            this.setState({
                error_data: "Response type is missing!"
            });
            return false;
        } else if (response_type && response_type !== "token") {
            this.setState({
                error_data: "Response type is wrong!"
            });
            return false;
        }

        if (!client_id) {
            this.setState({
                error_data: "Client id is missing!"
            });
            return false;
        } else if (client_id && client_id.length < 40) {
            this.setState({
                error_data: "Client id must be minimum 40 characters!"
            });
            return false;
        }

        if (!redirect_uri) {
            this.setState({
                error_data: "Redirect uri is missing!"
            });
            return false;
        }

        this.setState({
            response_type: response_type,
            client_id: client_id,
            redirect_uri: redirect_uri
        }, () => {
            /* For Auto Login Start */
            /* try {
                this.callGetSession();
                this.loginInterval = setInterval(async () => {
                    console.log("-----------   Setinterval Called -------------->>>>>>>>>>>>>")
                    this.callGetSession();
                }, 5000);
            } catch (e) {
                console.log(e);
            } */
        });
    }

    changehandle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ error_email: "Please enter email!" });
            return false;
        } else {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(this.state.email).toLowerCase())) {
                this.setState({ error_email: "Please enter a valid email!" });
                return false;
            }
            this.setState({ error_email: "" });
        }

        if (!this.state.password) {
            this.setState({ error_password: "Please enter password!" });
            return false;
        }

        this.setState({ loader: true });
        let resultLogin = await ownerCommongLogin(this.state.email, this.state.password);
        if (resultLogin && resultLogin.status === 200) {
            localStorage.setItem("alias",resultLogin?.user)
            if (resultLogin.email_verified === true && resultLogin.mfa_enabled === false) {
                //await setCookies(resultLogin.cookies_list);
                console.log("Loged In Successfully.....");
                this.redirectOnSuccess(resultLogin.email);
            } else if (resultLogin.email_verified === false) {
                this.setState({ loader: false });
                toast.error("Email not verified! Please verify your email first.");
            } else if (resultLogin.mfa_enabled === true) {
                this.setState({
                    isMFAEnabled: true,
                    loader: false,
                    cookiesList: resultLogin.cookies_list
                })
                return false;
            } else {
                this.setState({ loader: false });
                toast.error("Something went wrong, please try again after some time!");
            }
        } else {
            console.log("Loged In Failed.....");
            this.setState({ loader: false });
            toast.error(resultLogin.message);
        }
        return false;
    }
    
    /* MFA functions start */

    verifyMFA = async () => {
        this.setState({
            loader: true
        });
        let emailValue = this.state.email ? this.state.email : document.getElementById("email").value;
        let mfaOtp = this.state.mfa_otp ? this.state.mfa_otp : document.getElementById("mfa_otp").value;
        const verifyMFA = await MFAVerify(emailValue, mfaOtp);
        if(verifyMFA.status === 200) {
           // await setCookies(this.state.cookiesList);
            this.redirectOnSuccess(emailValue);
        } else {
            this.setState({
                loader: false,
                error_mfa_otp: verifyMFA.message ? verifyMFA.message : 'Something went wrong, please try again after some time!'
            });
        }
    }

    /* MFA functions End */

    render() {
        return (
            <>
                <ToastContainer autoClose={5000} />
                <div className="login-container loginBG">
                    <div className="loginPage">
                        <div className="container">
                            <div className="login-header">
                                <img className="loginHeaderLogo" src={require("../../assets/images/appypie-livechat.svg")} alt="logo" />
                            </div>
                        </div>
                        <div className="container">
                            {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                            {this.state.error_data ? (<div className="loginBody pt-5">
                                <div className="login-description signup-description veryfy-your-mail-description">
                                    <h5>Error</h5>
                                </div>
                                <div id="agent_login">
                                    <p style={{ textAlign: "center" }}>{this.state.error_data}</p>
                                </div>
                            </div>) : (<div className="loginBody pt-5">
                                <div className="login-description signup-description veryfy-your-mail-description">
                                    <h5>Login to chat with your visitors and customers</h5>
                                </div>
                                <div id="agent_login">
                                    <div className="login-body">
                                        <div className="veryfy-your-mail-body">
                                            <div className="veryfy-your-mail-form veryfy-your-mail-form-cutome">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <TextField className="form-control w-100" variant="outlined" error={this.state.error_email ? true : false} helperText={this.state.error_email ? this.state.error_email : ""} placeholder="Enter Email" label="Email" id="email" name="email" value={this.state.email} onChange={this.changehandle} />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextField type="password" className="form-control w-100" variant="outlined" error={this.state.error_password ? true : false} helperText={this.state.error_password ? this.state.error_password : ""} placeholder="Enter Password" label="Password" id="password" name="password" value={this.state.password} onChange={this.changehandle} />
                                                    </div>
                                                    {this.state.error && (<div className="text-center pt-8">{this.state.error}</div>)}
                                                    <div className="text-center pt-8">
                                                        <button type="submit" className="btn-blue white-text btn-radius  px-10 py-4">Login</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}

                            {/* To show Masking div start from here */}
                            {this.state.isMFAEnabled && (<div id="shadowMasking" className="popupShadow" style={{ display: 'block'}}></div>)}
                            {/* To show Masking div start end here */}

                            {/* MFA Screen start */}
                            {this.state.isMFAEnabled && (<div className="popupInstallLivechat popupLivechat">
                                <div className="popupHeader py-4 px-5 k-flex align-items-center">
                                    <h6 className="card-title mb-0 white-text">Two step verification</h6>
                                    <span className="close-popup ml-auto" onClick={() => this.setState({ isMFAEnabled: false })} >
                                        <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                                            <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                                            <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                                <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                                <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <div className="popupBody pb-5 px-5">
                                    <div className="py-5 feilds-instruction">
                                        <h6 className="card-title mb-0">Enter Google Authenticater OTP</h6>
                                        <TextField type="password" placeholder="*********" className="form-control w-100" variant="outlined" error={this.state.error_mfa_otp ? true : false} helperText={this.state.error_mfa_otp ? this.state.error_mfa_otp : ""} label="OTP" id="mfa_otp" name="mfa_otp" value={this.state.mfa_otp} onChange={this.changehandle} />
                                    </div>
                                    <div>
                                        <button className="btnBlueSettings" onClick={this.verifyMFA}>Verify</button>
                                        &nbsp;
                                        <button className="btnWhiteSettings mr-2" onClick={() => this.setState({ isMFAEnabled: false })}>Cancel</button>
                                    </div>
                                </div>
                            </div>)}
                            {/* MFA Screen End */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Authorize;